import { add, format } from "date-fns";
import { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { REQUIRED_FIELD_MESSAGE } from "../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../app/types";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../components/FormField/FormField";
import formFieldStyles from "../../../../../components/FormField/FormField.module.scss";
import Label from "../../../../../components/FormField/Label/Label";
import Textarea from "../../../../../components/FormField/Textarea/Textarea";
import Popover from "../../../../../components/Popover/Popover";
import Typography from "../../../../../components/Typography/Typography";
import { IEmbedSingleUseDetail } from "../../../../../modules/embedSingleUseSlice";
import { isValidEmail } from "../../../../../utils/isValidEmail/isValidEmail";
import { formId } from "../SendEmbedEmailNotificationModal";
import styles from "./SendEmbedEmailNotificationForm.module.scss";

export type Inputs = {
  to: string;
  subject: string;
  expiresOn: string;
  time: IFieldOption;
  message1: string;
  message2: string;
};

interface ISendEmbedEmailNotificationForm {
  userFirstName: string;
  detail: IEmbedSingleUseDetail;
  handleSend: (data: Inputs) => void;
}

/**
 * Send embed email notification form
 * @param userFirstName The user's first name to be used in the email
 * @param detail        The embed detail
 * @param handleSend    Function to handle the form submission
 * @returns JSX.Element
 */
function SendEmbedEmailNotificationForm({
  userFirstName,
  detail,
  handleSend,
}: ISendEmbedEmailNotificationForm): JSX.Element {
  // Add the embed lifespan days to the current date to get the default expiry date
  const defaultDateStr = format(add(new Date(), { days: detail.embedLifespanDays }), "yyyy/MM/dd");

  // The date and time strings to render in the dummy message section
  const [dateStr, setDateStr] = useState<string>("");
  const [timeStr, setTimeStr] = useState<string>("");

  // Form hook
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      subject: detail.subject,
      expiresOn: defaultDateStr,
      time: { value: detail.embedExpiryTime, label: detail.embedExpiryTime }, // Use IFieldOption for time picker type field
      message1: detail.defaultText,
      message2: `Kind regards\n${userFirstName}`,
    }, // Set the default value for the second message part
  });

  // Set the default date and time strings when the detail is fetched
  useEffect(() => {
    if (detail) {
      setDateStr(format(new Date(defaultDateStr), "dd/MM/yyyy"));
      setTimeStr(detail.embedExpiryTime);
    }
  }, [detail, defaultDateStr]);

  // Set the date and time strings to be rendered in the dummy message section
  // when the form values change
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "expiresOn") {
        const date = new Date(value.expiresOn as string);
        setDateStr(date ? format(date, "dd/MM/yyyy") : "");
      }

      if (name === "time") {
        setTimeStr(value.time?.value as string);
      }
    });

    return () => subscription.unsubscribe(); // Destroy subscription on unmount
  }, [watch]);

  return (
    <form id={formId} onSubmit={handleSubmit(handleSend)}>
      <Div spacing={{ pb: 5 }}>
        <FormField
          name="to"
          label="To"
          register={register}
          validate={(value: string) => {
            if (!isValidEmail(value)) {
              return "Email is invalid";
            }
          }}
          error={errors.to}
          fullWidth
          required
        />
      </Div>
      <Div spacing={{ pb: 6 }}>
        <FormField name="subject" label="Subject" register={register} error={errors.subject} fullWidth required />
      </Div>
      <Div
        display={{ base: "flex" }}
        alignItems={{ base: "center" }}
        className={styles.expiresOnWrapper}
        spacing={{ pb: 6 }}
      >
        <Label htmlFor="expiresOn">Expires on</Label>
        <FormField
          name="expiresOn"
          register={register}
          type={cFormFieldType.DatePicker}
          error={errors.expiresOn}
          minDate={new Date()}
          required
        />
        <Label htmlFor="time">at</Label>
        <FormField
          name="time"
          register={register}
          type={cFormFieldType.TimePicker}
          control={control}
          error={errors.time as FieldError}
          required
        />
      </Div>
      <Label htmlFor="message1">Message</Label>
      <Div className={styles.messageArea}>
        <Textarea
          fieldProps={{ ...register("message1") }}
          fieldClx={styles.messageTextarea}
          name="message1"
          disabled={false}
          placeholder="Enter your additional message here"
          testId="message1-field"
          autoResize
          fullWidth
        />
        <br />

        <Popover // Create the popover and button
          variant="tooltip"
          popoverPlacement={EPopoverPlacement.Top}
          width="auto"
          popoverContents="System generated text cannot be edited"
          divProps={{
            disabledTooltip: (
              <>
                <Typography className={styles.staticMessage}>
                  Please click <span className={styles.dummyLink}>here</span> to begin. Please note that you will need
                  to complete the process before {dateStr} {timeStr} (UTC+02:00).
                </Typography>
                <br />
              </>
            ),
          }}
        />
        <Textarea
          fieldProps={{ ...register("message2", { required: REQUIRED_FIELD_MESSAGE }) }}
          fieldClx={styles.messageTextarea}
          name="message2"
          disabled={false}
          testId="message2-field"
          autoResize
          fullWidth
        />
      </Div>
      {errors.message1?.message && ( //  If there is an error on message1, display it
        <Div className={formFieldStyles.errorTextWrapper}>
          <Div className={formFieldStyles.error}>{errors.message1.message}</Div>
        </Div>
      )}
      {errors.message2?.message && ( //  If there is an error on message2, display it
        <Div className={formFieldStyles.errorTextWrapper}>
          <Div className={formFieldStyles.error}>{errors.message2.message}</Div>
        </Div>
      )}
    </form>
  );
}

export default SendEmbedEmailNotificationForm;
