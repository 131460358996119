import { isEqual } from "lodash";
import { Control, UseFormRegister, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import Div from "../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../components/FormField/FormField";
import { ITableCardColumn } from "../../components/TableCard/TableCard";
import { TAdvancedFilter } from "../../modules/analyticsSlice";
import ColumnActionWrapper from "../../pages/AnalyticsContainer/Analytics/CustomExportContainer/CustomExport/ColumnActionWrapper/ColumnActionWrapper";

interface IAdvancedFiltersColumns {
  register: UseFormRegister<any>;
}

/**
 * Map data table rows into expected format
 * @param register The register function from react-hook-form
 * @returns ITableCardColumn[]
 */
export const advancedFiltersColumns = ({ register }: IAdvancedFiltersColumns): ITableCardColumn[] => {
  return [
    {
      heading: "Export Column Name",
      testId: "advanced-filter-column-name",
      width: "35%",
    },
    {
      heading: "Include?",
      columnAction: (
        <ColumnActionWrapper>
          <FormField
            type={cFormFieldType.Checkbox}
            name="selectAllAdvancedInclude"
            register={register}
            options={[{ label: "All", value: "allAdvancedInclude" }]}
          />
        </ColumnActionWrapper>
      ),
      testId: "advanced-filter-column-include",
      width: "15%",
    },
    {
      heading: "Filter?",
      columnAction: (
        <ColumnActionWrapper>
          <FormField
            type={cFormFieldType.Checkbox}
            name="selectAllAdvancedFilter"
            register={register}
            options={[{ label: "All", value: "allAdvancedFilter" }]}
          />
        </ColumnActionWrapper>
      ),
      testId: "advanced-filter-column-filter",
      width: "15%",
    },
    {
      heading: "Filter on",
      testId: "advanced-filter-column-controls",
      width: "35%",
    },
  ];
};

// Interface for creating advanced filter rows
interface ICreateAdvancedFiltersRows {
  advancedFilters: TAdvancedFilter[];
  defaultValues: any;
  advancedFilterVisibility: { [key: string]: boolean };
  register: UseFormRegister<any>;
  control: Control<any, any>;
}

/**
 * Create the advanced filter rows
 * @param advancedFilters          The advanced filters
 * @param defaultValues            The form default values
 * @param advancedFilterVisibility Advanced filter visibility array state
 * @param register                 The register function
 * @param control                  The control function
 * @returns React.ReactNode[]
 */
export const createAdvancedFiltersRows = ({
  advancedFilters,
  defaultValues,
  advancedFilterVisibility,
  register,
  control,
}: ICreateAdvancedFiltersRows) => {
  return advancedFilters?.map((filter: TAdvancedFilter) => {
    const { name, description } = "text" in filter ? filter.text : filter.list;
    const include = defaultValues.advanced[name]?.include;
    const isFilter = advancedFilterVisibility[name];
    const value = defaultValues.advanced[name]?.value;
    const options: IFieldOption[] =
      "list" in filter ? filter.list.options.map((option) => ({ label: option, value: option })) : [];

    return [
      <ColumnActionWrapper key={`${name}-label`}>{description}</ColumnActionWrapper>,
      <ColumnActionWrapper key={`${name}-include`}>
        <FormField
          fullWidth
          type={cFormFieldType.Checkbox}
          name={`advanced.${name}.include`}
          register={register}
          options={[{ label: "", value: "" }]}
          defaultValue={include?.toString()}
        />
      </ColumnActionWrapper>,
      <ColumnActionWrapper key={`${name}-filter`}>
        <FormField
          fullWidth
          type={cFormFieldType.Checkbox}
          name={`advanced.${name}.filter`}
          register={register}
          options={[{ label: "", value: "" }]}
          defaultValue={isFilter?.toString()}
        />
      </ColumnActionWrapper>,
      <Div key={`${name}-control`} display={{ base: isFilter ? "flex" : "none" }} alignItems={{ base: "center" }}>
        {"list" in filter ? (
          <FormField
            type={cFormFieldType.Select}
            name={`advanced.${name}.value`}
            control={control}
            register={register}
            options={options}
            defaultValue={value || ""}
          />
        ) : (
          <FormField
            type={cFormFieldType.Text}
            fullWidth
            name={`advanced.${name}.value`}
            register={register}
            defaultValue={(value as string) || ""}
          />
        )}
      </Div>,
    ];
  });
};

// Interface for hasFormChanged function
interface IFormHasChanged {
  defaultValues: any;
  currentValues: any;
}

/**
 * Returns true if default values are different to the current form values
 * which determines if the form values have changed
 * @param defaultValues The default form values
 * @param currentValues The current form values
 * @returns boolean
 */
export function hasFormChanged({ defaultValues, currentValues }: IFormHasChanged): boolean {
  return !isEqual(defaultValues, currentValues);
}

// Interface for handleSelectAll function
interface IHandleSelectAll {
  fieldName: string;
  selectAllValue: string | boolean;
  itemIds?: number[];
  setValue: UseFormSetValue<any>;
}

/**
 * Handles the "Select all" functionality for the owners and stages filters
 * ie. when the "All" checkbox is checked, all the options are selected
 * otherwise, all the options are deselected
 * @param fieldName      The field name to update
 * @param selectAllValue The value of the "All" checkbox
 * @param itemIds        The item ids to collate and select
 * @param setValue       The setValue function from react-hook-form
 */
export function handleSelectAll({ fieldName, selectAllValue, itemIds, setValue }: IHandleSelectAll) {
  if (selectAllValue !== false && itemIds) {
    setValue(fieldName, itemIds.map(String));
  } else {
    setValue(fieldName, []);
  }
}

// Interface for handleSelectAllAdvanced function
interface IHandleSelectAllAdvanced {
  type: "include" | "filter";
  value: string;
  setValue: UseFormSetValue<any>;
  advancedFilters: TAdvancedFilter[];
}

/**
 * Helper function to handle "select all" for advanced filters
 * @param type            The type of select all to handle
 * @param value           The value of the "All" checkbox
 * @param setValue        The setValue function from react-hook-form
 * @param advancedFilters The advanced filters
 * @returns void
 */
export function handleSelectAllAdvanced({ type, value, setValue, advancedFilters }: IHandleSelectAllAdvanced): void {
  advancedFilters.forEach((filter: TAdvancedFilter) => {
    const filterName = "text" in filter ? filter.text!.name : filter.list!.name;
    // Type assertion needed here due to dynamic key access
    setValue(
      `advanced.${filterName}.${type}` as any,
      value === `allAdvanced${type.charAt(0).toUpperCase() + type.slice(1)}`,
    );
  });
}

// Interface for handleSaveAsTemplateClick function
interface IHandleSaveAsTemplateClick {
  trigger: UseFormTrigger<any>;
  setIsSaveAsTemplateModalOpen: (isOpen: boolean) => void;
}

/**
 * Validates the form and, if valid, sets the form submit action type submit action type and opens the Save as template modal
 * @param trigger                      The form trigger function from react-hook-form
 * @param setIsSaveAsTemplateModalOpen The function to set the save as template modal open state
 */
export async function handleSaveAsTemplateClick({ trigger, setIsSaveAsTemplateModalOpen }: IHandleSaveAsTemplateClick) {
  const isValid = await trigger();

  if (isValid) {
    setIsSaveAsTemplateModalOpen(true);
  }
}
