import { createAsyncThunk, createSlice, ListenerEffectAPI, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import api from "../app/api";
import {
  analyticsUrl,
  documentTypeCustomExportsUrl,
  documentTypeFiltersUrl,
  documentTypeReportRunUrl,
  documentTypeReportTemplateDeleteUrl,
  documentTypeReportTemplateSaveUrl,
  graphReportFiltersUrl,
  graphReportRunUrl,
  matterTypeCustomExportsUrl,
  matterTypeFiltersUrl,
  matterTypeReportRunUrl,
  matterTypeReportTemplateDeleteUrl,
  matterTypeReportTemplateSaveUrl,
  packagedReportFiltersUrl,
  packagedReportRunUrl,
} from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { IFieldOption } from "../components/FormField/FormField";
import { isNotFoundError } from "../utils/errors/errors";
import { postDocumentStages } from "./documentStagesSlice";
import { postDocumentStates } from "./documentStatesSlice";
import { postDocumentTypes } from "./documentTypesSlice";
import { postUsersByID } from "./usersSlice";

/**
 * Describe the optional filters
 */
interface IOptionalFilters {
  documentTypeIDs: number[];
  descriptionContains: {
    description: string | null;
    caseSensitive: boolean;
  };
  documentDataContains: {
    documentData: string | null;
    caseSensitive: boolean;
  };
  stateIDs: number[];
  stageIDs: number[];
  ownerIDs: number[];
}

/**
 * Describe a packaged report filter set
 */
export type IPackagedReportFilters = Partial<
  Record<
    EReportCodes,
    {
      date: string;
      documentClass: "draft" | "file" | null;
      optionalFilters: IOptionalFilters;
      dateFilter: "Original Creation Date" | "Last Modified Date";
      startDate: string;
      endDate: string;
    }
  >
>;

/**
 * Possible document classes
 */
export enum EDocumentClasses {
  Draft = "draft",
  File = "file",
  All = "all",
}

/**
 * Possible dateFilter options
 */
export enum EDateFilterOptions {
  OriginalCreationDate = "Original Creation Date",
  LastModifiedDate = "Last Modified Date",
}

/**
 * Possible file formats for report exports
 */
export enum EExportFileFormat {
  XML = "xml",
  XLSX = "xlsx",
}

/**
 * Possible report codes
 */
export enum EReportCodes {
  Osr008 = "OSR008",
  Osr002 = "OSR002",
  Osr001 = "OSR001",
  Osr009 = "OSR009",
  Osr006 = "OSR006",
  Osr003 = "OSR003",
  Osr004 = "OSR004",
  Osr005 = "OSR005",
  Osr010 = "OSR010",
  Osr007 = "OSR007",
}

interface IReportBase {
  name: string;
  code: string; // Unified property for handling both id and code
}

/**
 * Describe a packaged report type
 */
export interface IPackagedReport extends IReportBase {
  code: EReportCodes;
}

/**
 * Describe a document analytics type
 */
export interface IDocumentAnalytics extends IReportBase {
  id: number;
  access: "enabled" | "inaccessible";
}

/**
 * Describe the custom exports type
 */
export interface ICustomExport {
  id: number;
  name: string;
  documentTypeID: number;
  configID: number | null;
  handleDeleteReportTemplate: (configID: number) => void;
}

/**
 * Types of matter custom exports
 */
export enum EMatterCustomExportType {
  Data = "D",
  Management = "M",
}

/**
 * Describe matter custom exports
 */
export interface IMatterCustomExport {
  id: number;
  name: string;
  matterTypeID: number;
  configID: number | null;
  type: EMatterCustomExportType;
}

/**
 * Describe packaged reports
 */
export interface IPackagedReports {
  entries: IPackagedReport[];
  filters: IPackagedReportFilters;
}

/**
 * Describe custom exports
 */
export interface ICustomExports {
  entries: ICustomExport[];
  filters: ICustomExportFilters;
}

/**
 * Describe textField for custom exports
 */
export interface ITextField {
  value: string | null;
  caseSensitive: boolean;
}

/**
 * Describe common filter options
 */
export interface IFilterOptions<T> {
  options: Array<T>;
  value: T;
}

/**
 * Describe advanced document type filters for text fields
 */
export interface IAdvancedTextFilter {
  type: "text";
  id: number;
  name: string;
  description: string;
  value: string | null;
  include: boolean;
  filter: boolean;
}

/**
 * Describe advanced document type filters for list options
 */
export interface IAdvancedListFilter {
  type: "list";
  id: number;
  name: string;
  description: string;
  value: string | null;
  include: boolean;
  filter: boolean;
  options: string[];
}

/**
 * Combined type for advanced document type filters
 */
export type TAdvancedFilter = { text: IAdvancedTextFilter } | { list: IAdvancedListFilter };

/**
 * Describe a document type report filter set
 */
export interface ICustomExportFilters {
  documentTypeID: number;
  configName?: string;
  documentClass: IFilterOptions<EDocumentClasses>;
  startDate: string;
  endDate: string;
  dateFilter: IFilterOptions<EDateFilterOptions>;
  format: IFilterOptions<EExportFileFormat>;
  optionalFilters: {
    descriptionContains: ITextField;
    documentDataContains: ITextField;
    includeTerminated: boolean;
    states: { ids: number[]; value: string[] };
    stages: { ids: number[]; value: string[] };
    owners: { ids: number[]; value: string[] };
    advanced: TAdvancedFilter[];
  };
}

/**
 * Describe a document type report filters to dispatch as payload
 */
export interface ICustomExportFiltersApiPayload {
  documentTypeID: number;
  documentClass: EDocumentClasses;
  startDate: string;
  endDate: string;
  dateFilter: EDateFilterOptions;
  format: EExportFileFormat;
  optionalFilters: {
    descriptionContains: ITextField;
    documentDataContains: ITextField;
    includeTerminated: boolean;
    states: number[];
    stages: number[];
    owners: number[];
    advanced: {
      id: number;
      name: string;
      value: string | null;
      include: boolean;
      filter: boolean;
    }[];
  };
  templateName?: string;
}

/**
 * Describe matter type report filters to dispatch as payload
 */
export interface IMatterCustomExportFiltersApiPayload {
  matterTypeID: number;
  startDate: string;
  endDate: string;
  dateFilter: EDateFilterOptions;
  type: EMatterCustomExportType;
  optionalFilters: {
    descriptionContains: ITextField;
    owners: number[];
    advanced: {
      id: number;
      name: string;
      value: string | null;
      include: boolean;
      filter: boolean;
    }[];
  };
  templateName?: string;
}

/**
 * Describe custom exports state
 */
export interface ICustomExportsState {
  entries: Record<number, ICustomExport>; // record for custom exports
  status: cStatusType; // API call status
  error?: string;
}

/**
 * Describe document analytics state
 */
export interface IDocumentAnalyticsState {
  entries: Record<number, IDocumentAnalytics>; // records
  status: cStatusType; // API call status
  error?: string;
  customExports: ICustomExport[];
  customExportsStatus: cStatusType; // API call status for custom exports
  customExportsError?: string; // Error for custom exports
  filters: ICustomExportFilters | null;
  filtersError?: string;
  configID: number | null;
  documentTypeID: number | null;
  filtersLoadingStatus: cStatusType; // API call status for filters
  runStatus: cStatusType; // API call status for running the custome export
  runError?: string; // Error for running the custom export
  saveStatus: cStatusType; // API call status for saving the custom export
  saveError?: string; // Error for saving the custom export
  deleteStatus?: cStatusType; // API call status for deleting the custom export
  deleteError?: string; // Error for deleting the custom export
}

/**
 * Describe matter analytics state
 */
interface IMatterAnalyticsState {
  entries: Record<number, IDocumentAnalytics>; // records
  status: cStatusType; // API call status
  error?: string;
  matterTypeID: number | null;
  customExports: IMatterCustomExport[];
  customExportsStatus: cStatusType; // API call status for custom exports
  customExportsError?: string; // Error for custom exports
  filters: ICustomExportFilters | null;
  filtersError?: string;
  configID: number | null;
  filtersLoadingStatus: cStatusType; // API call status for filters
  runStatus: cStatusType; // API call status for running the custom export
  runError?: string; // Error for running the custom export
  saveStatus: cStatusType; // API call status for saving the custom export
  saveError?: string; // Error for saving the custom export
  deleteStatus?: cStatusType; // API call status for deleting the custom export
  deleteError?: string; // Error for deleting the custom export
}

/**
 * Interface for the packaged reports state
 */
export interface IPackagedReportsState {
  entries: Record<number, IPackagedReport>; // records
  status: cStatusType; // API call status
  error?: string;
  filtersLoadingStatus: cStatusType; // API call status for filters
  filtersError?: string; // Error for filters
  filters: IPackagedReportFilters | null;
  runStatus: cStatusType; // API call status for running the packaged report
  runError?: string; // Error for running the packaged report
}

/**
 * Describe a graph
 */
export interface IGraph {
  code: EReportCodes;
  name: string;
  access: cAccessType;
}

/**
 * Describe the graph actions
 */
export enum EGraphActions {
  ByUser = "byUser",
  ByType = "byType",
  ByLevel = "byLevel",
  Default = "default",
}

/**
 * Describe the graph field types
 */
export enum EGraphFieldTypes {
  Date = "date",
  List = "list",
}

/**
 * Describe a graph field
 */
export interface IGraphField {
  type: EGraphFieldTypes;
  value: string;
  label: string;
  name: string;
  options?: IFieldOption[];
}

/**
 * Describe the graph filters
 */
interface IGraphsFilters {
  actions: EGraphActions[];
  fields: IGraphField[];
}

/**
 * Describe the series data
 */
export interface ISeriesData {
  name: string;
  y: number;
}

/**
 * Describe the drilldown series
 */
interface IDrilldownSeries {
  name: string;
  id: string;
  data: ISeriesData[];
}

/**
 * Describe graph data
 */
export interface IGraphData {
  series: {
    data: ISeriesData[];
    title: string;
    subtitle: string;
  };
  drilldown: {
    series: IDrilldownSeries[];
    title: string;
    subtitle: string | null;
  };
}

/**
 * Describe the graphs state
 */
interface IGraphsState {
  entries: Record<number, IGraph>; // records
  filters: IGraphsFilters | null;
  status: cStatusType; // API call status
  error?: string;
  graphData?: IGraphData;
}

/**
 * Interface for the analytics state
 */
export interface IAnalyticsState {
  analyticsInaccessible: boolean;
  packagedReports: IPackagedReportsState;
  documentAnalytics: IDocumentAnalyticsState;
  matterAnalytics: IMatterAnalyticsState;
  graphs: IGraphsState;
}

/**
 * Initial state for the analytics
 */
const initialState: IAnalyticsState = {
  analyticsInaccessible: false,
  packagedReports: {
    entries: {},
    filters: null,
    status: cStatusType.Idle,
    filtersLoadingStatus: cStatusType.Idle,
    runStatus: cStatusType.Idle,
  },
  documentAnalytics: {
    entries: {},
    status: cStatusType.Idle,
    customExports: [],
    customExportsStatus: cStatusType.Idle,
    filters: null,
    filtersLoadingStatus: cStatusType.Idle,
    runStatus: cStatusType.Idle,
    saveStatus: cStatusType.Idle,
    configID: null,
    documentTypeID: null,
  },
  matterAnalytics: {
    entries: {},
    status: cStatusType.Idle,
    matterTypeID: null,
    customExports: [],
    customExportsStatus: cStatusType.Idle,
    filters: null,
    configID: null,
    filtersLoadingStatus: cStatusType.Idle,
    runStatus: cStatusType.Idle,
    saveStatus: cStatusType.Idle,
  },
  graphs: {
    entries: {},
    status: cStatusType.Idle,
    filters: null,
  },
};

/**
 * Thunk for fetching analytics
 */
export const fetchAnalyticsData = createAsyncThunk(
  "analytics/fetchAnalyticsData",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = analyticsUrl;
      const response = await api({ endpoint, dispatch, method: "GET" });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching packaged report filters
 */
export const postPackagedReportFilters = createAsyncThunk(
  "analytics/postPackagedReportFilters",
  async (code: EReportCodes, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = packagedReportFiltersUrl;
      const response = await api({ endpoint, dispatch, body: { code } });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for running a packaged report
 */
export const postRunPackagedReport = createAsyncThunk(
  "analytics/postRunPackagedReport",
  async (payload: IPackagedReportFilters, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = packagedReportRunUrl;
      const response = await api({ endpoint, dispatch, body: payload });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching custom exports for document analytics
 */
export const postDocumentTypeCustomExports = createAsyncThunk(
  "analytics/postDocumentTypeCustomExports",
  async (documentTypeID: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentTypeCustomExportsUrl;
      const response = await api({ endpoint, dispatch, body: { documentTypeID } });

      return { documentTypeID, customExports: response.data.customExports };
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching document type report filters
 */
export const postDocumentTypeReportFilters = createAsyncThunk(
  "analytics/postDocumentTypeReportFilters",
  async (
    { documentTypeID, configID }: { documentTypeID: number; configID: number | null },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = documentTypeFiltersUrl;
      const response = await api({ endpoint, dispatch, body: { configID, documentTypeID } });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for running a custom export
 */
export const postRunDocumentTypeReport = createAsyncThunk(
  "analytics/postRunDocumentTypeReport",
  async (payload: ICustomExportFiltersApiPayload, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentTypeReportRunUrl;
      const response = await api({ endpoint, dispatch, body: payload });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for saving a document type report custom export
 */
export const postDocumentTypeReportTemplateSave = createAsyncThunk(
  "analytics/postDocumentTypeReportTemplateSave",
  async (payload: ICustomExportFiltersApiPayload & { templateName: string }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentTypeReportTemplateSaveUrl;
      const response = await api({ endpoint, dispatch, body: payload });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching custom exports for matter analytics
 */
export const postMatterTypeCustomExports = createAsyncThunk(
  "analytics/postMatterTypeCustomExports",
  async (matterTypeID: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypeCustomExportsUrl;
      const response = await api({ endpoint, dispatch, body: { matterTypeID } });

      return { matterTypeID, customExports: response.data.customExports };
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a document type report custom export
 */
export const postDeleteDocumentTypeReportCustomExport = createAsyncThunk(
  "analytics/postDeleteDocumentTypeReportCustomExport",
  async (configID: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentTypeReportTemplateDeleteUrl;
      await api({ endpoint, dispatch, body: { configID } });

      return configID;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Interface for the postMatterTypeCustomExports payload
 */
interface IPostMatterTypeReportFiltersPayload {
  matterTypeID: number;
  configID: number | null;
  type: EMatterCustomExportType;
}

/**
 * Thunk for fetching matter type report filters
 */
export const postMatterTypeReportFilters = createAsyncThunk(
  "analytics/postMatterTypeReportFilters",
  async ({ matterTypeID, configID, type }: IPostMatterTypeReportFiltersPayload, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypeFiltersUrl;
      const response = await api({ endpoint, dispatch, body: { configID, matterTypeID, type } });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for running a matter custom export
 */
export const postRunMatterTypeReport = createAsyncThunk(
  "analytics/postRunMatterTypeReport",
  async (payload: IMatterCustomExportFiltersApiPayload, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypeReportRunUrl;
      const response = await api({ endpoint, dispatch, body: payload });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for saving a matter type report custom export
 */
export const postMatterTypeReportTemplateSave = createAsyncThunk(
  "analytics/postMatterTypeReportTemplateSave",
  async (payload: IMatterCustomExportFiltersApiPayload & { templateName: string }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypeReportTemplateSaveUrl;
      const response = await api({ endpoint, dispatch, body: payload });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Interface for the postDeleteMatterTypeReportCustomExport payload
 */
interface IPostDeleteMatterTypeReportCustomExport {
  matterTypeID: number;
  configID: number;
}

/**
 * Thunk for deleting a matter type report custom export
 */
export const postDeleteMatterTypeReportCustomExport = createAsyncThunk(
  "analytics/postDeleteMatterTypeReportCustomExport",
  async ({ matterTypeID, configID }: IPostDeleteMatterTypeReportCustomExport, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterTypeReportTemplateDeleteUrl;
      await api({ endpoint, dispatch, body: { matterTypeID, configID } });

      return configID;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Interface for the postGraphReportFilters response payload
 */
type TGraphReportFieldReturn =
  | {
      date: {
        name: string;
        label: string;
        value: string;
      };
    }
  | {
      list: {
        name: string;
        label: string | number;
        value: string | number;
        options: string[] | number[];
      };
    };

/**
 * Thunk for fetching graph report filters
 */
export const postGraphReportFilters = createAsyncThunk(
  "analytics/postGraphReportFilters",
  async (code: EReportCodes, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = graphReportFiltersUrl;
      const response = await api({ endpoint, dispatch, body: { code } });

      const fields = response.data.fields.map((field: TGraphReportFieldReturn) => {
        if ("date" in field) {
          return {
            type: EGraphFieldTypes.Date,
            ...field.date,
          };
        } else if ("list" in field) {
          return {
            type: EGraphFieldTypes.List,
            ...field.list,
          };
        }
      });

      return { actions: response.data.actions, fields };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for running a graph report
 */
export const postRunGraphReport = createAsyncThunk(
  "analytics/postRunGraphReport",
  async (payload: { code: EReportCodes; action: EGraphActions; filters: any }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = graphReportRunUrl;
      const response = await api({ endpoint, dispatch, body: payload });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch the packaged report filter items after the filter IDs have been updated
 * @param action           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchPackagedReportFiltersEffect = (
  action: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;

  cancelActiveListeners();

  const state = getState() as RootState;
  const {
    analytics: {
      packagedReports: { filters },
    },
  } = state;

  /**
   * Get the filter IDs
   * @param key The key to get the filter IDs for
   * @returns The filter IDs
   */
  function getFilterIds(key: string): number[] {
    const selectedCode = Object.keys(action.payload as Object)[0];

    return selectedCode !== null && filters?.[selectedCode as EReportCodes]
      ? (filters?.[selectedCode as EReportCodes]?.optionalFilters[key as keyof IOptionalFilters] as number[])
      : [];
  }

  const documentTypeIds = getFilterIds("documentTypeIDs");
  const documentOwnerIds = getFilterIds("ownerIDs");
  const documentStateIds = getFilterIds("stateIDs");
  const documentStageIds = getFilterIds("stageIDs");

  if (documentTypeIds.length > 0) {
    dispatch(postDocumentTypes({ ids: documentTypeIds }));
  }

  if (documentOwnerIds.length > 0) {
    dispatch(postUsersByID({ ids: documentOwnerIds }));
  }

  if (documentStateIds.length > 0) {
    dispatch(postDocumentStates(documentStateIds));
  }

  if (documentStageIds.length > 0) {
    dispatch(postDocumentStages(documentStageIds));
  }
};

/**
 * Fetch the document type report filter items after the config IDs have been updated
 * @param action           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchDocumentAnalyticsFiltersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;

  cancelActiveListeners();

  const state = getState() as RootState;
  const {
    analytics: {
      documentAnalytics: { filters },
    },
  } = state;

  /**
   * Get the filter IDs for specific filter keys
   * @param key The key to get the filter IDs for
   * @returns The filter IDs
   */
  function getFilterIds(key: keyof ICustomExportFilters["optionalFilters"]): number[] {
    const filter = filters?.optionalFilters?.[key];

    // If the filter is not present, return an empty array
    if (!filter) return [];

    // If the filter is an object with an ids property, return the ids
    if (typeof filter === "object" && "ids" in filter) {
      return filter.ids.map(Number);
    } else {
      throw new Error("Not an ids filter");
    }
  }

  // Get the IDs for owners, states, and stages using the new helper function
  try {
    const documentOwnerIds = getFilterIds("owners");
    const documentStateIds = getFilterIds("states");
    const documentStageIds = getFilterIds("stages");

    // Dispatch the appropriate actions using the new IDs
    if (documentOwnerIds.length > 0) {
      dispatch(postUsersByID({ ids: documentOwnerIds }));
    }

    if (documentStateIds.length > 0) {
      dispatch(postDocumentStates(documentStateIds));
    }

    if (documentStageIds.length > 0) {
      dispatch(postDocumentStages(documentStageIds));
    }
  } catch (e: any) {
    console.error(e.message);
  }
};

/**
 * Fetch the matter type report filter items after the config IDs have been updated
 * @param action           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchMatterAnalyticsFiltersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;

  cancelActiveListeners();

  const state = getState() as RootState;
  const {
    analytics: {
      matterAnalytics: { filters },
    },
  } = state;

  // Get the IDs for owners, states, and stages using the new helper function
  try {
    const documentOwnerIds = filters?.optionalFilters?.owners.ids.map(Number) || [];

    // Dispatch the appropriate actions using the new IDs
    if (documentOwnerIds.length > 0) {
      dispatch(postUsersByID({ ids: documentOwnerIds }));
    }
  } catch (e: any) {
    console.error(e.message);
  }
};

// Create the analytics slice
export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // Reset the graph filters and data
    resetGraphState: (state) => {
      state.graphs.filters = null;
      state.graphs.graphData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get analytics data
      .addCase(fetchAnalyticsData.pending, (state) => {
        state.packagedReports.status = cStatusType.Loading;
        state.packagedReports.error = undefined;

        state.documentAnalytics.status = cStatusType.Loading;
        state.documentAnalytics.error = undefined;

        state.matterAnalytics.status = cStatusType.Loading;
        state.matterAnalytics.error = undefined;

        state.graphs.status = cStatusType.Loading;
        state.graphs.error = undefined;
      })
      .addCase(fetchAnalyticsData.rejected, (state, action) => {
        state.packagedReports.status = cStatusType.Failed;
        state.packagedReports.error = action.payload as string;

        state.documentAnalytics.status = cStatusType.Failed;
        state.documentAnalytics.error = action.payload as string;

        state.matterAnalytics.status = cStatusType.Failed;
        state.matterAnalytics.error = action.payload as string;

        state.graphs.status = cStatusType.Failed;
        state.graphs.error = action.payload as string;
      })
      .addCase(fetchAnalyticsData.fulfilled, (state, action) => {
        state.packagedReports.status = cStatusType.Idle;
        state.packagedReports.entries = { ...state.packagedReports.entries, ...action.payload.packagedReports };

        state.documentAnalytics.status = cStatusType.Idle;
        state.documentAnalytics.entries = { ...state.documentAnalytics.entries, ...action.payload.documentAnalytics };

        state.matterAnalytics.status = cStatusType.Idle;
        state.matterAnalytics.entries = { ...state.matterAnalytics.entries, ...action.payload.matterAnalytics };

        state.graphs.status = cStatusType.Idle;
        state.graphs.entries = { ...state.graphs.entries, ...action.payload.graphs };
      })
      // Fetch packged report filters
      .addCase(postPackagedReportFilters.pending, (state) => {
        state.packagedReports.filtersLoadingStatus = cStatusType.Loading;
        state.packagedReports.error = undefined;
      })
      .addCase(postPackagedReportFilters.rejected, (state, action) => {
        state.packagedReports.filtersLoadingStatus = cStatusType.Failed;
        state.packagedReports.error = action.payload as string;
      })
      .addCase(postPackagedReportFilters.fulfilled, (state, action) => {
        state.packagedReports.filtersLoadingStatus = cStatusType.Idle;
        state.packagedReports.filters = { ...state.packagedReports.filters, ...action.payload };
      })
      // Fetch document type custom exports
      .addCase(postDocumentTypeCustomExports.pending, (state) => {
        state.documentAnalytics.customExportsStatus = cStatusType.Loading;
        state.documentAnalytics.customExportsError = undefined;
      })
      .addCase(postDocumentTypeCustomExports.fulfilled, (state, action) => {
        state.documentAnalytics.customExportsStatus = cStatusType.Idle;
        state.documentAnalytics.customExports = action.payload.customExports;
      })
      .addCase(postDocumentTypeCustomExports.rejected, (state, action) => {
        state.documentAnalytics.customExportsStatus = cStatusType.Failed;
        state.documentAnalytics.customExportsError = action.payload as string;
      })
      // Fetch document type report filters
      .addCase(postDocumentTypeReportFilters.pending, (state) => {
        state.documentAnalytics.filtersLoadingStatus = cStatusType.Loading;
        state.documentAnalytics.filtersError = undefined;
      })
      .addCase(postDocumentTypeReportFilters.fulfilled, (state, action) => {
        state.documentAnalytics.filtersLoadingStatus = cStatusType.Idle;
        state.documentAnalytics.filters = action.payload;
      })
      .addCase(postDocumentTypeReportFilters.rejected, (state, action) => {
        state.documentAnalytics.filtersLoadingStatus = cStatusType.Failed;
        state.documentAnalytics.filtersError = action.payload as string;
      })
      // Run packaged report
      .addCase(postRunPackagedReport.pending, (state) => {
        state.packagedReports.runStatus = cStatusType.Loading;
        state.packagedReports.runError = undefined;
      })
      .addCase(postRunPackagedReport.rejected, (state, action) => {
        state.packagedReports.runStatus = cStatusType.Failed;
        state.packagedReports.runError = action.payload as string;
      })
      .addCase(postRunPackagedReport.fulfilled, (state) => {
        state.packagedReports.runStatus = cStatusType.Idle;
      })
      // Run Custom Export (Document Type Report)
      .addCase(postRunDocumentTypeReport.pending, (state) => {
        state.documentAnalytics.runStatus = cStatusType.Loading;
        state.documentAnalytics.runError = undefined;
      })
      .addCase(postRunDocumentTypeReport.rejected, (state, action) => {
        state.documentAnalytics.runStatus = cStatusType.Failed;
        state.documentAnalytics.runError = action.payload as string;
      })
      .addCase(postRunDocumentTypeReport.fulfilled, (state) => {
        state.documentAnalytics.runStatus = cStatusType.Idle;
      })
      // Save a document type report
      .addCase(postDocumentTypeReportTemplateSave.pending, (state) => {
        state.documentAnalytics.saveStatus = cStatusType.Loading;
        state.documentAnalytics.saveError = undefined;
      })
      .addCase(postDocumentTypeReportTemplateSave.rejected, (state, action) => {
        state.documentAnalytics.saveStatus = cStatusType.Failed;
        state.documentAnalytics.saveError = action.payload as string;
      })
      .addCase(postDocumentTypeReportTemplateSave.fulfilled, (state) => {
        state.documentAnalytics.saveStatus = cStatusType.Idle;
      })
      // Fetch matter type custom exports
      .addCase(postMatterTypeCustomExports.pending, (state) => {
        state.matterAnalytics.customExportsStatus = cStatusType.Loading;
        state.matterAnalytics.customExportsError = undefined;
      })
      .addCase(postMatterTypeCustomExports.fulfilled, (state, action) => {
        state.matterAnalytics.customExportsStatus = cStatusType.Idle;
        state.matterAnalytics.customExports = action.payload.customExports;
      })
      .addCase(postMatterTypeCustomExports.rejected, (state, action) => {
        state.matterAnalytics.customExportsStatus = cStatusType.Failed;
        state.matterAnalytics.customExportsError = action.payload as string;
      })
      // Fetch matter type report filters
      .addCase(postMatterTypeReportFilters.pending, (state) => {
        state.matterAnalytics.filtersLoadingStatus = cStatusType.Loading;
        state.matterAnalytics.filtersError = undefined;
      })
      .addCase(postMatterTypeReportFilters.fulfilled, (state, action) => {
        state.matterAnalytics.filtersLoadingStatus = cStatusType.Idle;
        state.matterAnalytics.filters = action.payload;
      })
      .addCase(postMatterTypeReportFilters.rejected, (state, action) => {
        state.matterAnalytics.filtersLoadingStatus = cStatusType.Failed;
        state.matterAnalytics.filtersError = action.payload as string;
      })
      // Run Custom Export (Matter Type Report)
      .addCase(postRunMatterTypeReport.pending, (state) => {
        state.matterAnalytics.runStatus = cStatusType.Loading;
        state.matterAnalytics.runError = undefined;
      })
      .addCase(postRunMatterTypeReport.rejected, (state, action) => {
        state.matterAnalytics.runStatus = cStatusType.Failed;
        state.matterAnalytics.runError = action.payload as string;
      })
      .addCase(postRunMatterTypeReport.fulfilled, (state) => {
        state.matterAnalytics.runStatus = cStatusType.Idle;
      })
      // Save a matter type report
      .addCase(postMatterTypeReportTemplateSave.pending, (state) => {
        state.matterAnalytics.saveStatus = cStatusType.Loading;
        state.matterAnalytics.saveError = undefined;
      })
      .addCase(postMatterTypeReportTemplateSave.rejected, (state, action) => {
        state.matterAnalytics.saveStatus = cStatusType.Failed;
        state.matterAnalytics.saveError = action.payload as string;
      })
      .addCase(postMatterTypeReportTemplateSave.fulfilled, (state) => {
        state.matterAnalytics.saveStatus = cStatusType.Idle;
      })
      // Delete document type report custom export
      .addCase(postDeleteDocumentTypeReportCustomExport.pending, (state) => {
        state.documentAnalytics.deleteStatus = cStatusType.Loading;
      })
      .addCase(postDeleteDocumentTypeReportCustomExport.rejected, (state, action) => {
        state.documentAnalytics.deleteStatus = cStatusType.Failed;
        state.documentAnalytics.deleteError = action.payload as string;
      })
      .addCase(postDeleteDocumentTypeReportCustomExport.fulfilled, (state) => {
        state.documentAnalytics.deleteStatus = cStatusType.Idle;
      })
      // Delete matter type report custom export
      .addCase(postDeleteMatterTypeReportCustomExport.pending, (state) => {
        state.matterAnalytics.deleteStatus = cStatusType.Loading;
      })
      .addCase(postDeleteMatterTypeReportCustomExport.rejected, (state, action) => {
        state.matterAnalytics.deleteStatus = cStatusType.Failed;
        state.matterAnalytics.deleteError = action.payload as string;
      })
      .addCase(postDeleteMatterTypeReportCustomExport.fulfilled, (state) => {
        state.matterAnalytics.deleteStatus = cStatusType.Idle;
      })
      // Fetch graph report filters
      .addCase(postGraphReportFilters.pending, (state) => {
        state.graphs.status = cStatusType.Loading;
        state.graphs.error = undefined;
      })
      .addCase(postGraphReportFilters.rejected, (state, action) => {
        state.graphs.status = cStatusType.Failed;
        state.graphs.error = action.payload as string;
      })
      .addCase(postGraphReportFilters.fulfilled, (state, action) => {
        state.graphs.status = cStatusType.Idle;
        state.graphs.filters = action.payload;
      })
      // Run graph report
      .addCase(postRunGraphReport.pending, (state) => {
        state.graphs.status = cStatusType.Loading;
        state.graphs.error = undefined;
      })
      .addCase(postRunGraphReport.rejected, (state, action) => {
        state.graphs.status = cStatusType.Failed;
        state.graphs.error = action.payload as string;
      })
      .addCase(postRunGraphReport.fulfilled, (state, action) => {
        state.graphs.status = cStatusType.Idle;
        state.graphs.graphData = action.payload;
      })
      // If the customer does not have access to analytics, set the flag so that
      // the user is redirected away from the analytics page. This matcher will
      // catch errors where the code is 5000 (not allowed)
      .addMatcher(
        (action) =>
          action.type.startsWith("analytics/") &&
          action.type.endsWith("/rejected") &&
          isNotFoundError(parseInt(action.payload, 10)),
        (state) => {
          if (state.analyticsInaccessible === false) {
            state.analyticsInaccessible = true;
          }
        },
      );
  },
});

export const { resetGraphState } = analyticsSlice.actions;

// Select packaged reports
export const selectAllPackagedReports = (state: RootState) => state.analytics.packagedReports.entries;
export const selectPackagedReportStatus = (state: RootState) => state.analytics.packagedReports.status;
export const selectPackagedReportsError = (state: RootState) => state.analytics.packagedReports.error;

// Select packaged reports filters
export const selectAllPackagedReportFilters = (state: RootState) => state.analytics.packagedReports.filters;
export const selectPackagedReportFiltersStatus = (state: RootState) =>
  state.analytics.packagedReports.filtersLoadingStatus;
export const selectPackagedReportFiltersError = (state: RootState) => state.analytics.packagedReports.filtersError;

// Select packaged reports run
export const selectRunPackagedReportStatus = (state: RootState) => state.analytics.packagedReports.runStatus;
export const selectRunPackagedReportError = (state: RootState) => state.analytics.packagedReports.runError;

// Select document analytics
export const selectAllDocumentAnalytics = (state: RootState) => state.analytics.documentAnalytics.entries;
export const selectDocumentAnalyticsStatus = (state: RootState) => state.analytics.documentAnalytics.status;
export const selectDocumentAnalyticsError = (state: RootState) => state.analytics.documentAnalytics.error;

// Select matter analytics
export const selectAllMatterAnalytics = (state: RootState) => state.analytics.matterAnalytics.entries;
export const selectMatterAnalyticsStatus = (state: RootState) => state.analytics.matterAnalytics.status;
export const selectMatterAnalyticsError = (state: RootState) => state.analytics.matterAnalytics.error;

// Select custom exports
export const selectAllCustomExports = (state: RootState) => state.analytics.documentAnalytics.customExports;
export const selectCustomExportsStatus = (state: RootState) => state.analytics.documentAnalytics.customExportsStatus;
export const selectCustomExportsError = (state: RootState) => state.analytics.documentAnalytics.customExportsError;

// Selectors for document analytics filters
export const selectAllDocumentAnalyticsFilters = (state: RootState) => state.analytics.documentAnalytics.filters;
export const selectDocumentAnalyticsFiltersStatus = (state: RootState) =>
  state.analytics.documentAnalytics.filtersLoadingStatus;
export const selectDocumentAnalyticsFiltersError = (state: RootState) => state.analytics.documentAnalytics.filtersError;

// Select custom export run
export const selectRunDocumentTypeReportStatus = (state: RootState) => state.analytics.documentAnalytics.runStatus;
export const selectRunDocumentTypeReportError = (state: RootState) => state.analytics.documentAnalytics.runError;

// Select document type report custom export save
export const selectDocumentTypeReportTemplateSaveStatus = (state: RootState) =>
  state.analytics.documentAnalytics.saveStatus;
export const selectDocumentTypeReportTemplateSaveError = (state: RootState) =>
  state.analytics.documentAnalytics.saveError;

// Select matter custom exports
export const selectAllMatterCustomExports = (state: RootState) => state.analytics.matterAnalytics.customExports;
export const selectMatterCustomExportsStatus = (state: RootState) =>
  state.analytics.matterAnalytics.customExportsStatus;
export const selectMatterCustomExportsError = (state: RootState) => state.analytics.matterAnalytics.customExportsError;

// Selectors for matter analytics filters
export const selectAllMatterAnalyticsFilters = (state: RootState) => state.analytics.matterAnalytics.filters;
export const selectMatterAnalyticsFiltersStatus = (state: RootState) =>
  state.analytics.matterAnalytics.filtersLoadingStatus;
export const selectMatterAnalyticsFiltersError = (state: RootState) => state.analytics.matterAnalytics.filtersError;

// Select matter custom export run
export const selectRunMatterTypeReportStatus = (state: RootState) => state.analytics.matterAnalytics.runStatus;
export const selectRunMatterTypeReportError = (state: RootState) => state.analytics.matterAnalytics.runError;

// Select matter type report custom export save
export const selectMatterTypeReportTemplateSaveStatus = (state: RootState) =>
  state.analytics.matterAnalytics.saveStatus;
export const selectMatterTypeReportTemplateSaveError = (state: RootState) => state.analytics.matterAnalytics.saveError;

// Select the report template delete status
export const selectDeleteTemplateStatus = (state: RootState) => state.analytics.documentAnalytics.deleteStatus;
export const selectDeleteTemplateStatusError = (state: RootState) => state.analytics.documentAnalytics.deleteError;

// Select the matter report template delete status
export const selectDeleteMatterTemplateStatus = (state: RootState) => state.analytics.matterAnalytics.deleteStatus;
export const selectDeleteMatterTemplateStatusError = (state: RootState) => state.analytics.matterAnalytics.deleteError;

// Select the flag for analytics accessibility
export const selectAnalyticsInaccessible = (state: RootState) => state.analytics.analyticsInaccessible;

// Select graphs
export const selectAllGraphs = (state: RootState) => state.analytics.graphs.entries;
export const selectGraphsStatus = (state: RootState) => state.analytics.graphs.status;
export const selectGraphsError = (state: RootState) => state.analytics.graphs.error;
export const selectGraphsFilters = (state: RootState) => state.analytics.graphs.filters;
export const selectGraphData = (state: RootState) => state.analytics.graphs.graphData;

export default analyticsSlice.reducer;
