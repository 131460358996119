import { cMonoColorType } from "../../../../../../app/constants";
import Container from "../../../../../../components/Grid/Container/Container";
import styles from "../../../Analytics.module.scss";

interface IAnalyticsFiltersContainer {
  children: React.ReactNode;
}

/**
 * The documents and matters analytics filters container
 * @param children The children
 * @returns JSX.Element
 */
function AnalyticsFiltersContainer({ children }: IAnalyticsFiltersContainer): JSX.Element {
  return (
    <Container
      className={styles.reportContainer}
      width="lg"
      mx={{ base: 0 }}
      pb={{ base: 8 }}
      bgColor={cMonoColorType.White}
    >
      {children}
    </Container>
  );
}

export default AnalyticsFiltersContainer;
