import { debounce, isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { cStatusType, cThemeColorType } from "../../../../../../../app/constants";
import Button, { cButtonType } from "../../../../../../../components/Button/Button";
import DiscardChangesModal from "../../../../../../../components/DiscardChangesModal/DiscardChangesModal";
import DisplayError from "../../../../../../../components/DisplayError/DisplayError";
import FormField, { cFormFieldType } from "../../../../../../../components/FormField/FormField";
import { EIcon } from "../../../../../../../components/Icon/Icon";
import Modal, {
  ModalActions,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from "../../../../../../../components/Modal/Modal";
import { ECollaborationAction } from "../../../../../../../modules/collaborationSlice";
import { isValidEmail } from "../../../../../../../utils/isValidEmail/isValidEmail";
import styles from "./AddCollaboratorModal.module.scss";

interface IAddCollaboratorModal {
  isOpen: boolean;
  handleClose: () => void;
  handleEmailFieldChange: (emailAddress: string) => void;
  foundPerson: any;
  clearAutofillOptions: () => void;
  filteredEmailAddressOptions: any;
  handleSubmitFn: (data: TAddCollaboratorInputs) => Promise<void>;
  status: cStatusType;
  error?: string;
}

export type TAddCollaboratorInputs = {
  ema: string;
  confirmEmailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  action: string;
};

/**
 * Add collaborator modal
 * @param isOpen                      Is the modal open
 * @param handleClose                 Function to close the modal
 * @param handleEmailFieldChange      Search for a person by email address
 * @param foundPerson                 A person found from a search
 * @param clearAutofillOptions        Clears the autofill email address options
 * @param filteredEmailAddressOptions Filtered options for autocomplete
 * @param handleSubmitFn              Function to handle submit
 * @param status                      The status of the submission
 * @param error                       Any error that occurred
 * @returns JSX.Element
 */
function AddCollaboratorModal({
  isOpen,
  handleClose,
  handleEmailFieldChange,
  foundPerson,
  clearAutofillOptions,
  filteredEmailAddressOptions,
  handleSubmitFn,
  status,
  error,
}: IAddCollaboratorModal): JSX.Element {
  const spacing = { mb: 5 }; // Field spacing
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false); // Discard modal open state

  const defaultValues = {
    ema: "",
    confirmEmailAddress: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    action: "",
  };

  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    watch,
    control,
    reset,
    setValue,
  } = useForm<TAddCollaboratorInputs>({ defaultValues });

  const emailAddress = watch("ema");

  // Search for a person by email address on change of email address
  useEffect(() => {
    // Pause for 250 milliseconds before searching for a user (cancel any previous searches)
    debounce(() => {
      handleEmailFieldChange(emailAddress);
    }, 250);
  }, [emailAddress]);

  // Search for a person by email address on change of email address
  useEffect(() => {
    if (emailAddress !== "") {
      debouncedHandleEmailFieldChange(emailAddress);
    }
  }, [emailAddress]);

  // Debounced search for a person by email address
  const debouncedHandleEmailFieldChange = useCallback(
    debounce((emailAddress) => {
      handleEmailFieldChange(emailAddress);
    }, 250),
    [],
  );

  /**
   * Populates the form with values from the user found during the autocomplete search
   */
  function populateFormWithFoundUserDetails() {
    setValue("ema", foundPerson.emailAddress, { shouldValidate: true });
    setValue("confirmEmailAddress", foundPerson.emailAddress, { shouldValidate: true });
    setValue("firstName", foundPerson.firstName, { shouldValidate: true });
    setValue("lastName", foundPerson.lastName, { shouldValidate: true });
    setValue("phoneNumber", foundPerson.phoneNumber, { shouldValidate: true });
  }

  useEffect(() => {
    // If we have found a user using the email search, use it to populate the form
    if (foundPerson) {
      populateFormWithFoundUserDetails();
      clearAutofillOptions();
    }
  }, [foundPerson]);

  /**
   * Close the modal and reset the form
   */
  function handleCloseFn() {
    const currentValues = watch();
    if (!isEqual(currentValues, defaultValues)) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose();
      reset();
      clearAutofillOptions();
    }
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<TAddCollaboratorInputs> = async (data) => {
    try {
      await handleSubmitFn(data);
      handleClose();
      reset();
      clearAutofillOptions();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Handle discard changes modal close
   * @param canDiscard Can the changes be discarded
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
      clearAutofillOptions();
    }
    setIsDiscardChangesModalOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleCloseFn}
        testId="add-collaborator-modal"
        portalClassName={styles.modalPortal}
      >
        <ModalHeader>
          <ModalTitle>Add a Collaborator</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <form id="add-collaborator-form" onSubmit={handleSubmit(onSubmit)}>
            <FormField
              label="Email"
              name="ema"
              register={register}
              error={errors.ema}
              spacing={spacing}
              options={filteredEmailAddressOptions}
              clearOptions={clearAutofillOptions}
              fullWidth
              control={control as any}
              validate={(value: string) => {
                if (!isValidEmail(value)) {
                  return "Email is invalid";
                }
              }}
              autoComplete="off"
            />
            <FormField
              label="Confirm email"
              name="confirmEmailAddress"
              register={register}
              error={errors.confirmEmailAddress}
              spacing={spacing}
              required
              fullWidth
              validate={(value: string) => {
                if (!isValidEmail(value)) {
                  return "Email is invalid";
                }
                if (watch("ema") !== value) {
                  return "Emails do no match";
                }
              }}
              disabled={!!foundPerson}
            />
            <FormField
              label="First name"
              name="firstName"
              register={register}
              error={errors.firstName}
              spacing={spacing}
              required
              fullWidth
              data-lpignore={true}
            />
            <FormField
              label="Last name"
              name="lastName"
              register={register}
              error={errors.lastName}
              spacing={spacing}
              required
              fullWidth
              data-lpignore={true}
            />
            <FormField
              label="Contact number"
              name="phoneNumber"
              type={cFormFieldType.Telephone}
              register={register}
              error={errors.phoneNumber}
              spacing={spacing}
              fullWidth
              control={control as Control<any, any>}
              validate={(value: string) => {
                if (value) {
                  if (value !== null && !isValidPhoneNumber(value)) {
                    return "Contact number is invalid";
                  }
                }
              }}
              required
            />
            <FormField
              testId="privileges-buttons"
              name="action"
              label="Privileges"
              register={register}
              type={cFormFieldType.Buttons}
              options={[
                {
                  label: "View only",
                  value: ECollaborationAction.View,
                  icon: EIcon.VisibilityOn,
                },
                {
                  label: "Edit",
                  value: ECollaborationAction.Edit,
                  icon: EIcon.Edit,
                },
              ]}
              error={errors.action}
              required
            />
          </form>
          {error && <DisplayError>{error}</DisplayError>}
        </ModalContent>
        <ModalActions>
          <Button
            color={cThemeColorType.Secondary}
            onClick={handleCloseFn}
            testId="add-collaborator-modal-close-button"
          >
            Cancel
          </Button>
          <Button
            formId="add-collaborator-form"
            type={cButtonType.SubmitType}
            testId="add-collaborator-modal-submit-button"
            isLoading={status === cStatusType.Loading}
          >
            Add
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
        portalClassName={styles.modalPortal}
      />
    </>
  );
}

export default AddCollaboratorModal;
