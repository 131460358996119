import {
  createAsyncThunk,
  createSlice,
  isAllOf,
  ListenerEffectAPI,
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import api from "../app/api";
import {
  addCollaboratorUrl,
  deleteCollaboratorUrl,
  fetchCollaborationUrl,
  sendCollaborationLinkUrl,
} from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { TAddCollaboratorInputs } from "../pages/DocumentsContainer/Documents/ManageCollaborationModalContainer/ManageCollaborationModal/AddCollaboratorModalContainer/AddCollaboratorModal/AddCollaboratorModal";
import { ICollaborator } from "../pages/DocumentsContainer/Documents/ManageCollaborationModalContainer/ManageCollaborationModal/ManageCollaborationModal";
import { EWalkEndType, postWalkAction } from "./walkSlice";

/**
 * Describe the collaborators object
 */
export interface ICollaborators {
  editors: ICollaborator[];
  viewers: ICollaborator[];
}

/**
 * Describe possible WOPI file extensions
 */
export enum EFileExtension {
  Docx = "docx",
  Xlsx = "xlsx",
  Pptx = "pptx",
}

/**
 * Describe the collaboration state object
 */
interface ICollaborationState {
  status: cStatusType; // API call status
  collabIsActive: boolean;
  error?: string;
  collaborators: ICollaborators;
  processID?: number;
  collaborationLink?: string;
  addStatus: cStatusType;
  addError?: string;
  deleteCollaboratorStatus: cStatusType;
  deleteCollaboratorError?: string;
  sendCollaborationLinkStatus: cStatusType;
  sendCollaborationLinkError?: string;
  fileExtension?: EFileExtension;
}

/**
 * Initial state
 */
const initialState: ICollaborationState = {
  status: cStatusType.Idle,
  collabIsActive: false,
  collaborators: {
    editors: [],
    viewers: [],
  },
  addStatus: cStatusType.Idle,
  deleteCollaboratorStatus: cStatusType.Idle,
  sendCollaborationLinkStatus: cStatusType.Idle,
};

/**
 * Thunk for fetching a collaboration object
 */
export const postCollaborationFetch = createAsyncThunk(
  "collaboration/postCollaborationFetch",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = fetchCollaborationUrl;
      const response = await api({ endpoint, dispatch, body: { id } });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Collaboration action type
 */
export enum ECollaborationAction {
  Edit = "E",
  View = "V",
}

/**
 * Thunk for adding a collaborator
 */
export const postAddCollaborator = createAsyncThunk(
  "collaboration/postAddCollaborator",
  async (formData: TAddCollaboratorInputs, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;

      const body = {
        processID: state.collaboration.processID,
        action: formData.action,
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailAddress: formData.ema,
        mobilePhoneNo: formData.phoneNumber,
      };

      const endpoint = addCollaboratorUrl;
      const response = await api({ endpoint, dispatch, body });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a collaborator
 */
export const postDeleteCollaborator = createAsyncThunk(
  "collaboration/postDeleteCollaborator",
  async (id: number, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const { processID } = state.collaboration;
      const endpoint = deleteCollaboratorUrl;

      const body = {
        collaboratorID: id,
        processID,
      };

      const response = await api({ endpoint, dispatch, body });

      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for sending a collaboration link
 */
export const postSendCollaborationLink = createAsyncThunk(
  "collaboration/postSendCollaborationLink",
  async (collaboratorID: number | "all", { dispatch, rejectWithValue }) => {
    try {
      const endpoint = sendCollaborationLinkUrl;
      await api({ endpoint, dispatch, body: { collaboratorID } });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch collaboration object on add / delete of a collaborator
 * @param action       The action
 * @param listenerApi  The listener API
 * @returns void
 */
export const refreshCollaborationEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { getState } = listenerApi;
  const state = getState() as RootState;
  const { processID } = state.collaboration;
  const { dispatch, cancelActiveListeners } = listenerApi;

  cancelActiveListeners();

  dispatch(postCollaborationFetch(processID as number));
};

/**
 * Collaboration reducer
 */
export const collaborationSlice = createSlice({
  name: "collaboration",
  initialState,
  reducers: {
    // Open / close collaboration modal
    updateCollabIsActive: (state, action: PayloadAction<boolean>) => {
      state.collabIsActive = action.payload;
    },
    // Reset the add error
    resetAddError: (state) => {
      state.addError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCollaborationFetch.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postCollaborationFetch.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postCollaborationFetch.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.collaborators = action.payload.collaborators;
        state.processID = action.payload.processID;
        state.collaborationLink = action.payload.collaborationLink;
        state.fileExtension = action.payload.fileExtension;
      })
      .addCase(postAddCollaborator.pending, (state) => {
        state.addStatus = cStatusType.Loading;
        state.addError = undefined;
      })
      .addCase(postAddCollaborator.rejected, (state, action) => {
        state.addStatus = cStatusType.Failed;
        state.addError = action.payload as string;
      })
      .addCase(postAddCollaborator.fulfilled, (state) => {
        state.addStatus = cStatusType.Idle;
      })
      .addCase(postDeleteCollaborator.pending, (state) => {
        state.deleteCollaboratorStatus = cStatusType.Loading;
        state.deleteCollaboratorError = undefined;
      })
      .addCase(postDeleteCollaborator.rejected, (state, action) => {
        state.deleteCollaboratorStatus = cStatusType.Failed;
        state.deleteCollaboratorError = action.payload as string;
      })
      .addCase(postDeleteCollaborator.fulfilled, (state) => {
        state.deleteCollaboratorStatus = cStatusType.Idle;
      })
      .addCase(postSendCollaborationLink.pending, (state) => {
        state.sendCollaborationLinkStatus = cStatusType.Loading;
        state.sendCollaborationLinkError = undefined;
      })
      .addCase(postSendCollaborationLink.rejected, (state, action) => {
        state.sendCollaborationLinkStatus = cStatusType.Failed;
        state.sendCollaborationLinkError = action.payload as string;
      })
      .addCase(postSendCollaborationLink.fulfilled, (state) => {
        state.sendCollaborationLinkStatus = cStatusType.Idle;
      })
      .addMatcher(isAllOf(postWalkAction.fulfilled), (state, action) => {
        // If walk action payload is an end type, reset the collaboration state
        if ([EWalkEndType.Discarded, EWalkEndType.Paused, EWalkEndType.Done].includes(action.payload)) {
          return initialState;
        }
      });
  },
});

export const { updateCollabIsActive, resetAddError } = collaborationSlice.actions;

// Select the status
export const selectCollaborationStatus = (state: RootState) => state.collaboration.status;
// Select the collaborators
export const selectCollaborators = (state: RootState) => state.collaboration.collaborators;
// Select error
export const selectCollaborationError = (state: RootState) => state.collaboration.error;
// Select collabIsActive
export const selectCollabIsActive = (state: RootState) => state.collaboration.collabIsActive;
// Select addStatus
export const selectAddStatus = (state: RootState) => state.collaboration.addStatus;
// Select addError
export const selectAddError = (state: RootState) => state.collaboration.addError;
// Select deleteCollaboratorStatus
export const selectDeleteCollaboratorStatus = (state: RootState) => state.collaboration.deleteCollaboratorStatus;
// Select deleteCollaboratorError
export const selectDeleteCollaboratorError = (state: RootState) => state.collaboration.deleteCollaboratorError;
// Select collaboration link
export const selectCollaborationLink = (state: RootState) => state.collaboration.collaborationLink;
// Select sendCollaborationLinkStatus
export const selectSendCollaborationLinkStatus = (state: RootState) => state.collaboration.sendCollaborationLinkStatus;
// Select sendCollaborationLinkError
export const selectSendCollaborationLinkError = (state: RootState) => state.collaboration.sendCollaborationLinkError;
// Select file extension
export const selectFileExtension = (state: RootState) => state.collaboration.fileExtension;

export default collaborationSlice.reducer;
