import { cStatusType, cThemeColorType } from "../../../../../../app/constants";
import Button from "../../../../../../components/Button/Button";
import DisplayError from "../../../../../../components/DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../../components/Modal/Modal";
import Typography from "../../../../../../components/Typography/Typography";

interface IInvalidateEmbedLinkModal {
  isOpen: boolean;
  wipDTM?: string;
  handleClose: () => void;
  handleInvalidation: () => Promise<void>;
  status: cStatusType;
  error?: string;
}

/**
 * Modal to invalidate embed links that have been sent out
 * @param isOpen             Is the modal open?
 * @param wipDTM             The WIP DTM will update the modal text if the walk is already in progress
 * @param handleClose        Close the modal
 * @param handleInvalidation Handle the invalidation of the embed link
 * @param status             The status of the invalidation
 * @param error              The error message
 * @returns JSX.Element
 */
function InvalidateEmbedLinkModal({
  isOpen,
  wipDTM,
  handleClose,
  handleInvalidation,
  status,
  error,
}: IInvalidateEmbedLinkModal): JSX.Element {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <ModalTitle>Invalidate this link?</ModalTitle>
      </ModalHeader>
      <ModalContent>
        {wipDTM ? (
          <Typography>
            The EMBED user has already started the interview. Invalidating the link will abandon the in-progress
            interview. Are you sure you want to continue?
          </Typography>
        ) : (
          <Typography>
            An email has been sent out with this link, but the interview has not been started yet. Invalidating the link
            will allow you to resend the email. Are you sure you want to continue?
          </Typography>
        )}
        {error && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions>
        <Button color={cThemeColorType.Secondary} onClick={handleClose} disabled={status === cStatusType.Loading}>
          Cancel
        </Button>
        <Button
          color={wipDTM ? cThemeColorType.Danger : cThemeColorType.Primary}
          onClick={handleInvalidation}
          isLoading={status === cStatusType.Loading}
        >
          Invalidate
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default InvalidateEmbedLinkModal;
