import {
  createAsyncThunk,
  createSlice,
  ListenerEffectAPI,
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import { format } from "date-fns";
import { clone, findKey, isEmpty, map, omit, uniq } from "lodash";
import { toast } from "react-toastify";
import api from "../app/api";
import {
  attachSupportingDocumentUrl,
  checkDeleteDocumentUrl,
  deleteDocumentUrl,
  documentAddNoteUrl,
  documentFiltersUrl,
  documentOwnershipTransferUrl,
  documentsPageUrl,
  documentTimelineUrl,
  downloadTimelineFileUrl,
  renameDocumentUrl,
  setDocumentStageUrl,
  terminateDocumentUrl,
  transferDocumentUsersUrl,
} from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { EEmptyType, IID } from "../app/types";
import { EDocumentDeleteType } from "../pages/AdminContainer/Admin/DeleteDocument/DeleteDocument";
import { errorToast } from "../toast/toast";
import { areSomeDefined } from "../utils/areSomeDefined/areSomeDefined";
import createIdList from "../utils/createIdList/createIdList";
import { downloadBase64File } from "../utils/download/download";
import getExpiredIdCacheList, { TUnknownEntries } from "../utils/getExpiredIdCacheList/getExpiredIdCacheList";
import { handleTerminationToast } from "../utils/handleTerminationToast/handleTerminationToast";
import { postDocumentStages } from "./documentStagesSlice";
import { postDocumentStates } from "./documentStatesSlice";
import { postDocumentTypes } from "./documentTypesSlice";
import { EESignActionHintType } from "./esignSlice";
import { postUsersByID } from "./usersSlice";
import { EWalkType } from "./walkSlice";

/**
 * Document action menu item types
 */
export enum EDocumentActionMenuItemKey {
  DraftNewVersion = "draftNewVersion",
  UploadNewVersion = "uploadNewVersion",
  UpdateInterviewData = "updateInterviewData",
  TransferOwner = "transferOwner",
  AddNote = "addNote",
  AddEvent = "addEvent",
  AttachSuportingDocument = "attachSuportingDocument",
  Terminate = "terminate",
  RenameDocument = "renameDocument",
  ChangeStage = "changeStage",
  Clone = "clone",
  CollaborateNewVersion = "collaborateNewVersion",
}

/**
 * Available timeline group options
 */
export enum ETimelineGroup {
  All = "all",
  Events = "events",
  Files = "files",
}

/**
 * Possible timeline entry types
 */
export enum ETimelineEntryType {
  Assigned = "taskAssignEvent",
  Create = "create",
  Description = "description",
  Discard = "discard",
  CustomEvent = "customEvent",
  InterviewEvent = "interviewEvent",
  Milestone = "milestone",
  Note = "note",
  Pause = "pause",
  StatusChange = "statusChange",
  SupportingDocument = "supportingDocument",
  TransferOwner = "transferOwner",
  TransferWIP = "transferWIP",
  VersionData = "versionData",
  VersionDraft = "versionDraft",
  VersionFile = "versionFile",
  VersionCollab = "versionCollab",
}

/**
 * Describes a timeline file
 */
export interface ITimelineFile {
  id: number;
  state: cAccessType;
  name: string;
  type: string;
  isSigned: boolean;
}

/**
 * Describes a timeline event
 */
export interface ITimelineEvent {
  customEventID?: number;
  isRetrospective: boolean;
  notified: boolean;
  recipients: string[];
  date: string;
  notifyDate: string;
}

/**
 * Describes timeline common properties
 */
interface ITimelineCommon {
  date: string;
  createdDate: string;
  description: string;
  group: ETimelineGroup[];
}

/**
 * Describes a document action
 */
export interface IDocumentAction {
  state: cAccessType;
  tooltip?: string;
}

/**
 * The possible timeline types
 */
export type TTimeline =
  | {
      type: ETimelineEntryType.CustomEvent;
      common: ITimelineCommon;
      interviewLog?: never;
      userID: number;
      userName: string;
      event: ITimelineEvent;
      action: { [key: string]: { state: cAccessType; id?: never; hint?: never } };
      files?: never;
      urn?: never;
      signedDate?: never;
    }
  | {
      type: ETimelineEntryType.Discard | ETimelineEntryType.Pause;
      common: ITimelineCommon;
      interviewLog?: never;
      userID: number;
      userName: string;
      event?: never;
      action?: never;
      files?: never;
      urn?: never;
      signedDate?: never;
    }
  | {
      type:
        | ETimelineEntryType.StatusChange
        | ETimelineEntryType.Description
        | ETimelineEntryType.Note
        | ETimelineEntryType.Create;
      common: ITimelineCommon;
      interviewLog?: never;
      userID: number;
      userName: string;
      event: ITimelineEvent;
      action?: never;
      files?: never;
      urn?: never;
      signedDate?: never;
    }
  | {
      type: ETimelineEntryType.TransferWIP | ETimelineEntryType.TransferOwner;
      common: ITimelineCommon;
      interviewLog?: never;
      userID: number;
      userName: string;
      event?: ITimelineEvent;
      action?: never;
      files?: never;
      urn?: never;
      signedDate?: never;
    }
  | {
      type: ETimelineEntryType.SupportingDocument;
      common: ITimelineCommon;
      interviewLog?: never;
      userID: number;
      userName: string;
      event?: never;
      action: { [key: string]: { state: cAccessType; id: number; hint: EESignActionHintType } };
      files: { [key: string]: ITimelineFile };
      urn: number;
      signedDate: string;
    }
  | {
      type: ETimelineEntryType.InterviewEvent;
      common: ITimelineCommon;
      interviewLog?: never;
      userID?: never;
      userName: string;
      event: ITimelineEvent;
      action?: never;
      files?: never;
      urn?: never;
      signedDate?: never;
    }
  | {
      type: ETimelineEntryType.VersionDraft | ETimelineEntryType.VersionFile | ETimelineEntryType.VersionCollab;
      common: ITimelineCommon;
      interviewLog: { state: cAccessType; walkID: number };
      userID: number;
      userName: string;
      event?: never;
      action: { [key: string]: { state: cAccessType; id: number; hint: EESignActionHintType } };
      files: { [key: string]: ITimelineFile };
      urn: number;
      signedDate: string;
    }
  | {
      type: ETimelineEntryType.VersionData;
      common: ITimelineCommon;
      interviewLog: { state: cAccessType; walkID: number };
      userID: number;
      userName: string;
      event: ITimelineEvent;
      action?: never;
      files?: never;
      urn?: never;
      signedDate?: never;
    }
  | {
      type: ETimelineEntryType.Assigned;
      common: ITimelineCommon;
      interviewLog?: never;
      userID: number;
      userName: string;
      event: ITimelineEvent;
      action?: never;
      files?: never;
      urn?: never;
      signedDate?: never;
    };

/**
 * Document timeline entry
 */
export interface IDocumentTimelineEntry {
  documentID: number;
  groupingID: number | null;
  type: ETimelineEntryType;
  name: string;
  documentTypeID: number;
  date: Date;
  ownerID: number;
  stageName: string;
  timeline: TTimeline[];
  action: Record<string, IDocumentAction>;
}

/**
 * Document walk in progress object
 */
export type TDocumentWalkInProgress =
  | {
      ownedByMe: true;
      state: cAccessType;
      tooltip: string;
      wipID?: number;
      action: EWalkType | null;
    }
  | {
      ownedByMe: false;
      state: cAccessType;
      tooltip: string;
      wipID?: never;
      action?: never;
    };

/**
 * Describes document termination
 */
export interface IDocumentTermination {
  terminateDate: string;
  terminated: boolean;
}

/**
 * Describe a document
 */
export interface IDocument {
  compositeState: number;
  description: string;
  documentType: IID;
  id: number;
  lastModifiedDate?: string;
  ownerUser: IID;
  signed?: boolean;
  walkInProgress?: TDocumentWalkInProgress;
  action: Record<string, IDocumentAction>;
  termination?: IDocumentTermination;
  isSigned: boolean;
  signedDate: string | null;
  groupingID?: number;
  historySortDate?: string;
  stageID?: number;
}

/**
 * Render type for a document
 */
export type TDocumentRender = Omit<IDocument, "documentType" | "ownerUser"> & {
  documentType: string;
  owner: string;
};

/**
 * Describe an API Document
 */
export interface IAPIDocument {
  id: string;
  description: string;
}

/**
 * Describe the documents state object
 */
interface IDocumentsState {
  entries: Record<string, IDocument>; // Array of documents
  status: cStatusType; // API call status
  documentByIdStatus?: cStatusType; // Document by id API call status
  deleteStatus?: cStatusType;
  renameStatus?: cStatusType;
  fetched: boolean; // Have entries been fetched?
  typeIDS?: number[];
  stageIDS?: number[];
  ownerIDs?: number[];
  stateIDs?: number[];
  error?: string;
  page: number;
  filterData?: IPostDocuments;
  addNoteError?: string;
  emptyType?: EEmptyType;
  timelineEntry?: IDocumentTimelineEntry;
  replacementUserIDs?: number[];
  docUploadProgress?: number;
  isInformationUpdatedModalOpen: boolean;
  setStageStatus?: cStatusType;
  setStageError?: string;
  transferUsersStatus?: cStatusType;
  transferUsersError?: string;
  terminateDocumentStatus?: cStatusType;
  terminateDocumentError?: string;
}

/**
 * Interface for post documents thunk
 */
export interface IPostDocuments {
  documentTypeID?: number | string;
  descriptionContains?: string;
  dataContains?: string;
  urn?: number;
  stateIDs?: number[];
  stageIDs?: number[];
  ownerIDs?: number[];
}

/**
 * Describes a post terminate document
 */
export interface IPostTerminateDocument {
  documentID: number;
  terminateDate: string;
  note: string;
}

/**
 * Describes upload supporting doc formData
 */
export interface IUploadSupportingDocFormData {
  documentID: number;
  file: { name: string; fileData: unknown };
  note?: string;
}

/**
 * Interface for describing rename document thunk params
 */
interface IPostRenameDocument {
  ID: number;
  description: string;
}

/**
 * Initial state
 */
const initialState: IDocumentsState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
  page: 1,
  filterData: {},
  isInformationUpdatedModalOpen: false,
};

/**
 * Thunk for fetching documents
 */
export const postDocuments = createAsyncThunk(
  "documents/postDocuments",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const endpoint = documentsPageUrl;
      const {
        documents: { page, filterData },
      } = getState() as RootState;

      const body: { page: number; filter?: IPostDocuments } = {
        page: page,
      };

      const filter = clone(filterData);

      if (areSomeDefined(filter)) {
        if (filter?.documentTypeID === "") {
          delete filter.documentTypeID;
        }
        body.filter = filter;
      }

      const response = await api({ endpoint, dispatch, body });
      return createIdList(response.data.documents);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching documents by page
 */
export const postDocumentsByPage = createAsyncThunk(
  "documents/postDocumentsByPage",
  async (page: number, { dispatch, rejectWithValue, getState }) => {
    try {
      const endpoint = documentsPageUrl;
      const {
        documents: { filterData },
      } = getState() as RootState;

      const body: { page: number; filter?: IPostDocuments } = {
        page: page,
      };

      const filter = clone(filterData);

      if (areSomeDefined(filter)) {
        if (filter?.documentTypeID === "") {
          delete filter.documentTypeID;
        }
        body.filter = filter;
      }

      const response = await api({ endpoint, dispatch, body });
      return createIdList(response.data.documents);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching document filters
 */
export const getDocumentFilters = createAsyncThunk(
  "documents/getDocumentFilters",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { documentTypes, stages, owners, states },
      } = await api({ endpoint: documentFiltersUrl, dispatch, method: "GET" });
      const typeIDs = documentTypes?.map((documentType: any) => documentType.id);
      const stageIDs = stages?.map((stage: any) => stage.id);
      const ownerIDs = owners?.map((owner: any) => owner.id);
      const stateIDs = states?.map((state: any) => state.id);
      return { typeIDs, stageIDs, ownerIDs, stateIDs };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Interface for post document note thunk
 */
export interface IPostDocumentNote {
  documentID: number;
  note: string;
}

/**
 * Thunk for posting a document note
 */
export const postDocumentNote = createAsyncThunk(
  "documents/postDocumentNote",
  async (body: IPostDocumentNote, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        documents: { entries },
      } = getState() as RootState;
      const { documentID, note } = body;

      // Send compositeState so the api knows if the existing data is stale
      const compositeState = entries[body.documentID]?.compositeState;

      const response = await api({
        endpoint: documentAddNoteUrl,
        dispatch,
        body: { documentID, note, compositeState },
      });

      return response;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Converts the timeline data into a useable structure
 * Files are returned from the server in different structures so we need to map them to a consistent format
 * e.g. convert
 *
 * signed: {
 *    files: {
 *        signedOnCreate: {
 *          signed: {
 *          }
 *      }
 *    }
 * }
 *
 * to:
 *
 * files: {
 *    signed: {
 *      isSigned: true
 *     }
 *   }
 * }
 * @param response The api response
 * @returns any
 */
export function convertTimelineData(response: any): any {
  try {
    const timeline: any[] = response.data.timeline;
    const mappedTimeline = timeline.map((timelineEntry: any) => {
      const timelineEntryType = Object.keys(timelineEntry)[0];
      // Create file structure for unsigned files
      if (timelineEntry[timelineEntryType].unsigned) {
        // Get the unsigned files
        const files = timelineEntry[timelineEntryType].unsigned.files;
        for (const file in files) {
          // Iterate the files and marked them as isSigned false
          files[file].isSigned = false;
        }
        // Return the timeline entry in a consistent format
        return { ...timelineEntry[timelineEntryType].unsigned, files: { ...files }, type: timelineEntryType };
      }
      // Create file structure for signed files
      if (timelineEntry[timelineEntryType].signed) {
        let files;
        if (timelineEntryType === ETimelineEntryType.SupportingDocument) {
          // Get the supportingDocument signed files
          // supportingDocument files are in the format supportingDocument.signed.files
          files = timelineEntry[timelineEntryType].signed.files;
        } else {
          // Get the versionFile or versionDraft signed files
          // versionFile and versionDraft have an extra level e.g. versionDraft.signed.files.signedOnCreate
          const fileKey = Object.keys(timelineEntry[timelineEntryType].signed.files)[0]; // signedOnCreate or signedAfterCreate
          files = timelineEntry[timelineEntryType].signed.files[fileKey];
        }
        // Iterate the files and marked signed as isSigned true
        for (const file in files) {
          if (file === "signed") {
            files[file].isSigned = true;
          }
        }
        // Return the timeline entry in a consistent format
        return { ...timelineEntry[timelineEntryType].signed, files: { ...files }, type: timelineEntryType };
      }
      return {
        ...timelineEntry[timelineEntryType],
        type: timelineEntryType,
      };
    });
    return {
      ...response.data,
      timeline: mappedTimeline,
    };
  } catch (error) {
    throw new Error("Error converting timeline data");
  }
}

/**
 * Thunk for posting a document timeline
 */
export const postDocumentTimeline = createAsyncThunk(
  "documents/postDocumentTimeline",
  async (documentID: number, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        documents: { entries },
      } = getState() as RootState;

      // Send compositeState so the api knows if the existing data is stale
      const compositeState = entries[documentID]?.compositeState;

      const response = await api({ endpoint: documentTimelineUrl, dispatch, body: { documentID, compositeState } });
      return convertTimelineData(response);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for downloading a timeline file
 */
export const postDownloadTimelineFile = createAsyncThunk(
  "documents/postDownloadTimelineFile",
  async ({ documentID, fileID }: { documentID: number; fileID: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: downloadTimelineFileUrl, dispatch, body: { documentID, fileID } });
      return response.data;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Interface for post documents by ID
 */
interface IPostDocumentsById {
  ids: number[];
  force?: boolean;
}

/**
 * Thunk for fetching documents by ID
 */
export const postDocumentsById = createAsyncThunk(
  "documents/postDocumentsById",
  async ({ ids, force = false }: IPostDocumentsById, { dispatch, rejectWithValue, getState }) => {
    try {
      let id = ids;
      // If we are not forcing the fetch, check if we have the data in the cache
      // (force will fetch the data regardless of the cache state)
      if (!force) {
        const state = getState();

        const {
          documents: { entries },
        } = state as RootState;

        // Get the expired ids
        id = getExpiredIdCacheList({ ids, entries: entries as unknown as TUnknownEntries });
      }

      if (id.length === 0) return {};
      const response = await api({ endpoint: documentsPageUrl, dispatch, body: { id } });
      return createIdList(response.data.documents);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching transfer users
 */
export const postDocumentTransferUsers = createAsyncThunk(
  "documents/postDocumentTransferUsers",
  async (documentID: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: transferDocumentUsersUrl, dispatch, body: { documentID } });
      return response.data.userIDs;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for transfering ownership
 */
export const postDocumentOwnershipTransfer = createAsyncThunk(
  "documents/postDocumentOwnershipTransfer",
  async (
    {
      documentID,
      fromUserID,
      toUserID,
      transferNote,
    }: { documentID: number; fromUserID: number; toUserID: number; transferNote: string },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      const {
        documents: { entries },
      } = getState() as RootState;

      // Send compositeState so the api knows if the existing data is stale
      const compositeState = entries[documentID]?.compositeState;

      const response = await api({
        endpoint: documentOwnershipTransferUrl,
        dispatch,
        body: { documentID, fromUserID, toUserID, transferNote, compositeState },
      });
      toast("Document transferred");

      // User no longer has access to the document
      if (response.data.document === null) {
        return { remove: documentID };
      }

      return createIdList([response.data.document]);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for terminating a document
 */
export const postTerminateDocument = createAsyncThunk(
  "documents/postTerminateDocument",
  async ({ documentID, terminateDate, note }: IPostTerminateDocument, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        documents: { entries },
      } = getState() as RootState;

      // Send compositeState so the api knows if the existing data is stale
      const compositeState = entries[documentID]?.compositeState;

      const response = await api({
        endpoint: terminateDocumentUrl,
        dispatch,
        body: { documentID, terminateDate: format(new Date(terminateDate), "yyyy-MM-dd"), note, compositeState },
      });
      handleTerminationToast(response.data.document);
      return createIdList([response.data.document]);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for checking if a document can be deleted
 */
export const postCheckDeleteDocument = createAsyncThunk(
  "documents/postCheckDeleteDocument",
  async ({ type, urn }: { type: EDocumentDeleteType; urn: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({
        endpoint: checkDeleteDocumentUrl,
        dispatch,
        body: { type, urn: Number(urn) },
      });
      return response.data[type];
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a document
 */
export const postDeleteDocument = createAsyncThunk(
  "documents/postDeleteDocument",
  async ({ type, urn }: { type: EDocumentDeleteType; urn: string }, { dispatch, rejectWithValue }) => {
    try {
      await api({
        endpoint: deleteDocumentUrl,
        dispatch,
        body: { type, urn: Number(urn) },
      });
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for uploading a supporting document
 */
export const postUploadSupportingDocument = createAsyncThunk(
  "documents/postUploadSupportingDocument",
  async (formData: IUploadSupportingDocFormData, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        documents: { entries },
      } = getState() as RootState;

      const { documentID } = formData;
      const compositeState = entries[documentID]?.compositeState;

      await api({
        endpoint: attachSupportingDocumentUrl,
        dispatch,
        body: {
          ...formData,
          compositeState,
        },
        progressAction: updateDocUploadProgress,
      });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for renaming a document
 */
export const postRenameDocument = createAsyncThunk(
  "documents/postRenameDocument",
  async ({ ID, description }: IPostRenameDocument, { dispatch, rejectWithValue }) => {
    try {
      await api({
        endpoint: renameDocumentUrl,
        dispatch,
        body: {
          ID,
          description,
        },
      });
      toast("Document renamed");

      return { ID, description };
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for setting a document stage
 */
export const postDocumentSetStage = createAsyncThunk(
  "documents/postDocumentSetStage",
  async ({ id, stageID }: { id: number; stageID: number }, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        documents: { entries },
      } = getState() as RootState;

      const compositeState = entries[id]?.compositeState;

      await api({
        endpoint: setDocumentStageUrl,
        dispatch,
        body: { id, stageID, compositeState },
      });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Listener effect for fetching document types and owners after documents have been fetched
 * @param _           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchDocumentsEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;
  const state = getState();
  const {
    documents: { entries },
  } = state as RootState;

  cancelActiveListeners();

  const uniqDocTypeIds = uniq(map(entries, (document) => document.documentType.id));

  if (uniqDocTypeIds.length > 0) {
    dispatch(postDocumentTypes({ ids: uniqDocTypeIds }));
  }

  const uniqOwnerIds = uniq(map(entries, (document) => document.ownerUser.id));

  if (uniqOwnerIds.length > 0) {
    dispatch(postUsersByID({ ids: uniqOwnerIds }));
  }
};

/**
 * Listener effect for fetching document filter items after filter IDs have been fetched
 * @param _           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchDocumentFiltersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;
  const state = getState();
  const {
    documents: { stageIDS, stateIDs, ownerIDs, typeIDS },
  } = state as RootState;

  cancelActiveListeners();

  if (ownerIDs && !isEmpty(ownerIDs)) {
    dispatch(postUsersByID({ ids: ownerIDs }));
  }

  if (stateIDs && !isEmpty(stateIDs)) {
    dispatch(postDocumentStates(stateIDs));
  }

  if (stageIDS && !isEmpty(stageIDS)) {
    dispatch(postDocumentStages(stageIDS));
  }

  if (typeIDS && !isEmpty(typeIDS)) {
    dispatch(postDocumentTypes({ ids: typeIDS }));
  }
};

/**
 * Documents reducer
 */
export const documentsSlice = createSlice({
  name: "documents", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    // Add a new document to the state
    updateDocument: (state, action: PayloadAction<IDocument>) => {
      state.entries[action.payload.id] = { ...state.entries[action.payload.id], ...action.payload };
    },
    resetDocumentsState: (state) => {
      state.page = 1;
      state.entries = {};
      state.error = undefined;
    },
    updateSideFilterState: (state, action: PayloadAction<IPostDocuments>) => {
      state.filterData = action.payload;
    },
    resetSideFilterState: (state) => {
      state.filterData = {};
    },
    // Resets walk in progress by setting ownedByMe false
    resetWalkInProgress: (state, action: PayloadAction<any>) => {
      const wipID = action.payload;
      const entry = findKey(state.entries, (e) => {
        return e.walkInProgress?.wipID === wipID;
      });
      state.entries = omit(state.entries, [entry as string]);
    },
    resetTimelineEntry: (state) => {
      state.timelineEntry = undefined;
    },
    updateDocUploadProgress: (state, action: PayloadAction<number>) => {
      state.docUploadProgress = action.payload;
    },
    deleteDocument: (state, action: PayloadAction<string>) => {
      state.entries = omit(state.entries, [action.payload]);
    },
    resetDocumentsError: (state) => {
      state.error = undefined;
    },
    openInformationUpdatedModal: (state) => {
      state.isInformationUpdatedModalOpen = true;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postDocuments.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
        state.emptyType = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocuments.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocuments.fulfilled, (state, action) => {
        // If there is filter data and there are no entries
        if (Object.keys(state.filterData as IPostDocuments).length > 0 && Object.keys(action.payload).length === 0) {
          state.emptyType = EEmptyType.FilterNotFound;
          // If there are no entries and there is no filter data
        } else if (
          Object.keys(state.filterData as IPostDocuments).length === 0 &&
          Object.keys(state.entries).length === 0 &&
          Object.keys(action.payload).length === 0
        ) {
          state.emptyType = EEmptyType.NotFound;
          // Otherwise, end of list has been reached
        } else if (Object.keys(action.payload).length === 0) {
          state.emptyType = EEmptyType.End;
        } else {
          state.page = state.page + 1;
          state.entries = { ...state.entries, ...action.payload };
        }
        state.status = cStatusType.Idle;
        state.fetched = true; // Set fetched to true
        state.error = undefined;
      }) // Set status to loading when the promise is pending
      .addCase(postDocumentsByPage.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postDocumentsByPage.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postDocumentsByPage.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.error = undefined;
      })
      .addCase(getDocumentFilters.pending, (state) => {
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(getDocumentFilters.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(getDocumentFilters.fulfilled, (state, action) => {
        state.typeIDS = action.payload.typeIDs;
        state.stateIDs = action.payload.stateIDs;
        state.ownerIDs = action.payload.ownerIDs;
        state.stageIDS = action.payload.stageIDs;
      })
      .addCase(postDocumentNote.pending, (state) => {
        state.addNoteError = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocumentNote.rejected, (state, action) => {
        state.addNoteError = action.payload as string;
      })
      .addCase(postDocumentTimeline.pending, (state) => {
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocumentTimeline.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocumentTimeline.fulfilled, (state, action) => {
        state.timelineEntry = action.payload;
      })
      // Reset error state when the promise is pending
      .addCase(postDownloadTimelineFile.pending, (state) => {
        state.error = undefined;
      })
      // Set error to response if the promise is rejected
      .addCase(postDownloadTimelineFile.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      // Download the file once the promise has been fulfilled
      .addCase(postDownloadTimelineFile.fulfilled, (_, action) => {
        downloadBase64File(action.payload);
      })
      // Set status to loading when the promise is pending
      .addCase(postDocumentsById.pending, (state) => {
        state.documentByIdStatus = cStatusType.Loading;
        state.error = undefined;
      })
      // Set error to response if the promise is rejected
      .addCase(postDocumentsById.rejected, (state, action) => {
        state.documentByIdStatus = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocumentsById.fulfilled, (state, action) => {
        state.documentByIdStatus = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
      })
      // Set status to loading when the promise is pending
      .addCase(postDocumentTransferUsers.pending, (state) => {
        state.transferUsersStatus = cStatusType.Loading;
        state.transferUsersError = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocumentTransferUsers.rejected, (state, action) => {
        state.transferUsersStatus = cStatusType.Failed;
        state.transferUsersError = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocumentTransferUsers.fulfilled, (state, action) => {
        state.transferUsersStatus = cStatusType.Idle;
        state.replacementUserIDs = action.payload;
      })
      // Set status to loading when the promise is pending
      .addCase(postDocumentOwnershipTransfer.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocumentOwnershipTransfer.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocumentOwnershipTransfer.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;

        // If user no longer has access to the document, remove from state
        // otherwise update the document
        if (action.payload.hasOwnProperty("remove")) {
          const updatedEntries = omit(state.entries, [action.payload.remove]);
          state.entries = updatedEntries;
        } else {
          state.entries = { ...state.entries, ...action.payload };
        }
      })
      // Set status to loading when the promise is pending
      .addCase(postTerminateDocument.pending, (state) => {
        state.terminateDocumentStatus = cStatusType.Loading;
        state.terminateDocumentError = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postTerminateDocument.rejected, (state, action) => {
        state.terminateDocumentStatus = cStatusType.Failed;
        state.terminateDocumentError = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postTerminateDocument.fulfilled, (state, action) => {
        state.terminateDocumentStatus = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
      })
      .addCase(postCheckDeleteDocument.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postCheckDeleteDocument.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postCheckDeleteDocument.fulfilled, (state) => {
        state.status = cStatusType.Idle;
      })
      .addCase(postDeleteDocument.pending, (state) => {
        state.deleteStatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postDeleteDocument.rejected, (state, action) => {
        state.deleteStatus = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postDeleteDocument.fulfilled, (state) => {
        state.deleteStatus = cStatusType.Idle;
      })
      .addCase(postRenameDocument.pending, (state) => {
        state.renameStatus = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postRenameDocument.rejected, (state, action) => {
        state.renameStatus = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postRenameDocument.fulfilled, (state, action) => {
        state.renameStatus = cStatusType.Idle;
        state.entries[action.payload.ID].description = action.payload.description;
      })
      // Set document stage
      .addCase(postDocumentSetStage.pending, (state) => {
        state.setStageStatus = cStatusType.Loading;
        state.setStageError = undefined;
      })
      .addCase(postDocumentSetStage.rejected, (state, action) => {
        state.setStageStatus = cStatusType.Failed;
        state.setStageError = action.payload as string;
      })
      .addCase(postDocumentSetStage.fulfilled, (state) => {
        state.setStageStatus = cStatusType.Idle;
      });
  },
});

// Extract add and edit reducers
export const {
  updateDocument,
  resetDocumentsState,
  updateSideFilterState,
  resetSideFilterState,
  resetWalkInProgress,
  resetTimelineEntry,
  updateDocUploadProgress,
  deleteDocument,
  resetDocumentsError,
  openInformationUpdatedModal,
} = documentsSlice.actions;
// Select the status
export const selectDocumentsStatus = (state: RootState) => state.documents.status;
// Select all documents
export const selectDocuments = (state: RootState) => state.documents.entries;
// Select document by ID
export const selectDocumentById = (state: RootState, id: number) => state.documents.entries[id];
// Select fetched
export const selectDocumentsFetched = (state: RootState) => state.documents.fetched;
// Select error
export const selectDocumentsError = (state: RootState) => state.documents.error;
// Select empty type
export const selectDocumentsEmptyType = (state: RootState) => state.documents.emptyType;
// Select typeIds
export const selectDocumentTypeIds = (state: RootState) => state.documents.typeIDS;
// Select stateIds
export const selectDocumentStateIds = (state: RootState) => state.documents.stateIDs;
// Select stageIds
export const selectDocumentStageIds = (state: RootState) => state.documents.stageIDS;
// Select ownerIds
export const selectDocumentOwnerIds = (state: RootState) => state.documents.ownerIDs;
// Select add note error
export const selectDocumentsAddNoteError = (state: RootState) => state.documents.addNoteError;
// Select timelineEntry
export const selectTimelineEntry = (state: RootState) => state.documents.timelineEntry;
// Select replacementUserIDs
export const selectDocumentReplacementUserIDs = (state: RootState) => state.documents.replacementUserIDs;
// Select docUploadProgress
export const selectDocUploadProgress = (state: RootState) => state.documents.docUploadProgress;
// Select the document delete status
export const selectDeleteDocumentStatus = (state: RootState) => state.documents.deleteStatus;
// Select the information modal open status
export const selectIsInformationUpdatedModalOpen = (state: RootState) => state.documents.isInformationUpdatedModalOpen;
// Select the rename document status
export const selectDocumentRenameStatus = (state: RootState) => state.documents.renameStatus;
// Select set stage status
export const selectSetStageStatus = (state: RootState) => state.documents.setStageStatus;
// Select set stage error
export const selectSetStageError = (state: RootState) => state.documents.setStageError;
// Select transfer users status
export const selectDocumentsTransferUsersStatus = (state: RootState) => state.documents.transferUsersStatus;
// Select transfer users error
export const selectDocumentTransferUsersError = (state: RootState) => state.documents.transferUsersError;
// Select terminate document status
export const selectTerminateDocumentStatus = (state: RootState) => state.documents.terminateDocumentStatus;
// Select terminate document error
export const selectTerminateDocumentError = (state: RootState) => state.documents.terminateDocumentError;

export default documentsSlice.reducer;
