import { cMonoColorType, cStatusType } from "../../../../../../app/constants";
import Div from "../../../../../../components/Div/Div";
import Typography from "../../../../../../components/Typography/Typography";
import { ICollaborators } from "../../../../../../modules/collaborationSlice";
import Collaborator from "./Collaborator/Collaborator";

interface ICollaboratorsList {
  collaborators: ICollaborators;
  userEmail?: string;
  handleDeleteCollaboratorClick: (id: number) => void;
  handleSendCollaborationLink: (collaboratorID: number | "all") => void;
  sendCollaborationLinkStatus: cStatusType;
}

/**
 * Collaborators list
 * @param collaborators                 The collaborators
 * @param userEmail                     The current user's email
 * @param handleDeleteCollaboratorClick Open the delete collaborator modal
 * @param handleSendCollaborationLink   Send the collaboration link
 * @param sendCollaborationLinkStatus   The send collaboration link status
 * @returns JSX.Element
 */
function CollaboratorsList({
  collaborators,
  userEmail,
  handleDeleteCollaboratorClick,
  handleSendCollaborationLink,
  sendCollaborationLinkStatus,
}: ICollaboratorsList): JSX.Element {
  return (
    <Div bgColor={cMonoColorType.Light} p={{ base: 6 }}>
      <Typography variant="h5" spacing={{ mb: 4 }}>
        Collaborators
      </Typography>
      <Typography variant="preHeading" color={cMonoColorType.Light} spacing={{ mb: 6 }}>
        Collaborators will be automatically notified via email when added
      </Typography>
      <Div>
        {collaborators.editors.map((collaborator: any) => (
          <Collaborator
            key={collaborator.id}
            collaborator={collaborator}
            userEmail={userEmail}
            handleDeleteCollaboratorClick={handleDeleteCollaboratorClick}
            handleSendCollaborationLink={handleSendCollaborationLink}
            sendCollaborationLinkStatus={sendCollaborationLinkStatus}
          />
        ))}
        {collaborators.viewers.map((collaborator: any) => (
          <Collaborator
            key={collaborator.id}
            collaborator={collaborator}
            userEmail={userEmail}
            handleDeleteCollaboratorClick={handleDeleteCollaboratorClick}
            handleSendCollaborationLink={handleSendCollaborationLink}
            sendCollaborationLinkStatus={sendCollaborationLinkStatus}
          />
        ))}
      </Div>
    </Div>
  );
}

export default CollaboratorsList;
