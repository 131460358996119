/**
 * Named constants for API call statuses to be used in state slices
 */
export enum cStatusType {
  Idle = "idle",
  Loading = "loading",
  Failed = "failed",
}

/**
 * Named constants for access states of resources
 */
export enum cAccessType {
  Enabled = "enabled",
  Inaccessible = "inaccessible",
  Denied = "denied",
  Disabled = "disabled",
}

/**
 * Named constants for theme colouring
 */
export enum cThemeColorType {
  Primary = "primary",
  Secondary = "secondary",
  Danger = "danger",
}

/**
 * Named constants for monochrome colouring
 */
export enum cMonoColorType {
  White = "white",
  Dark = "dark",
  Light = "light",
}

/**
 * Named constants for semantic colors e.g. error, warning, info...
 */
export enum cSemanticColorType {
  Warning = "warning",
  Info = "info",
  Error = "error",
  Success = "success",
}

/**
 * Named constants for component sizing
 */
export enum cSizeType {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

/**
 * Named constants for routes
 */
export enum cRouteType {
  Login = "/",
  Document = "document",
  Walk = "walk",
  PasswordReset = "password-reset",
  Admin = "admin",
  Matters = "matters",
  Notifications = "notification",
  InterviewLog = "interview-log",
  Analytics = "analytics",
  NotFoundPage = "*",
}

/**
 * Named constants for analytics URL routes
 */
export enum cAnalyticsRouteType {
  PackagedReports = "packaged-reports",
  DocumentAnalytics = "document-analytics",
  MatterAnalytics = "matter-analytics",
  Graphs = "graphs",
}

export const APIKEY = "apiKey"; // API BearerToken localStorage key
export const APIKEY_EXPIRY = "apiKeyExpiry"; // API BearerToken expiry localStorage key
export const CUSTOMER_CODE = "customerCode"; // customerCode localStorage key (JSON)
export const SAML_REDIRECT = "samlRedirect"; // Redirect information for SAML logins
export const WALK_AMEND_TYPE = "walkAmendType"; // The last used type of document amend - to restore the action screen
export const CUSTOMER_SUPPORT_TEL_NO = "+27 (0)21 300 3808";
export const WALK_RETURN_URL = "walkReturnUrl"; // The url to return to when a walk finishes
export const MATTER_CLONE_INITIATION_CONTEXT = "matterCloneInitiationContext"; // The context to return to when a matter clone finishes

// Form error messages
export const REQUIRED_FIELD_MESSAGE = "This field is required";
export const ALPHABETICAL_AND_SPACE_MESSAGE = "Only alphabetical characters and spaces allowed";
export const PAST_DATE_ERROR = "Date cannot be in the past";

// Regex
export const ALPHABETICAL_AND_SPACE_REGEX = /^[a-zA-Z ]*$/;

// Field max lengths
export const MAX_LENGTH_VERSION_NOTES = 1000;
