import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import FormField, { cFormFieldType } from "../../../../../components/FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import { postDocumentTimeline } from "../../../../../modules/documentsSlice";
import { postCancelESign, selectESignStatus } from "../../../../../modules/esignSlice";

/**
 * Form inputs
 */
type Inputs = {
  reason: string;
};

/**
 * Form default values
 */
const defaultValues = {
  reason: "",
};

interface ICancelESignModal {
  isOpen: boolean;
  handleClose: () => void;
  esignID?: number | null;
  documentID: number;
}

/**
 * Modal to confirm cancellation of an esign process
 * @param isOpen       Is the modal open?
 * @param handleClose  Function to close the modal
 * @param esignID      The esign id
 * @param documentID   The document id
 * @returns JSX.Element
 */
function CancelESignModal({ isOpen, handleClose, esignID, documentID }: ICancelESignModal) {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    reset,
  } = useForm<Inputs>({ defaultValues });
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectESignStatus);

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    handleClose();
    reset();
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (esignID) {
        const { reason } = data;
        await dispatch(postCancelESign({ id: esignID, reason })).unwrap();
        dispatch(postDocumentTimeline(documentID));
        toast("Signing process cancelled");
        handleClose();
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleModalClose} testId="cancel-esign-modal" size="md">
      <ModalHeader>
        <ModalTitle>Cancel Signing Process</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <form id="cancel-esign-form" onSubmit={handleSubmit(onSubmit)}>
          <FormField
            testId="cancel-reason"
            type={cFormFieldType.Textarea}
            label="Please provide a reason for cancelling the process"
            name="reason"
            register={register}
            error={errors.reason}
            fullWidth
            required
          />
        </form>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleModalClose} color={cThemeColorType.Secondary} testId="cancel-esign-modal-close-button">
          Go back
        </Button>
        <Button
          formId="cancel-esign-form"
          type={cButtonType.SubmitType}
          isLoading={status === cStatusType.Loading}
          testId="cancel-esign-modal-submit-button"
        >
          Cancel Process
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default CancelESignModal;
