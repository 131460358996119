import { format } from "date-fns";
import { chain, filter, map, startCase } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IFieldOption } from "../../../../components/FormField/FormField";
import {
  EDateFilterOptions,
  EDocumentClasses,
  EExportFileFormat,
  ICustomExportFilters,
  postDeleteDocumentTypeReportCustomExport,
  postDocumentTypeCustomExports,
  postDocumentTypeReportFilters,
  postDocumentTypeReportTemplateSave,
  postRunDocumentTypeReport,
  selectAllCustomExports,
  selectAllDocumentAnalyticsFilters,
  selectDeleteTemplateStatus,
  selectDeleteTemplateStatusError,
  selectDocumentAnalyticsFiltersStatus,
  selectDocumentTypeReportTemplateSaveStatus,
  selectRunDocumentTypeReportStatus,
} from "../../../../modules/analyticsSlice";
import { selectAllowDocumentStages } from "../../../../modules/customerSlice";
import { selectDocumentStages } from "../../../../modules/documentStagesSlice";
import { selectDocumentStates } from "../../../../modules/documentStatesSlice";
import { selectUser } from "../../../../modules/userSlice";
import { IUser, selectUsers } from "../../../../modules/usersSlice";
import { errorToast } from "../../../../toast/toast";
import { downloadBase64File } from "../../../../utils/download/download";
import ConfirmDeleteReportTemplateModal from "./ConfirmDeleteReportTemplateModal/ConfirmDeleteReportTemplateModal";
import CustomExport, { Inputs } from "./CustomExport/CustomExport";

/**
 * The Custom export container
 * @returns JSX.Element
 */

function CustomExportContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { documentTypeID, configID } = useParams();
  const filtersStatus = useAppSelector(selectDocumentAnalyticsFiltersStatus);
  const users = useAppSelector(selectUsers);
  const user = useAppSelector(selectUser);
  const documentStages = useAppSelector(selectDocumentStages);
  const documentStates = useAppSelector(selectDocumentStates);
  const filters = useAppSelector(selectAllDocumentAnalyticsFilters);
  const allowDocumentStages = useAppSelector(selectAllowDocumentStages);
  const runDocumentTypeReportStatus = useAppSelector(selectRunDocumentTypeReportStatus);
  const saveDocumentTypeReportTemplateStatus = useAppSelector(selectDocumentTypeReportTemplateSaveStatus);
  const deleteTemplateStatus = useAppSelector(selectDeleteTemplateStatus);
  const configName = filters?.configName;
  const updatedCustomExports = useAppSelector(selectAllCustomExports);
  const [newTemplateName, setNewTemplateName] = useState<string | null>(null);
  const [isDeleteReportTemplateModalOpen, setIsDeleteReportTemplateModalOpen] = useState(false);
  const deleteError = useAppSelector(selectDeleteTemplateStatusError);

  // Fetch filters for the custom export
  useEffect(() => {
    if (configID) {
      dispatch(postDocumentTypeReportFilters({ configID: Number(configID), documentTypeID: Number(documentTypeID) }));
    }
  }, [configID]);

  // if a new config is created, get the updated customExports and navigate to the new configID
  useEffect(() => {
    if (newTemplateName) {
      const newConfig = updatedCustomExports.find((customExport) => customExport.name === newTemplateName);
      if (newConfig) {
        navigate(`/analytics/document-analytics/${documentTypeID}/${newConfig.id}`);
      }
    }
  }, [newTemplateName, updatedCustomExports]);

  const owners = useMemo(() => {
    const documentOwnerIds = filters?.optionalFilters?.owners ? filters.optionalFilters.owners.ids.map(Number) : [];
    if (documentOwnerIds) {
      return filter(users, ({ id }) => documentOwnerIds.includes(id));
    }
    return [];
  }, [filters, users]);

  const ownerFilterOptions: IFieldOption[] & Pick<IUser, "isInternal"> = chain(owners)
    // Sort by lastName, firstName ignoring case
    .orderBy([(owner) => owner.lastName?.toLowerCase(), (owner) => owner.firstName.toLowerCase()])
    // Pull EMBED user to the top
    .sort((owner) => {
      if (owner.isInternal) {
        return -1;
      } else if (!owner.isInternal) {
        return 1;
      }
      return 0;
    })
    // Pull logged in user to the top
    .sort((owner) => {
      if (owner.id === user?.id) {
        return -1;
      } else if (owner.id !== user?.id) {
        return 1;
      }
      return 0;
    })
    .map((owner) => {
      return {
        label:
          owner.id === user?.id
            ? `Me (${owner.firstName} ${owner.lastName})`
            : owner.isInternal
              ? `${owner.firstName}`
              : `${owner.lastName}, ${owner.firstName}`,
        value: owner.id,
        isInternal: owner.isInternal,
      };
    })
    .value();

  // Update stage filters
  const stageFilterOptions: IFieldOption[] = useMemo(() => {
    // If document stages are not allowed, return an empty array to prevent
    // throwing an error when trying to map over undefined
    if (!allowDocumentStages) {
      return [];
    }

    return (
      filters?.optionalFilters?.stages.ids.map((id: number) => ({
        label: documentStages[id]?.displayName,
        value: id,
        key: `stage_${id}`,
      })) || []
    );
  }, [filters, documentStages, allowDocumentStages]);

  // Update document state filters with value
  const stateFilterOptions: IFieldOption[] = useMemo(() => {
    return (
      filters?.optionalFilters?.states.ids.map((id: number) => ({
        label: documentStates[id]?.displayName,
        value: id,
        key: `state_${id}`,
      })) || []
    );
  }, [filters, documentStates]);

  // get the includeTerminated value from api
  const includeTerminatedValue = filters?.optionalFilters?.includeTerminated;

  // Create date filter options
  const dateFilterOptions: IFieldOption[] = map(filters?.dateFilter?.options, (option) => ({
    label: option,
    value: option,
  }));

  // Create format options
  const formatOptions: IFieldOption[] = map(filters?.format?.options, (option) => {
    let label;
    if (option === "xlsx") {
      label = "MS Excel";
    } else if (option === "xml") {
      label = option.toUpperCase();
    } else {
      label = option;
    }

    return {
      label: label,
      value: option,
    };
  });

  // Create document class options
  const documentClassOptions: IFieldOption[] = map(filters?.documentClass?.options, (option) => ({
    label: startCase(option),
    value: option,
  }));

  /**
   * Handles the event when the back button is clicked.
   * Navigates the user back to the originating documentTypeID.
   */
  const handleBackClick = () => {
    if (documentTypeID) {
      navigate(`/analytics/document-analytics/${documentTypeID}/`);
    }
  };

  /**
   * Handles the closing of the delete report template modal
   */
  const handleDeleteReportTemplateModalClose = () => {
    setIsDeleteReportTemplateModalOpen(false);
  };

  /**
   * Handles the deletion of a report template
   */
  async function handleDeleteReportTemplate() {
    try {
      await dispatch(postDeleteDocumentTypeReportCustomExport(Number(configID))).unwrap();
      toast("Custom report deleted");
      // close the modal
      setIsDeleteReportTemplateModalOpen(false);
      // navigate back to the document analytics page
      navigate(`/analytics/document-analytics/${documentTypeID}/`);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Builds the payload for run and save actions
   * @param data The form values
   * @returns The payload for the custom export
   */
  const buildPayload = (data: Inputs) => {
    // Destructure fields related to optionalFilters to group them separately
    // Exclude 'Select all' logic from the the data as it is for UI control only
    const {
      documentClass,
      descriptionContains,
      documentDataContains,
      states,
      includeTerminated,
      stages,
      owners,
      dateFilter,
      startDate,
      endDate,
      format: fileFormat,
      advanced,
    } = data;

    const templateName = data.templateName || configName || "";

    // Convert data before dispatching
    const formattedStartDate = startDate ? format(new Date(startDate), "yyyy-MM-dd") : "";
    const formattedEndDate = endDate ? format(new Date(endDate), "yyyy-MM-dd") : "";

    let selectedDocumentClass = documentClass;
    if (selectedDocumentClass === "" || selectedDocumentClass === "All") {
      selectedDocumentClass = "all";
    }

    // convert documentTypeID to number
    const documentTypeIDasNumber = parseInt(documentTypeID ?? "", 10);

    // convert ids to numbers
    const stateIDsAsNumbers = states ? states.map(Number) : [];
    const ownerIDsAsNumbers = owners ? owners.map(Number) : [];
    const stageIDsAsNumbers = stages ? stages.map(Number) : [];

    const descriptionContainsCaseSensitive = Boolean(descriptionContains.caseSensitive);
    const documentDataContainsCaseSensitive = Boolean(documentDataContains.caseSensitive);

    const includeTerminatedBoolean = !!includeTerminated;

    // Transform the advanced filters
    // Type guard to check if value is an object with a value property
    const isOptionObject = (value: any): value is { label: string; value: string } => {
      return typeof value === "object" && value !== null && "value" in value;
    };
    const transformedAdvancedFilters =
      filters?.optionalFilters?.advanced?.map((filter) => {
        const { id, name } = "text" in filter ? filter.text : filter.list;
        let value = advanced?.[name]?.value;

        if (isOptionObject(value)) {
          value = value.value;
        }

        return {
          id,
          name,
          value: (value as string) || "",
          include: !!advanced?.[name]?.include, // Ensure `include?` is a boolean
          filter: !!advanced?.[name]?.filter, // Ensure `filter?` is a boolean
        };
      }) || [];

    // Common payload for runReport and saveChanges
    const commonPayload = {
      documentTypeID: documentTypeIDasNumber,
      documentClass: selectedDocumentClass as EDocumentClasses,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      dateFilter: dateFilter as EDateFilterOptions,
      format: fileFormat as EExportFileFormat,
      optionalFilters: {
        descriptionContains: {
          value: descriptionContains.value,
          caseSensitive: descriptionContainsCaseSensitive,
        },
        documentDataContains: {
          value: documentDataContains.value,
          caseSensitive: documentDataContainsCaseSensitive,
        },
        includeTerminated: includeTerminatedBoolean,
        states: stateIDsAsNumbers,
        stages: stageIDsAsNumbers,
        owners: ownerIDsAsNumbers,
        advanced: transformedAdvancedFilters,
      },
    };
    return { commonPayload, templateName, documentTypeIDasNumber };
  };

  /**
   * Handles the save template click
   * @param data The form values
   * @returns void
   * @async
   */
  const handleSaveTemplateClick = async (data: Inputs & { templateName?: string }) => {
    try {
      const { commonPayload, templateName } = buildPayload(data);
      await dispatch(postDocumentTypeReportTemplateSave({ ...commonPayload, templateName })).unwrap();
      toast("Template saved");
      // reload the report
      dispatch(postDocumentTypeReportFilters({ configID: Number(configID), documentTypeID: Number(documentTypeID) }));
    } catch (error: any) {
      errorToast(error.message || error.toString());
    }
  };

  /**
   * Handles the save as template click
   * @param data The form data to submit
   */
  const handleSaveAsTemplateClick = async (data: Inputs & { templateName?: string }) => {
    try {
      const { commonPayload, templateName, documentTypeIDasNumber } = buildPayload(data);
      await dispatch(postDocumentTypeReportTemplateSave({ ...commonPayload, templateName })).unwrap();
      toast("Saved as new template");
      // set new name and navigate to the new report
      setNewTemplateName(templateName);
      await dispatch(postDocumentTypeCustomExports(documentTypeIDasNumber)).unwrap();
    } catch (error: any) {
      errorToast(error.message || error.toString());
    }
  };

  /**
   * Handles the run report click
   * @param data The form values
   * @returns void
   * @async
   */
  const handleRunReportClick = async (data: Inputs) => {
    try {
      const { commonPayload } = buildPayload(data);
      const action = await dispatch(postRunDocumentTypeReport(commonPayload));
      const response = action.payload;
      const { fileData, fileName } = response;

      downloadBase64File({ fileData, fileName });
    } catch (error: any) {
      errorToast(error.message || error.toString());
    }
  };

  return (
    <>
      <CustomExport
        configID={Number(configID) || null}
        filters={filters as ICustomExportFilters}
        filtersStatus={filtersStatus}
        ownerFilterOptions={ownerFilterOptions}
        stageFilterOptions={stageFilterOptions}
        stateFilterOptions={stateFilterOptions}
        includeTerminatedValue={includeTerminatedValue || false}
        dateFilterOptions={dateFilterOptions}
        formatOptions={formatOptions}
        documentClassOptions={documentClassOptions}
        configName={configName}
        handleBackClick={handleBackClick}
        allowDocumentStages={allowDocumentStages}
        runDocumentTypeReportStatus={runDocumentTypeReportStatus}
        saveDocumentTypeReportTemplateStatus={saveDocumentTypeReportTemplateStatus}
        handleSaveTemplateClick={handleSaveTemplateClick}
        handleSaveAsTemplateClick={handleSaveAsTemplateClick}
        setIsDeleteReportTemplateModalOpen={setIsDeleteReportTemplateModalOpen}
        handleRunReportClick={handleRunReportClick}
      />
      <ConfirmDeleteReportTemplateModal
        isOpen={isDeleteReportTemplateModalOpen}
        handleModalClose={handleDeleteReportTemplateModalClose}
        deleteTemplateStatus={deleteTemplateStatus}
        handleDeleteReportTemplate={handleDeleteReportTemplate}
        error={deleteError}
      />
    </>
  );
}

export default CustomExportContainer;
