import { chain, filter, find, map, orderBy, reduce } from "lodash";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { cRouteType, cStatusType, WALK_RETURN_URL } from "../../app/constants";
import { useAppDispatch, useAppSelector, useCreateOrCloneMatterActions } from "../../app/hooks";
import { IFieldOption } from "../../components/FormField/FormField";
import InformationUpdatedModal from "../../components/InformationUpdatedModal/InformationUpdatedModal";
import { openInformationUpdatedModal, selectIsInformationUpdatedModalOpen } from "../../modules/documentsSlice";
import {
  deleteMatter,
  getMattersFilters,
  postMatters,
  resetLinkedMattersState,
  resetMattersState,
  resetSideFilterState,
  selectMatters,
  selectMattersEmptyType,
  selectMattersError,
  selectMattersOwnerIds,
  selectMattersStatus,
} from "../../modules/mattersSlice";
import {
  getMatterTypeMilestones,
  getMatterTypesFilterTags,
  getMatterTypeUniqueTagValues,
  IMatterType,
  resetMilestonesState,
  selectMatterTypes,
  selectMilestones,
} from "../../modules/matterTypesSlice";
import { selectOwners, updateOwners } from "../../modules/ownersSlice";
import { selectUser } from "../../modules/userSlice";
import { selectUsers } from "../../modules/usersSlice";
import { EWalkAction, postWalkAction, selectWalkIsActive, updateWalkIsActive } from "../../modules/walkSlice";
import { isDocumentAbandonedError, isOutdatedContentError } from "../../utils/errors/errors";
import Matters from "./Matters/Matters";
import { IHandleFocus } from "./Matters/Sidebar/AdvancedOptionalFiltersContents/AdvancedOptionalFiltersContents";

export enum MatterKey {
  MatterType = "matterType",
  MatterDocumentType = "matterDocumentType",
  OwnerUser = "ownerUser",
  MileStone = "milestone",
}

/**
 * The matters container
 * @returns JSX.Element
 */
function MattersContainer(): JSX.Element {
  const navigate = useNavigate(); // History object for handling redirect
  const dispatch = useAppDispatch();
  const rawMatters = useAppSelector(selectMatters);
  const mattersStatus = useAppSelector(selectMattersStatus);
  const matterTypes = useAppSelector(selectMatterTypes);
  const milestones = useAppSelector(selectMilestones);
  const error = useAppSelector(selectMattersError);
  const emptyType = useAppSelector(selectMattersEmptyType); // Get empty type
  const owners = useAppSelector(selectOwners);
  const users = useAppSelector(selectUsers);
  const user = useAppSelector(selectUser);
  const matterOwnerIds = useAppSelector(selectMattersOwnerIds);
  const walkIsActive = useAppSelector(selectWalkIsActive); // Get walk state
  const isInformationUpdatedModalOpen = useAppSelector(selectIsInformationUpdatedModalOpen);
  const [inViewRef, inView] = useInView();
  const mattersTableRef = useRef<any>(null);
  const { handleCreateOrCloneMatterClick } = useCreateOrCloneMatterActions();

  useEffect(() => {
    if (inView && mattersStatus !== cStatusType.Loading) {
      dispatch(postMatters()); // Load matters when react-intersection-observer is in view
    }
  }, [inView]);

  useEffect(() => {
    // If walk is active, redirect to the walk view
    if (walkIsActive) {
      navigate(`/${cRouteType.Walk}`);
    }
  }, [walkIsActive]);

  /**
   * Dispatches an action to reset the document filter data
   */
  function resetFilterData() {
    dispatch(resetSideFilterState());
  }

  /**
   * Dispatches an action to reset the milestones
   */
  function resetMilestones() {
    dispatch(resetMilestonesState());
  }

  /**
   * Resumes a walk
   * @param wipID          The walk in progress id
   * @param id             The walk matter ID
   * @param compositeState The walk document/matter composite state
   */
  async function resumeWalk(wipID: number, id: number, compositeState?: number) {
    try {
      await dispatch(postWalkAction({ type: EWalkAction.ResumePaused, wipID, compositeState })).unwrap();
      dispatch(updateWalkIsActive(true));
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${id}`);
    } catch (error) {
      const errorCode = parseInt(error as string, 10);
      // Delete the matter if it has been abandonded
      if (isDocumentAbandonedError(errorCode)) {
        const foundMatter = find(rawMatters, (matter) => matter.walkInProgress?.wipID === wipID);
        if (foundMatter) {
          dispatch(deleteMatter(foundMatter.id));
        }
      }
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      }
      console.error(error);
    }
  }

  /**
   * Resets milestones and dispatches an action to fetch the type optional filters
   */
  function getTypeOptionalFilters(id: number): void {
    resetMilestones();
    dispatch(getMatterTypesFilterTags(id));
    dispatch(getMatterTypeMilestones(id));
  }

  useEffect(() => {
    dispatch(getMattersFilters()); // Load matters filters for the sidebar
    dispatch(resetLinkedMattersState()); // Reset linked matters
    resetMatters(); // Reset matters on mount - this prevents a previously open matter from being rendered by itself

    return () => {
      resetMatters(); // Reset the side filter when we navigate away
      resetFilterData(); // Reset documents state when we navigate away
      resetMilestones(); // Reset milestones state when we navigate away
    };
  }, []);

  // Update owners needed for the matter filter
  useEffect(() => {
    if (matterOwnerIds) {
      const owners = filter(users, (user) => matterOwnerIds.includes(user.id));
      dispatch(updateOwners(owners));
    }
  }, [users, matterOwnerIds]);

  // Type filter options for the sidebar
  const typeFilterOptions: Array<IFieldOption & Pick<IMatterType, "isRetired">> = map(matterTypes, (type) => {
    return {
      label: type.name,
      value: type.id,
      isRetired: type.isRetired,
    };
  }).sort((a, b) => a.label.localeCompare(b.label));

  // Milestone filter options for the sidebar
  const milestoneFilterOptions: IFieldOption[] = map(milestones, (type) => {
    return {
      label: type.displayName,
      value: type.id,
    };
  }).sort((a, b) => a.label.localeCompare(b.label));

  // Advanced optional filter tags split by matter type ID
  const matterTypeTags = reduce(
    matterTypes,
    (acc, matterType) => {
      if (matterType.hasOwnProperty("filterTags")) {
        acc[matterType.id] = matterType.filterTags;
      }

      return acc;
    },
    {} as { [key: number]: IMatterType["filterTags"] },
  );

  typeFilterOptions.unshift({ label: "Select...", value: "", isRetired: false });

  const ownerFilterOptions = chain(owners)
    // Sort by lastName, firstName ignoring case
    .orderBy([(owner) => owner.lastName?.toLowerCase(), (owner) => owner.firstName.toLowerCase()])
    // Pull EMBED user to the top
    .sort((owner) => {
      if (owner.isInternal) {
        return -1;
      } else if (!owner.isInternal) {
        return 1;
      }
      return 0;
    })
    // Pull logged in user to the top
    .sort((owner) => {
      if (owner.id === user?.id) {
        return -1;
      } else if (owner.id !== user?.id) {
        return 1;
      }
      return 0;
    })
    .map((owner) => {
      return {
        label:
          owner.id === user?.id
            ? `Me (${owner.firstName} ${owner.lastName})`
            : owner.isInternal
              ? `${owner.firstName}`
              : `${owner.lastName}, ${owner.firstName}`,
        value: owner.id,
        isInternal: owner.isInternal,
      };
    })
    .value();

  // Map the matters and replace IDs with descriptive data
  const matters = orderBy(
    map(rawMatters, (matter) => {
      const matterType = matterTypes?.[matter.matterType.id]?.name;
      const ownerObj = users?.[matter.ownerUser.id];

      return {
        ...matter,
        matterType,
        owner: ownerObj?.lastName ? `${ownerObj?.firstName} ${ownerObj?.lastName}` : ownerObj?.firstName,
      };
    }),
    ["historySortDate"],
    ["desc"],
  );

  /**
   * Scrolls to the top of the matters table
   * so we can take the user back to the top if they apply a filter
   */
  function scrollToTopOfMattersTable() {
    mattersTableRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * Dispatches an action to reset the matters state
   */
  function resetMatters() {
    dispatch(resetMattersState());
  }

  /**
   * Dispatch a call to get filtered documents
   * @param filterValues The filter values
   */
  function onFilter() {
    dispatch(postMatters());
    scrollToTopOfMattersTable();
  }

  /**
   * Fetch tag values on focus of the dropdown field
   * @param matterTypeID The matter type ID being queried
   * @param tagName      The tag name being queried
   */
  function handleOptionalFilterFocus({ matterTypeID, tagName }: IHandleFocus) {
    dispatch(getMatterTypeUniqueTagValues({ matterTypeID, tagName }));
  }

  return (
    <>
      <Matters
        inViewRef={inViewRef}
        matters={matters}
        rawMatters={rawMatters}
        typeFilterOptions={typeFilterOptions}
        milestoneFilterOptions={milestoneFilterOptions}
        ownerFilterOptions={ownerFilterOptions}
        onFilter={onFilter}
        onInputChange={() => {}}
        error={error}
        emptyType={emptyType}
        mattersStatus={mattersStatus}
        mattersTableRef={mattersTableRef}
        resetMatters={resetMatters}
        matterTypeTags={matterTypeTags}
        handleOptionalFilterFocus={handleOptionalFilterFocus}
        getTypeOptionalFilters={getTypeOptionalFilters}
        resetMilestones={resetMilestones}
        resumeWalk={resumeWalk}
        matterTypes={matterTypes}
        handleCreateOrCloneMatterClick={handleCreateOrCloneMatterClick}
      />
      <InformationUpdatedModal isOpen={isInformationUpdatedModalOpen} />
    </>
  );
}

export default MattersContainer;
