import Div from "../../../../../../components/Div/Div";

interface IColumnActionWrapper {
  children?: React.ReactNode;
}

/**
 * Wrap column actions in a div to align them correctly
 * @param children React component children
 * @returns JSX.Element
 */
const ColumnActionWrapper = ({ children }: IColumnActionWrapper) => {
  return (
    <Div display={{ base: "flex" }} alignItems={{ base: "center" }} pt={{ base: 4 }}>
      {children}
    </Div>
  );
};
export default ColumnActionWrapper;
