import { Dispatch, SetStateAction } from "react";
import { cAccessType, cSizeType, cThemeColorType } from "../../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../../app/types";
import Button, { EButtonVariant } from "../../../../../../components/Button/Button";
import Div from "../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../components/Icon/Icon";
import Popover from "../../../../../../components/Popover/Popover";
import Column from "../../../../../../components/Row/Column/Column";
import Row from "../../../../../../components/Row/Row";
import Typography from "../../../../../../components/Typography/Typography";
import { ISpawnItem } from "../../../../../../modules/matterTasksSlice";
import { IUser } from "../../../../../../modules/usersSlice";
import { EWalkType, IPostMatterLaunchTask } from "../../../../../../modules/walkSlice";
import { IMatterTasksColumn } from "../MatterTasksColumn";
import styles from "./MatterTask.module.scss";
import TaskLockedMessageContainer from "./TaskLockedMessageContainer/TaskLockedMessageContainer";

// Partial type for the matter tasks column
type TPartialMatterTasksColumn = Pick<IMatterTasksColumn, "handleDraftDocument" | "setSelectedTask" | "allowEmbed">;

// Partial types for the matter tasks column with optional setSelectedTask and allowEmbed
type TPartialMatterTasksColumnWithOptionalSetSelectedTask = {
  handleDraftDocument: TPartialMatterTasksColumn["handleDraftDocument"];
  setSelectedTask?: TPartialMatterTasksColumn["setSelectedTask"];
  allowEmbed?: TPartialMatterTasksColumn["allowEmbed"];
};

// Combine the spawn item with the post matter launch task types and include taskAssignee and setEmbedData props
type TMatterTask = ISpawnItem &
  Omit<IPostMatterLaunchTask, "spawnID"> &
  TPartialMatterTasksColumnWithOptionalSetSelectedTask & {
    taskAssignee?: IUser;
    setEmbedData: Dispatch<
      SetStateAction<{ custWalkTypeID: number; matterID?: number; interviewSpawnID?: number } | null>
    >;
    setInvalidateData: Dispatch<
      SetStateAction<{
        interviewSpawnID: number;
        wipDTM?: string;
      } | null>
    >;
  };

// Tooltip text constants
export const tooltipUpload = "Start via upload";
export const tooltipDraft = "Start via draft";
const tooltipDenied = "Insufficient privileges";
const tooltipInaccessible = "Currently locked for enhancement";
const tooltipWillLaunch = "Will launch automatically";
export const tooltipSendEmbed = "Send in email";
const tooltipIsMultiUse = "Multi-use";

/**
 * Is the access denied?
 * @param access The access state of the upload/draft button
 * @returns boolean
 */
function isAccessDenied(access: cAccessType) {
  return access === cAccessType.Denied;
}

/**
 * Is the access inaccessible?
 * @param access The access state of the upload/draft button
 * @returns boolean
 */
function isAccessInaccessible(access: cAccessType) {
  return access === cAccessType.Inaccessible;
}

/**
 * Is the access disabled?
 * @param access The access state of the upload/draft button
 * @returns boolean
 */
function isAccessDisabled(access: cAccessType) {
  return access === cAccessType.Disabled;
}

/**
 * Is the button disabled?
 * @param access   The access state of the upload/draft button
 * @param isLocked Is the spawn locked
 * @returns boolean
 */
export function isBtnDisabled(access: cAccessType, isLocked?: boolean) {
  return isAccessDisabled(access) || isAccessDenied(access) || isAccessInaccessible(access) || isLocked;
}

/**
 * Returns the correct tooltip text depending on the button state
 * @param access      The access state of the upload/draft button
 * @param tooltipText The tooltip text to show if the button is enabled
 * @returns string
 */
export function getTooltipText(access: cAccessType, tooltipText: string) {
  if (isAccessDisabled(access)) {
    return tooltipInaccessible;
  } else if (isAccessDenied(access)) {
    return tooltipDenied;
  } else if (isAccessInaccessible(access)) {
    return tooltipInaccessible;
  } else {
    return tooltipText;
  }
}

// Common popover props
const popoverProps = {
  variant: "tooltip" as const,
  popoverPlacement: "top" as EPopoverPlacement,
  width: "auto",
};

/**
 * A single matter task item
 * @param id                  The spawn ID
 * @param name                The spawn name
 * @param description         The spawn description
 * @param state               Spawn draftable state
 * @param walkType            Draft or file walk type
 * @param custWalkTypeID      Walk type ID to start interview
 * @param handleDraftDocument Start spawn walk
 * @param matterID            The wrapping matter ID
 * @param isLaunchExternally  Is the spawn launched externally? eg. API
 * @param isSingleUse         Is the spawn single use?
 * @param compositeState      The composite state of the spawn
 * @param taskAssignee        The assignee for the task
 * @param setSelectedTask     Set the selected task for reassignment modal
 * @param canReassign         Can the task be reassigned?
 * @param allowEmbed          Is embed allowed in the customer config?
 * @param setEmbedData        Set the embed data for the send embed email notification modal
 * @param setInvalidateData   Set the invalidate data for the invalidate embed link modal
 * @returns JSX.Element
 */
function MatterTask({
  id,
  name,
  description,
  state,
  walkType,
  custWalkTypeID,
  handleDraftDocument,
  matterID,
  isLaunchExternally,
  isSingleUse,
  compositeState,
  taskAssignee,
  setSelectedTask,
  canReassign,
  allowEmbed,
  setEmbedData,
  setInvalidateData,
}: TMatterTask): JSX.Element {
  /**
   * Renders the correct button
   * @returns JSX.Element
   */
  function renderButton() {
    if (isLaunchExternally) {
      return (
        <Popover
          {...popoverProps}
          popoverContents={tooltipWillLaunch}
          divProps={{
            icon: EIcon.ApiTask,
            iconColor: cThemeColorType.Secondary,
          }}
        />
      );
    }

    // If the spawn is locked
    if (state.isLocked) {
      // If the locked spawn was sent as an embed, render the invalidate embed button
      if (state.activeEmbedCreateDTM) {
        return (
          <Popover
            {...popoverProps}
            popoverContents={<Div>Invalidate EMBED link</Div>}
            buttonContents={<Icon icon={EIcon.Unlink} />}
            buttonProps={{
              size: cSizeType.Small,
              color: cThemeColorType.Secondary,
              testId: "invalidate-embed-btn",
              onClick: () => {
                setInvalidateData({
                  interviewSpawnID: id,
                  wipDTM: state.wipDTM,
                });
              },
            }}
          />
        );
      }

      // Otherwise, render the spawn locked button
      return (
        <Button
          size={cSizeType.Small}
          variant={EButtonVariant.Square}
          icon={EIcon.Unlink}
          disabled={state.lockedState === cAccessType.Disabled}
          color={cThemeColorType.Secondary}
          testId="spawn-locked-btn"
        />
      );
    } else {
      if (walkType === EWalkType.File) {
        return (
          <Div display={{ base: "flex" }} className={styles.btnWrapper}>
            <Popover
              {...popoverProps}
              popoverContents={<Div>{getTooltipText(state.state, tooltipUpload)}</Div>}
              buttonContents={<Icon icon={EIcon.UploadDocument} />}
              buttonProps={{
                variant: EButtonVariant.Square,
                size: cSizeType.Small,
                color: cThemeColorType.Secondary,
                disabled: isBtnDisabled(state.state, state.isLocked),
                onClick: () => {
                  handleDraftDocument({ custWalkTypeID, matterID, spawnID: id, compositeState });
                },
                testId: "spawn-file-btn",
              }}
            />
            {allowEmbed && (
              <Popover
                {...popoverProps}
                popoverContents={<Div>{getTooltipText(state.state, tooltipSendEmbed)}</Div>}
                buttonContents={<Icon icon={EIcon.Email} />}
                buttonProps={{
                  variant: EButtonVariant.Square,
                  size: cSizeType.Small,
                  color: cThemeColorType.Secondary,
                  disabled: isBtnDisabled(state.state, state.isLocked),
                  testId: "embed-matter-button",
                  onClick: () => setEmbedData({ custWalkTypeID, matterID, interviewSpawnID: id }),
                }}
              />
            )}
          </Div>
        );
      } else {
        return (
          <Div display={{ base: "flex" }} className={styles.btnWrapper}>
            <Popover
              {...popoverProps}
              popoverContents={<Div>{getTooltipText(state.state, tooltipDraft)}</Div>}
              buttonContents={<Icon icon={EIcon.UpdateDocument} />}
              buttonProps={{
                size: cSizeType.Small,
                variant: EButtonVariant.Square,
                color: cThemeColorType.Secondary,
                disabled: isBtnDisabled(state.state, state.isLocked),
                onClick: () => {
                  handleDraftDocument({ custWalkTypeID, matterID, spawnID: id, compositeState });
                },
                testId: "spawn-draft-btn",
              }}
            />
            {allowEmbed && (
              <Popover
                {...popoverProps}
                popoverContents={<Div>{getTooltipText(state.state, tooltipSendEmbed)}</Div>}
                buttonContents={<Icon icon={EIcon.Email} />}
                buttonProps={{
                  size: cSizeType.Small,
                  variant: EButtonVariant.Square,
                  color: cThemeColorType.Secondary,
                  disabled: isBtnDisabled(state.state, state.isLocked),
                  testId: "embed-matter-button",
                  onClick: () => setEmbedData({ custWalkTypeID, matterID, interviewSpawnID: id }),
                }}
              />
            )}
          </Div>
        );
      }
    }
  }

  return (
    <Div className={styles.matterTask} testId="matter-task">
      <Row justifyContent={{ base: "space-between" }} alignItems={{ base: "flex-start" }}>
        <Column all={12} md={7} lg={8} spacing={{ pr: 4 }}>
          <Typography className={styles.name} weight="medium">
            {!isSingleUse && (
              <>
                <Popover
                  {...popoverProps}
                  popoverContents={tooltipIsMultiUse}
                  divProps={{
                    icon: EIcon.MultiUse,
                    iconColor: cThemeColorType.Secondary,
                    className: styles.multiUseIcon,
                  }}
                />
              </>
            )}
            {name}
          </Typography>
          <Typography className={styles.description}>{description}</Typography>
          {taskAssignee && setSelectedTask && (
            <Button
              spacing={{ pt: 4 }}
              variant={EButtonVariant.Link}
              icon={EIcon.User}
              size={cSizeType.Small}
              className={`${styles.assignee} ${!canReassign ? styles.disabled : ""}`}
              onClick={() => {
                if (canReassign) {
                  setSelectedTask({ id, name, description, assigneeUserID: taskAssignee.id });
                }
              }}
              disabled={!canReassign}
            >{`${taskAssignee?.firstName} ${taskAssignee?.lastName}`}</Button>
          )}
          {(state.wipDTM || state.activeEmbedCreateDTM) && (
            <Div spacing={{ pt: 4 }}>
              <TaskLockedMessageContainer
                wipDTM={state.wipDTM}
                wipUserID={state.wipUserID}
                activeEmbedCreateDTM={state.activeEmbedCreateDTM}
              />
            </Div>
          )}
        </Column>
        <Column
          all={12}
          md={5}
          lg={4}
          display={{ base: "flex" }}
          justifyContent={{ base: "flex-start", md: "flex-end" }}
        >
          {renderButton()}
        </Column>
      </Row>
    </Div>
  );
}

export default MatterTask;
