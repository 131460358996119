import { add, format, parse } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  IPostEmbedSingleUseSend,
  postEmbedSingleUseInitiate,
  postEmbedSingleUseSend,
  resetEmbedSingleUseDetail,
  selectEmbedSingleUseDetail,
  selectEmbedSingleUseError,
  selectEmbedSingleUseSendError,
  selectEmbedSingleUseSendStatus,
  selectEmbedSingleUseStatus,
} from "../../../modules/embedSingleUseSlice";
import { selectUser } from "../../../modules/userSlice";
import { Inputs } from "./SendEmbedEmailNotificationModal/SendEmbedEmailNotificationForm/SendEmbedEmailNotificationForm";
import SendEmbedEmailNotificationModal from "./SendEmbedEmailNotificationModal/SendEmbedEmailNotificationModal";
import SendEmbedEmailNotificationSuccessModal from "./SendEmbedEmailNotificationSuccessModal/SendEmbedEmailNotificationSuccessModal";

/**
 * Interface for embed data to initiate the modal content
 */
interface IEmbedData {
  custWalkTypeID: number;
  matterID?: number;
  interviewSpawnID?: number;
}

interface ISendEmbedEmailNotificationModalContainer {
  isOpen: boolean;
  handleClose: () => void;
  embedData: IEmbedData | null;
  matterRef?: string;
  onSend?: () => void;
}

/**
 * Send embed email notification modal container
 * @param isOpen      Is the modal open?
 * @param handleClose Function to close the modal
 * @param embedData   Data to initiate the modal content
 * @param matterRef   The matter reference code (if applicable)
 * @param onSend      Function to call after the email is sent
 * @returns JSX.Element
 */
function SendEmbedEmailNotificationModalContainer({
  isOpen,
  handleClose,
  embedData,
  matterRef,
  onSend,
}: ISendEmbedEmailNotificationModalContainer): JSX.Element {
  const dispatch = useAppDispatch();

  // State to store the success data
  const [successData, setSuccessData] = useState<{ email?: string; date?: string; time?: string }>({});

  // Get the current user details (user first name will be rendered in message2)
  const user = useAppSelector(selectUser);

  // Get the embed detail, status and error
  const detail = useAppSelector(selectEmbedSingleUseDetail);
  const status = useAppSelector(selectEmbedSingleUseStatus);
  const error = useAppSelector(selectEmbedSingleUseError);

  // Get the send status and error
  const sendStatus = useAppSelector(selectEmbedSingleUseSendStatus);
  const sendError = useAppSelector(selectEmbedSingleUseSendError);

  // Fetch the detail to be set as default form values
  useEffect(() => {
    if (embedData) {
      dispatch(postEmbedSingleUseInitiate(embedData));
    }
  }, [embedData]);

  // Reset the detail when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      dispatch(resetEmbedSingleUseDetail());
    }
  }, [isOpen]);

  /**
   * Handle the form submission
   * @param data The form data
   */
  async function handleSend(data: Inputs): Promise<void> {
    try {
      if (embedData) {
        // Combine the date and time strings to create the expiry date time string
        const combinedDate = `${data.expiresOn} ${data.time.value}`;
        const date = parse(combinedDate, "yyyy/MM/dd HH:mm", new Date());
        const embedExpiryDTM = add(date, { hours: 2 }).toISOString(); // Add 2 hours to match SAST time

        // Create the payload
        const payload: IPostEmbedSingleUseSend = {
          custWalkTypeID: embedData.custWalkTypeID,
          mailTo: data.to,
          mailSubject: data.subject,
          mailPartOne: data.message1,
          mailPartTwo: data.message2,
          embedExpiryDTM,
        };

        // Add the matter ID if it exists (for external resources and spawn documents)
        if (embedData.matterID) {
          payload.matterID = embedData.matterID;
        }

        // Add the interview spawn ID if it exists (for spawn documents)
        if (embedData.interviewSpawnID) {
          payload.interviewSpawnID = embedData.interviewSpawnID;
        }

        // Dispatch the send thunk
        await dispatch(postEmbedSingleUseSend(payload)).unwrap();
        // Set the success data to render in the success modal
        setSuccessData({ email: data.to, date: format(date, "d MMM yyyy"), time: data.time.value as string });
        // Close the modal
        handleClose();
        // Call the onSend function
        if (onSend) {
          onSend();
        }
      }
    } catch (err: any) {
      console.error(err.message);
    }
  }

  return (
    <>
      <SendEmbedEmailNotificationModal
        isOpen={isOpen}
        handleClose={handleClose}
        userFirstName={user?.firstName || ""}
        detail={detail}
        status={status}
        error={error}
        handleSend={handleSend}
        sendStatus={sendStatus}
        sendError={sendError}
        matterRef={matterRef}
      />
      <SendEmbedEmailNotificationSuccessModal
        isOpen={!isEmpty(successData)}
        handleClose={() => setSuccessData({})}
        {...successData}
      />
    </>
  );
}

export default SendEmbedEmailNotificationModalContainer;
