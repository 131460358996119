import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  postMatterTypeCustomExports,
  selectAllMatterAnalytics,
  selectAllMatterCustomExports,
  selectMatterCustomExportsStatus,
} from "../../../../modules/analyticsSlice";
import MatterAnalytics from "./MatterAnalytics/MatterAnalytics";

/**
 * The Matter Analytics Container
 * @returns JSX.Element
 */
function MatterAnalyticsContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const customExportStatus = useAppSelector(selectMatterCustomExportsStatus);
  const customExports = useAppSelector(selectAllMatterCustomExports);
  const { matterTypeID } = useParams();
  const matterAnalytics = useAppSelector(selectAllMatterAnalytics);

  // Fetch custom exports for the matter type
  useEffect(() => {
    if (matterTypeID) {
      dispatch(postMatterTypeCustomExports(Number(matterTypeID))); // Convert id to number
    }
  }, [matterTypeID]);

  return (
    <MatterAnalytics
      customExportStatus={customExportStatus}
      customExports={customExports}
      matterTypeID={Number(matterTypeID)}
      matterAnalytics={Object.values(matterAnalytics)}
    />
  );
}

export default MatterAnalyticsContainer;
