import { SubmitHandler, useForm } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType, EButtonVariant } from "../../../../components/Button/Button";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import Div from "../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import Label from "../../../../components/FormField/Label/Label";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import InfoBox, { EInfoBoxVariant } from "../../../../components/InfoBox/InfoBox";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Popover from "../../../../components/Popover/Popover";
import Typography from "../../../../components/Typography/Typography";
import {
  IDocument,
  openInformationUpdatedModal,
  postTerminateDocument,
  selectTerminateDocumentError,
  selectTerminateDocumentStatus,
} from "../../../../modules/documentsSlice";
import { postTerminateMatterDocument } from "../../../../modules/matterDocumentsSlice";
import { errorToast } from "../../../../toast/toast";
import { isOutdatedContentError } from "../../../../utils/errors/errors";

type Inputs = {
  terminateDate: string;
  note: string;
};

export interface ITerminateDocumentModal {
  isOpen: boolean;
  handleClose: () => void;
  documentType: string;
  document?: IDocument;
  onSubmitSuccess?: Function;
  matterID?: number;
}

/**
 * Render a modal to transfer ownership
 * @param isOpen	               Is the modal open?
 * @param handleClose	           Function to close the modal
 * @param documentType	         The document type
 * @param document	             The document
 * @param onSubmitSuccess	       Function called on submit success
 * @param matterID	             The matter id (if terminating a matter document)
 * @returns JSX.Element
 */
function TerminateDocumentModal({
  isOpen,
  handleClose,
  documentType,
  document,
  onSubmitSuccess,
  matterID,
}: ITerminateDocumentModal): JSX.Element {
  const status = useAppSelector(selectTerminateDocumentStatus);
  const error = useAppSelector(selectTerminateDocumentError);

  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    reset,
  } = useForm<Inputs>();
  const dispatch = useAppDispatch();

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    handleClose();
    reset();
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (document) {
        const { id } = document;
        const { terminateDate, note } = data;
        if (matterID) {
          await dispatch(
            postTerminateMatterDocument({
              documentID: id,
              terminateDate,
              note,
              matterID,
            }),
          ).unwrap();
        } else {
          await dispatch(
            postTerminateDocument({
              documentID: id,
              terminateDate,
              note,
            }),
          ).unwrap();
        }
        reset();
        handleClose();
        onSubmitSuccess && onSubmitSuccess();
      }
    } catch (error: any) {
      const errorCode = parseInt(error);
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      } else {
        errorToast(error);
      }
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleModalClose} size="lg" testId="terminate-document-modal">
      <ModalHeader>
        <ModalTitle>Terminate document</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography weight="medium" spacing={{ mb: 4 }}>
          The following document will be set as terminated:
        </Typography>
        <Typography>{documentType}</Typography>
        <Typography>{document?.description}</Typography>
        <Div spacing={{ py: 6 }}>
          {error && <DisplayError>{error}</DisplayError>}
          <form id="terminatedDocumentForm" onSubmit={handleSubmit(onSubmit)}>
            <FormField
              testId="termination-datepicker"
              name="terminateDate"
              label="Effective date of termination"
              register={register}
              type={cFormFieldType.DatePicker}
              error={errors.terminateDate}
              required
              spacing={{ mb: 5 }}
            />
            <Div display={{ base: "flex" }} justifyContent={{ base: "space-between" }} alignItems={{ base: "center" }}>
              <Label>Please add a termination note</Label>
              <Popover
                popoverContents="Reminder: please upload a copy of the notice of termination as a supporting document."
                buttonContents={<Icon icon={EIcon.Help} />}
                buttonProps={{ variant: EButtonVariant.Link }}
              />
            </Div>
            <FormField
              name="note"
              maxLength={900}
              rows={3}
              type={cFormFieldType.Textarea}
              register={register}
              error={errors.note}
              spacing={{ mb: 5 }}
              testId="termination-note-field"
              required
              fullWidth
            />
          </form>
        </Div>
        <Div spacing={{ pb: 5 }}>
          <InfoBox variant={EInfoBoxVariant.Warning}>
            <ul>
              <li>The state of the document will be irreversibly set as terminated.</li>
              <li>All future notifications will be abandoned.</li>
            </ul>
          </InfoBox>
        </Div>
      </ModalContent>
      <ModalActions>
        <Button
          color={cThemeColorType.Secondary}
          onClick={handleModalClose}
          testId="terminate-document-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          formId="terminatedDocumentForm"
          type={cButtonType.SubmitType}
          color={cThemeColorType.Danger}
          isLoading={status === cStatusType.Loading}
        >
          Set as terminated
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default TerminateDocumentModal;
