import { cStatusType, cThemeColorType } from "../../../../app/constants";
import Button, { cButtonType } from "../../../../components/Button/Button";
import DisplayError from "../../../../components/DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Spinner from "../../../../components/Spinner/Spinner";
import Typography from "../../../../components/Typography/Typography";
import { IEmbedSingleUseDetail } from "../../../../modules/embedSingleUseSlice";
import SendEmbedEmailNotificationForm, {
  Inputs,
} from "./SendEmbedEmailNotificationForm/SendEmbedEmailNotificationForm";
import styles from "./SendEmbedEmailNotificationModal.module.scss";

interface ISendEmbedEmailNotificationModal {
  isOpen: boolean;
  handleClose: () => void;
  userFirstName: string;
  detail?: IEmbedSingleUseDetail;
  status: cStatusType;
  error?: string;
  matterRef?: string;
  handleSend: (data: Inputs) => void;
  sendStatus: cStatusType;
  sendError?: string;
}

// Form ID
export const formId = "send-embed-email-notification-form";

/**
 * Send embed email notification modal
 * @param isOpen        Is the modal open?
 * @param handleClose   Function to close the modal
 * @param userFirstName The user's first name to be used in the email
 * @param detail        The embed detail
 * @param status        The embed status
 * @param error         The embed error
 * @param matterRef     The matter reference code (if applicable)
 * @param handleSend    Function to handle the form submission
 * @param sendStatus    The send status
 * @param sendError     The send error
 * @returns JSX.Element
 */
function SendEmbedEmailNotificationModal({
  isOpen,
  handleClose,
  userFirstName,
  detail,
  status,
  error,
  matterRef,
  handleSend,
  sendStatus,
  sendError,
}: ISendEmbedEmailNotificationModal): JSX.Element {
  // Combine the errors
  const combinedErr = error || sendError;

  return (
    <Modal size="lg" isOpen={isOpen} handleClose={handleClose} testId="send-embed-email-notification-modal">
      <ModalHeader hasBoxShadow>
        {matterRef && (
          <Typography className={styles.urn} variant="preHeading" casing="upper" testId="matter-ref">
            {matterRef}
          </Typography>
        )}
        <ModalTitle>Send email notification</ModalTitle>
      </ModalHeader>
      <ModalContent>
        {status === cStatusType.Loading ? (
          <Spinner />
        ) : combinedErr ? (
          <DisplayError>{combinedErr}</DisplayError>
        ) : (
          isOpen &&
          detail && (
            <SendEmbedEmailNotificationForm userFirstName={userFirstName} detail={detail} handleSend={handleSend} />
          )
        )}
      </ModalContent>
      <ModalActions hasBoxShadow>
        <Button
          onClick={handleClose}
          testId="send-embed-email-notification-modal-cancel-button"
          disabled={sendStatus === cStatusType.Loading}
          color={cThemeColorType.Secondary}
        >
          Cancel
        </Button>
        <Button
          formId={formId}
          type={cButtonType.SubmitType}
          disabled={status === cStatusType.Loading}
          isLoading={sendStatus === cStatusType.Loading}
          testId="send-embed-email-notification-modal-send-button"
        >
          Send
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default SendEmbedEmailNotificationModal;
