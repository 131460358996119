import classNames from "classnames";
import ReactModal, { OnAfterOpenCallbackOptions } from "react-modal";
import { cMonoColorType, cThemeColorType } from "../../app/constants";
import { useSpacing } from "../../app/hooks";
import { IJustifyContent } from "../../app/styleTypes";
import { ISpacing, IXDirection } from "../../app/types";
import utilityStyles from "../../scss/generic/Utilities.module.scss";
import Button, { EButtonVariant } from "../Button/Button";
import Div from "../Div/Div";
import Icon, { EIcon } from "../Icon/Icon";
import Typography, { Variant } from "../Typography/Typography";
import styles from "./Modal.module.scss";

/**
 * Generic interface with children prop
 */
interface IModalGeneric {
  children: React.ReactNode;
  spacing?: ISpacing;
  bgColor?: cMonoColorType;
}

/**
 * The modal icon
 * @param children
 * @returns JSX.Element
 */
export function ModalIcon({ children }: IModalGeneric) {
  return (
    <Div display={{ base: "flex" }} justifyContent={{ base: "center" }} spacing={{ pt: 6 }}>
      {children}
    </Div>
  );
}

interface IModalHeader {
  children?: IModalTitle | React.ReactNode;
  justifyContent?: IJustifyContent;
  hasBoxShadow?: boolean;
  handleClose?: () => void;
  testId?: string;
  className?: string;
  childrenWrapperClassName?: string;
}

/**
 * The modal header
 * @param children                 The children
 * @param hasBoxShadow             Does the header have a box shadow?
 * @param handleCloseFn            The function to call when the close button is clicked
 * @param testId                   The test id
 * @param justifyContent           The IJustifyContent
 * @param className                Custom class
 * @param childrenWrapperClassName Custom class for the children wrapper
 * @returns JSX.Element
 */
export function ModalHeader({
  children,
  hasBoxShadow = false,
  handleClose,
  testId,
  justifyContent = { base: "space-between" },
  className,
  childrenWrapperClassName,
}: IModalHeader) {
  const clx = classNames(
    styles.className, // Generic
    hasBoxShadow ? styles.modalHeaderWithShadow : styles.modalHeaderNoShadow, // Does the header get a box shadow
    className, // Custom
  );

  return (
    <Div className={clx} justifyContent={justifyContent}>
      <Div alignSelf={{ base: "center" }} testId={testId} className={childrenWrapperClassName}>
        {children as React.ReactNode}
      </Div>
      {handleClose && (
        <Button
          color={cThemeColorType.Secondary} // Color of link button
          variant={EButtonVariant.Round} // No styling on button
          spacing={{ ml: 3 }} // Space from heading
          onClick={handleClose}
        >
          <Icon icon={EIcon.Close} />
        </Button>
      )}
    </Div>
  );
}

interface IModalTitle {
  children?: React.ReactNode;
  variant?: Variant;
  testId?: string;
  className?: string;
  align?: IXDirection;
}

/**
 * The modal title
 * @param children       The children
 * @param variant        The typography variant
 * @param testId         The test id
 * @param className      The class name
 * @param align          The IXDirection
 * @returns JSX.Element
 */
export function ModalTitle({ children, variant = "h4", testId, className, align }: IModalTitle) {
  return (
    <Typography variant={variant} testId={testId} className={className} align={align}>
      {children}
    </Typography>
  );
}

/**
 * The modal content
 * @param children
 * @param spacing  Set Spacing object
 * @param bgColor  Set background color for the modal body
 * @returns JSX.Element
 */
export function ModalContent({ children, spacing, bgColor }: IModalGeneric) {
  const spacingClx = useSpacing(spacing);
  const clx = classNames(
    bgColor && utilityStyles[`bg-${bgColor}`], // Apply background color
    (spacing = useSpacing(spacing)),
    spacingClx, // Spacing
    styles.modalBody, // Modal content styles
  );
  return (
    <Div bgColor={bgColor} className={clx}>
      {children}
    </Div>
  );
}

interface IModalActions {
  children: React.ReactNode;
  justifyContent?: IJustifyContent;
  hasBoxShadow?: boolean;
}

/**
 * The modal actions
 * @param children       The children
 * @param justifyContent The IJustifyContent
 * @param hasBoxShadow   Do the actions have a box shadow?
 * @returns JSX.Element
 */
export function ModalActions({ children, justifyContent, hasBoxShadow = false }: IModalActions) {
  return (
    <Div
      className={hasBoxShadow ? styles.modalFooterWithShadow : styles.modalFooterNoShadow}
      justifyContent={justifyContent}
    >
      {children}
    </Div>
  );
}

/**
 * Optional modal sizes
 */
export type Size = "sm" | "md" | "lg" | "xl" | "xxl" | "fullscreen";

interface IModalProps {
  children: React.ReactElement<IModalTitle> | React.ReactElement<IModalGeneric> | React.ReactElement<IModalActions>[];
  isOpen: boolean;
  onRender?: (obj?: OnAfterOpenCallbackOptions | undefined) => void;
  handleClose?: () => void;
  className?: string;
  testId?: string;
  size?: Size;
  modalBoxClassName?: string;
  overlayClassName?: string;
  scrollable?: boolean;
  portalClassName?: string;
}

/**
 * A modal component that renders in the root of the body
 * @param children          Modal contents - ModalIcon, ModalTitle, ModalContent, ModalActions
 * @param isOpen            Is the modal open?
 * @param onRender          Function to run on modal render
 * @param handleClose       State function to close the modal
 * @param className         Modal base classes
 * @param size              The modal size
 * @param modalBoxClassName Modal box class
 * @param overlayClassName  Overlay class
 * @param scrollable        Is the modal body scrollable?
 * @param portalClassName   Class for the entire render portal
 * @returns JSX.Element
 */
function Modal({
  children,
  isOpen,
  onRender,
  handleClose,
  size,
  className,
  testId,
  modalBoxClassName,
  overlayClassName,
  scrollable,
  portalClassName,
}: IModalProps): JSX.Element {
  const modalClx = {
    base: classNames(
      styles.base,
      className,
      size && styles[`${size}`], // Apply modal size styles
      scrollable && styles.scrollable, // Scrollable content
    ),
    afterOpen: styles.afterOpen,
    beforeClose: styles.beforeClose,
  };

  const overlayClx = {
    base: classNames(styles.overlayBase, overlayClassName),
    afterOpen: styles.overlayAfterOpen,
    beforeClose: styles.overlayBeforeClose,
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={onRender}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleClose}
      className={modalClx}
      overlayClassName={overlayClx}
      closeTimeoutMS={200}
      ariaHideApp={false}
      testId={testId}
      portalClassName={portalClassName}
    >
      <Div className={classNames(styles.modalBox, modalBoxClassName)}>{children}</Div>
    </ReactModal>
  );
}

export default Modal;
