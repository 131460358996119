import classNames from "classnames";
import { useEffect, useState } from "react";
import Div from "../Div/Div";
import Tab from "./Tab/Tab";
import styles from "./Tabs.module.scss";

/**
 * Describe a tab item
 */
export interface ITabItem {
  header: React.ReactNode;
  contents: React.ReactNode;
  className?: string;
}

interface ITabs {
  items: ITabItem[];
  className?: string;
  navWrapperClassName?: string;
  sticky?: boolean;
  defaultIsOpen?: number;
  onClick?: (i: number) => void;
}

export type TIsOpen = number | null; // Is open state type

/**
 * A tabs component capable of displaying a single item at a time
 * @param items               Array of headers and contents
 * @param className           Custom classes for the tabs wrapper
 * @param navWrapperClassName Custom classes for nav wrapper
 * @param sticky              Is the tabs wrapper sticky
 * @param defaultIsOpen       Tab that is open by default on mount
 * @param onClick             Function to run on tab click
 * @returns JSX.Element
 */
function Tabs({ items, className, navWrapperClassName, sticky = false, defaultIsOpen, onClick }: ITabs): JSX.Element {
  const [isOpen, setIsOpen] = useState<TIsOpen>(null); // State to track what is open
  const clx = classNames(styles.className, className);

  const navWrapperClx = classNames(
    styles.tabsNavWrapper, // Default styles
    sticky && styles.sticky, // Sticky styles if sticky prop is true
    navWrapperClassName, // Custom classes
  );

  useEffect(() => {
    if (defaultIsOpen !== undefined) {
      // If default value provided
      setIsOpen(defaultIsOpen); // Open the index of the tab
    }
    handleClick(defaultIsOpen || 0);
  }, []);

  /**
   * Handle click of tab
   * @param i The index of the clicked item
   */
  function handleClick(i: number): void {
    setIsOpen(i); // Set the new state

    if (onClick) {
      onClick(i); // Run the onClick function if provided
    }
  }

  return (
    <Div className={clx} testId="tabs">
      <Div className={navWrapperClx} testId="tabs-nav-wrapper">
        {items.map(({ header }, index) => (
          <Tab key={index} index={index} isOpen={isOpen === index} onClick={handleClick} header={header} />
        ))}
      </Div>
      {isOpen !== null && (
        <Div testId="tab" className={items[isOpen].className || ""}>
          {items[isOpen].contents}
        </Div>
      )}
    </Div>
  );
}

export default Tabs;
