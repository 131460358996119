import { cSizeType } from "../../../../app/constants";
import { IXDirection } from "../../../../app/types";
import Button from "../../../../components/Button/Button";
import Div from "../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../components/Icon/Icon";
import Modal, { ModalContent } from "../../../../components/Modal/Modal";
import Typography from "../../../../components/Typography/Typography";
import styles from "./SendEmbedEmailNotificationSuccessModal.module.scss";

interface ISendEmbedEmailNotificationSuccessModal {
  isOpen: boolean;
  email?: string;
  date?: string;
  time?: string;
  handleClose: () => void;
}

/**
 * Success modal to be rendered after sending the embed email notification
 * @param isOpen      Is the modal open?
 * @param email       The email address to which the email was sent
 * @param date        The expiry date of the embed
 * @param time        The expiry time of the embed
 * @param handleClose Function to close the modal
 * @returns JSX.Element
 */
function SendEmbedEmailNotificationSuccessModal({
  isOpen,
  email,
  date,
  time,
  handleClose,
}: ISendEmbedEmailNotificationSuccessModal): JSX.Element {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} testId="send-embed-email-notification-success-modal">
      <ModalContent>
        <Div className={styles.iconWrapper} mb={{ base: 6 }}>
          <Icon icon={EIcon.NotificationSent} size={cSizeType.Large} className={styles.icon} />
        </Div>
        <Typography align={IXDirection.Center}>
          An email has been sent to <strong>{email}</strong>.
        </Typography>
        <Typography align={IXDirection.Center}>
          They will have until{" "}
          <strong>
            {time} on {date}
          </strong>{" "}
          to complete the process.
        </Typography>
      </ModalContent>
      <Div className={styles.buttonWrapper} pb={{ base: 6 }}>
        <Button
          onClick={handleClose}
          className={styles.button}
          testId="send-embed-email-notification-success-modal-ok-button"
        >
          OK
        </Button>
      </Div>
    </Modal>
  );
}

export default SendEmbedEmailNotificationSuccessModal;
