import { memo, useState } from "react";
import { cMonoColorType, cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import { IFieldOption } from "../../../../../components/FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Spinner from "../../../../../components/Spinner/Spinner";
import Typography from "../../../../../components/Typography/Typography";
import { isOutdatedContentError } from "../../../../../utils/errors/errors";
import ChangeStageModalForm from "./ChangeStageModalForm/ChangeStageModalForm";

interface IChangeStageModal {
  isOpen: boolean;
  handleClose: () => void;
  status: cStatusType;
  error?: string;
  options: IFieldOption[];
  currentStage?: number;
  handleSetStage: (stageID: number) => void;
  saveStatus: cStatusType;
}

// The form ID
export const changeStageFormId = "change-stage-form";

/**
 * Change document stage modal
 * @param isOpen         Is the modal open?
 * @param handleClose    Function to close the modal
 * @param status         The status of API calls
 * @param error          Any errors
 * @param options        The options for the stage field
 * @param currentStage   The current stage ID
 * @param handleSetStage Function to set/update the stage
 * @param saveStatus     The status of the save API call
 * @returns JSX.Element
 */
const ChangeStageModal = memo(function ChangeStageModal({
  isOpen,
  handleClose,
  status,
  error,
  options,
  currentStage,
  handleSetStage,
  saveStatus,
}: IChangeStageModal): JSX.Element {
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const currentStageName = currentStage && options.find((option) => option.value === String(currentStage))?.label;

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} testId="change-stage-modal" size="md" scrollable>
      <ModalHeader hasBoxShadow>
        <ModalTitle>Change document stage</ModalTitle>
        {currentStageName && (
          <Typography variant="preHeading" color={cMonoColorType.Light} spacing={{ mt: 4, mb: 0 }}>
            <strong>Current stage:</strong> {currentStageName}
          </Typography>
        )}
      </ModalHeader>
      <ModalContent>
        {status === cStatusType.Loading ? (
          <Spinner />
        ) : options.length === 0 ? (
          <Typography>No stages configured.</Typography>
        ) : (
          <ChangeStageModalForm
            options={options}
            currentStage={currentStage}
            setFormIsChanged={setIsFormChanged}
            handleSetStage={handleSetStage}
          />
        )}
        {error && !isOutdatedContentError(parseInt(error)) && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions hasBoxShadow>
        <Button onClick={handleClose} color={cThemeColorType.Secondary}>
          Cancel
        </Button>
        <Button
          formId={changeStageFormId}
          type={cButtonType.SubmitType}
          disabled={status === cStatusType.Loading || !isFormChanged}
          isLoading={saveStatus === cStatusType.Loading}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
});

export default ChangeStageModal;
