import { useEffect, useState } from "react";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import { TSpacingOpts } from "../../../../../app/styleTypes";
import { EPopoverPlacement } from "../../../../../app/types";
import Button, { cButtonType, EButtonVariant } from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import LinkButton, { ELinkTarget } from "../../../../../components/LinkButton/LinkButton";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Popover from "../../../../../components/Popover/Popover";
import Spinner from "../../../../../components/Spinner/Spinner";
import Typography from "../../../../../components/Typography/Typography";
import { ECollaborationAction, EFileExtension, ICollaborators } from "../../../../../modules/collaborationSlice";
import { EWalkStage, EWalkType } from "../../../../../modules/walkSlice";
import WalkDiscardModal from "../../../../WalkContainer/Walk/WalkDiscardModal/WalkDiscardModal";
import AddCollaboratorModalContainer from "./AddCollaboratorModalContainer/AddCollaboratorModalContainer";
import CollaborationVersionNotes, {
  collaborationVersionNotesFormId,
} from "./CollaborationVersionNotes/CollaborationVersionNotes";
import CollaboratorsList from "./CollaboratorsList/CollaboratorsList";
import DeleteCollaboratorConfirmModal from "./DeleteCollaboratorConfirmModal/DeleteCollaboratorConfirmModal";
import FinaliseCollaborationModal from "./FinaliseCollaborationModal/FinaliseCollaborationModal";
import styles from "./ManageCollaborationModal.module.scss";

/**
 * Individual collaborator interface
 */
export interface ICollaborator {
  id: number;
  name: string;
  email: string;
  mobilePhoneNo: string;
  isInternalUser: boolean;
  action: ECollaborationAction;
  isMailSent: boolean;
}

interface IManageCollaborationModal {
  isOpen: boolean;
  handleClose: () => void;
  documentType: string;
  documentDescription: string;
  collaborators: ICollaborators;
  handleDiscardCollaboration: () => void;
  stage: EWalkStage;
  handleVersionNotesSubmit: (versionNotes: string) => void;
  walkStatus: cStatusType;
  error?: string;
  userEmail?: string;
  handleDeleteCollaborator: (id: number) => void;
  deleteCollaboratorError?: string;
  collaborationLink?: string;
  handleSendCollaborationLink: (collaboratorID: number | "all") => void;
  sendCollaborationLinkStatus: cStatusType;
  sendCollaborationLinkError?: string;
  fileExtension?: EFileExtension;
  handleWalkForward: () => void;
  walkError?: string;
}

/**
 * Manage collaboration modal
 * @param isOpen                      Is the modal open
 * @param handleClose                 Function to close the modal
 * @param documentType                The document type
 * @param documentDescription         The document description
 * @param collaborators               The collaborators
 * @param handleDiscardCollaboration  Function to handle discard collaboration
 * @param stage                       The current walk stage
 * @param handleVersionNotesSubmit    Function to handle version notes submit
 * @param walkStatus                  The walk status
 * @param error                       Any error that occurred
 * @param userEmail                   The current user's email
 * @param handleDeleteCollaborator    Function to handle delete collaborator
 * @param deleteCollaboratorError     Any error that occurred while deleting a collaborator
 * @param collaborationLink           The collaboration link
 * @param handleSendCollaborationLink Function to handle send collaboration link
 * @param sendCollaborationLinkStatus The send collaboration link status
 * @param sendCollaborationLinkError  Any error that occurred while sending a collaboration link
 * @param fileExtension               The collaboration file extension
 * @param handleWalkForward           Function to handle walk forward
 * @param walkError                   Any error that occurred during the walk
 * @returns JSX.Element
 */
function ManageCollaborationModal({
  isOpen,
  handleClose,
  documentType,
  documentDescription,
  collaborators,
  handleDiscardCollaboration,
  stage,
  handleVersionNotesSubmit,
  walkStatus,
  error,
  userEmail,
  handleDeleteCollaborator,
  deleteCollaboratorError,
  collaborationLink,
  handleSendCollaborationLink,
  sendCollaborationLinkStatus,
  sendCollaborationLinkError,
  fileExtension,
  handleWalkForward,
  walkError,
}: IManageCollaborationModal): JSX.Element {
  const [isAddCollaboratorModalOpen, setIsAddCollaboratorModalOpen] = useState<boolean>(false);

  // If the stage is Action, the modal should be closable (discard collaboration)
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState<number | null>(null);
  const [isFinaliseCollaborationModalOpen, setIsFinaliseCollaborationModalOpen] = useState(false);

  /**
   * Open the WalkDiscardModal
   */
  const openDiscardModal = () => {
    setIsDiscardModalOpen(true);
  };

  /**
   * Handle the discard button click and close the WalkDiscardModal
   */
  const handleDiscardAndCloseModal = () => {
    handleDiscardCollaboration();
    setIsDiscardModalOpen(false);
  };

  /**
   * Handle the close button click for loading, Collab1, and Action scenarios
   */
  const handleCloseFn =
    walkStatus === cStatusType.Loading
      ? () => {}
      : stage === EWalkStage.Collab1
        ? handleClose
        : // If the stage is Action, close the modal (discard collaboration) without extra actions
          stage === EWalkStage.Action
          ? handleDiscardAndCloseModal
          : openDiscardModal; // For other cases, open the WalkDiscardModal

  /**
   * Handle delete collaborator click - open the delete collaborator confirm modal
   * @param id The collaborator ID
   * @returns void
   */
  function handleDeleteCollaboratorClick(id: number) {
    setDeleteID(id);
  }

  /**
   * Open the finalise collaboration modal
   * @returns void
   */
  function handleFinaliseCollaborationBtnClick() {
    setIsFinaliseCollaborationModalOpen(true);
  }

  /**
   * Handle closing the finalise collaboration modal
   * @returns void
   */
  function handleFinaliseCollaborationModalClose() {
    setIsFinaliseCollaborationModalOpen(false);
  }

  // If the stage is action, close the finalise collaboration modal
  useEffect(() => {
    if (stage === EWalkStage.Action) {
      setIsFinaliseCollaborationModalOpen(false);
    }

    // Close the finalise collaboration modal when the stage changes
    // or the manage collaboration modal is closed
    return () => {
      setIsFinaliseCollaborationModalOpen(false);
    };
  }, [stage]);

  const errorSpacing = { py: { base: 5 as TSpacingOpts }, px: { base: 6 as TSpacingOpts } };
  // Initialize the file icon, file app, and button class
  let fileIcon,
    fileApp,
    btnClx = styles.openDocumentBtn;

  // Set the file icon, file app, and button class based on the file extension
  switch (fileExtension) {
    case EFileExtension.Xlsx:
      fileIcon = EIcon.ExcelWeb;
      fileApp = "Excel";
      btnClx += ` ${styles.excel}`;
      break;

    case EFileExtension.Pptx:
      fileIcon = EIcon.PowerPointWeb;
      fileApp = "PowerPoint";
      btnClx += ` ${styles.powerpoint}`;
      break;

    default:
      fileIcon = EIcon.WordWeb;
      fileApp = "Word";
      btnClx += ` ${styles.word}`;
      break;
  }

  /**
   * Render the content section based on the stage
   * @param stage The walk stage
   * @returns JSX.Element
   */
  function renderContentSection(stage: EWalkStage) {
    switch (stage) {
      // Manage collaborators
      case EWalkStage.Collab1:
        return (
          <CollaboratorsList
            collaborators={collaborators}
            userEmail={userEmail}
            handleDeleteCollaboratorClick={handleDeleteCollaboratorClick}
            handleSendCollaborationLink={handleSendCollaborationLink}
            sendCollaborationLinkStatus={sendCollaborationLinkStatus}
          />
        );
      // Auto-confirming finalise collaboration
      case EWalkStage.Collab2:
        return (
          <Div py={{ base: 3 }}>
            <Spinner />
          </Div>
        );
      // Version notes form
      default:
        return <CollaborationVersionNotes handleVersionNotesSubmit={handleVersionNotesSubmit} />;
    }
  }

  const popoverParagraphSpacing = { mb: 5 };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleCloseFn}
        scrollable
        testId="manage-collaboration-modal"
        size={stage === EWalkStage.Action ? "lg" : "xl"}
        portalClassName={styles.modalPortal}
      >
        <ModalHeader handleClose={handleCloseFn} childrenWrapperClassName={styles.childrenWrapper}>
          <Div display={{ base: "flex" }} justifyContent={{ base: "space-between" }}>
            <ModalTitle>Manage collaboration</ModalTitle>
            {stage === EWalkStage.Collab1 && (
              <Popover // Create the popover and button
                variant="popover"
                popoverPlacement={EPopoverPlacement.BottomStart}
                width="auto"
                popoverContents={
                  <Div testId="manage-collaboration-instructions">
                    <Typography spacing={popoverParagraphSpacing}>
                      Your document has been created and can be viewed/edited by clicking the{" "}
                      <strong>
                        <em>Open in {fileApp} for the web</em>
                      </strong>{" "}
                      button.
                    </Typography>
                    <Typography spacing={popoverParagraphSpacing}>
                      You can add and remove collaborators at any time. Once added,{" "}
                      <strong>Collaborators will automatically be notified via email</strong> with a link to the
                      document.
                    </Typography>
                    <Typography spacing={popoverParagraphSpacing}>
                      Changes made in the Word document by you and collaborators are{" "}
                      <strong>saved automatically</strong> and you can access the document again from this window.
                    </Typography>
                    <Typography>
                      When you're ready to end the collaboration, click the{" "}
                      <strong>
                        <em>Finalise collaboration</em>
                      </strong>{" "}
                      button to proceed to the next step.
                    </Typography>
                  </Div>
                }
                buttonContents={<Icon icon={EIcon.Help} />}
                buttonProps={{
                  variant: EButtonVariant.Round,
                  spacing: { mr: 3 },
                  color: cThemeColorType.Secondary,
                }}
              />
            )}
          </Div>
        </ModalHeader>
        <Div px={{ base: 6 }} pb={{ base: 5 }} border={{ bb: true }}>
          <Typography>{documentType}</Typography>
          <Typography weight="medium">{documentDescription}</Typography>
        </Div>

        <ModalContent spacing={stage === EWalkStage.Collab1 ? { p: 0 } : { py: 4, px: 0 }}>
          {stage === EWalkStage.Collab1 ? (
            <Div px={{ base: 6 }} py={{ base: 5 }} display={{ md: "flex" }} className={styles.actionBar}>
              <Button
                color={cThemeColorType.Secondary}
                testId="add-collaborator-btn"
                icon={EIcon.AddUser}
                spacing={{ mr: 5 }}
                className={styles.addCollaboratorBtn}
                onClick={() => setIsAddCollaboratorModalOpen(true)}
              >
                Add collaborator
              </Button>
              <LinkButton
                color={cThemeColorType.Secondary}
                icon={fileIcon}
                testId="open-document-btn"
                className={btnClx}
                link={collaborationLink || ""}
                target={ELinkTarget.Blank}
              >
                Open in {fileApp} for the web
              </LinkButton>
            </Div>
          ) : (
            <></>
          )}
          {renderContentSection(stage)}
          {error && (
            <Div {...errorSpacing}>
              <DisplayError>{error}</DisplayError>
            </Div>
          )}
          {sendCollaborationLinkError && (
            <Div {...errorSpacing}>
              <DisplayError>{sendCollaborationLinkError}</DisplayError>
            </Div>
          )}
        </ModalContent>
        <ModalActions hasBoxShadow>
          <Button
            onClick={openDiscardModal}
            color={cThemeColorType.Secondary}
            icon={EIcon.Delete}
            testId="discard-collaboration-btn"
            disabled={walkStatus === cStatusType.Loading}
          >
            Discard collaboration
          </Button>
          {stage === EWalkStage.Action ? (
            <Button
              formId={collaborationVersionNotesFormId}
              className={styles.versionNotesForwardBtn}
              icon={EIcon.Next}
              type={cButtonType.SubmitType}
              testId="submit-version-notes-btn"
              disabled={walkStatus === cStatusType.Loading}
            />
          ) : (
            <Button
              color={cThemeColorType.Primary}
              onClick={handleFinaliseCollaborationBtnClick}
              testId="finalise-collaboration-btn"
              disabled={walkStatus === cStatusType.Loading}
            >
              Finalise collaboration
            </Button>
          )}
        </ModalActions>
      </Modal>
      <AddCollaboratorModalContainer
        isOpen={isAddCollaboratorModalOpen}
        handleClose={() => setIsAddCollaboratorModalOpen(false)}
      />
      <WalkDiscardModal
        isOpen={isDiscardModalOpen}
        setIsDiscardModalOpen={setIsDiscardModalOpen}
        handleDiscard={handleDiscardAndCloseModal}
        walkTypeClass={EWalkType.Collaboration}
        portalClassName={styles.modalPortal}
        walkStatus={walkStatus}
      />
      <DeleteCollaboratorConfirmModal
        isOpen={!!deleteID}
        handleModalClose={() => setDeleteID(null)}
        isLoading={walkStatus === cStatusType.Loading}
        handleDeleteCollaborator={() => {
          if (deleteID) {
            handleDeleteCollaborator(deleteID);
            setDeleteID(null);
          }
        }}
        error={deleteCollaboratorError}
      />
      <FinaliseCollaborationModal
        isOpen={isFinaliseCollaborationModalOpen}
        handleClose={handleFinaliseCollaborationModalClose}
        handleWalkForward={handleWalkForward}
        walkStatus={walkStatus}
        error={walkError}
      />
    </>
  );
}

export default ManageCollaborationModal;
