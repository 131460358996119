import classNames from "classnames";
import { UseFormRegister } from "react-hook-form";
import Div from "../../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../../components/FormField/FormField";
import styles from "../../../Analytics.module.scss";

interface IAnalyticsDateFilter {
  register: UseFormRegister<any>;
  dateFilterOptions: IFieldOption[];
  defaultValue?: string;
}

/**
 * The analytics date filter
 * @param register          The register function from react-hook-form
 * @param dateFilterOptions The date filter options
 * @param defaultValue      The field default value
 * @returns JSX.Element
 */
function AnalyticsDateFilter({ register, dateFilterOptions, defaultValue }: IAnalyticsDateFilter): JSX.Element {
  return (
    <Div className={classNames(styles.formRow, styles.justified)} testId="date-filter">
      <label htmlFor="dateFilter" className={styles.label}>
        Date filter
      </label>
      <FormField
        name="dateFilter"
        register={register}
        type={cFormFieldType.Buttons}
        options={dateFilterOptions}
        defaultValue={defaultValue}
      />
    </Div>
  );
}

export default AnalyticsDateFilter;
