import { lazy, Suspense } from "react";
import Div from "../../../../components/Div/Div";

const LazyGraphReportContainer = lazy(() => import("./GraphReportContainer"));

const GraphReportContainer = () => (
  <Suspense fallback={<Div />}>
    <LazyGraphReportContainer />
  </Suspense>
);

export default GraphReportContainer;
