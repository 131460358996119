import { some, uniq } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { cRouteType, WALK_RETURN_URL } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectAllowEmbed } from "../../../../modules/customerSlice";
import { selectDocumentTypes } from "../../../../modules/documentTypesSlice";
import {
  postMatterResources,
  selectMatterResources,
  selectMatterResourcesError,
  selectMatterResourcesStatus,
} from "../../../../modules/matterResourcesSlice";
import {
  ISpawnItem,
  postMatterReassignTask,
  postMatterTasks,
  resetReassignError,
  selectMatterReassignError,
  selectMatterReassignStatus,
  selectMatterTasks,
  selectMatterTasksError,
  selectMatterTasksStatus,
} from "../../../../modules/matterTasksSlice";
import { IUser, postUsersByID, selectUsers } from "../../../../modules/usersSlice";
import {
  IPostMatterLaunchExternalResource,
  IPostMatterLaunchTask,
  postMatterLaunchExternalResource,
  postMatterLaunchTask,
} from "../../../../modules/walkSlice";
import SendEmbedEmailNotificationModalContainer from "../../../MattersContainer/SendEmbedEmailNotificationModalContainer/SendEmbedEmailNotificationModalContainer";
import InvalidateEmbedLinkModalContainer from "./InvalidateEmbedLinkModalContainer/InvalidateEmbedLinkModalContainer";
import MatterReassignTasksModal, { EReassignType } from "./MatterReassignTasksModal/MatterReassignTasksModal";
import MatterTasksColumn from "./MatterTasksColumn/MatterTasksColumn";

interface IMatterTasksColumnContainer {
  id: number;
  matterRef: string;
}

/**
 * Embed data interface for the send embed email notification modal
 */
interface IEmbedData {
  custWalkTypeID: number;
  matterID?: number;
  interviewSpawnID?: number;
}

/**
 * Container for the matter tasks and resources column
 * @param id        The matter ID
 * @param matterRef The matter reference code
 * @returns JSX.Element
 */
function MatterTasksColumnContainer({ id, matterRef }: IMatterTasksColumnContainer): JSX.Element {
  const dispatch = useAppDispatch();

  const [embedData, setEmbedData] = useState<IEmbedData | null>(null); // Embed data for the send embed email notification modal
  const [invalidateData, setInvalidateData] = useState<{ wipDTM?: string; interviewSpawnID: number } | null>(null); // Invalidate data for the invalidate embed link modal

  // Get the allow embed status to render embed buttons
  const allowEmbed = useAppSelector(selectAllowEmbed);

  // Get the tasks from the store
  const tasksError = useAppSelector(selectMatterTasksError);
  const tasksStatus = useAppSelector(selectMatterTasksStatus);
  const tasksEntries = useAppSelector(selectMatterTasks);

  // Get the external resources from the store
  const resourcesError = useAppSelector(selectMatterResourcesError);
  const resourcesStatus = useAppSelector(selectMatterResourcesStatus);
  const resourcesEntries = useAppSelector(selectMatterResources);
  const documentTypes = useAppSelector(selectDocumentTypes);

  // Get the users from the store
  const users = useAppSelector(selectUsers);

  // Get the reassign task status and error
  const reassignError = useAppSelector(selectMatterReassignError);
  const reassignStatus = useAppSelector(selectMatterReassignStatus);

  // Get the resources for the matter
  const externalResources = resourcesEntries?.[id]?.resources?.map((resource) => {
    return {
      ...resource,
      // Add the document type name for rendering
      documentType: documentTypes[resource.documentTypeID] ? documentTypes[resource.documentTypeID].name : "",
    };
  });

  // Get the tasks for the matter
  const tasks = tasksEntries[id];
  const [selectedTask, setSelectedTask] = useState<Pick<
    ISpawnItem,
    "id" | "description" | "name" | "assigneeUserID"
  > | null>(null);

  // Get the unique assignees
  const taskAssignees = tasks?.spawnItems.reduce((acc: IUser[], task) => {
    if (task.hasOwnProperty("assigneeUserID") && users[task.assigneeUserID as number]) {
      acc.push(users[task.assigneeUserID as number]);
    }

    return acc;
  }, []);

  const location = useLocation();
  const { pathname } = location;

  // Fetch the tasks and external resources on render
  useEffect(() => {
    dispatch(postMatterTasks(id));
    dispatch(postMatterResources(id));
  }, [id]);

  // Reset the reassign error when the selected task is null (modal is closed)
  useEffect(() => {
    if (selectedTask === null) {
      dispatch(resetReassignError());
    }
  }, [selectedTask]);

  // Fetch the users for the tasks
  useEffect(() => {
    // If any of the tasks have an assignee, fetch the users
    if (tasks?.spawnItems && some(tasks?.spawnItems, "assigneeUserID")) {
      dispatch(
        postUsersByID({
          ids:
            // Get the unique assignees
            uniq(
              // Get the assignees
              tasks.spawnItems.reduce((acc: number[], task) => {
                // If the task has an assignee, add it to the list
                // note: in certain instances, the assignee may be null where the spawn is not auto assigned
                if (task.hasOwnProperty("assigneeUserID") && task?.assigneeUserID !== null) {
                  acc.push(task.assigneeUserID as number);
                }
                return acc;
              }, []),
            ) || [],
        }),
      );
    }
  }, [tasks]);

  /**
   * Draft a spawn document
   * @param custWalkTypeID Walk type ID to start the walk
   * @param matterID       Matter ID to attach the spawn to
   * @param spawnID        Spawn ID that walk is launched from
   * @param compositeState The composite state of the spawn
   * @returns void
   */
  async function handleDraftDocument({
    custWalkTypeID,
    matterID,
    spawnID,
    compositeState,
  }: IPostMatterLaunchTask): Promise<void> {
    await dispatch(postMatterLaunchTask({ custWalkTypeID, matterID, spawnID, compositeState })).unwrap();
    // Set the return URL for the walk
    if (pathname.includes(cRouteType.Notifications)) {
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Notifications}`);
    } else {
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${id}`);
    }
  }

  /**
   * Launch an external resource walk
   * @param custWalkTypeID Walk type ID to start the walk
   * @param matterID       Matter ID to attach the spawn to
   * @returns void
   */
  async function handleLaunchExternalResource({
    custWalkTypeID,
    matterID,
  }: IPostMatterLaunchExternalResource): Promise<void> {
    await dispatch(postMatterLaunchExternalResource({ custWalkTypeID, matterID })).unwrap();
    // Set the return URL for the walk
    if (pathname.includes(cRouteType.Notifications)) {
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Notifications}`);
    } else {
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${id}`);
    }
  }

  /**
   * Reassign task / tasks
   * @param type Assign only this task or assign all user's tasks
   */
  async function reassignTask(type: EReassignType): Promise<void> {
    try {
      const data =
        type === EReassignType.Only
          ? { taskID: selectedTask?.id as number }
          : { assigneeUserID: selectedTask?.assigneeUserID as number };
      await dispatch(postMatterReassignTask(data)).unwrap();
      await dispatch(postMatterTasks(id)).unwrap();
      setSelectedTask(null);
    } catch (error: any) {
      console.error(error.message);
    }
  }

  /**
   * On send and on invalidate callback for the send embed email
   * notification modal and embed invalidate modal (reloads the tasks)
   */
  function onEmbedAction(): void {
    dispatch(postMatterTasks(id));
  }

  return (
    <>
      <MatterTasksColumn
        tasks={tasks}
        tasksError={tasksError}
        tasksStatus={tasksStatus}
        externalResources={externalResources}
        externalResourcesError={resourcesError}
        externalResourcesStatus={resourcesStatus}
        handleDraftDocument={handleDraftDocument}
        handleLaunchExternalResource={handleLaunchExternalResource}
        matterID={id}
        taskAssignees={taskAssignees}
        setSelectedTask={setSelectedTask}
        allowEmbed={allowEmbed}
        setEmbedData={setEmbedData}
        setInvalidateData={setInvalidateData}
      />
      <MatterReassignTasksModal
        isOpen={selectedTask !== null}
        handleClose={() => setSelectedTask(null)}
        taskName={selectedTask?.name || ""}
        taskDescription={selectedTask?.description || ""}
        user={taskAssignees?.find((taskAssignee) => taskAssignee.id === selectedTask?.assigneeUserID) as IUser}
        reassignTask={reassignTask}
        error={reassignError}
        status={reassignStatus}
      />
      <SendEmbedEmailNotificationModalContainer
        isOpen={embedData !== null}
        handleClose={() => setEmbedData(null)}
        embedData={embedData}
        matterRef={matterRef}
        onSend={onEmbedAction}
      />
      <InvalidateEmbedLinkModalContainer
        isOpen={invalidateData !== null}
        handleClose={() => setInvalidateData(null)}
        interviewSpawnID={invalidateData?.interviewSpawnID}
        wipDTM={invalidateData?.wipDTM}
        reloadTasks={onEmbedAction}
      />
    </>
  );
}

export default MatterTasksColumnContainer;
