import { useEffect, useState } from "react";
import { cStatusType } from "../../../../../app/constants";
import AnalyticsCustomExportsList from "../../../../../components/AnalyticsCustomExportsList/AnalyticsCustomExportsList";
import { IDocumentAnalytics, IMatterCustomExport } from "../../../../../modules/analyticsSlice";

interface IMatterAnalyticsProps {
  matterAnalytics: IDocumentAnalytics[];
  customExportStatus: cStatusType;
  customExports: IMatterCustomExport[];
  matterTypeID: number | null;
}

/**
 * The Matter Analytics component
 * @param customExportStatus             The status of custom exports fetching
 * @param customExports                  The list of custom exports
 * @param matterTypeID                   The matter type id
 * @param matterAnalytics                The list of matter analytics
 * @returns JSX.Element
 */
function MatterAnalytics({
  customExportStatus,
  customExports,
  matterTypeID,
  matterAnalytics,
}: IMatterAnalyticsProps): JSX.Element {
  const [selectedMatterType, setSelectedMatterType] = useState<IDocumentAnalytics>();

  // Find the selected matterAnalytics and set it to the selectedMatterType
  useEffect(() => {
    const foundMatterType = matterAnalytics.find((currentMatterType) => currentMatterType.id === matterTypeID);
    if (foundMatterType) {
      setSelectedMatterType(foundMatterType);
    }
  }, [matterAnalytics, matterTypeID]);

  // Create the link list items for the custom exports
  const linkListItems = customExports.map((customExport: IMatterCustomExport, index: number | string) => {
    const configID = customExport.id;
    return {
      text: customExport.name,
      key: customExport.id,
      testId: `custom-export-${index}`,
      arrow: true,
      link: `${customExport.type}/${String(configID)}`,
    };
  });

  // Destructure linkListItems to separate the default `Management report`, `Data report` and the custom `Saved templates`
  const [managementReport, dataReport, ...savedTemplates] = linkListItems;

  return (
    <AnalyticsCustomExportsList
      title={selectedMatterType?.name || ""}
      status={customExportStatus}
      defaultExports={managementReport && dataReport ? [managementReport, dataReport] : []}
      customExports={savedTemplates}
    />
  );
}

export default MatterAnalytics;
