import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { embedSingleUseInitiateUrl, embedSingleUseInvalidateUrl, embedSingleUseSendUrl } from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";

/**
 * Interface for the embed single use detail
 */
export interface IEmbedSingleUseDetail {
  documentTypeID: number;
  defaultText: string;
  embedLifespanDays: number;
  embedExpiryTime: string;
  subject: string;
}

/**
 * Interface for the embed single use slice state
 */
interface IEmbedSingleUse {
  detail?: IEmbedSingleUseDetail;
  error?: string;
  status: cStatusType;
  sendError?: string;
  sendStatus: cStatusType;
  invalidateError?: string;
  invalidateStatus: cStatusType;
}

/**
 * Interface for the post embed single use initiate thunk
 */
interface IPostEmbedSingleUseInitiate {
  custWalkTypeID: number;
  matterID?: number;
  interviewSpawnID?: number;
}

/**
 * Interface for the post embed single use send thunk
 */
export interface IPostEmbedSingleUseSend extends IPostEmbedSingleUseInitiate {
  mailTo: string;
  mailSubject: string;
  mailPartOne: string;
  mailPartTwo: string;
  embedExpiryDTM: string;
}

/**
 * Interface for the post embed single use invalidate thunk
 */
interface IPostEmbedSingleUseInvalidate {
  interviewSpawnID: number;
}

/**
 * Thunk to fetch the initial embed modal detail
 */
export const postEmbedSingleUseInitiate = createAsyncThunk(
  "embedSingleUse/postEmbedSingleUseInitiate",
  async ({ custWalkTypeID, matterID, interviewSpawnID }: IPostEmbedSingleUseInitiate, { rejectWithValue }) => {
    try {
      let body;
      if (interviewSpawnID) {
        // For tasks
        body = { matterSpawn: { custWalkTypeID, interviewSpawnID, matterID } };
      } else if (matterID) {
        // For external resources
        body = { externalResource: { custWalkTypeID, matterID } };
      } else if (custWalkTypeID) {
        // For matter constructors
        body = { matterConstructor: { custWalkTypeID } };
      }
      const response = await api({ endpoint: embedSingleUseInitiateUrl, body });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk to send the embed single use email
 */
export const postEmbedSingleUseSend = createAsyncThunk(
  "embedSingleUse/postEmbedSingleUseSend",
  async (
    {
      custWalkTypeID,
      mailTo,
      mailSubject,
      mailPartOne,
      mailPartTwo,
      embedExpiryDTM,
      matterID,
      interviewSpawnID,
    }: IPostEmbedSingleUseSend,
    { rejectWithValue },
  ) => {
    try {
      let body;
      if (interviewSpawnID) {
        // For tasks
        body = {
          matterSpawn: {
            custWalkTypeID,
            interviewSpawnID,
            matterID,
            mailTo,
            mailSubject,
            mailPartOne,
            mailPartTwo,
            embedExpiryDTM,
          },
        };
      } else if (matterID) {
        // For external resources
        body = {
          externalResource: { custWalkTypeID, matterID, mailTo, mailSubject, mailPartOne, mailPartTwo, embedExpiryDTM },
        };
      } else if (custWalkTypeID) {
        // For matter constructors
        body = { matterConstructor: { custWalkTypeID, mailTo, mailSubject, mailPartOne, mailPartTwo, embedExpiryDTM } };
      }
      const response = await api({ endpoint: embedSingleUseSendUrl, body });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk to invalidate the embed single use link
 */
export const postEmbedSingleUseInvalidate = createAsyncThunk(
  "embedSingleUse/postEmbedSingleUseInvalidate",
  async ({ interviewSpawnID }: IPostEmbedSingleUseInvalidate, { rejectWithValue }) => {
    try {
      const response = await api({ endpoint: embedSingleUseInvalidateUrl, body: { interviewSpawnID } });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Initial state for the embed single use slice
 */
const initialState: IEmbedSingleUse = {
  status: cStatusType.Idle,
  sendStatus: cStatusType.Idle,
  invalidateStatus: cStatusType.Idle,
};

/**
 * Create the reducer slice
 */
export const embedSingleUseSlice = createSlice({
  name: "embedSingleUse",
  initialState,
  reducers: {
    // Reset the embed single use detail
    resetEmbedSingleUseDetail: (state) => {
      state.detail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, rejected, and fulfilled states for the post embed single use initiate thunk
      .addCase(postEmbedSingleUseInitiate.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postEmbedSingleUseInitiate.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postEmbedSingleUseInitiate.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.detail = action.payload;
      })
      // Handle the pending, rejected, and fulfilled states for the post embed single use send thunk
      .addCase(postEmbedSingleUseSend.pending, (state) => {
        state.sendStatus = cStatusType.Loading;
        state.sendError = undefined;
      })
      .addCase(postEmbedSingleUseSend.rejected, (state, action) => {
        state.sendStatus = cStatusType.Failed;
        state.sendError = action.payload as string;
      })
      .addCase(postEmbedSingleUseSend.fulfilled, (state) => {
        state.sendStatus = cStatusType.Idle;
      })
      // Handle the pending, rejected, and fulfilled states for the post embed single use invalidate thunk
      .addCase(postEmbedSingleUseInvalidate.pending, (state) => {
        state.invalidateStatus = cStatusType.Loading;
        state.invalidateError = undefined;
      })
      .addCase(postEmbedSingleUseInvalidate.rejected, (state, action) => {
        state.invalidateStatus = cStatusType.Failed;
        state.invalidateError = action.payload as string;
      })
      .addCase(postEmbedSingleUseInvalidate.fulfilled, (state) => {
        state.invalidateStatus = cStatusType.Idle;
      });
  },
});

/**
 * Export the actions
 */
export const { resetEmbedSingleUseDetail } = embedSingleUseSlice.actions;

// Select the embed single use detail object
export const selectEmbedSingleUseDetail = (state: RootState) => state.embedSingleUse.detail;

// Select the embed single use error and status
export const selectEmbedSingleUseError = (state: RootState) => state.embedSingleUse.error;
export const selectEmbedSingleUseStatus = (state: RootState) => state.embedSingleUse.status;

// Select the embed single use send error and status
export const selectEmbedSingleUseSendError = (state: RootState) => state.embedSingleUse.sendError;
export const selectEmbedSingleUseSendStatus = (state: RootState) => state.embedSingleUse.sendStatus;

// Select the embed single use invalidate error and status
export const selectEmbedSingleUseInvalidateError = (state: RootState) => state.embedSingleUse.invalidateError;
export const selectEmbedSingleUseInvalidateStatus = (state: RootState) => state.embedSingleUse.invalidateStatus;

export default embedSingleUseSlice.reducer;
