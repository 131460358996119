import { lazy, Suspense } from "react";
import Div from "../../../../components/Div/Div";

const LazyNotificationGroupsContainer = lazy(() => import("./NotificationGroupsContainer"));

const NoticationGroupsContainer = () => (
  <Suspense fallback={<Div />}>
    <LazyNotificationGroupsContainer />
  </Suspense>
);

export default NoticationGroupsContainer;
