import styles from "./LinkList.module.scss";
import ListItem, { TListItem } from "./ListItem/ListItem";

interface ILinkList {
  items: TListItem[];
  testId?: string;
  onClick?: () => void;
}

/**
 * A list of links / buttons with icons
 * @param items   List items to render
 * @param testId  The test id
 * @param onClick The function to run on click
 * @returns JSX.Element
 */
function LinkList({ items, testId }: ILinkList): JSX.Element {
  return (
    <ul className={styles.className} data-testid={testId}>
      {items.map((item) => (
        <ListItem {...item} key={item.text} />
      ))}
    </ul>
  );
}

export default LinkList;
