import classNames from "classnames";
import { map } from "lodash";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cRouteType } from "../../../app/constants";
import Div from "../../../components/Div/Div";
import Drawer from "../../../components/Drawer/Drawer";
import drawerStyles from "../../../components/Drawer/Drawer.module.scss";
import DrawerRowWrapper from "../../../components/DrawerRowWrapper/DrawerRowWrapper";
import { IFieldOption } from "../../../components/FormField/FormField";
import Column from "../../../components/Row/Column/Column";
import { IErrorCode } from "../../../modules/errorCodesSlice";
import { IUser } from "../../../modules/usersSlice";
import styles from "./Admin.module.scss";
import DeleteDocument from "./DeleteDocument/DeleteDocument";
import ErrorCodes from "./ErrorCodes/ErrorCodes";
import NotificationGroupsContainer from "./NotificationGroupsContainer/NotificationGroupsContainer.lazy";
import UserActivityReport from "./UserActivityReport/UserActivityReport";
import Users from "./Users/Users";

interface IAdmin {
  users: Record<number, IUser>;
  loggedInUser: IUser;
  notifyGroupOptions: IFieldOption[];
  securityGroupOptions: IFieldOption[];
  allowSecurityGroups: boolean;
  editUserInit: (userID: number) => void;
  editUser?: IUser;
  securityGroupIDs?: number[];
  disableUserInit: (userID: number) => void;
  enableUserInit: (userID: number) => void;
  deleteUserInit: (userID: number) => void;
  resetPasswordInit: (userID: number) => void;
  reassignAllTasksInit: (userID: number) => void;
  errorCodes: Record<number, IErrorCode>;
  usersError?: string;
}

/**
 * The Admin component
 * @param users                The users entries
 * @param loggedInUser         The logged in user
 * @param notifyGroupOptions   The notify group options
 * @param securityGroupOptions The security group options
 * @param allowSecurityGroups  Are security groups allowed?
 * @param editUserInit         Function to start the edit user process
 * @param editUser             The edit user info
 * @param securityGroupIDs     The securityGroupIDs for the edit user
 * @param disableUserInit      Function to start the disable a user process
 * @param enableUserInit       Function to start the enable a user process
 * @param deleteUserInit       Function to start the delete a user process
 * @param resetPasswordInit    Function to start the reset password process
 * @param reassignAllTasksInit Function to start the reassign all tasks process
 * @param errorCodes           The list of error codes
 * @param usersError           The users error message
 * @returns JSX.Element
 */
export function Admin({
  users,
  loggedInUser,
  notifyGroupOptions,
  securityGroupOptions,
  allowSecurityGroups,
  editUserInit,
  editUser,
  securityGroupIDs,
  disableUserInit,
  enableUserInit,
  deleteUserInit,
  resetPasswordInit,
  reassignAllTasksInit,
  errorCodes,
  usersError,
}: IAdmin) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [activeManageItemsIndex, setActiveManageItemsIndex] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  /**
   * Open the admin navigation drawer
   */
  function openDrawer() {
    setIsDrawerOpen(true);
  }

  const manageItems = [
    {
      name: "Users",
      link: "users",
      component: (
        <Users
          users={users}
          loggedInUser={loggedInUser}
          notifyGroupOptions={notifyGroupOptions}
          securityGroupOptions={securityGroupOptions}
          allowSecurityGroups={allowSecurityGroups}
          editUserInit={editUserInit}
          editUser={editUser}
          securityGroupIDs={securityGroupIDs}
          openDrawer={openDrawer}
          disableUserInit={disableUserInit}
          enableUserInit={enableUserInit}
          deleteUserInit={deleteUserInit}
          resetPasswordInit={resetPasswordInit}
          reassignAllTasksInit={reassignAllTasksInit}
          error={usersError}
        />
      ),
      testId: "admin-users-link",
    },
    {
      name: "Notification Groups",
      link: "notification-groups",
      component: <NotificationGroupsContainer />,
      testId: "admin-notification-groups-link",
    },
    {
      name: "Delete Document",
      link: "delete-document",
      component: <DeleteDocument openDrawer={openDrawer} />,
      testId: "admin-delete-document-link",
    },
    {
      name: "User Activity Report",
      link: "user-activity-report",
      component: <UserActivityReport openDrawer={openDrawer} />,
      testId: "admin-user-activity-report-link",
    },
    {
      name: "Error Codes",
      link: "error-codes",
      component: <ErrorCodes errorCodes={errorCodes} openDrawer={openDrawer} />,
      testId: "admin-error-codes-link",
    },
  ];

  /**
   * Sets the correct component based on the pathname
   */
  function handleRoutingOnLoad() {
    if (pathname === `/${cRouteType.Admin}`) {
      navigate("/admin/users");
      setActiveManageItemsIndex(0);
    } else {
      const tokens = pathname.split("/");
      if (tokens.length > 1) {
        const link = tokens[2];
        const linkIndex = manageItems.findIndex((item) => item.link === link);
        if (linkIndex) {
          setActiveManageItemsIndex(linkIndex);
        }
      }
    }
  }

  /**
   * Sets the drawer open/closed based on inner width
   */
  function handleResize() {
    const mobileBreakpoint = 992;
    const innerWidth = window.innerWidth;

    if (innerWidth > mobileBreakpoint) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleRoutingOnLoad();
    handleResize();
  }, [location]);

  /**
   * Handle routing when a user clicks the link
   * @param itemIndex The manageItems item index
   */
  function handleRouting(itemIndex: number) {
    const item = manageItems[itemIndex];
    setActiveManageItemsIndex(itemIndex);
    navigate(`${item.link}`);
  }

  return (
    <Div className={styles.interfaceWrapper}>
      <DrawerRowWrapper>
        <Div className={styles.className}>
          <Drawer
            isOpen={isDrawerOpen}
            width={300}
            testId="admin-drawer"
            setIsOpen={setIsDrawerOpen}
            className={drawerStyles.drawer}
            showExpand
          >
            <Div className={drawerStyles.sidebarInner}>
              <Column grow>
                <ul className={drawerStyles.sidebarNavMenu} data-testid="admin-manage-menu">
                  {map(manageItems, (item, index) => (
                    <li
                      key={item.name}
                      className={classNames(drawerStyles.link, {
                        [drawerStyles.active]: index === activeManageItemsIndex,
                      })}
                      onClick={() => handleRouting(index)}
                      data-testid={item.testId}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </Column>
            </Div>
          </Drawer>
          <Div className={styles.pageContent}>{manageItems[activeManageItemsIndex].component}</Div>
        </Div>
      </DrawerRowWrapper>
    </Div>
  );
}

export default memo(Admin);
