import { cMonoColorType, cStatusType } from "../../../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../../../app/types";
import Button, { EButtonVariant } from "../../../../../../../components/Button/Button";
import Div from "../../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../../components/Icon/Icon";
import Popover from "../../../../../../../components/Popover/Popover";
import Column from "../../../../../../../components/Row/Column/Column";
import Row from "../../../../../../../components/Row/Row";
import Typography from "../../../../../../../components/Typography/Typography";
import { ECollaborationAction } from "../../../../../../../modules/collaborationSlice";
import { ICollaborator } from "../../ManageCollaborationModal";
import styles from "./Collaborator.module.scss";

interface ICollaboratorComponent {
  collaborator: ICollaborator;
  userEmail?: string;
  handleDeleteCollaboratorClick: (id: number) => void;
  handleSendCollaborationLink: (collaboratorID: number | "all") => void;
  sendCollaborationLinkStatus: cStatusType;
}

/**
 * Collaborator
 * @param collaborator                  The collaborator
 * @param userEmail                     The current user's email
 * @param handleDeleteCollaboratorClick Open the delete collaborator modal
 * @param handleSendCollaborationLink   Send the collaboration link
 * @param sendCollaborationLinkStatus   The send collaboration link status
 * @returns JSX.Element
 */
function Collaborator({
  collaborator,
  userEmail,
  handleDeleteCollaboratorClick,
  handleSendCollaborationLink,
  sendCollaborationLinkStatus,
}: ICollaboratorComponent): JSX.Element {
  const isLoggedInUser = collaborator.email === userEmail;

  /**
   * Render the action icon
   * @returns JSX.Element
   */
  const renderActionIcon = () => {
    // If the collaborator is the current user, display owner
    if (isLoggedInUser)
      return (
        <Div className={styles.action}>
          <Typography color={cMonoColorType.Light}>Owner</Typography>
        </Div>
      );

    let icon, testId;

    // If the collaborator is an editor, display the edit icon
    if (collaborator.action === ECollaborationAction.Edit) {
      icon = EIcon.Edit;
      testId = "edit-collaborator";
    } else {
      // Otherwise, display the view icon
      icon = EIcon.VisibilityOn;
      testId = "view-collaborator";
    }

    return (
      <Div testId={testId} className={styles.action}>
        <Icon icon={icon} />
      </Div>
    );
  };

  return (
    <Row
      alignItems={{ base: "center" }}
      key={collaborator.id}
      className={styles.collaborator}
      testId="collaborator-item"
    >
      <Column all={12} md={3} className={styles.columnData}>
        <Typography weight="medium">
          {collaborator.name} {isLoggedInUser && <span className={styles.ownerIndicator}>(you)</span>}
        </Typography>
      </Column>
      <Column all={12} md={4} className={styles.columnData}>
        <Typography>{collaborator.email}</Typography>
      </Column>
      <Column all={12} md={2} className={styles.columnData}>
        <Typography>{collaborator.mobilePhoneNo}</Typography>
      </Column>
      <Column all={12} md={1} className={styles.columnData}>
        <Div>{renderActionIcon()}</Div>
      </Column>
      <Column all={12} md={1} className={styles.notify}>
        <Popover
          variant="tooltip"
          popoverPlacement={EPopoverPlacement.Top}
          width="auto"
          popoverContents={<Div>Notify again</Div>}
          buttonContents={<Icon icon={collaborator.isMailSent ? EIcon.NotificationSent : EIcon.Email} />}
          buttonProps={{
            color: cMonoColorType.Dark,
            testId: "notify-btn",
            disabled: sendCollaborationLinkStatus === cStatusType.Loading,
            onClick: () => handleSendCollaborationLink(collaborator.id),
            variant: EButtonVariant.Square,
          }}
        />
      </Column>
      <Column all={12} md={1} className={styles.columnData}>
        <Div className={styles.deleteCollaboratorButton}>
          {!isLoggedInUser && (
            <Button
              color={cMonoColorType.Dark}
              variant={EButtonVariant.Link}
              icon={EIcon.Close}
              onClick={() => handleDeleteCollaboratorClick(collaborator.id)}
              testId="remove-collaborator-btn"
            />
          )}
        </Div>
      </Column>
    </Row>
  );
}

export default Collaborator;
