import { Dispatch, SetStateAction } from "react";
import { cSizeType, cThemeColorType } from "../../../../../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../../../../../app/types";
import { EButtonVariant } from "../../../../../../../../../components/Button/Button";
import Div from "../../../../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../../../../components/Icon/Icon";
import Popover from "../../../../../../../../../components/Popover/Popover";
import Column from "../../../../../../../../../components/Row/Column/Column";
import Row from "../../../../../../../../../components/Row/Row";
import Typography from "../../../../../../../../../components/Typography/Typography";
import { IWalkType } from "../../../../../../../../../modules/matterResourcesSlice";
import { IPostMatterLaunchExternalResource } from "../../../../../../../../../modules/walkSlice";
import {
  getTooltipText,
  isBtnDisabled,
  tooltipDraft,
  tooltipSendEmbed,
  tooltipUpload,
} from "../../../../MatterTask/MatterTask";
import styles from "./GeneralResourceActionRow.module.scss";

interface IGeneralResourceActionRow {
  type: "draft" | "file";
  data: IWalkType;
  matterID: number;
  handleLaunchExternalResource: ({ custWalkTypeID, matterID }: IPostMatterLaunchExternalResource) => void;
  setEmbedData: Dispatch<
    SetStateAction<{ custWalkTypeID: number; matterID?: number; interviewSpawnID?: number } | null>
  >;
  allowEmbed?: boolean;
}

/**
 * Matter general resource action row
 * @param type                         The type of resource
 * @param data                         The data for the resource
 * @param matterID                     The ID of the matter to post to the API
 * @param handleLaunchExternalResource Start external resource walk
 * @param setEmbedData                 Set the embed data for the send embed email notification modal
 * @param allowEmbed                   Is embed allowed in the customer config?
 * @returns JSX.Element
 */
function GeneralResourceActionRow({
  type,
  data,
  matterID,
  handleLaunchExternalResource,
  setEmbedData,
  allowEmbed,
}: IGeneralResourceActionRow): JSX.Element {
  return (
    <Row justifyContent={{ base: "space-between" }} alignItems={{ base: "center" }} className={styles.className}>
      <Column all={9} spacing={{ pr: 4 }}>
        <Typography className={styles.action}>{type === "draft" ? "Draft" : "File"}</Typography>
      </Column>
      <Column all={3} display={{ base: "flex" }} justifyContent={{ base: "flex-end" }}>
        <Div display={{ base: "flex" }} className={styles.buttonWrapper}>
          <Popover
            variant="tooltip"
            popoverPlacement={EPopoverPlacement.Top}
            width="auto"
            popoverContents={<Div>{getTooltipText(data.state, type === "draft" ? tooltipDraft : tooltipUpload)}</Div>}
            buttonContents={<Icon icon={type === "draft" ? EIcon.UpdateDocument : EIcon.UploadDocument} />}
            buttonProps={{
              size: cSizeType.Small,
              variant: EButtonVariant.Square,
              color: cThemeColorType.Secondary,
              disabled: isBtnDisabled(data.state),
              onClick: () => {
                handleLaunchExternalResource({ custWalkTypeID: data.custWalkTypeID as number, matterID });
              },
              testId: "external-resource-button",
            }}
          />
          {allowEmbed && (
            <Popover
              variant="tooltip"
              popoverPlacement={EPopoverPlacement.Top}
              width="auto"
              popoverContents={<Div>{getTooltipText(data.state, tooltipSendEmbed)}</Div>}
              buttonContents={<Icon icon={EIcon.Email} />}
              buttonProps={{
                size: cSizeType.Small,
                variant: EButtonVariant.Square,
                color: cThemeColorType.Secondary,
                disabled: isBtnDisabled(data.state),
                onClick: () => {
                  setEmbedData({ custWalkTypeID: data.custWalkTypeID as number, matterID });
                },
                testId: "external-resource-embed-button",
              }}
            />
          )}
        </Div>
      </Column>
    </Row>
  );
}

export default GeneralResourceActionRow;
