import classNames from "classnames";
import { isEmpty } from "lodash";
import { Control, FieldError, UseFormRegisterReturn, UseFormResetField } from "react-hook-form";
import { Value } from "react-phone-number-input";
import { cSizeType, cThemeColorType, REQUIRED_FIELD_MESSAGE } from "../../app/constants";
import { useSpacing } from "../../app/hooks";
import { ISpacing } from "../../app/types";
import fullWidthStyles from "../../scss/generic/FullWidth.module.scss";
import Button, { EButtonVariant } from "../Button/Button";
import Div from "../Div/Div";
import FileUploader from "../FileUploader/FileUploader";
import Icon, { EIcon } from "../Icon/Icon";
import { IButtonOption } from "./Buttons/Buttons";
import Buttons from "./Buttons/Buttons.lazy";
import Checkbox from "./Checkbox/Checkbox.lazy";
import DatepickerField from "./DatepickerField/DatepickerField.lazy";
import styles from "./FormField.module.scss";
import Input from "./Input/Input.lazy";
import Label from "./Label/Label.lazy";
import PasswordStrength from "./PasswordStrength/PasswordStrength";
import RadioButton from "./RadioButton/RadioButton.lazy";
import SelectAutocomplete from "./SelectAutocomplete/SelectAutocomplete";
import Switch from "./Switch/Switch.lazy";
import Tel from "./Tel/Tel.lazy";
import Textarea from "./Textarea/Textarea.lazy";
import TimepickerField from "./TimepickerField/TimepickerField.lazy";

/**
 * Form field type enum
 */
export enum cFormFieldType {
  Text = "text",
  Textarea = "textarea",
  Telephone = "tel",
  Number = "number",
  Password = "password",
  Radio = "radio",
  Checkbox = "checkbox",
  Select = "select",
  AutoComplete = "autocomplete",
  Switch = "switch",
  DatePicker = "datepicker",
  TimePicker = "timepicker",
  Buttons = "buttons",
  File = "file",
}

/**
 * Field option interface for radio, checkbox, select, buttons and auto complete type fields
 */
export interface IFieldOption {
  label: string | React.ReactNode;
  value: string | number;
  disabled?: boolean;
  className?: string; // Custom label class name
}

/**
 * Regex pattern object interface
 */
export interface IFieldPattern {
  value: RegExp;
  message: string;
}

/**
 * Generic form field props
 */
interface IFormFieldGeneric {
  label?: string | React.ReactNode | Element;
  name: string;
  required?: boolean;
  register: Function;
  error?: FieldError;
  success?: boolean;
  fullWidth?: boolean;
  spacing?: ISpacing;
  disabled?: boolean;
  validate?: Function;
  value?: unknown;
  hidden?: boolean;
  testId?: string;
  inputClassName?: string;
  maxLength?: number;
  rows?: number;
  autoFocus?: boolean;
}

export type TAffix = string | number | React.ReactNode;

/**
 * Strict typing based on form field type
 */
type IFormFieldType =
  | {
      type?: cFormFieldType.Text | cFormFieldType.Number | cFormFieldType.Password;
      pattern?: IFieldPattern | {};
      options?: IFieldOption[];
      clearOptions?: () => void;
      defaultValue?: string;
      onInputChange?: never;
      clearable?: boolean;
      searchable?: never;
      isMulti?: never;
      prepend?: TAffix;
      append?: TAffix;
      showPassword?: boolean;
      toggleShowPassword?: () => void;
      control?: never;
      selectFooter?: never;
      resetField?: never;
      placeholder?: string;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: "on" | "off";
      autoFocus?: boolean;
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.Telephone;
      pattern?: IFieldPattern | {};
      options?: never;
      clearOptions?: never;
      defaultValue?: string;
      onInputChange?: never;
      clearable?: boolean;
      searchable?: never;
      isMulti?: never;
      prepend?: TAffix;
      append?: TAffix;
      showPassword?: boolean;
      toggleShowPassword?: () => void;
      control: Control<any, any>;
      selectFooter?: never;
      resetField?: UseFormResetField<any>;
      placeholder?: string;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: "on" | "off";
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.Textarea;
      pattern?: IFieldPattern | {};
      options?: never;
      clearOptions?: never;
      defaultValue?: string;
      onInputChange?: never;
      clearable?: boolean;
      searchable?: never;
      isMulti?: never;
      prepend?: never;
      append?: never;
      showPassword?: never;
      toggleShowPassword?: never;
      control?: never;
      selectFooter?: never;
      resetField?: never;
      placeholder?: string;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: "on" | "off";
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: boolean;
    }
  | {
      type?: cFormFieldType.Select;
      pattern?: never;
      options: IFieldOption[];
      clearOptions?: never;
      defaultValue?: string | number | IFieldOption;
      onInputChange?: never;
      clearable?: boolean;
      searchable?: boolean;
      isMulti?: boolean;
      prepend?: TAffix;
      append?: TAffix;
      showPassword?: never;
      toggleShowPassword?: never;
      control: Control<any, any>;
      selectFooter?: JSX.Element;
      resetField?: never;
      placeholder?: never;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: () => void;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: never;
      highlightSelected?: never;
      customWidth?: number | string;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.Radio | cFormFieldType.Checkbox;
      pattern?: never;
      options: IFieldOption[];
      clearOptions?: never;
      defaultValue?: string | number | IFieldOption;
      onInputChange?: never;
      clearable?: never;
      searchable?: never;
      isMulti?: never;
      prepend?: never;
      append?: never;
      showPassword?: never;
      toggleShowPassword?: never;
      control?: never;
      selectFooter?: never;
      resetField?: never;
      placeholder?: never;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: never;
      highlightSelected?: boolean;
      customWidth?: never;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.Buttons;
      pattern?: never;
      options: IButtonOption[];
      clearOptions?: never;
      defaultValue?: string | number | IFieldOption;
      onInputChange?: never;
      clearable?: never;
      searchable?: never;
      isMulti?: never;
      prepend?: never;
      append?: never;
      showPassword?: never;
      toggleShowPassword?: never;
      control?: never;
      selectFooter?: never;
      resetField?: never;
      placeholder?: never;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: never;
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.AutoComplete;
      pattern?: never;
      options?: IFieldOption[];
      clearOptions?: never;
      defaultValue?: IFieldOption;
      onInputChange: (value: string) => void;
      clearable?: boolean;
      searchable?: boolean;
      isMulti?: boolean;
      prepend?: TAffix;
      append?: TAffix;
      showPassword?: never;
      toggleShowPassword?: never;
      control: Control<any, any>;
      selectFooter?: JSX.Element;
      resetField?: never;
      placeholder?: never;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: never;
      highlightSelected?: never;
      customWidth?: number | string;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.Switch;
      pattern?: never;
      options?: IFieldOption[];
      clearOptions?: never;
      defaultValue?: boolean;
      onInputChange?: never;
      clearable?: never;
      searchable?: never;
      isMulti?: never;
      prepend?: never;
      append?: never;
      showPassword?: never;
      toggleShowPassword?: never;
      control?: never;
      selectFooter?: never;
      resetField?: never;
      placeholder?: never;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: never;
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.DatePicker;
      pattern?: never;
      options?: never;
      clearOptions?: never;
      defaultValue?: string;
      onInputChange?: never;
      clearable?: boolean;
      searchable?: never;
      isMulti?: never;
      prepend?: TAffix;
      append?: TAffix;
      showPassword?: never;
      toggleShowPassword?: never;
      control?: never;
      selectFooter?: never;
      resetField?: never;
      placeholder?: never;
      maxFileUploadSize?: never;
      maxDate?: Date;
      onFocus?: never;
      minDate?: Date;
      defaultActiveStartDate?: Date;
      autoComplete?: never;
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.TimePicker;
      pattern?: never;
      options?: never;
      clearOptions?: never;
      defaultValue?: string;
      onInputChange?: never;
      clearable?: never;
      searchable?: never;
      isMulti?: never;
      prepend?: TAffix;
      append?: TAffix;
      showPassword?: never;
      toggleShowPassword?: never;
      control: Control<any, any>;
      selectFooter?: never;
      resetField?: never;
      placeholder?: never;
      maxFileUploadSize?: never;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: never;
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: never;
    }
  | {
      type?: cFormFieldType.File;
      pattern?: never;
      options?: never;
      clearOptions?: never;
      defaultValue?: File;
      onInputChange?: never;
      clearable?: never;
      searchable?: never;
      isMulti?: never;
      prepend?: never;
      append?: never;
      showPassword?: never;
      toggleShowPassword?: never;
      control: Control<any, any>;
      selectFooter?: never;
      resetField?: UseFormResetField<any>;
      placeholder?: never;
      maxFileUploadSize?: number;
      maxDate?: never;
      onFocus?: never;
      minDate?: never;
      defaultActiveStartDate?: never;
      autoComplete?: never;
      highlightSelected?: never;
      customWidth?: never;
      autoResize?: never;
    };

export type IFormField = IFormFieldGeneric & IFormFieldType; // Combine generic and type
export type IClearFn = (ref: any) => void; // Clear function type

/**
 * A form field of any type that includes a label
 * @param label                  The label text
 * @param name                   The field name
 * @param type                   The field type (defaults to "text")
 * @param required               Is the field required?
 * @param error                  Any error text
 * @param success                Apply success style onto text type fields
 * @param fullWidth              Display the field and wrapper at 100%
 * @param options                Options for radio buttons, checkboxes and selects
 * @param clearOptions           Function to clear the options
 * @param defaultValue           Field default value
 * @param spacing                Form field wrapper spacing
 * @param onInputChange          Function to run (usually to update the options) on autocomplete input value change
 * @param clearable              Can the field be cleared by click of "x"?
 * @param isMulti                Can the select have multiple selections
 * @param prepend                Prepend to the field
 * @param append                 Append to the field
 * @param disabled               Is the field disabled?
 * @param validate               A validate function
 * @param value                  The field value
 * @param showPassword           Is the password visible?
 * @param toggleShowPassword     Toggles show password
 * @param hidden                 Is the field hidden?
 * @param control                React hook form control
 * @param testId                 The test id
 * @param selectFooter           A custom footer on the Select component
 * @param resetField             React hook form resetField function
 * @param inputClassName         Custom className for inputs
 * @param placeholder            Placeholder text for inputs / textareas
 * @param maxFileUploadSize      The max file upload size
 * @param maxDate                The max date to display (to disable dates past that date)
 * @param onFocus                onFocus callback for Selects
 * @param minDate                The min date to display (to disable dates before that date)
 * @param maxLength              The max character input length
 * @param rows                   The height of the input
 * @param defaultActiveStartDate The start date to display on the datepicker
 * @param autoComplete           Browser auto complete on/off
 * @param autoFocus              Should the input auto focus?
 * @param highlightSelected      Should the selected option/s be highlighted?
 * @param customWidth            Custom width for the select component
 * @param autoResize             Should the textarea resize automatically?
 * @returns JSX.Element
 */
function FormField({
  label,
  name,
  type = cFormFieldType.Text,
  required,
  pattern,
  register,
  error,
  success,
  fullWidth,
  options,
  clearOptions,
  defaultValue,
  spacing,
  onInputChange,
  clearable = false,
  isMulti = false,
  prepend,
  append,
  disabled = false,
  validate,
  value,
  showPassword,
  toggleShowPassword,
  hidden = false,
  control,
  selectFooter,
  testId,
  resetField,
  inputClassName,
  placeholder,
  maxFileUploadSize,
  maxDate,
  onFocus,
  minDate,
  maxLength,
  rows,
  defaultActiveStartDate,
  autoComplete,
  autoFocus,
  highlightSelected,
  customWidth,
  autoResize,
  ...props
}: IFormField): JSX.Element {
  let field: React.ReactNode = <Div />; // Setup field var

  const clx = classNames(
    styles.className, // Add initial className
    { [fullWidthStyles.className]: fullWidth }, // Add full width class name if set
    { [styles.errorField]: error }, // Add error class name if set
    { [styles.success]: success }, // Add success class name if set
    inputClassName,
  );

  const requiredMsg = REQUIRED_FIELD_MESSAGE; // Error message to display for required fields
  const wrapperClx: string = useSpacing(spacing, hidden); // Get wrapper spacing class

  const fieldProps: UseFormRegisterReturn = {
    // Setup field props
    ...register(
      // Register the field with React Hook Form
      name, // Include the field name
      {
        required:
          required === true // If the field is required
            ? requiredMsg // Add the field rule with the message
            : false, // Otherwise set the required value to false
        pattern: pattern && !isEmpty(pattern) ? pattern : false, // Add the regex pattern validation if it exists
        validate: validate,
      },
    ),
  };

  let typedOptions; // Options for radio, checkbox, select elements - cannot be IButtonOption since it contains React.ReactNode (https://stackoverflow.com/questions/59988669/variable-of-union-type-causes-error-in-switch-statement)

  /**
   * Clear a field value
   * @param ref The ref of the field to be cleared
   */
  function handleClear(ref: any): void {
    if (ref.hasOwnProperty("current")) {
      ref.current.value = ""; // Set the hidden field value
      fieldProps.onChange({
        // Call React Hook Form's onChange method
        target: {
          // Create a fake event object
          value: "", // Send the field value
          name, // Send the field name
          type: "text", // Type should always be "text"
        },
        type: "change", // The event type
      });

      ref.current.dispatchEvent(
        // Dispatch the change event on the hidden field to update React Hook Form
        new Event("change", { bubbles: true }),
      );
    }
  }

  // Get the field disabled state to be used for those with disabled options (where disabled is contained in the option)
  const fieldDisabled = disabled;

  // Create the field depending on the type prop and spread the field props from React Hook Form
  switch (type as cFormFieldType) {
    case cFormFieldType.Textarea:
      field = (
        <>
          <Textarea
            fieldProps={fieldProps}
            fieldClx={clx}
            onClear={
              clearable // If clearable
                ? handleClear // Send handle clear function
                : undefined // Otherwise, undefined
            }
            fullWidth={fullWidth}
            disabled={disabled} // If disabled
            name={name} // Field name
            testId={testId}
            placeholder={placeholder}
            maxLength={maxLength}
            rows={rows}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            autoResize={autoResize}
          />
        </>
      );
      break;

    case cFormFieldType.Buttons:
      typedOptions = options as IFieldOption[];
      field = (
        <Div testId={testId}>
          {options && (
            <Buttons
              name={name}
              fieldProps={fieldProps} //  Field props from React Hook Form's register
              options={typedOptions}
              hidden={hidden}
            />
          )}
        </Div>
      );
      break;

    case cFormFieldType.Radio:
      typedOptions = options as IFieldOption[];

      field = (
        <>
          {typedOptions?.map(({ label, value, disabled, className }) => {
            return (
              //  Map the options to radio buttons
              <RadioButton
                value={value}
                name={name}
                fieldProps={fieldProps} //  Field props from React Hook Form's register
                label={label}
                key={value} //  Unique key
                defaultValue={defaultValue as string}
                disabled={fieldDisabled || disabled}
                className={className}
                highlightSelected={highlightSelected}
              />
            );
          })}
        </>
      );
      break;

    case cFormFieldType.Checkbox:
      typedOptions = options as IFieldOption[];
      field = (
        <>
          <Div testId={testId}>
            {typedOptions?.map((option) => {
              const { label, value, disabled, className } = option;
              return (
                <Checkbox
                  value={value}
                  name={name}
                  fieldProps={fieldProps}
                  label={label}
                  key={value}
                  disabled={fieldDisabled || disabled}
                  className={className}
                  highlightSelected={highlightSelected}
                />
              );
            })}
          </Div>
        </>
      );
      break;

    case cFormFieldType.Switch:
      typedOptions = options as IFieldOption[];
      field = (
        <>
          <Div className={styles.switchGrid}>
            {typedOptions?.map(({ label, value }) => {
              return (
                //  Map the options to radio buttons
                <Switch
                  value={value}
                  name={name}
                  fieldProps={fieldProps} //  Field props from React Hook Form's register
                  label={label}
                  key={value} //  Unique key
                  disabled={fieldDisabled || disabled} //  If disabled
                  testId={testId}
                  hidden={hidden}
                />
              );
            })}
          </Div>
        </>
      );
      break;

    case cFormFieldType.Select:
      typedOptions = options as IFieldOption[];
      field = (
        <>
          <SelectAutocomplete
            options={typedOptions || []}
            control={control}
            fullWidth={fullWidth} // Should the field render at full width?
            disabled={disabled} // If disabled
            prepend={prepend} // Prepend content
            append={append} // Append content
            name={name} // Field name
            defaultValue={defaultValue}
            isClearable={clearable}
            isSearchable={false}
            isMulti={isMulti}
            required={required}
            footer={selectFooter}
            hidden={hidden}
            onFocus={onFocus}
            customWidth={customWidth}
          />
        </>
      );
      break;

    case cFormFieldType.AutoComplete:
      typedOptions = options as IFieldOption[];
      field = (
        <SelectAutocomplete
          options={typedOptions || []}
          control={control}
          fullWidth={fullWidth} // Should the field render at full width?
          disabled={disabled} // If disabled
          prepend={prepend} // Prepend content
          append={append} // Append content
          name={name} // Field name
          defaultValue={defaultValue}
          isClearable={clearable}
          isSearchable={true}
          isMulti={isMulti}
          onInputChange={onInputChange}
          required={required}
          footer={selectFooter}
          hidden={hidden}
          customWidth={customWidth}
        />
      );
      break;

    case cFormFieldType.DatePicker:
      field = (
        <>
          <DatepickerField
            fieldProps={fieldProps}
            className={clx}
            name={name}
            prepend={prepend}
            append={append}
            fullWidth={fullWidth}
            defaultValue={
              typeof defaultValue === "string" // If default value is a string
                ? defaultValue // Set the value
                : undefined // Otherwise, send undefined
            }
            disabled={disabled} // If disabled
            hidden={hidden}
            maxDate={maxDate}
            minDate={minDate}
            testId={testId}
            defaultActiveStartDate={defaultActiveStartDate}
          />
        </>
      );
      break;

    case cFormFieldType.TimePicker:
      field = (
        <>
          <TimepickerField
            control={control}
            prepend={prepend} // Prepend content
            append={append} // Append content
            disabled={disabled} // Should the field be disabled?
            name={name} // Field name
          />
        </>
      );
      break;

    case cFormFieldType.Telephone:
      field = (
        <>
          <Tel
            name={name}
            disabled={disabled} // If disabled
            prepend={prepend} // Prepend content
            append={append} // Append content
            fullWidth={fullWidth} // Should the field render at full width?
            defaultValue={
              typeof defaultValue === "string" // If default value is a string
                ? (defaultValue as Value) // Set the value
                : undefined // Otherwise, send undefined
            }
            control={control}
            required={required}
            resetField={resetField}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />
        </>
      );
      break;

    case cFormFieldType.File:
      field = (
        <>
          <FileUploader
            control={control}
            name={name} // Field name
            defaultValue={defaultValue as File}
            maxFileUploadSize={maxFileUploadSize as number}
            required={required}
            resetField={resetField}
          />
        </>
      );
      break;

    case cFormFieldType.Number:
    case cFormFieldType.Password:
    default:
      typedOptions = options as IFieldOption[];
      field = (
        <>
          <Input
            type={showPassword ? cFormFieldType.Text : type} // If showPassword show a text input else default to input type of passed in prop
            className={clx} // Input classes
            fullWidth={fullWidth} // Should the field render at full width?
            name={name}
            defaultValue={
              typeof defaultValue === "string" // If default value is a string
                ? defaultValue // Set the value
                : undefined // Otherwise, send undefined
            }
            fieldProps={fieldProps} // Spread field props from React Hook Form's register
            onClear={
              clearable // If clearable
                ? handleClear // Send handle clear function
                : undefined // Otherwise, undefined
            }
            prepend={prepend} // Prepend content
            append={append} // Append content
            disabled={disabled} // If disabled
            hidden={hidden}
            testId={testId}
            placeholder={placeholder}
            options={typedOptions || []}
            clearOptions={clearOptions}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            {...props}
          />
        </>
      );
      break;
  }

  /**
   * Render the password strength component if the showPassword prop is true
   * @returns JSX.Element
   */
  function renderPasswordStrength() {
    if (toggleShowPassword && value) {
      return (
        <Div spacing={{ mt: 3 }}>
          <PasswordStrength password={value} />
        </Div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className={wrapperClx}>
      {toggleShowPassword ? (
        <Div display={{ base: "flex" }} justifyContent={{ base: "space-between" }}>
          {label && !hidden && <Label htmlFor={name}>{label}</Label>}
          <Button
            variant={EButtonVariant.Link}
            size={cSizeType.Medium}
            onClick={toggleShowPassword}
            testId="toggle-password"
            tabIndex={-1}
          >
            <Icon
              icon={showPassword ? EIcon.VisibilityOff : EIcon.VisibilityOn}
              color={cThemeColorType.Primary}
              size={cSizeType.Medium}
            />
          </Button>
        </Div>
      ) : (
        label && !hidden && <Label htmlFor={name}>{label}</Label>
      )}
      {field}
      {renderPasswordStrength()}
      {error?.message && ( //  If there is an error, display it
        <Div className={styles.errorTextWrapper}>
          <Div className={styles.error}>{error.message}</Div>
        </Div>
      )}
    </div>
  );
}

export default FormField;
