import { UseFormRegister } from "react-hook-form";
import { cThemeColorType } from "../../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../../app/types";
import { EButtonVariant } from "../../../../../../components/Button/Button";
import Div from "../../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../../components/FormField/FormField";
import Icon, { EIcon } from "../../../../../../components/Icon/Icon";
import Popover from "../../../../../../components/Popover/Popover";
import Column from "../../../../../../components/Row/Column/Column";
import Row from "../../../../../../components/Row/Row";
import styles from "../../../Analytics.module.scss";

interface IAnalyticsDescriptionContainsFieldGroup {
  register: UseFormRegister<any>;
  analyticsType: "matter" | "document";
}

/**
 * The analytics name contains field group
 * @param register      The register function from react-hook-form
 * @param analyticsType The analytics type
 * @returns JSX.Element
 */
function AnalyticsDescriptionContainsFieldGroup({
  register,
  analyticsType,
}: IAnalyticsDescriptionContainsFieldGroup): JSX.Element {
  const labelText = analyticsType === "matter" ? "Matter name contains" : "Document name contains";
  const popoverText =
    analyticsType === "matter"
      ? "Include only Matters containing this text as part of their name."
      : "Include only documents containing this text as part of their name.";

  return (
    <Div className={styles.formRow} testId="description-filter">
      <Row>
        <Column sm={7}>
          <Div className={styles.labelWithPopover}>
            <label htmlFor="descriptionContains.value">
              {labelText}
              {"\u00A0"}
            </label>
            <Popover
              popoverPlacement={EPopoverPlacement.Top}
              popoverContents={popoverText}
              buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
              buttonProps={{ variant: EButtonVariant.Link }}
              className={styles.popover}
            />
          </Div>
          <FormField
            name="descriptionContains.value"
            register={register}
            data-lpignore={true}
            append={
              <FormField
                spacing={{ ml: 5 }}
                name="descriptionContains.caseSensitive"
                register={register}
                type={cFormFieldType.Switch}
                options={[
                  {
                    label: "Case sensitive",
                    value: "false",
                  },
                ]}
                testId="document-description-case-sensitive-switch"
              />
            }
            testId="description-contains-input"
            fullWidth
          />
        </Column>
      </Row>
    </Div>
  );
}

export default AnalyticsDescriptionContainsFieldGroup;
