import { chain } from "lodash";
import { useEffect, useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { cSizeType, cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import DiscardChangesModal from "../../../../../components/DiscardChangesModal/DiscardChangesModal";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../components/Div/Div";
import { ETimelineType } from "../../../../../components/Events/TimelineEvents";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../components/FormField/FormField";
import Label from "../../../../../components/FormField/Label/Label";
import InfoBox from "../../../../../components/InfoBox/InfoBox";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Spinner from "../../../../../components/Spinner/Spinner";
import Typography from "../../../../../components/Typography/Typography";
import { IDocument } from "../../../../../modules/documentsSlice";
import { IMatter } from "../../../../../modules/mattersSlice";
import { IUser } from "../../../../../modules/usersSlice";
import { areSomeDefined } from "../../../../../utils/areSomeDefined/areSomeDefined";
import { IHandleOwnershipTransfer } from "../TransferOwnershipModalContainer";
import styles from "./TransferOwnershipModal.module.scss";

// Form inputs
type Inputs = {
  user: IFieldOption | null;
  transferNote: string;
};

// Generic properties for component
interface ITransferOwnershipModal {
  isOpen: boolean;
  handleClose: () => void;
  onSubmitSuccess?: () => void;
  type: ETimelineType;
  documentID?: number;
  matterID?: number;
  matters?: Record<number, IMatter>;
  documents?: Record<string, IDocument>;
  users: Record<number, IUser>;
  usersStatus: cStatusType;
  usersError?: string;
  transferUsersStatus?: cStatusType;
  transferUsersError?: string;
  replacementUserIDs?: number[];
  documentsStatus: cStatusType;
  documentsError?: string;
  mattersStatus: cStatusType;
  mattersError?: string;
  handleOwnershipTransfer: (params: IHandleOwnershipTransfer) => Promise<void>;
}

const formId = "transferOwnershipForm";

/**
 * Render a modal to transfer ownership
 * @param isOpen	                Is the modal open?
 * @param handleClose	            Function to close the modal
 * @param onSubmitSuccess	        Function called on submit success
 * @param type      	            Document or matter transfer ownership
 * @param documentID              The document ID
 * @param documents               Document records
 * @param matterID                The matter ID
 * @param matters                 Matter records
 * @param users                   User records
 * @param usersStatus             User status
 * @param usersError              User error
 * @param transferUsersStatus     Transfer user status
 * @param transferUsersError      Transfer user error
 * @param replacementUserIDs      Replacement user IDs
 * @param documentsStatus         Documents status
 * @param documentsError          Documents error
 * @param mattersStatus           Matters status
 * @param mattersError            Matters error
 * @param handleOwnershipTransfer Function to handle ownership transfer
 * @returns JSX.Element
 */
function TransferOwnershipModal({
  isOpen,
  handleClose,
  onSubmitSuccess,
  type,
  documentID,
  documents,
  matterID,
  matters,
  users,
  usersStatus,
  usersError,
  transferUsersStatus,
  transferUsersError,
  replacementUserIDs,
  documentsStatus,
  documentsError,
  mattersStatus,
  mattersError,
  handleOwnershipTransfer,
}: ITransferOwnershipModal): JSX.Element {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
    control,
    reset,
    watch,
  } = useForm<Inputs>();

  // Open state for discard changes modal
  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);

  // The document or matter record - information is used in the modal
  const [record, setRecord] = useState<IDocument | IMatter>();

  // Options for the replacement user select field
  const [replacementUserOptions, setReplacementUserOptions] = useState<IFieldOption[]>([]);

  // Set the record once record set exists
  useEffect(() => {
    // If the type is document and the documents and documentID exist, set the record
    if (type === ETimelineType.Document) {
      if (documents && documentID) {
        setRecord(documents[documentID]);
      }
      // Otherwise this is a matter
    } else {
      // If the matters and matterID exist, set the record
      if (matters && matterID) {
        setRecord(matters[matterID]);
      }
    }
  }, [type, documents, documentID, matters, matterID]);

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    // If there are changes, open the discard changes modal
    if (areSomeDefined(watch())) {
      setIsDiscardChangesModalOpen(true);
    } else {
      // Otherwise, close the modal
      handleClose();
      reset();
    }
  }

  /**
   * Handles DiscardChangesModal close
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
    }
    setIsDiscardChangesModalOpen(false);
  }

  // Set the replacement user options on users state update
  useEffect(() => {
    if (usersStatus === cStatusType.Idle && transferUsersStatus === cStatusType.Idle) {
      // Put replacement user options into field option format
      const userOptions: IFieldOption[] = chain(users)
        .orderBy([(user) => user?.lastName?.toLowerCase(), (user) => user?.firstName?.toLowerCase()])
        .reduce((acc: IFieldOption[], user: IUser) => {
          if (replacementUserIDs?.includes(user.id)) {
            acc.push({ label: `${user.lastName}, ${user.firstName} `, value: user.id });
          }
          return acc;
        }, [])
        .value();

      // Add a select option to the beginning of the options
      userOptions.unshift({ label: "Select...", value: "" });
      setReplacementUserOptions(userOptions);
    }
  }, [users, usersStatus, transferUsersStatus, replacementUserIDs]);

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (record) {
      // Get the record ID and owner user
      const { id, ownerUser } = record;
      const { user, transferNote } = data;

      // Handle the ownership transfer
      await handleOwnershipTransfer({ user, transferNote, id, ownerUser });
      reset();
      handleClose();

      // Call the success callback
      onSubmitSuccess && onSubmitSuccess();
    }
  };

  // Set the error message from any of the possible errors
  const error = usersError || transferUsersError || documentsError || mattersError;

  // Title case the type
  const titleCaseType = type === ETimelineType.Matter ? type.charAt(0).toUpperCase() + type.slice(1) : type;

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} size="md" testId="transfer-ownership-modal">
        <ModalHeader>
          <ModalTitle>Transfer {type ? titleCaseType : ""} ownership</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Div spacing={{ mb: 5 }}>
            <InfoBox>
              <ul>
                <li>Ownership of this {type} will transfer to the replacement user.</li>
                <li>Future {titleCaseType} owner notifications will go to the replacement user.</li>
              </ul>
            </InfoBox>
          </Div>
          <Label>Transferring</Label>
          <Typography>{record?.description}</Typography>
          <Div spacing={{ mt: 5 }}>
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
              {error && <DisplayError>{error}</DisplayError>}
              <Label>To</Label>
              <Typography className={styles.small} fontStyle="italic">
                This {type} can only be transferred to users who have viewing rights to the {type} and are listed here:
              </Typography>
              {transferUsersStatus === cStatusType.Loading || usersStatus === cStatusType.Loading ? (
                <Spinner size={cSizeType.Small} />
              ) : (
                <Div className={styles.userFieldWrapper}>
                  <FormField
                    name="user"
                    type={cFormFieldType.AutoComplete}
                    register={register}
                    options={replacementUserOptions}
                    error={errors.user as any}
                    control={control as Control<any, any>}
                    required
                    spacing={{ mt: 3, mb: 5 }}
                    onInputChange={() => {}}
                    fullWidth
                  />
                </Div>
              )}
              <FormField
                testId="transfer-note"
                label="Transfer note (optional)"
                name="transferNote"
                type={cFormFieldType.Textarea}
                register={register}
                error={errors.transferNote}
                spacing={{ mb: 5 }}
                fullWidth
              />
            </form>
          </Div>
        </ModalContent>
        <ModalActions>
          <Button
            color={cThemeColorType.Secondary}
            onClick={handleModalClose}
            testId="transfer-ownership-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            formId={formId}
            type={cButtonType.SubmitType}
            testId="transfer-ownership-modal-submit-button"
            isLoading={documentsStatus === cStatusType.Loading || mattersStatus === cStatusType.Loading}
            disabled={Boolean(
              transferUsersStatus === cStatusType.Loading ||
                usersStatus === cStatusType.Loading ||
                usersError ||
                transferUsersError ||
                documentsError ||
                mattersError ||
                !record,
            )}
          >
            Transfer
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
    </>
  );
}

export default TransferOwnershipModal;
