import classNames from "classnames";
import { memo } from "react";
import { IXDirection, TFilterType } from "../../app/types";
import Div from "../Div/Div";
import { IFieldOption, IFormField } from "../FormField/FormField";
import { IColumn } from "../Row/Column/Column";
import styles from "./TableCard.module.scss";
import TableCardFilterRow from "./TableCardFilterRow/TableCardFilterRow";
import TableCardHeaderCell from "./TableCardHeaderCell/TableCardHeaderCell";
import TableCardRow from "./TableCardRow/TableCardRow";

/**
 * Generic column type
 */
export type ITableCardColumnGeneric = {
  heading: string | null; // The column heading
  width?: string; // Manual column width
  colspan?: IColumn; // Amount of columns to span (1 to 12)
  align?: IXDirection; // Column alignments
  onOrder?: (value: "asc" | "desc", heading: string | null) => void; // Function to run on order - if this exists, the column will be orderable
  onFilter?: (values: any) => void; // Function to run on filter - all filter values as params
  testId?: string;
  className?: string; // A class name for the table column
  columnAction?: JSX.Element; // For column actions in header (e.g. checkbox)
  headerClassName?: string; // A class name for the table header
};

/**
 * Use type union to allow for different props based on filter type
 */
type TTableCardColumnFilter =
  | {
      filterType?: TFilterType.Date;
      filterOptions?: never;
      onInputChange?: never;
      fieldFullWidth?: never;
    }
  | {
      filterType?: TFilterType.Input; // If filter type is date or input
      filterOptions?: never; // Don't allow filter options
      onInputChange?: never; // Don't allow on input change function
      fieldFullWidth?: boolean; // Allow full width field
    }
  | {
      filterType: TFilterType.Select; // If filter type is select
      filterOptions?: IFieldOption[]; // Filter options is required
      onInputChange?: never; // Don't allow on input change function
      fieldFullWidth?: boolean; // Allow full width field
    }
  | {
      filterType: TFilterType.AutoComplete; // If filter type is auto complete
      filterOptions?: IFieldOption[]; // Allow optional filter options
      onInputChange?: IFormField["onInputChange"]; // On input change function for auto complete is required
      fieldFullWidth?: boolean; // Allow full width field
    }
  | {
      filterType: TFilterType.Actions; // If filter column is action
      filterOptions?: never; // Don't allow filter options
      onInputChange?: never; // Don't allow on input change function
      fieldFullWidth?: boolean; // Allow full width field
    };

export type ITableCardColumn = ITableCardColumnGeneric & TTableCardColumnFilter; // Combine types

/**
 * Cell types
 */
export type ITableCardColumnCell =
  | React.ReactNode // JSX
  | string // String value
  | number; // Number value

export type ITableCardColumnCells = ITableCardColumnCell[]; // Array of cells

export interface ITableCard {
  rows: ITableCardColumnCells[];
  columns: ITableCardColumn[];
  onFilter?: () => void; // Function to run on filter
  onRowClick?: (rowIndex: number) => void; // Function to set the row index when the user clicks
  className?: string;
  variant?: "standard" | "timeline" | "flush";
  testId?: string;
  hasClickableRows?: boolean;
  rowIndexAndClassName?: { index: number; className: string };
}

/**
 * Render a data table with filtering options and ordering
 * @param rows                 Rows to pass in - 2-tier array of rows and columns
 * @param columns              Array of columns and their behaviours
 * @param onFilter             Function to run on filter field update
 * @param className            Custom classes for the card table
 * @param variant              The table variant
 * @param testId               A testId to query in the tests
 * @param hasClickableRows     Are the rows clickable?
 * @param rowIndexAndClassName Index and className for a row with a custom class
 * @returns JSX.Element
 */
function TableCard({
  rows,
  columns,
  onFilter,
  onRowClick,
  className,
  variant = "standard",
  testId,
  hasClickableRows,
  rowIndexAndClassName,
}: ITableCard): JSX.Element {
  const clx = classNames(
    // Build class string
    styles.className, // General class
    className, // Custom classes if any
  );

  return (
    <Div testId={testId}>
      <Div className={classNames(variant === "standard" && styles.tableWrapper, variant === "flush" && styles.flush)}>
        <table // Start Cards list
          className={clx} // Base class
        >
          <thead
            className={classNames(styles.tableCardHeader, variant === "timeline" && styles.timeline)}
            data-testid="table-card-header-row"
          >
            <tr>
              {columns.map((column, i) => (
                <TableCardHeaderCell // Create card list header
                  {...column}
                  key={i}
                />
              ))}
            </tr>
            {onFilter && (
              <tr className={styles.filterRow}>
                <TableCardFilterRow columns={columns} onFilter={onFilter} />
              </tr>
            )}
          </thead>
          <tbody>
            {rows.map(
              (
                row,
                i, // Iterate rows
              ) => (
                <TableCardRow // Render rows
                  cells={row} // Row cells
                  columns={columns} // Column data
                  key={i}
                  rowIndex={i}
                  onRowClick={onRowClick}
                  isClickable={hasClickableRows}
                  rowIndexAndClassName={rowIndexAndClassName}
                />
              ),
            )}
          </tbody>
        </table>
      </Div>
    </Div>
  );
}

export default memo(TableCard);
