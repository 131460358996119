import { lazy, Suspense } from "react";
import LazyPageFallback from "../../components/LazyPageFallback/LazyPageFallback";

const LazyAnalyticsContainer = lazy(() => import("./AnalyticsContainer"));

const AnalyticsContainer = (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={<LazyPageFallback />}>
    <LazyAnalyticsContainer {...props} />
  </Suspense>
);

export default AnalyticsContainer;
