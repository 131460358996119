import { reduce } from "lodash";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"; // https://react-redux.js.org/
import { useLocation } from "react-router-dom";
import { postCreateMatter, postMatterClone } from "../modules/walkSlice";
import borderStyles from "../scss/generic/Border.module.scss";
import scrollStyles from "../scss/generic/Scroll.module.scss";
import spacingStyles from "../scss/generic/Spacing.module.scss";
import utilityStyles from "../scss/generic/Utilities.module.scss";
import { MATTER_CLONE_INITIATION_CONTEXT } from "./constants";
import type { AppDispatch, RootState } from "./store";
import {
  IAlignContent,
  IAlignItems,
  IAlignSelf,
  IFlexDirection,
  IJustifyContent,
  IJustifyItems,
  IJustifySelf,
  IMargin,
  IMarginB,
  IMarginL,
  IMarginR,
  IMarginT,
  IMarginX,
  IMarginY,
  IPadding,
  IPaddingB,
  IPaddingL,
  IPaddingR,
  IPaddingT,
  IPaddingX,
  IPaddingY,
  TDisplay,
} from "./styleTypes";
import { IBorder, IScroll, ISpacing } from "./types";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// These are typed for TypeScript
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function setResponsiveClx(
  prefix: string,
  params:
    | IAlignContent
    | IAlignItems
    | IAlignSelf
    | IFlexDirection
    | IJustifyContent
    | IJustifyItems
    | IJustifySelf
    | IMargin
    | IMarginB
    | IMarginL
    | IMarginR
    | IMarginT
    | IMarginX
    | IMarginY
    | IPadding
    | IPaddingB
    | IPaddingL
    | IPaddingR
    | IPaddingT
    | IPaddingX
    | IPaddingY
    | TDisplay
    | undefined,
) {
  return params // If params exist
    ? reduce(
        // Reduce params to...
        params,
        (acc, param, key): string => {
          let style;
          if (`${key}` === "base") {
            style = utilityStyles[`${prefix}-${param}`];
          } else if (`${key}` != null && `${param}` != null) {
            style = utilityStyles[`${prefix}-${key}-${param}`];
          }
          acc += ` ${style}`; // Add the style string to the accumulator
          return acc; // Return the accumulator
        },
        "",
      ).trim() // Trim off any whitespace, otherwise
    : // If no params exist...
      ""; // Return empty string
}

/**
 * Hook to return the appropriate spacing classes from the spacing CSS module
 * @param params Spacing object
 * @returns string
 */
export function useSpacing(params: ISpacing | undefined, hidden = false): any {
  if (hidden) {
    return;
  }
  return params // If params exist
    ? // Reduce the params to...
      reduce(
        params,
        (acc, param, key): string => {
          const style = spacingStyles[`${key}-${param}`];
          acc += ` ${style}`; // Add the style string to the accumulator
          return acc; // Return the accumulator
        },
        "",
      ).trim() // Trim off any whitespace, otherwise
    : // If no params exist...
      ""; // Return empty string
}

/**
 * Hook to return the appropriate border classes from the border CSS module
 * @param params Border object
 * @returns string
 */
export function useBorder(params: IBorder | undefined): string {
  return params
    ? reduce(
        params,
        (acc, param, key): string => {
          if (param === false) {
            return "";
          }
          // Reduce the params to...
          const style = borderStyles[`${key}`]; // Get the appropriate border style
          acc += ` ${style}`; // Add the style string to the accumulator
          return acc; // Return the accumulator
        },
        "",
      ).trim() // Trim off any whitespace, otherwise
    : // If no params exist...
      ""; // Return empty string
}

/**
 * Hook to return the appropriate scrolling classes from the scroll CSS module
 * @param params Scroll object
 * @returns string
 */
export function useScroll(params: IScroll | undefined): string {
  return params
    ? reduce(
        params,
        (acc, key): string => {
          // Reduce the params to...
          const style = scrollStyles[`${key}`]; // Get the appropriate scroll direction
          acc += ` ${style}`; // Add the style string to the accumulator
          return acc; // Return the accumulator
        },
        "",
      ).trim() // Trim off any whitespace, otherwise
    : // If no params exist...
      ""; // Return empty string
}

/**
 * Hook to return the utility classes for "display"
 * @param params Utility object: display
 * @returns string
 */

export function useDisplay(params: TDisplay | undefined): any {
  return setResponsiveClx("d", params);
}

/**
 * Hook to return the spacing classes for "padding"
 * @param params Spacing object: padding
 * @returns string
 */
export function usePadding(params: IPadding | undefined): any {
  return setResponsiveClx("p", params);
}

/**
 * Hook to return the spacing classes for "padding-y"
 * @param params Spacing object: padding-y
 * @returns string
 */
export function usePaddingY(params: IPaddingY | undefined): any {
  return setResponsiveClx("py", params);
}

/**
 * Hook to return the spacing classes for "padding-x"
 * @param params Spacing object: padding-x
 * @returns string
 */
export function usePaddingX(params: IPaddingX | undefined): any {
  return setResponsiveClx("px", params);
}

/**
 * Hook to return the spacing classes for "padding-top"
 * @param params Spacing object: padding-top
 * @returns string
 */
export function usePaddingT(params: IPaddingT | undefined): any {
  return setResponsiveClx("pt", params);
}

/**
 * Hook to return the spacing classes for "padding-right"
 * @param params Spacing object: padding-right
 * @returns string
 */
export function usePaddingR(params: IPaddingR | undefined): any {
  return setResponsiveClx("pr", params);
}

/**
 * Hook to return the spacing classes for "padding-bottom"
 * @param params Spacing object: padding-bottom
 * @returns string
 */
export function usePaddingB(params: IPaddingB | undefined): any {
  return setResponsiveClx("pb", params);
}

/**
 * Hook to return the spacing classes for "padding-left"
 * @param params Spacing object: padding-left
 * @returns string
 */
export function usePaddingL(params: IPaddingL | undefined): any {
  return setResponsiveClx("pl", params);
}

/**
 * Hook to return the spacing classes for "margin"
 * @param params Spacing object: margin
 * @returns string
 */
export function useMargin(params: IMargin | undefined): any {
  return setResponsiveClx("m", params);
}

/**
 * Hook to return the spacing classes for "margin-top"
 * @param params Spacing object: margin-top
 * @returns string
 */
export function useMarginT(params: IMarginT | undefined): any {
  return setResponsiveClx("mt", params);
}

/**
 * Hook to return the spacing classes for "margin-right"
 * @param params Spacing object: margin-right
 * @returns string
 */
export function useMarginR(params: IMarginR | undefined): any {
  return setResponsiveClx("mr", params);
}

/**
 * Hook to return the spacing classes for "margin-bottom"
 * @param params Spacing object: margin-bottom
 * @returns string
 */
export function useMarginB(params: IMarginB | undefined): any {
  return setResponsiveClx("mb", params);
}

/**
 * Hook to return the spacing classes for "margin-left"
 * @param params Spacing object: margin-left
 * @returns string
 */
export function useMarginL(params: IMarginL | undefined): any {
  return setResponsiveClx("ml", params);
}

/**
 * Hook to return the spacing classes for "margin-x"
 * @param params Spacing object: margin-x
 * @returns string
 */
export function useMarginX(params: IMarginX | undefined): any {
  return setResponsiveClx("mx", params);
}

/**
 * Hook to return the spacing classes for "margin-y"
 * @param params Spacing object: margin-y
 * @returns string
 */
export function useMarginY(params: IMarginY | undefined): any {
  return setResponsiveClx("my", params);
}

/**
 * Hook to return the utility classes for "flexDirection"
 * @param params Utility object: display
 * @returns string
 */

export function useFlexDirection(params: IFlexDirection | undefined): any {
  return setResponsiveClx("flex", params);
}

/**
 * Hook to return the utility classes for "align-content"
 * @param params Utility object: display
 * @returns string
 */

export function useAlignContent(params: IAlignContent | undefined): any {
  return setResponsiveClx("align-content", params);
}

/**
 * Hook to return the utility classes for "align-items"
 * @param params Utility object: display
 * @returns string
 */

export function useAlignItems(params: IAlignItems | undefined): any {
  return setResponsiveClx("align-items", params);
}

/**
 * Hook to return the utility classes for "align-self"
 * @param params Utility object: display
 * @returns string
 */

export function useAlignSelf(params: IAlignSelf | undefined): any {
  return setResponsiveClx("align-self", params);
}

/**
 * Hook to return the utility classes for "justify-content"
 * @param params Utility object: display
 * @returns string
 */

export function useJustifyContent(params: IJustifyContent | undefined): any {
  return setResponsiveClx("justify-content", params);
}

/**
 * Hook to return the utility classes for "justify-items"
 * @param params Utility object: display
 * @returns string
 */

export function useJustifyItems(params: IJustifyItems | undefined): any {
  return setResponsiveClx("justify-items", params);
}

/**
 * Hook to return the utility classes for "justify-self"
 * @param params Utility object: display
 * @returns string
 */

export function useJustifySelf(params: IJustifySelf | undefined): any {
  return setResponsiveClx("justify-self", params);
}

/**
 * Interface for useComponentVisible return object
 */
interface IUseComponentVisibleReturn {
  ref: MutableRefObject<any>;
  isComponentVisible: boolean;
  setIsComponentVisible: Dispatch<SetStateAction<boolean>>;
}

/**
 * Hide components when clicking outside them
 * @param initialIsVisible The visible state on initialisation
 * @link https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 * @returns IUseComponentVisibleReturn
 */
export function useComponentVisible(initialIsVisible: boolean): IUseComponentVisibleReturn {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible); // Component visibility state
  const ref = useRef<any>(null); // Component ref

  /**
   * Handle clicking outside the component
   * @param event The mouse click event
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      // If there is a ref and it is not the current DOM element
      setIsComponentVisible(false); // Set visibility to false
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true); // Add click listener
    return () => {
      // Use effect return statement runs on unmount
      document.removeEventListener("click", handleClickOutside, true); // So... remove the event listener
    };
  }); // Run once

  return { ref, isComponentVisible, setIsComponentVisible }; // Public return
}

/**
 * Delay mount / unmount to enable transition CSS
 * @param isMounted The mount state of the component
 * @param unmountDelay Mount / unmount delay in ms
 * @link https://letsbuildui.dev/articles/how-to-animate-mounting-content-in-react
 * @returns boolean
 */
export function useMountTransition(isMounted: boolean, unmountDelay: number) {
  const [hasTransitionedIn, setHasTransitionedIn] = useState(false); // Transition complete state

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>; // Initialise timeout

    if (isMounted && !hasTransitionedIn) {
      // If component has mounted and has not yet transitioned in
      setHasTransitionedIn(true); // Transition the component in
    } else if (!isMounted && hasTransitionedIn) {
      // If component should unmount and is transitioned in
      timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay); // Transition the component out
    }
    return () => {
      // On unmount
      clearTimeout(timeoutId); // Clear the timeout for garbage collection
    };
  }, [unmountDelay, isMounted, hasTransitionedIn]); // Run the effect on these dependency changes

  return hasTransitionedIn; // Return transition bool
}

/**
 * Retrieve query params from the URL eg.
 * const query = useQuery();
 * query.get("something");
 * @link https://v5.reactrouter.com/web/example/query-parameters
 * @returns string
 */
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Sets the initial dimensions on the element
 * @returns { ref: React.RefObject, width: number, height number }
 */
export function useDimensions() {
  const ref = useRef<any>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  /**
   * Set the dimensions from the ref
   */
  function setDimensions() {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }

  useLayoutEffect(() => {
    setDimensions();
    window.addEventListener("resize", setDimensions, true);
    return () => window.removeEventListener("resize", setDimensions, true);
  }, []);

  return { ref, width, height };
}

/**
 * Detects whether a click is outside the ref provided
 * @param ref The ref
 * @returns boolean
 */
export function useOutsideClick(ref: React.RefObject<any>) {
  const [isOutside, setIsOutside] = useState(true);

  /**
   * Handles a click event
   * @param event The mouse event
   */
  function handleClick(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOutside(true);
    } else {
      setIsOutside(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => document.removeEventListener("click", handleClick, true);
  }, []);

  return isOutside;
}

/**
 * Use this hook for cloning or creating a Matter.
 * @param id The ID of the Matter to be cloned
 */
export function useCreateOrCloneMatterActions() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  /**
   * Handles the click event for cloning or creating a matter.
   * Stores the current location pathname as the initiation context.
   * Then dispatches the clone or create action
   *
   * @param id           The ID of the matter to be cloned (null if creating a new matter)
   * @param isClone      A boolean indicating whether it's a clone or create action
   * @param matterTypeID The Matter Type ID for creating a new matter
   */
  async function handleCreateOrCloneMatterClick(id: number | null, isClone: boolean = true, matterTypeID?: number) {
    // Store the initiation context where the clone or create was initiated from
    const initiationContext = location.pathname;
    localStorage.setItem(MATTER_CLONE_INITIATION_CONTEXT, initiationContext);

    if (isClone && id) {
      // Dispatch the clone action if it's a clone Matter and ID is provided
      await dispatch(postMatterClone({ id })).unwrap();
    } else if (matterTypeID) {
      // Dispatch the create action if it's not a create Matter and Matter Type ID is provided
      await dispatch(postCreateMatter(matterTypeID)).unwrap();
    }
  }

  return { handleCreateOrCloneMatterClick };
}
