import { chain, filter, map } from "lodash";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { cStatusType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Div from "../../../../components/Div/Div";
import { IFieldOption } from "../../../../components/FormField/FormField";
import Spinner from "../../../../components/Spinner/Spinner";
import {
  EReportCodes,
  IPackagedReport,
  IPackagedReportFilters,
  postPackagedReportFilters,
  selectAllPackagedReportFilters,
  selectAllPackagedReports,
  selectPackagedReportStatus,
} from "../../../../modules/analyticsSlice";
import { selectDocumentStages } from "../../../../modules/documentStagesSlice";
import { selectDocumentStates } from "../../../../modules/documentStatesSlice";
import { selectDocumentTypes } from "../../../../modules/documentTypesSlice";
import { selectUser } from "../../../../modules/userSlice";
import { IUser, selectUsers } from "../../../../modules/usersSlice";
import PackagedReport from "./PackagedReport/PackagedReport";

/**
 * The Packaged Report Container
 * @returns JSX.Element
 */

function PackagedReportContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const { code } = useParams<{ code: string }>();
  const codeToUppercase = code?.toUpperCase();
  const packagedReports = useAppSelector(selectAllPackagedReports);
  const packagedReportFilters = useAppSelector(selectAllPackagedReportFilters);
  const packagedReportsStatus = useAppSelector(selectPackagedReportStatus);
  const documentTypes = useAppSelector(selectDocumentTypes); // Get all the document types
  const users = useAppSelector(selectUsers);
  const user = useAppSelector(selectUser);
  const documentStages = useAppSelector(selectDocumentStages);
  const documentStates = useAppSelector(selectDocumentStates);

  // Get the name of the packagedReport
  const packagedReportName = Object.values(packagedReports).find((report) => report.code === codeToUppercase)?.name;

  useEffect(() => {
    if (code && Object.values(EReportCodes).includes(codeToUppercase as EReportCodes)) {
      dispatch(postPackagedReportFilters(codeToUppercase as EReportCodes));
    }
  }, [code]);

  const documentTypeIds =
    code !== null && packagedReportFilters?.[codeToUppercase as EReportCodes]
      ? packagedReportFilters?.[codeToUppercase as EReportCodes]?.optionalFilters.documentTypeIDs
      : [];
  const documentOwnerIds =
    code !== null && packagedReportFilters?.[codeToUppercase as EReportCodes]
      ? packagedReportFilters?.[codeToUppercase as EReportCodes]?.optionalFilters.ownerIDs
      : [];

  const owners = useMemo(() => {
    if (documentOwnerIds) {
      return filter(users, (user) => documentOwnerIds.includes(user.id));
    }
    return [];
  }, [documentOwnerIds, users]);

  // Filter the document types based on the selected document type ids
  const filteredDocumentTypeIds = documentTypeIds || [];

  const documentTypeFilterOptions: IFieldOption[] = useMemo(() => {
    return filteredDocumentTypeIds
      .map((id) => {
        const documentType = documentTypes[id];
        if (documentType) {
          return {
            label: documentType.name,
            value: documentType.id,
          } as IFieldOption;
        }
        return null;
      })
      .filter((option) => option !== null) as IFieldOption[]; // Use 'as IFieldOption[]' to assert the type explicitly after filtering out nulls
  }, [filteredDocumentTypeIds, documentTypes]);

  const ownerFilterOptions: IFieldOption[] & Pick<IUser, "isInternal"> = chain(owners)
    // Sort by lastName, firstName ignoring case
    .orderBy([(owner) => owner.lastName?.toLowerCase(), (owner) => owner.firstName.toLowerCase()])
    // Pull EMBED user to the top
    .sort((owner) => {
      if (owner.isInternal) {
        return -1;
      } else if (!owner.isInternal) {
        return 1;
      }
      return 0;
    })
    // Pull logged in user to the top
    .sort((owner) => {
      if (owner.id === user?.id) {
        return -1;
      } else if (owner.id !== user?.id) {
        return 1;
      }
      return 0;
    })
    .map((owner) => {
      return {
        label:
          owner.id === user?.id
            ? `Me (${owner.firstName} ${owner.lastName})`
            : owner.isInternal
              ? `${owner.firstName}`
              : `${owner.lastName}, ${owner.firstName}`,
        value: owner.id,
        isInternal: owner.isInternal,
      };
    })
    .value();

  const stateFilterOptions: IFieldOption[] = map(documentStates, (state) => {
    return {
      label: state.displayName,
      value: state.id,
    };
  });

  const terminatedStateID = stateFilterOptions.find((option) => option.label === "Include Terminated")?.value;

  const stageFilterOptions: IFieldOption[] = useMemo(() => {
    return map(documentStages, (stage) => {
      return {
        label: stage.displayName,
        value: stage.id,
      };
    }).sort();
  }, [documentStages]);

  if (packagedReportsStatus === cStatusType.Loading) {
    return (
      <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
        <Spinner />
      </Div>
    );
  }

  return (
    <PackagedReport
      code={code?.toUpperCase() as EReportCodes}
      name={packagedReportName as IPackagedReport["name"]}
      reportFilters={packagedReportFilters as IPackagedReportFilters}
      documentTypeFilterOptions={documentTypeFilterOptions}
      ownerFilterOptions={ownerFilterOptions}
      stageFilterOptions={stageFilterOptions}
      stateFilterOptions={stateFilterOptions}
      terminatedStateID={terminatedStateID as number}
    />
  );
}

export default PackagedReportContainer;
