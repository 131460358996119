import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { postDocumentTimeline } from "./documentsSlice";
import {
  postDocumentAmend,
  postDocumentClone,
  postMatterLaunchExternalResource,
  postMatterLaunchTask,
  postWalkAction,
} from "./walkSlice";

const initialState = {
  isLoading: false,
};

/**
 * Holds the loading state which is set by listening to matchers in other slices
 */
export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          postDocumentTimeline.pending,
          postWalkAction.pending,
          postDocumentAmend.pending,
          postDocumentClone.pending,
          postMatterLaunchTask.pending,
          postMatterLaunchExternalResource.pending,
        ),
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          postDocumentTimeline.rejected,
          postDocumentTimeline.fulfilled,
          postWalkAction.rejected,
          postWalkAction.fulfilled,
          postDocumentAmend.rejected,
          postDocumentAmend.fulfilled,
          postDocumentClone.rejected,
          postDocumentClone.fulfilled,
          postMatterLaunchTask.rejected,
          postMatterLaunchTask.fulfilled,
          postMatterLaunchExternalResource.rejected,
          postMatterLaunchExternalResource.fulfilled,
        ),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const selectIsLoading = (state: RootState) => state.loading.isLoading;

export default loadingSlice.reducer;
