import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  postEmbedSingleUseInvalidate,
  selectEmbedSingleUseInvalidateError,
  selectEmbedSingleUseInvalidateStatus,
} from "../../../../../modules/embedSingleUseSlice";
import InvalidateEmbedLinkModal from "./InvalidateEmbedLinkModal/InvalidateEmbedLinkModal";

interface IInvalidateEmbedLinkModalContainer {
  isOpen: boolean;
  handleClose: () => void;
  interviewSpawnID?: number;
  wipDTM?: string;
  reloadTasks: () => void;
}

/**
 * Container for the invalidate embed link modal
 * @param isOpen            Is the modal open?
 * @param handleClose       Close the modal
 * @param interviewSpawnID  The interview spawn ID to send as the payload
 * @param wipDTM            The WIP DTM will update the modal text if the walk is already in progress
 * @param reloadTasks       Reload the tasks after the link is invalidated
 * @returns JSX.Element
 */
function InvalidateEmbedLinkModalContainer({
  isOpen,
  handleClose,
  interviewSpawnID,
  wipDTM,
  reloadTasks,
}: IInvalidateEmbedLinkModalContainer): JSX.Element {
  const dispatch = useAppDispatch();

  // Select status and error
  const status = useAppSelector(selectEmbedSingleUseInvalidateStatus);
  const error = useAppSelector(selectEmbedSingleUseInvalidateError);

  /**
   * Handle the invalidation of the embed link
   */
  async function handleInvalidation() {
    try {
      if (interviewSpawnID) {
        await dispatch(postEmbedSingleUseInvalidate({ interviewSpawnID })).unwrap();
        handleClose(); // Close the modal
        toast("Link invalidated"); // Show a toast notification
        reloadTasks(); // Reload the tasks
      }
    } catch (err: any) {
      console.error(err);
    }
  }

  return (
    <InvalidateEmbedLinkModal
      isOpen={isOpen}
      handleClose={handleClose}
      wipDTM={wipDTM}
      handleInvalidation={handleInvalidation}
      status={status}
      error={error}
    />
  );
}

export default InvalidateEmbedLinkModalContainer;
