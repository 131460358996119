import { useEffect, useState } from "react";
import AnalyticsCustomExportsList from "../../../../../components/AnalyticsCustomExportsList/AnalyticsCustomExportsList";
import { ICustomExport, IDocumentAnalytics } from "../../../../../modules/analyticsSlice";

interface IDocumentAnalyticsProps {
  documentAnalytics: IDocumentAnalytics[];
  customExportStatus: string;
  customExports: ICustomExport[];
  documentTypeID: number | null;
}

/**
 * The Document Analytics component
 * @param customExportStatus             The status of custom exports fetching
 * @param customExports                  The list of custom exports
 * @param documentTypeID                 The document type id
 * @param documentAnalytics              The list of document analytics
 * @returns JSX.Element
 */
function DocumentAnalytics({
  customExportStatus,
  customExports,
  documentTypeID,
  documentAnalytics,
}: IDocumentAnalyticsProps): JSX.Element {
  const [selectedDocumentType, setSelectedDocumentType] = useState<IDocumentAnalytics>();

  // Find the selected documentAnalytics and set it to the selectedDocumentType
  useEffect(() => {
    const foundDocumentType = documentAnalytics.find(
      (currentDocumentType) => currentDocumentType.id === documentTypeID,
    );
    if (foundDocumentType) {
      setSelectedDocumentType(foundDocumentType);
    }
  }, [documentAnalytics, documentTypeID]);

  // Create the link list items for the custom exports
  const linkListItems = customExports.map((customExport: ICustomExport, index: number | string) => {
    const configID = customExport.id;
    return {
      text: customExport.name,
      key: customExport.id,
      testId: `custom-export-${index}`,
      arrow: true,
      link: String(configID),
    };
  });

  // Destructure linkListItems to separate the default `Complete report` and the custom `Saved templates`
  const [completeReport, ...savedTemplates] = linkListItems;

  return (
    <AnalyticsCustomExportsList
      title={selectedDocumentType?.name || ""}
      status={customExportStatus}
      defaultExports={completeReport ? [completeReport] : []}
      customExports={savedTemplates}
    />
  );
}

export default DocumentAnalytics;
