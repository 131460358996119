import { useAppSelector } from "../../../../../../app/hooks";
import { selectUser } from "../../../../../../modules/userSlice";
import AnalyticsFiltersHeader, { IAnalyticsFiltersHeader } from "./AnalyticsFiltersHeader/AnalyticsFiltersHeader";

/**
 * Container for the analytics filters header - fetches the isAdmin state from the redux store
 * @param configName                           The name of the configuration
 * @param configID                             The ID of the configuration
 * @param hasChanges                           Whether there are changes in the form values from the initial values
 * @param saveDocumentTypeReportTemplateStatus The status of saving the document type report template
 * @param runDocumentTypeReportStatus          The status of running the document type report
 * @param handleBackClick                      The function to handle the back button click
 * @param setIsDeleteReportTemplateModalOpen   The function to set the delete report template modal open state
 * @param trigger                              The form trigger
 * @param setIsSaveAsTemplateModalOpen         The function to set the save as template modal open state
 * @param handleSubmit                         The react-hook-form function to handle the form submit
 * @param handleRunReportClick                 The function to handle the run report button click
 * @param handleSaveTemplateClick              The function to handle the save template button click
 * @returns JSX.Element
 */
function AnalyticsFiltersHeaderContainer({
  configName,
  configID,
  hasChanges,
  saveReportTemplateStatus,
  runReportStatus,
  handleBackClick,
  setIsDeleteReportTemplateModalOpen,
  trigger,
  setIsSaveAsTemplateModalOpen,
  handleSubmit,
  handleRunReportClick,
  handleSaveTemplateClick,
}: Omit<IAnalyticsFiltersHeader, "isAdmin">) {
  const user = useAppSelector(selectUser);
  const isAdmin = user?.isAdmin;

  return (
    <AnalyticsFiltersHeader
      configName={configName}
      configID={configID}
      hasChanges={hasChanges}
      saveReportTemplateStatus={saveReportTemplateStatus}
      runReportStatus={runReportStatus}
      handleBackClick={handleBackClick}
      setIsDeleteReportTemplateModalOpen={setIsDeleteReportTemplateModalOpen}
      trigger={trigger}
      setIsSaveAsTemplateModalOpen={setIsSaveAsTemplateModalOpen}
      handleSubmit={handleSubmit}
      handleRunReportClick={handleRunReportClick}
      handleSaveTemplateClick={handleSaveTemplateClick}
      isAdmin={isAdmin || false}
    />
  );
}

export default AnalyticsFiltersHeaderContainer;
