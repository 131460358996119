import { cAccessType } from "../../../../../../app/constants";
import { EIcon } from "../../../../../../components/Icon/Icon";
import LinkList from "../../../../../../components/LinkList/LinkList";
import { EUserStatus, IUser } from "../../../../../../modules/usersSlice";

interface IContentsProps {
  userID: number;
  loggedInUser: IUser;
  editUserInit: () => void;
  resetPasswordInit: () => void;
  disableUserInit: () => void;
  status: EUserStatus;
  enableUserInit: () => void;
  deleteUserInit: () => void;
  reassignAllTasksInit: () => void;
  streamsEnabled?: boolean;
}

/**
 * Contents of document list entry action popover
 * @param userID               The users table row user
 * @param loggedInUser         The logged in user
 * @param editUserInit         Function to start the edit user process
 * @param resetPasswordInit    Function to start the reset password process
 * @param disableUserInit      Function to start the disable user process
 * @param status               The user status
 * @param enableUserInit       Function to start the enable a user process
 * @param deleteUserInit       Function to start the delete a user process
 * @param reassignAllTasksInit Function to start the reassign all tasks process
 * @param streamsEnabled       Are streams enabled?
 * @returns JSX.Element
 */
function Contents({
  userID,
  loggedInUser,
  editUserInit,
  resetPasswordInit,
  disableUserInit,
  status,
  enableUserInit,
  deleteUserInit,
  reassignAllTasksInit,
  streamsEnabled,
}: IContentsProps): JSX.Element {
  const isAdminUser = loggedInUser && loggedInUser.isAdmin && loggedInUser.id === userID;
  let userDisableAccess;

  if (isAdminUser) {
    userDisableAccess = cAccessType.Denied;
  } else if (status === EUserStatus.Locked) {
    userDisableAccess = cAccessType.Inaccessible;
  } else {
    userDisableAccess = cAccessType.Enabled;
  }

  const items = [
    {
      icon: EIcon.Edit,
      text: "Edit user details",
      onClick: editUserInit,
      testId: "edit-user-button",
    },
    {
      icon: EIcon.ResetPassword,
      text: "Reset password",
      onClick: resetPasswordInit,
      testId: "reset-user-password-button",
      access: status === EUserStatus.Active && !isAdminUser ? cAccessType.Enabled : cAccessType.Denied,
    },
    {
      icon: EIcon.UserDisable,
      text: "Disable user",
      onClick: disableUserInit,
      testId: "disable-user-button",
      access: userDisableAccess,
    },
    {
      icon: EIcon.Reassign,
      text: "Reassign all tasks",
      onClick: reassignAllTasksInit,
      testId: "reassign-tasks-button",
      access: streamsEnabled ? cAccessType.Enabled : cAccessType.Inaccessible,
    },
    {
      icon: EIcon.UserEnable,
      text: "Enable user",
      onClick: enableUserInit,
      testId: "enable-user-button",
      access: status === EUserStatus.Locked ? cAccessType.Enabled : cAccessType.Inaccessible,
    },
    {
      icon: EIcon.Delete,
      text: "Delete user",
      onClick: deleteUserInit,
      testId: "delete-user-button",
      access: !isAdminUser ? cAccessType.Enabled : cAccessType.Denied,
    },
  ];

  return <LinkList items={items} />;
}

export default Contents;
