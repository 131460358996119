import { cThemeColorType } from "../../../../../../app/constants";
import Button from "../../../../../../components/Button/Button";
import DisplayError from "../../../../../../components/DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../../components/Modal/Modal";
import Typography from "../../../../../../components/Typography/Typography";
import styles from "./DeleteCollaboratorConfirmModal.module.scss";

interface IDeleteCollaboratorConfirmModal {
  isOpen: boolean;
  handleModalClose: () => void;
  isLoading: boolean;
  handleDeleteCollaborator: () => void;
  error?: string;
}

/**
 * Confirm deletion of collaborator modal
 * @param isOpen                   Is the modal open
 * @param handleModalClose         Function to close the modal
 * @param isLoading                Is the API call being run?
 * @param handleDeleteCollaborator Handle delete collaborator function
 * @param error                    Any error that occurred
 * @returns JSX.Element
 */
function DeleteCollaboratorConfirmModal({
  isOpen,
  handleModalClose,
  isLoading,
  handleDeleteCollaborator,
  error,
}: IDeleteCollaboratorConfirmModal): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleModalClose}
      testId="delete-collaborator-confirm-modal"
      size="sm"
      portalClassName={styles.modalPortal}
    >
      <ModalHeader>
        <ModalTitle>Delete Collaborator?</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>Deleting this collaborator will remove their access to the document.</Typography>
        {error && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions>
        <Button
          onClick={handleModalClose}
          color={cThemeColorType.Secondary}
          testId="delete-collaborator-confirm-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleDeleteCollaborator}
          testId="delete-collaborator-confirm-modal-confirm-btn"
        >
          Remove
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default DeleteCollaboratorConfirmModal;
