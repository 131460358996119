import { createAsyncThunk, createSlice, ListenerEffectAPI, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import api from "../app/api";
import { matterExternalResourcesUrl } from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { postDocumentTypes } from "./documentTypesSlice";

/**
 * Describe an external resource walk type
 */
export interface IWalkType {
  state: cAccessType;
  custWalkTypeID?: number;
}

/**
 * Describe a matter resource
 */
export interface IResource {
  documentTypeID: number;
  draft?: IWalkType;
  file?: IWalkType;
}

/**
 * A matter resource entry
 */
export interface IMatterExternalResourceEntry {
  resources: IResource[];
}

/**
 * Describe the matter resources state object
 */
interface IMatterResourcesState {
  entries: Record<number, IMatterExternalResourceEntry>; // Object keyed by parent matter
  status: cStatusType; // API call status
  error?: string;
}

/**
 * Initial state
 */
const initialState: IMatterResourcesState = {
  entries: {},
  status: cStatusType.Idle,
};

/**
 * Thunk for fetching the external resources for a matter
 */
export const postMatterResources = createAsyncThunk(
  "matterResources/postMatterResources",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: matterExternalResourcesUrl, dispatch, body: { id } });
      return { id, resources: response.data.externalResources };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch the document types after fetching the matter external resources
 * @param _           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchMatterResourcesEffect = (
  action: any,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;
  cancelActiveListeners();
  const state = getState() as RootState;
  const {
    matterResources: { entries },
  } = state;

  // Get the matter ID
  const matterID = action.payload.id;

  // Get the document types for the resources in the target matter
  if (entries[matterID].resources.length > 0) {
    // Get the document type IDs
    const ids = entries[matterID].resources.map((resource) => resource.documentTypeID);
    // Fetch the document types
    dispatch(postDocumentTypes({ ids }));
  }
};

/**
 * Create the state slice
 */
export const matterResourcesSlice = createSlice({
  name: "matterResources", // The name of the slice
  initialState, // Set the initialState
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postMatterResources.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterResources.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterResources.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, [action.payload.id]: { resources: action.payload.resources } };
      });
  },
});

export const selectMatterResourcesStatus = (state: RootState) => state.matterResources.status;
export const selectMatterResourcesError = (state: RootState) => state.matterResources.error;
export const selectMatterResources = (state: RootState) => state.matterResources.entries;

export default matterResourcesSlice.reducer;
