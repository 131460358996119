import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  postCollaborationFetch,
  postDeleteCollaborator,
  postSendCollaborationLink,
  selectCollabIsActive,
  selectCollaborationError,
  selectCollaborationLink,
  selectCollaborators,
  selectDeleteCollaboratorError,
  selectFileExtension,
  selectSendCollaborationLinkError,
  selectSendCollaborationLinkStatus,
  updateCollabIsActive,
} from "../../../../modules/collaborationSlice";
import { postDocumentsByPage } from "../../../../modules/documentsSlice";
import { postMatterDocuments } from "../../../../modules/matterDocumentsSlice";
import { selectUser } from "../../../../modules/userSlice";
import {
  EWalkAction,
  EWalkStage,
  EWalkType,
  postWalkAction,
  selectWalkData,
  selectWalkError,
  selectWalkStatus,
  updateWalkIsActive,
} from "../../../../modules/walkSlice";
import ManageCollaborationModal from "./ManageCollaborationModal/ManageCollaborationModal";

/**
 * Manage collaboration modal container
 * @returns JSX.Element
 */
function ManageCollaborationModalContainer(): JSX.Element {
  const urlParams = useParams<any>(); // Set to any as this could render on any page
  const dispatch = useAppDispatch();
  const walkData = useAppSelector(selectWalkData);
  const walkStatus = useAppSelector(selectWalkStatus);
  const isOpen = useAppSelector(selectCollabIsActive);
  const collaborators = useAppSelector(selectCollaborators);
  const error = useAppSelector(selectCollaborationError);
  const deleteCollaboratorError = useAppSelector(selectDeleteCollaboratorError);
  const user = useAppSelector(selectUser);
  const collaborationLink = useAppSelector(selectCollaborationLink);
  const sendCollaborationLinkStatus = useAppSelector(selectSendCollaborationLinkStatus);
  const sendCollaborationLinkError = useAppSelector(selectSendCollaborationLinkError);
  const fileExtension = useAppSelector(selectFileExtension);
  const walkError = useAppSelector(selectWalkError);
  const userEmail = user?.emailAddress;

  // Fetch collaboration data
  useEffect(() => {
    if (walkData?.stage === EWalkStage.Collab1) {
      dispatch(postCollaborationFetch(walkData.processID));
    }
  }, [walkData]);

  /**
   * Handle modal close
   */
  function handleClose() {
    dispatch(updateCollabIsActive(false));
  }

  /**
   * Handle pause collaboration
   */
  async function handlePauseCollaboration() {
    await dispatch(postWalkAction({ type: EWalkAction.Pause })).unwrap();

    // If the user is on the matter documents page, refresh the matter documents
    // Otherwise, refresh the primary documents page. The refresh is done so we
    // can get the updated in progress state
    if (urlParams.matterId) {
      await dispatch(postMatterDocuments(Number(urlParams.matterId))).unwrap();
    } else {
      await dispatch(postDocumentsByPage(1)).unwrap();
    }
    handleClose();
  }

  /**
   * Handle discard collaboration
   */
  async function handleDiscardCollaboration() {
    await dispatch(postWalkAction({ type: EWalkAction.Discard })).unwrap();

    // If the user is on the matter documents page, refresh the matter documents
    // Otherwise, refresh the primary documents page. The refresh is done so we
    // can get the updated in progress state
    if (urlParams.matterId) {
      await dispatch(postMatterDocuments(Number(urlParams.matterId))).unwrap();
    } else {
      await dispatch(postDocumentsByPage(1)).unwrap();
    }
    handleClose();
  }

  /**
   * Handle version notes submit
   * @param versionNotes The version notes
   */
  function handleVersionNotesSubmit(versionNotes: string) {
    dispatch(postWalkAction({ formData: { versionNotes, action: EWalkType.Collaboration }, type: EWalkAction.Action }));
  }

  /**
   * Handle delete collaborator
   * @param id The collaborator ID
   */
  async function handleDeleteCollaborator(id: number) {
    try {
      await dispatch(postDeleteCollaborator(id)).unwrap();
      toast("Collaborator removed");
    } catch (error: any) {
      console.error(error.message);
    }
  }

  /**
   * Handle send collaboration link
   * @param collaboratorID The collaborator ID or "all"
   */
  async function handleSendCollaborationLink(collaboratorID: number | "all") {
    try {
      await dispatch(postSendCollaborationLink(collaboratorID)).unwrap();
      toast("Collaborator has been notified");
    } catch (error: any) {
      console.error(error.message);
    }
  }

  /**
   * Handle walk forward
   */
  async function handleWalkForward() {
    try {
      // Run action forward on collab1 stage
      await dispatch(postWalkAction({ type: EWalkAction.Forward })).unwrap();
      // Run action forward on collab2 stage. This is the confirmation stage on the legacy system
      // which is handled by the UI in React
      await dispatch(postWalkAction({ type: EWalkAction.Forward })).unwrap();
      // Launch into the walk
      dispatch(updateWalkIsActive(true));
    } catch (error: any) {
      console.error(error.message);
    }
  }

  return (
    <ManageCollaborationModal
      isOpen={isOpen}
      handleClose={handlePauseCollaboration}
      handleDiscardCollaboration={handleDiscardCollaboration}
      documentType={walkData?.documentType as string}
      documentDescription={walkData?.documentDescription as string}
      collaborators={collaborators}
      stage={walkData?.stage as EWalkStage}
      handleVersionNotesSubmit={handleVersionNotesSubmit}
      walkStatus={walkStatus}
      error={error}
      userEmail={userEmail}
      handleDeleteCollaborator={handleDeleteCollaborator}
      deleteCollaboratorError={deleteCollaboratorError}
      collaborationLink={collaborationLink}
      handleSendCollaborationLink={handleSendCollaborationLink}
      sendCollaborationLinkStatus={sendCollaborationLinkStatus}
      sendCollaborationLinkError={sendCollaborationLinkError}
      fileExtension={fileExtension}
      handleWalkForward={handleWalkForward}
      walkError={walkError}
    />
  );
}

export default ManageCollaborationModalContainer;
