import { UseFormRegister } from "react-hook-form";
import Div from "../../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../../components/FormField/FormField";
import styles from "../../../Analytics.module.scss";

interface IAnalyticsOwnersFilter {
  register: UseFormRegister<any>;
  ownerFilterOptions: IFieldOption[];
  maxOptions: number;
}

/**
 * The analytics owners filter
 * @param register            The register function from react-hook-form
 * @param ownerFilterOptions  The owner filter options
 * @param maxOptions          The maximum number of options before scrolling is enabled
 * @returns JSX.Element
 */
function AnalyticsOwnersFilter({ register, ownerFilterOptions, maxOptions }: IAnalyticsOwnersFilter): JSX.Element {
  return (
    <Div className={styles.formRow} testId="owner-filter">
      <label htmlFor="owners" className={styles.label}>
        Owners
      </label>
      <FormField
        type={cFormFieldType.Checkbox}
        name="selectAllOwners"
        options={[{ label: "All", value: "allOwners" }]}
        register={register}
        spacing={{ mb: 6 }}
      />
      <Div className={ownerFilterOptions && ownerFilterOptions.length > maxOptions ? styles.scrollBox : ""}>
        <FormField
          type={cFormFieldType.Checkbox}
          name="owners"
          options={ownerFilterOptions}
          register={register}
          testId="owner-options"
        />
      </Div>
    </Div>
  );
}

export default AnalyticsOwnersFilter;
