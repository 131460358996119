import { Link } from "react-router-dom";
import { ESpacing, IXDirection } from "../../app/types";
import Container from "../../components/Grid/Container/Container";
import Column from "../../components/Row/Column/Column";
import Row from "../../components/Row/Row";
import Typography from "../../components/Typography/Typography";
import styles from "./NotFoundPage.module.scss";

/**
 * The 'Page not found component'
 * @returns JSX.Element
 */

function NotFoundPage(): JSX.Element {
  return (
    <Container width="fluid" className={styles.pageContent}>
      <Row className={styles.notFoundContent} alignSelf={{ base: "center" }}>
        <Column all={10} md={6} xl={4} spacing={{ mx: ESpacing.Auto }} alignContent={{ base: "center" }}>
          <Typography variant="h2" spacing={{ mb: 6 }} align={IXDirection.Center}>
            404: Page not found
          </Typography>
          <Typography align={IXDirection.Center}>
            <p>
              The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.
            </p>
            <p>
              Go to the <Link to="/">home page</Link> or use the navigation menu to find your way.
            </p>
          </Typography>
        </Column>
      </Row>
    </Container>
  );
}

export default NotFoundPage;
