import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { cStatusType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IFieldOption } from "../../../../components/FormField/FormField";
import {
  openInformationUpdatedModal,
  postDocumentSetStage,
  selectDocuments,
  selectSetStageError,
  selectSetStageStatus,
} from "../../../../modules/documentsSlice";
import {
  selectDocumentStages,
  selectDocumentStagesError,
  selectDocumentStagesStatus,
} from "../../../../modules/documentStagesSlice";
import {
  postDocumentTypeStages,
  selectDocumentTypeStages,
  selectDocumentTypeStagesError,
  selectDocumentTypeStagesStatus,
} from "../../../../modules/documentTypesSlice";
import { selectMatterDocuments } from "../../../../modules/matterDocumentsSlice";
import { isOutdatedContentError } from "../../../../utils/errors/errors";
import ChangeStageModal from "./ChangeStageModal/ChangeStageModal";

interface IChangeStageModalContainer {
  isOpen: boolean;
  documentID?: number;
  handleClose: () => void;
  matterId?: number;
  onSubmitSuccess?: () => void;
}

/**
 * Change stage modal container
 * @param isOpen          Is the modal open?
 * @param documentID      The document ID
 * @param handleClose     Function to close the modal
 * @param matterId        The matter ID if this is a matter document
 * @param onSubmitSuccess Function to call after the stage has been saved
 * @returns JSX.Element
 */
function ChangeStageModalContainer({
  isOpen,
  documentID,
  handleClose,
  matterId,
  onSubmitSuccess,
}: IChangeStageModalContainer): JSX.Element {
  const dispatch = useAppDispatch();

  // Document type stages, status and error
  const documentTypeStages = useAppSelector(selectDocumentTypeStages);
  const documentTypeStagesStatus = useAppSelector(selectDocumentTypeStagesStatus);
  const documentTypeStagesError = useAppSelector(selectDocumentTypeStagesError);

  // Document stages, status and error
  const documentStages = useAppSelector(selectDocumentStages);
  const documentStagesStatus = useAppSelector(selectDocumentStagesStatus);
  const documentStagesError = useAppSelector(selectDocumentStagesError);

  // Documents state
  const documents = useAppSelector(selectDocuments);
  const matterDocuments = useAppSelector(selectMatterDocuments);

  // Set stage state
  const setStageStatus = useAppSelector(selectSetStageStatus);
  const setStageError = useAppSelector(selectSetStageError);

  // If this is a matter document, get the document type and stage from the matter documents
  // Otherwise, get the document type and stage from the primary documents
  const documentTypeId =
    matterId && documentID
      ? matterDocuments[matterId].documents[documentID].documentType.id
      : documentID && documents[documentID]?.documentType.id;

  const currentStage =
    matterId && documentID
      ? matterDocuments[matterId].documents[documentID].stageID
      : documentID && documents[documentID]?.stageID;

  useEffect(() => {
    if (documentTypeId) {
      // Fetch stages for the document type connected to the document
      dispatch(postDocumentTypeStages(documentTypeId));
    }
  }, [documentTypeId]);

  // Determine the status and error of the API calls
  let status = cStatusType.Idle;
  let error;

  // Determine the status of the API calls
  // If any of the calls are loading, the status is loading
  if (documentTypeStagesStatus === cStatusType.Loading || documentStagesStatus === cStatusType.Loading) {
    status = cStatusType.Loading;
  } else if (documentTypeStagesStatus === cStatusType.Failed || documentStagesStatus === cStatusType.Failed) {
    // If any of the calls failed, the status is failed
    status = cStatusType.Failed;
    error = documentTypeStagesError || documentStagesError || setStageError;
  } else {
    // Default status is idle
    status = cStatusType.Idle;
  }

  // Create options for the stage field
  const options = useMemo(() => {
    // If the document type stages are empty or the document stages are empty, return an empty array
    if (!documentTypeStages || isEmpty(documentStages)) {
      return [];
    }

    return documentTypeStages?.reduce((acc: IFieldOption[], stage: number) => {
      // If the stage is not in the document stages, skip it and return the accumulator
      if (!documentStages[stage]) {
        return acc;
      }

      // Add the stage to the accumulator
      acc.push({
        value: String(documentStages[stage].id),
        label: documentStages[stage].displayName,
        disabled: status === cStatusType.Loading,
      });

      return acc;
    }, []);
  }, [documentTypeStages, documentStages, status]);

  /**
   * Handle setting the stage
   * @param stageID The stage ID
   * @returns Promise<void>
   */
  async function handleSetStage(stageID: number) {
    try {
      await dispatch(postDocumentSetStage({ id: documentID as number, stageID }));
      handleClose();
      toast("Stage updated");
      onSubmitSuccess && onSubmitSuccess();
    } catch (err: any) {
      console.error(err);
      const errorCode = parseInt(err);
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      }
    }
  }

  return (
    <ChangeStageModal
      isOpen={isOpen}
      handleClose={handleClose}
      status={status}
      error={error}
      options={options || []}
      currentStage={currentStage}
      handleSetStage={handleSetStage}
      saveStatus={setStageStatus!}
    />
  );
}

export default ChangeStageModalContainer;
