import { isEqual } from "lodash";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../app/constants";
import Button, { cButtonType } from "../Button/Button";
import DiscardChangesModal from "../DiscardChangesModal/DiscardChangesModal";
import FormField from "../FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../Modal/Modal";
import Typography from "../Typography/Typography";
import styles from "./RenameDocumentModal.module.scss";

type Inputs = {
  newName: string;
};

interface IRenameDocumentModal {
  documentName: string;
  isOpen: boolean;
  handleClose: () => void;
  handleRenameDocumentSave: (description: string) => void;
  status?: cStatusType;
}

/**
 * The rename document modal
 * @param documentName             The current document name
 * @param isOpen                   Is the modal open?
 * @param handleClose              Function to close the modal
 * @param handleRenameDocumentSave Function to send the new description via API
 * @param status                   Rename API status
 * @returns JSX.Element
 */
function RenameDocumentModal({
  documentName,
  isOpen,
  handleClose,
  handleRenameDocumentSave,
  status,
}: IRenameDocumentModal) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<Inputs>();

  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    if (!isEqual(watch(), { newName: "" })) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose();
      reset();
    }
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    handleRenameDocumentSave(data.newName);
  };

  /**
   * Handles DiscardChangesModal close
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
    }
    setIsDiscardChangesModalOpen(false);
  }

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} testId="rename-document-modal">
        <ModalHeader>
          <ModalTitle>Rename document</ModalTitle>
        </ModalHeader>
        <ModalContent spacing={{ pt: 0 }}>
          <Typography spacing={{ mb: 6 }} className={styles.small}>
            Current name: {documentName}
          </Typography>
          <form id="renameDocumentForm" onSubmit={handleSubmit(onSubmit)}>
            <FormField
              name="newName"
              label="New name"
              register={register}
              error={errors.newName as any}
              testId="rename-document-modal-name-input"
              maxLength={500}
              required
              fullWidth
            />
          </form>
        </ModalContent>
        <ModalActions>
          <Button onClick={handleModalClose} color={cThemeColorType.Secondary}>
            Cancel
          </Button>
          <Button disabled={status === cStatusType.Loading} formId="renameDocumentForm" type={cButtonType.SubmitType}>
            Save
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
    </>
  );
}

export default RenameDocumentModal;
