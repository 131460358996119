import { format } from "date-fns";
import { chain, filter, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IFieldOption } from "../../../../components/FormField/FormField";
import {
  EDateFilterOptions,
  EMatterCustomExportType,
  ICustomExportFilters,
  postDeleteMatterTypeReportCustomExport,
  postMatterTypeCustomExports,
  postMatterTypeReportFilters,
  postMatterTypeReportTemplateSave,
  postRunMatterTypeReport,
  selectAllMatterAnalyticsFilters,
  selectAllMatterCustomExports,
  selectDeleteMatterTemplateStatus,
  selectDeleteMatterTemplateStatusError,
  selectMatterAnalyticsFiltersStatus,
  selectMatterTypeReportTemplateSaveStatus,
  selectRunMatterTypeReportStatus,
} from "../../../../modules/analyticsSlice";
import { selectUser } from "../../../../modules/userSlice";
import { IUser, selectUsers } from "../../../../modules/usersSlice";
import { errorToast } from "../../../../toast/toast";
import { downloadBase64File } from "../../../../utils/download/download";
import ConfirmDeleteReportTemplateModal from "../CustomExportContainer/ConfirmDeleteReportTemplateModal/ConfirmDeleteReportTemplateModal";
import MatterCustomExport, { Inputs } from "./MatterCustomExport/MatterCustomExport";

const managementReportPrefix = "Management Report:";
const dataReportPrefix = "Data Report:";

/**
 * The Matter custom export container
 * @returns JSX.Element
 */

function MatterCustomExportContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { matterTypeID, type, configID } = useParams();
  const filtersStatus = useAppSelector(selectMatterAnalyticsFiltersStatus);
  const users = useAppSelector(selectUsers);
  const user = useAppSelector(selectUser);
  const filters = useAppSelector(selectAllMatterAnalyticsFilters);
  const runMatterTypeReportStatus = useAppSelector(selectRunMatterTypeReportStatus);
  const saveDocumentTypeReportTemplateStatus = useAppSelector(selectMatterTypeReportTemplateSaveStatus);
  const deleteTemplateStatus = useAppSelector(selectDeleteMatterTemplateStatus);
  const configName = filters?.configName;
  const updatedCustomExports = useAppSelector(selectAllMatterCustomExports);
  const [newTemplateName, setNewTemplateName] = useState<string | null>(null);
  const [isDeleteReportTemplateModalOpen, setIsDeleteReportTemplateModalOpen] = useState(false);
  const deleteError = useAppSelector(selectDeleteMatterTemplateStatusError);

  // Fetch filters for the custom export
  useEffect(() => {
    if (configID && type) {
      dispatch(
        postMatterTypeReportFilters({
          configID: Number(configID),
          matterTypeID: Number(matterTypeID),
          type: type as EMatterCustomExportType,
        }),
      );
    }
  }, [configID, type]);

  // if a new config is created, get the updated customExports and navigate to the new configID
  useEffect(() => {
    if (newTemplateName) {
      const newConfig = updatedCustomExports.find((customExport) => customExport.name === newTemplateName);
      if (newConfig) {
        navigate(`/analytics/matter-analytics/${matterTypeID}/${type}/${newConfig.id}`);
      }
    }
  }, [newTemplateName, updatedCustomExports]);

  const owners = useMemo(() => {
    const documentOwnerIds = filters?.optionalFilters?.owners ? filters.optionalFilters.owners.ids.map(Number) : [];
    if (documentOwnerIds) {
      return filter(users, ({ id }) => documentOwnerIds.includes(id));
    }
    return [];
  }, [filters, users]);

  const ownerFilterOptions: IFieldOption[] & Pick<IUser, "isInternal"> = chain(owners)
    // Sort by lastName, firstName ignoring case
    .orderBy([(owner) => owner.lastName?.toLowerCase(), (owner) => owner.firstName.toLowerCase()])
    // Pull EMBED user to the top
    .sort((owner) => {
      if (owner.isInternal) {
        return -1;
      } else if (!owner.isInternal) {
        return 1;
      }
      return 0;
    })
    // Pull logged in user to the top
    .sort((owner) => {
      if (owner.id === user?.id) {
        return -1;
      } else if (owner.id !== user?.id) {
        return 1;
      }
      return 0;
    })
    .map((owner) => {
      return {
        label:
          owner.id === user?.id
            ? `Me (${owner.firstName} ${owner.lastName})`
            : owner.isInternal
              ? `${owner.firstName}`
              : `${owner.lastName}, ${owner.firstName}`,
        value: owner.id,
        isInternal: owner.isInternal,
      };
    })
    .value();

  // Create date filter options
  const dateFilterOptions: IFieldOption[] = map(filters?.dateFilter?.options, (option) => ({
    label: option,
    value: option,
  }));

  /**
   * Handles the event when the back button is clicked.
   * Navigates the user back to the originating documentTypeID.
   */
  const handleBackClick = () => {
    if (matterTypeID) {
      navigate(`/analytics/matter-analytics/${matterTypeID}/`);
    }
  };

  /**
   * Handles the closing of the delete report template modal
   */
  const handleDeleteReportTemplateModalClose = () => {
    setIsDeleteReportTemplateModalOpen(false);
  };

  /**
   * Handles the deletion of a report template
   */
  async function handleDeleteReportTemplate() {
    try {
      await dispatch(
        postDeleteMatterTypeReportCustomExport({ matterTypeID: Number(matterTypeID), configID: Number(configID) }),
      ).unwrap();
      toast("Custom report deleted");
      // close the modal
      setIsDeleteReportTemplateModalOpen(false);
      // navigate back to the matter analytics page
      navigate(`/analytics/matter-analytics/${matterTypeID}`);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Extracts the report config name from the report string
   * @param reportString The report string
   * @returns The report config name
   */
  function extractReportConfigName(reportString?: string) {
    if (!reportString) {
      return "";
    }
    // Define the prefixes to check
    const prefixes = [managementReportPrefix, dataReportPrefix];

    // Check if the string starts with any of the prefixes
    for (const prefix of prefixes) {
      if (reportString.startsWith(prefix)) {
        // Split the string by the prefix and return the last part
        return reportString.split(prefix)[1].trim();
      }
    }

    // Return the original reportString if no prefix is matched
    return reportString;
  }

  /**
   * Builds the payload for the runReport and saveChanges actions
   * @param data The form data
   * @returns Object containing the common payload and the template name
   */
  const buildPayload = (data: Inputs) => {
    // Destructure fields related to optionalFilters to group them separately
    // Exclude 'Select all' logic from the the data as it is for UI control only
    const { documentClass, descriptionContains, owners, dateFilter, startDate, endDate, advanced } = data;
    const templateName = data.templateName || extractReportConfigName(configName) || "";
    // Convert data before dispatching
    const formattedStartDate = startDate ? format(new Date(startDate), "yyyy-MM-dd") : "";
    const formattedEndDate = endDate ? format(new Date(endDate), "yyyy-MM-dd") : "";
    let selectedDocumentClass = documentClass;
    if (selectedDocumentClass === "" || selectedDocumentClass === "All") {
      selectedDocumentClass = "all";
    }
    // convert documentTypeID to number
    const matterTypeIDasNumber = parseInt(matterTypeID ?? "", 10);
    // convert ids to numbers
    const ownerIDsAsNumbers = owners ? owners.map(Number) : [];
    const descriptionContainsCaseSensitive = Boolean(descriptionContains.caseSensitive);
    // Transform the advanced filters
    // Type guard to check if value is an object with a value property
    const isOptionObject = (value: any): value is { label: string; value: string } => {
      return typeof value === "object" && value !== null && "value" in value;
    };
    const transformedAdvancedFilters =
      filters?.optionalFilters?.advanced?.map((filter) => {
        const { id, name } = "text" in filter ? filter.text : filter.list;
        let value = advanced?.[name]?.value;
        if (isOptionObject(value)) {
          value = value.value;
        }
        return {
          id,
          name,
          value: (value as string) || "",
          include: !!advanced?.[name]?.include, // Ensure `include?` is a boolean
          filter: !!advanced?.[name]?.filter, // Ensure `filter?` is a boolean
        };
      }) || [];
    // Common payload for runReport and saveChanges
    const commonPayload = {
      matterTypeID: matterTypeIDasNumber,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      dateFilter: dateFilter as EDateFilterOptions,
      type: type as EMatterCustomExportType,
      optionalFilters: {
        descriptionContains: {
          value: descriptionContains.value,
          caseSensitive: descriptionContainsCaseSensitive,
        },
        owners: ownerIDsAsNumbers,
        advanced: transformedAdvancedFilters,
      },
    };
    return { commonPayload, templateName, matterTypeIDasNumber };
  };

  /**
   * Handles the save template click
   * @param data The form values
   * @returns void
   * @async
   */
  const handleSaveTemplateClick = async (data: Inputs & { templateName?: string }) => {
    try {
      const { commonPayload, templateName } = buildPayload(data);
      await dispatch(postMatterTypeReportTemplateSave({ ...commonPayload, templateName })).unwrap();
      toast("Template saved");
      // reload the report
      dispatch(
        postMatterTypeReportFilters({
          configID: Number(configID),
          type: type as EMatterCustomExportType,
          matterTypeID: Number(matterTypeID),
        }),
      );
    } catch (error: any) {
      errorToast(error.message || error.toString());
    }
  };

  /**
   * Handles the event when the save as filters button is clicked.
   * @param data The form data
   */
  const handleSaveAsTemplateClick = async (data: Inputs & { templateName: string }) => {
    try {
      const { commonPayload, templateName, matterTypeIDasNumber } = buildPayload(data);
      await dispatch(postMatterTypeReportTemplateSave({ ...commonPayload, templateName })).unwrap();
      toast("Saved as new template");
      // set new name and navigate to the new report
      const typeName = type === EMatterCustomExportType.Management ? managementReportPrefix : dataReportPrefix;
      setNewTemplateName(`${typeName} ${templateName}`);
      await dispatch(postMatterTypeCustomExports(matterTypeIDasNumber)).unwrap();
    } catch (error: any) {
      errorToast(error.message || error.toString());
    }
  };

  /**
   * Handles the event when the run report button is clicked.
   * @param data The form data
   */
  const handleRunReportClick = async (data: Inputs) => {
    try {
      const { commonPayload } = buildPayload(data);
      const action = await dispatch(postRunMatterTypeReport(commonPayload));
      const response = action.payload;
      const { fileData, fileName } = response;
      downloadBase64File({ fileData, fileName });
    } catch (error: any) {
      errorToast(error.message || error.toString());
    }
  };

  return (
    <>
      <MatterCustomExport
        configID={Number(configID) || null}
        filters={filters as ICustomExportFilters}
        filtersStatus={filtersStatus}
        ownerFilterOptions={ownerFilterOptions}
        dateFilterOptions={dateFilterOptions}
        configName={configName}
        handleBackClick={handleBackClick}
        runMatterTypeReportStatus={runMatterTypeReportStatus}
        saveDocumentTypeReportTemplateStatus={saveDocumentTypeReportTemplateStatus}
        handleSaveAsTemplateClick={handleSaveAsTemplateClick}
        handleSaveTemplateClick={handleSaveTemplateClick}
        handleRunReportClick={handleRunReportClick}
        setIsDeleteReportTemplateModalOpen={setIsDeleteReportTemplateModalOpen}
      />
      <ConfirmDeleteReportTemplateModal
        isOpen={isDeleteReportTemplateModalOpen}
        handleModalClose={handleDeleteReportTemplateModalClose}
        deleteTemplateStatus={deleteTemplateStatus}
        handleDeleteReportTemplate={handleDeleteReportTemplate}
        error={deleteError}
      />
    </>
  );
}

export default MatterCustomExportContainer;
