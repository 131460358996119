import { every } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { cStatusType } from "../../../../../app/constants";
import Div from "../../../../../components/Div/Div";
import { IFieldOption } from "../../../../../components/FormField/FormField";
import Spinner from "../../../../../components/Spinner/Spinner";
import Typography from "../../../../../components/Typography/Typography";
import { ICustomExportFilters, TAdvancedFilter } from "../../../../../modules/analyticsSlice";
import {
  createAdvancedFiltersRows,
  handleSelectAll,
  handleSelectAllAdvanced,
  hasFormChanged,
} from "../../../../../utils/analytics/analytics";
import styles from "../../Analytics.module.scss";
import AnalyticsAdvancedFilters from "../../CustomExportContainer/CustomExport/AnalyticsAdvancedFilters/AnalyticsAdvancedFilters";
import AnalyticsDateFilter from "../../CustomExportContainer/CustomExport/AnalyticsDateFilter/AnalyticsDateFilter";
import AnalyticsDateRangeFilter from "../../CustomExportContainer/CustomExport/AnalyticsDateRangeFilter/AnalyticsDateRangeFilter";
import AnalyticsDescriptionContainsFieldGroup from "../../CustomExportContainer/CustomExport/AnalyticsDescriptionContainsFieldGroup/AnalyticsDescriptionContainsFieldGroup";
import AnalyticsFiltersContainer from "../../CustomExportContainer/CustomExport/AnalyticsFiltersContainer/AnalyticsFiltersContainer";
import AnalyticsFiltersHeaderContainer from "../../CustomExportContainer/CustomExport/AnalyticsFiltersHeaderContainer/AnalyticsFiltersHeaderContainer";
import AnalyticsOwnersFilter from "../../CustomExportContainer/CustomExport/AnalyticsOwnersFilter/AnalyticsOwnersFilter";
import SaveAsTemplateModal from "../../CustomExportContainer/SaveAsTemplateModal/SaveAsTemplateModal";

interface IMatterCustomExportProps {
  configID: number | null;
  filters: ICustomExportFilters;
  filtersStatus: cStatusType;
  ownerFilterOptions: IFieldOption[];
  dateFilterOptions: IFieldOption[];
  configName?: string;
  handleBackClick: () => void;
  runMatterTypeReportStatus: cStatusType;
  saveDocumentTypeReportTemplateStatus: cStatusType;
  handleSaveAsTemplateClick: (data: Inputs & { templateName: string }) => void;
  handleSaveTemplateClick: (data: Inputs & { templateName: string }) => void;
  handleRunReportClick: (data: any) => void;
  setIsDeleteReportTemplateModalOpen: (isOpen: boolean) => void;
}

export interface Inputs {
  date: string;
  documentClass: string;
  descriptionContains: { value: string | null; caseSensitive: boolean };
  owners?: string[];
  selectAllOwners: "allOwners" | boolean;
  dateFilter: string;
  startDate: string;
  endDate: string;
  selectAllAdvancedInclude: "allAdvancedInclude" | boolean;
  selectAllAdvancedFilter: "allAdvancedFilter" | boolean;
  advanced: AdvancedFilterInputs;
  templateName?: string;
}

// Type for the default advanced filter values
export type AdvancedFilterInputs = {
  [key: string]: {
    id: number;
    name: string;
    value: IFieldOption | string | null;
    include: boolean;
    filter: boolean;
    options?: Array<{ label: string; value: string }>;
  };
};

/**
 * The Custom export component
 * @param configID                             The configID of the report
 * @param filters                              The filters
 * @param filtersStatus                        The filters status
 * @param ownerFilterOptions                   The owner filter options
 * @param dateFilterOptions                    The date filter options
 * @param configName                           The config name
 * @param handleBackClick                      The handle back click function
 * @param runMatterTypeReportStatus            The run matter type report status
 * @param saveDocumentTypeReportTemplateStatus The save changes status
 * @param handleSaveAsTemplateClick            The filters save as function
 * @param handleSaveTemplateClick              The handle save template click function
 * @param handleRunReportClick                 The handle run report click function
 * @param setIsDeleteReportTemplateModalOpen   The set is delete report template modal open
 * @returns JSX.Element
 */

function MatterCustomExport({
  configID,
  filters,
  filtersStatus,
  ownerFilterOptions,
  dateFilterOptions,
  configName,
  handleBackClick,
  runMatterTypeReportStatus,
  saveDocumentTypeReportTemplateStatus,
  handleSaveAsTemplateClick,
  handleSaveTemplateClick,
  handleRunReportClick,
  setIsDeleteReportTemplateModalOpen,
}: IMatterCustomExportProps): JSX.Element {
  // Is the save as template modal open?
  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] = useState(false);

  // Set default values for filters as received from API
  const defaultValues = useMemo(() => {
    const advancedFiltersDefaults: AdvancedFilterInputs = {};

    // Loop through each advanced filter and set the default values
    filters?.optionalFilters?.advanced?.forEach((filter: TAdvancedFilter) => {
      // Build the advanced filter object based on the filter type (text or list)
      if ("text" in filter) {
        advancedFiltersDefaults[filter.text.name] = {
          id: filter.text.id,
          name: filter.text.name,
          value: filter.text.value ?? "",
          include: filter.text.include,
          filter: filter.text.filter,
        };
      } else if ("list" in filter) {
        // Check if the object has the expected structure
        if (filter.list && typeof filter.list === "object") {
          const { options, value } = filter.list;
          advancedFiltersDefaults[filter.list.name] = {
            id: filter.list.id,
            name: filter.list.name,
            value: value ? ({ label: value, value } as IFieldOption) : "", // Use IFieldOption for select fields as it is expected by react-select
            include: filter.list.include,
            filter: filter.list.filter,
            options: options.map((option) => ({ label: option, value: option })),
          };
        }
      }
    });

    return {
      startDate: configID === null ? "" : filters?.startDate || "",
      endDate: configID === null ? "" : filters?.endDate || "",
      documentClass:
        typeof filters?.documentClass?.value === "string" ? filters.documentClass.value.toLowerCase() : "all",
      dateFilter: typeof filters?.dateFilter?.value === "string" ? filters.dateFilter.value : "Last Modified Date",
      format: typeof filters?.format?.value === "string" ? filters.format.value : "xlsx",
      descriptionContains: {
        value: filters?.optionalFilters?.descriptionContains?.value,
        caseSensitive: filters?.optionalFilters?.descriptionContains?.caseSensitive,
      },
      states: filters?.optionalFilters?.states?.value?.map(String),
      stages: filters?.optionalFilters?.stages?.value?.map(String),
      owners: filters?.optionalFilters?.owners?.value?.map(String) || [],
      selectAllOwners:
        filters?.optionalFilters.owners.value.length === filters?.optionalFilters.owners.ids.length
          ? ("allOwners" as const)
          : false,
      selectAllAdvancedInclude: filters?.optionalFilters?.advanced?.every((filter: TAdvancedFilter) =>
        "text" in filter ? filter.text.include : filter.list.include,
      ),
      selectAllAdvancedFilter: filters?.optionalFilters?.advanced?.every((filter: TAdvancedFilter) =>
        "text" in filter ? filter.text.filter : filter.list.filter,
      ),
      advanced: advancedFiltersDefaults,
    };
  }, [filters, configID]);

  const {
    control,
    register,
    reset,
    watch,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues,
  });

  const values = watch();
  const startDate = values.startDate;
  const endDate = values.endDate;
  const [advancedFilterVisibility, setAdvancedFilterVisibility] = useState<{ [key: string]: boolean }>({});
  const [hasChanges, setHasChanges] = useState(false);

  // Set the maximum number of options before scrolling is enabled in a `scrollBox`
  const maxOptions = 8;

  // Reset the defaultValues
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, configID]);

  // Watch form fields for changes
  useEffect(() => {
    setHasChanges(hasFormChanged({ defaultValues, currentValues: getValues() }));
  }, [values, defaultValues]);

  // Set case sensitive values for description and document data
  useEffect(() => {
    const descriptionCaseSensitive = filters?.optionalFilters?.descriptionContains?.caseSensitive;
    setValue("descriptionContains.caseSensitive", descriptionCaseSensitive);
  }, [filters]);

  // Conditionally show the advanced filter inputs based on the filter? checkbox
  useEffect(() => {
    // Create object to store the visibility state for each advanced filter
    const initialVisibility: { [key: string]: boolean } = {};
    // Loop through each key in the advanced filters' defaultValues as received from API
    Object.keys(defaultValues.advanced).forEach((key) => {
      // Set initial visibility state based on the filter value in default values
      initialVisibility[key] = defaultValues.advanced[key].filter;
    });
    // Update state with the initial visibility values
    setAdvancedFilterVisibility(initialVisibility);
  }, [defaultValues.advanced]);

  useEffect(() => {
    // Set up a subscription to watch for form changes
    const subscription = watch((value, { name, type }) => {
      // Handle visibility of advanced filters
      if (/^advanced\..+\.filter$/.test(name as string)) {
        // Get the filter name from the field name
        const filterName = (name as string).split(".")[1];
        setAdvancedFilterVisibility((prev) => ({
          ...prev, // Spread the previous state
          // Update the visibility state of the targeted field based on the filter value
          [filterName]: !!value.advanced?.[filterName]?.filter,
        }));
      }

      // Only update on change events as others can be automatically triggered
      if (type === "change") {
        switch (true) {
          // Handle "select all" checkbox functionality for owners - check all owners if "All" is selected
          case name === "selectAllOwners":
            handleSelectAll({
              fieldName: "owners",
              selectAllValue: value.selectAllOwners as string,
              itemIds: filters?.optionalFilters.owners?.ids?.map(Number),
              setValue,
            });
            break;
          // Set the "select all" checkbox state based on whether all owners are selected
          case name === "owners":
            // Update "select all" state based on whether all owners are selected
            setValue("selectAllOwners", value.owners?.length === ownerFilterOptions.length ? "allOwners" : false);
            break;
          case /^advanced\..+\.include$/.test(name as string):
            // Check if all advanced filters are checked and set the include all checkbox value
            // if other checkboxes are checked
            setValue("selectAllAdvancedInclude", every(value.advanced, ["include", true]));
            break;
          case /^advanced\..+\.filter$/.test(name as string):
            // Check if all advanced filters are checked and set the filter all checkbox value
            // if other checkboxes are checked
            setValue("selectAllAdvancedFilter", every(value.advanced, ["filter", true]));
            break;
          case name === "selectAllAdvancedInclude":
            // Handle "select all" checkbox functionality for advanced filters - check all advanced filters "include" if "All" is selected
            handleSelectAllAdvanced({
              type: "include",
              value: value.selectAllAdvancedInclude as string,
              setValue,
              advancedFilters: filters?.optionalFilters?.advanced,
            });
            break;
          case name === "selectAllAdvancedFilter":
            // Handle "select all" checkbox functionality for advanced filters - check all advanced filters "filters" if "All" is selected
            handleSelectAllAdvanced({
              type: "filter",
              value: value.selectAllAdvancedFilter as string,
              setValue,
              advancedFilters: filters?.optionalFilters?.advanced,
            });
            break;
        }
      }
    });

    // Clean up the subscription when the component unmounts
    return () => subscription.unsubscribe();
  }, [watch, filters]);

  // Map the advanced filters into the table rows
  const advancedFiltersRows = createAdvancedFiltersRows({
    advancedFilters: filters?.optionalFilters?.advanced,
    defaultValues,
    advancedFilterVisibility,
    register,
    control,
  });

  /**
   * Closes the Save as template modal
   */
  function handleSaveAsTemplateModalClose() {
    setIsSaveAsTemplateModalOpen(false);
  }

  /**
   * Handles the submission of the Save As Template modal.
   * @param data            The data object that contains the form values
   * @param newTemplateName The new template name
   */
  function handleSaveAsTemplateModalSubmit(data: { newTemplateName: string }) {
    const formData = getValues();
    // get the data from the form to submit with the new template name
    handleSaveAsTemplateClick({ ...formData, templateName: data.newTemplateName });
    setIsSaveAsTemplateModalOpen(false);
  }

  return (
    <>
      <AnalyticsFiltersContainer>
        {filtersStatus !== cStatusType.Loading ? (
          <form>
            {/* Report header */}
            <AnalyticsFiltersHeaderContainer
              configName={configName}
              configID={configID}
              hasChanges={hasChanges}
              saveReportTemplateStatus={saveDocumentTypeReportTemplateStatus}
              runReportStatus={runMatterTypeReportStatus}
              setIsDeleteReportTemplateModalOpen={setIsDeleteReportTemplateModalOpen}
              setIsSaveAsTemplateModalOpen={setIsSaveAsTemplateModalOpen}
              trigger={trigger}
              handleBackClick={handleBackClick}
              handleSubmit={handleSubmit}
              handleRunReportClick={handleRunReportClick}
              handleSaveTemplateClick={handleSaveTemplateClick}
            />

            {/* Render the form fields based on the filters */}
            <AnalyticsDateRangeFilter
              register={register}
              startDateError={errors.startDate}
              endDateError={errors.endDate}
              startDate={startDate}
              endDate={endDate}
            />

            <AnalyticsDateFilter
              register={register}
              dateFilterOptions={dateFilterOptions as IFieldOption[]}
              defaultValue={filters?.dateFilter?.value}
            />

            <Div className={styles.formRowHeader}>
              <Typography variant="h4" spacing={{ mb: 0, pt: 4 }}>
                Optional filters
              </Typography>
            </Div>

            <AnalyticsDescriptionContainsFieldGroup register={register} analyticsType="matter" />

            <AnalyticsOwnersFilter
              register={register}
              ownerFilterOptions={ownerFilterOptions}
              maxOptions={maxOptions}
            />

            {advancedFiltersRows && advancedFiltersRows.length > 0 && (
              <AnalyticsAdvancedFilters register={register} advancedFiltersRows={advancedFiltersRows} />
            )}
          </form>
        ) : (
          <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
            <Spinner />
          </Div>
        )}
      </AnalyticsFiltersContainer>
      <SaveAsTemplateModal
        isOpen={isSaveAsTemplateModalOpen}
        handleClose={handleSaveAsTemplateModalClose}
        onSubmit={handleSaveAsTemplateModalSubmit}
      />
    </>
  );
}

export default MatterCustomExport;
