import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  postDocumentTypeCustomExports,
  selectAllCustomExports,
  selectAllDocumentAnalytics,
  selectCustomExportsStatus,
} from "../../../../modules/analyticsSlice";
import DocumentAnalytics from "./DocumentAnalytics/DocumentAnalytics";

/**
 * The Document Analytics Container
 * @returns JSX.Element
 */
function DocumentAnalyticsContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const customExportStatus = useAppSelector(selectCustomExportsStatus);
  const customExports = useAppSelector(selectAllCustomExports);
  const { documentTypeID } = useParams();
  const documentAnalytics = useAppSelector(selectAllDocumentAnalytics);

  useEffect(() => {
    if (documentTypeID) {
      dispatch(postDocumentTypeCustomExports(Number(documentTypeID))); // Convert id to number
    }
  }, [documentTypeID]);

  return (
    <DocumentAnalytics
      customExportStatus={customExportStatus}
      customExports={customExports}
      documentTypeID={Number(documentTypeID)}
      documentAnalytics={Object.values(documentAnalytics)}
    />
  );
}

export default DocumentAnalyticsContainer;
