import { lazy, Suspense } from "react";
import LazyPageFallback from "../../components/LazyPageFallback/LazyPageFallback";

const LazyDocumentsContainer = lazy(() => import("./DocumentsContainer"));

const DocumentsContainer = (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={<LazyPageFallback />}>
    <LazyDocumentsContainer {...props} />
  </Suspense>
);

export default DocumentsContainer;
