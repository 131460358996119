import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../../../app/constants";
import Button, { cButtonType } from "../../../../components/Button/Button";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Typography from "../../../../components/Typography/Typography";
import { EWalkType } from "../../../../modules/walkSlice";
import { IHandleDiscard } from "../../WalkContainer";

interface IWalkDiscardModal {
  isOpen: boolean;
  setIsDiscardModalOpen: Dispatch<SetStateAction<boolean>>;
  handleDiscard: (formData?: IHandleDiscard) => void;
  walkTypeClass: EWalkType;
  pauseTotal?: number;
  isFinalised?: boolean;
  portalClassName?: string;
  walkStatus?: cStatusType;
}

type Inputs = {
  note: string;
};

/**
 * Walk discard confirmation modal
 * @param isOpen                Is the modal open?
 * @param setIsDiscardModalOpen Set state function to set isOpen state
 * @param handleDiscard         Handle discard dispatcher
 * @param walkTypeClass         The walk type
 * @param pauseTotal            WIP pause count
 * @param isFinalised           Has this document been finalised before?
 * @param portalClassName       The portal class name (if required)
 * @param walkStatus            API loading status of the walk
 * @returns JSX.Element
 */
function WalkDiscardModal({
  isOpen,
  setIsDiscardModalOpen,
  handleDiscard,
  walkTypeClass,
  pauseTotal,
  isFinalised,
  portalClassName,
  walkStatus,
}: IWalkDiscardModal): JSX.Element {
  // Define state for the warning message and action derived from the walkTypeClass
  const [activityString, setActivityString] = useState<string>("");
  const [actionString, setActionString] = useState<string>("");

  // When discarding a walk, show a descriptive warning message and action based on the walk type
  useEffect(() => {
    switch (walkTypeClass) {
      case EWalkType.File:
        setActivityString("uploading a new document"); // The activity string for the warning message
        setActionString("uploading"); // The action string for the continue button
        break;
      case EWalkType.Collaboration:
        setActivityString("creating a new collaboration document");
        setActionString("collaborating");
        break;
      default:
        setActivityString("drafting a new document");
        setActionString("drafting");
        break;
    }
  }, [walkTypeClass]);

  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors }, // Errors that may occur
  } = useForm<Inputs>();

  /**
   * Handle modal close - update state
   */
  function handleClose() {
    setIsDiscardModalOpen(false);
  }

  /**
   * Should the discard notes field be rendered?
   * @returns boolean
   */
  function shouldShowNotesField(): boolean {
    if (isFinalised && pauseTotal) {
      return true;
    }

    return false;
  }

  /**
   * Submit the form
   * @param formData The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      handleDiscard(formData);
    }
  };

  /**
   * If notes field is shown, have the button submit the form
   * otherwise have it handle discard directly
   * @returns object
   */
  const discardBtnProps = () => {
    if (shouldShowNotesField()) {
      return {
        formId: "discard-walk-form",
        type: cButtonType.SubmitType,
      };
    } else {
      return {
        onClick: () => handleDiscard(),
      };
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} testId="walk-discard-modal" portalClassName={portalClassName}>
      <ModalHeader>
        <ModalTitle>Discard document</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>{`You are busy ${activityString}. Discarding will remove it from the system.`}</Typography>
        {shouldShowNotesField() && (
          <form onSubmit={handleSubmit(onSubmit)} id="discard-walk-form">
            <FormField
              register={register}
              name="note"
              label="Optional discard comment"
              type={cFormFieldType.Textarea}
              error={errors.note}
              spacing={{ mt: 5 }}
              fullWidth
            />
          </form>
        )}
      </ModalContent>
      <ModalActions>
        <Button color={cThemeColorType.Secondary} onClick={handleClose} disabled={walkStatus === cStatusType.Loading}>
          <>Continue {actionString}</>
        </Button>
        <Button {...discardBtnProps()} isLoading={walkStatus === cStatusType.Loading}>
          Discard document
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default WalkDiscardModal;
