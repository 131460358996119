import { cMonoColorType, cStatusType } from "../../app/constants";
import styles from "../../pages/AnalyticsContainer/Analytics/Analytics.module.scss";
import Div from "../Div/Div";
import Container from "../Grid/Container/Container";
import LinkList from "../LinkList/LinkList";
import { TListItem } from "../LinkList/ListItem/ListItem";
import Spinner from "../Spinner/Spinner";
import Typography from "../Typography/Typography";

interface IAnalyticsCustomExportsList {
  title: string;
  status: string;
  defaultExports: TListItem[];
  customExports: TListItem[];
}

/**
 * The Analytics Custom Exports List component
 * @param title           The title of the list
 * @param status          The status of the custom exports fetching
 * @param defaultExports  The list of default exports
 * @param customExports   The list of custom exports
 * @returns JSX.Element
 */
function AnalyticsCustomExportsList({
  title,
  status,
  defaultExports,
  customExports,
}: IAnalyticsCustomExportsList): JSX.Element {
  return (
    <Container
      className={styles.reportContainer}
      width="lg"
      mx={{ base: 0 }}
      pb={{ base: 5 }}
      bgColor={cMonoColorType.White}
    >
      <Div
        border={{ bb: true }}
        className={styles.actionBar}
        display={{ base: "flex" }}
        alignItems={{ base: "center" }}
        justifyContent={{ base: "space-between" }}
      >
        <Typography variant="h4" spacing={{ mb: 0 }}>
          {title}
        </Typography>
      </Div>
      <Div py={{ base: 6 }}>
        {status !== cStatusType.Loading ? (
          defaultExports.length + customExports.length > 0 ? (
            <>
              <LinkList items={defaultExports} />
              {customExports && customExports.length > 0 && (
                <Div pt={{ base: 7 }}>
                  <Typography variant="h6">Saved templates</Typography>
                  <LinkList items={customExports} />
                </Div>
              )}
            </>
          ) : (
            <div>There are no custom exports for this matter type.</div>
          )
        ) : (
          <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
            <Spinner />
          </Div>
        )}
      </Div>
    </Container>
  );
}

export default AnalyticsCustomExportsList;
