import classNames from "classnames";
import { forwardRef, MouseEvent } from "react";
import { mergeRefs } from "react-merge-refs";
import { cMonoColorType, cSizeType, cThemeColorType } from "../../app/constants";
import { useDimensions, useSpacing } from "../../app/hooks";
import { ISpacing, IXDirection } from "../../app/types";
import fullWidthStyles from "../../scss/generic/FullWidth.module.scss";
import Icon, { EIcon } from "../Icon/Icon";
import Popover from "../Popover/Popover";
import Spinner from "../Spinner/Spinner";
import Typography from "../Typography/Typography";
import styles from "./Button.module.scss";

export enum EButtonVariant {
  Standard = "standard",
  Round = "round",
  Link = "link",
  Outlined = "outlined",
  Square = "square",
}

export enum cButtonType {
  SubmitType = "submit",
  ButtonType = "button",
}

export interface IButton {
  children?: string | JSX.Element | JSX.Element[];
  fullWidth?: boolean;
  color?: cThemeColorType | cMonoColorType;
  size?: cSizeType;
  variant?: EButtonVariant;
  type?: cButtonType;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  spacing?: ISpacing;
  className?: string;
  icon?: EIcon;
  iconPosition?: IXDirection;
  testId?: string;
  isLoading?: boolean;
  formId?: string;
  tabIndex?: number;
  disabledReason?: string;
}

/**
 * A button (currently submit)
 * @param children        The contents of the button
 * @param fullWidth       Display 100% width?
 * @param color           The button style
 * @param size            Button size
 * @param variant         The button variant
 * @param type            Button type
 * @param disabled        Is the button disabled?
 * @param onClick         The function to run on click
 * @param onMouseEnter    The function to run on mouse enter
 * @param onMouseLeave    The function to run on mouse leave
 * @param spacing         Spacing object
 * @param className       Custom classes
 * @param icon            Icon to display in button
 * @param iconPosition    Position of icon to display in button
 * @param testId          Test id used to query buttin when testing
 * @param isLoading       Is the button loading?
 * @param formId          ID the form to submit a form via a button outside of the component?
 * @param tabIndex        The tab index of the button
 * @param disabledReason  Text to display in the disabled button tooltip
 * @returns JSX.Element
 */
const Button = forwardRef<HTMLButtonElement | {}, IButton>(
  (
    {
      children,
      fullWidth,
      color = cThemeColorType.Primary,
      size = cSizeType.Medium,
      variant,
      type = cButtonType.ButtonType,
      disabled = false,
      onClick,
      onMouseEnter,
      onMouseLeave,
      spacing,
      className,
      icon,
      iconPosition = IXDirection.Left,
      testId,
      isLoading,
      formId,
      tabIndex,
      disabledReason,
    }: IButton,
    ref,
  ): JSX.Element => {
    const { ref: dimensionsRef, width, height } = useDimensions();

    const spacingClx = useSpacing(spacing); // Get spacing class

    const clx = classNames(
      // Build final class string
      styles.className, // General class
      styles[size], // Size class
      styles[color], // Color class
      variant && styles[variant], // Variant class
      spacingClx, // Spacing class
      { [fullWidthStyles.className]: fullWidth === true }, // Full width class
      icon ? styles.icon : undefined, // Icon class
      icon && styles[`icon${iconPosition}`], // Icon position class
      icon && !children && styles.iconSolo, // If no children, apply icon right class (padding)
      disabled && styles.disabled,
      className, // Prop class
    );

    const iconClx = classNames(styles.iconInternal);

    const button = (): JSX.Element => {
      return (
        <button
          ref={mergeRefs([dimensionsRef, ref])}
          style={{ width: width > 0 && isLoading ? width : "", height: height > 0 && isLoading ? height : "" }} // Preserve the dimensions when the spinner is rendered
          data-testid={testId}
          type={type}
          className={clx}
          form={formId}
          onClick={handleClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          tabIndex={tabIndex}
        >
          {isLoading ? (
            <Spinner color={cMonoColorType.Light} size={cSizeType.Small} />
          ) : (
            <>
              {icon && iconPosition !== IXDirection.Right && <Icon icon={icon} className={iconClx} />}
              {children}
              {icon && iconPosition === IXDirection.Right && <Icon icon={icon} className={iconClx} />}
            </>
          )}
        </button>
      );
    };

    /**
     * Disable onClick if disabled prop is true
     * @param e Click event
     */
    function handleClick(e: MouseEvent<HTMLButtonElement>) {
      if (disabled || isLoading) {
        e.preventDefault();
      } else if (onClick) {
        onClick(e);
      }
    }

    return (
      // If disabled reason has been set, return a tooltip
      disabledReason ? (
        <Popover
          variant="tooltip"
          width="auto"
          popoverContents={<Typography>{disabledReason}</Typography>}
          divProps={{ disabledTooltip: button() }}
        />
      ) : (
        // otherwise show the button
        button()
      )
    );
  },
);

Button.displayName = "Button";
export default Button;
