import { format } from "date-fns";
import { cSemanticColorType } from "../../../../../../../../app/constants";
import Icon, { EIcon } from "../../../../../../../../components/Icon/Icon";
import Typography from "../../../../../../../../components/Typography/Typography";
import styles from "./TaskLockedMessage.module.scss";

/**
 * Convert a date time string to a human readable format as expected by the TaskLockedMessage component
 * @param dateTime The date time string to convert to a human readable format
 * @returns string
 */
function convertToDateTimeString(dateTime: string): string {
  return format(new Date(dateTime), "HH:mm EEE dd MMM yyyy");
}

interface ITaskLockedMessage {
  isEmbed: boolean;
  userName?: string;
  wipDTM?: string;
  activeEmbedCreateDTM?: string;
}

/**
 * Component to render the task locked message
 * @param isEmbed              The message to render
 * @param userName             The user name of the user who started the walk in progress
 * @param wipDTM               The date time string of the start of the walk in progress
 * @param activeEmbedCreateDTM The date time string of the initial send of the embed email notification
 * @returns JSX.Element
 */
function TaskLockedMessage({ isEmbed, userName, wipDTM, activeEmbedCreateDTM }: ITaskLockedMessage): JSX.Element {
  // If the task is not an embed task, render the user name and the date time the walk in progress started
  if (isEmbed === false) {
    return (
      <Typography className={styles.lockedMsg}>
        Started by user {userName} at {wipDTM ? convertToDateTimeString(wipDTM) : ""}.
      </Typography>
    );
  }

  // If the task is an embed task and the walk in progress has started, render the date time the walk in progress started
  if (wipDTM && activeEmbedCreateDTM) {
    return (
      <Typography className={styles.lockedMsg}>
        <Icon icon={EIcon.Success} className={styles.notificationSentIcon} color={cSemanticColorType.Success} />
        <span className={styles.success}>Interview started</span>
        <br />
        <span className={styles.datetime}>{convertToDateTimeString(activeEmbedCreateDTM)}</span>
      </Typography>
    );
  }

  // If the task is an embed task and the walk in progress has not started, render the date time the embed email notification was sent
  return (
    <Typography className={styles.lockedMsg}>
      <Icon icon={EIcon.NotificationSent} className={styles.notificationSentIcon} />
      <span className={styles.success}>Email sent</span>:{" "}
      <span className={styles.datetime}>{convertToDateTimeString(activeEmbedCreateDTM as string)}</span>
      <br />
      Interview not started
    </Typography>
  );
}

export default TaskLockedMessage;
