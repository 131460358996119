import { useState } from "react";
import { Control, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { cThemeColorType } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button, { cButtonType } from "../../../../components/Button/Button";
import DiscardChangesModal from "../../../../components/DiscardChangesModal/DiscardChangesModal";
import Div from "../../../../components/Div/Div";
import FileUploadFormField from "../../../../components/FileUploadFormField/FileUploadFormField";
import FormField, { cFormFieldType } from "../../../../components/FormField/FormField";
import InfoBox, { EInfoBoxVariant } from "../../../../components/InfoBox/InfoBox";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import { selectMaxFileUploadSize } from "../../../../modules/customerSlice";
import {
  IUploadSupportingDocFormData,
  openInformationUpdatedModal,
  postUploadSupportingDocument,
  selectDocUploadProgress,
  updateDocUploadProgress,
} from "../../../../modules/documentsSlice";
import { errorToast } from "../../../../toast/toast";
import { areSomeDefined } from "../../../../utils/areSomeDefined/areSomeDefined";
import { convertToBase64 } from "../../../../utils/convertToBase64/convertToBase64";
import { isOutdatedContentError } from "../../../../utils/errors/errors";
import UploadProgressModal from "../../../WalkContainer/Walk/WalkUpload/UploadProgressModal/UploadProgressModal";

type Inputs = {
  file: File[] | null;
  note: string | null;
};

export interface IAttachSupportingDocModal {
  isOpen: boolean;
  handleClose: () => void;
  documentID?: number;
  onSubmitSuccess?: Function;
}

/**
 * Render a modal to transfer ownership
 * @param isOpen	               Is the modal open?
 * @param handleClose	           Function to close the modal
 * @param documentID             The document ID
 * @param onSubmitSuccess	       Function called on submit success
 * @returns JSX.Element
 */
function AttachSupportingDocModal({
  isOpen,
  handleClose,
  documentID,
  onSubmitSuccess,
}: IAttachSupportingDocModal): JSX.Element {
  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    formState: { errors, isValid }, // Errors that may occur
    control,
    reset,
    watch,
    resetField,
  } = useForm<Inputs>({ mode: "onChange" });

  const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false);
  const maxFileUploadSize = useAppSelector(selectMaxFileUploadSize);
  const dispatch = useAppDispatch();
  const docUploadProgress = useAppSelector(selectDocUploadProgress);

  /**
   * Handles Modal close
   */
  function handleModalClose() {
    if (areSomeDefined(watch())) {
      setIsDiscardChangesModalOpen(true);
    } else {
      handleClose();
      reset();
    }
  }

  /**
   * Handles DiscardChangesModal close
   */
  function handleDiscardChangesModalClose(canDiscard: boolean) {
    if (canDiscard) {
      handleClose();
      reset();
    }
    setIsDiscardChangesModalOpen(false);
  }

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const { file, note } = data;
      const uploadedFile = file && file[0];
      if (uploadedFile) {
        const { name } = uploadedFile;
        const fileData = await convertToBase64(uploadedFile);
        const formData: IUploadSupportingDocFormData = {
          documentID: documentID as number,
          file: {
            fileData,
            name,
          },
        };
        if (note) {
          formData.note = note;
        }
        await dispatch(postUploadSupportingDocument(formData)).unwrap();
        toast("Supporting document attached");
        reset();
        handleClose();
        onSubmitSuccess && onSubmitSuccess();
      }
    } catch (error: any) {
      console.error(error);
      const errorCode = parseInt(error);
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      } else {
        errorToast(error);
      }
    } finally {
      dispatch(updateDocUploadProgress(0));
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleModalClose} size="md" testId="attach-supporting-doc-modal">
        <ModalHeader>
          <ModalTitle>Attach a supporting file</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Div spacing={{ mb: 5 }}>
            <InfoBox variant={EInfoBoxVariant.Warning}>
              Please note: Signed versions or amendments to the Primary Document should not be attached here. Please use
              the <i>Upload a new or signed version</i> function instead.
            </InfoBox>
          </Div>
          <Div spacing={{ mb: 5 }}>
            <form id="attachSupportingDocForm" onSubmit={handleSubmit(onSubmit)}>
              <FileUploadFormField
                control={control as Control<any, any>}
                register={register}
                errors={errors as any}
                maxFileUploadSize={maxFileUploadSize as number}
                resetField={resetField}
              />
              <FormField
                testId="note"
                label="Add a note (optional)"
                name="note"
                type={cFormFieldType.Textarea}
                register={register}
                error={errors.note}
                spacing={{ mb: 5 }}
                fullWidth
              />
            </form>
          </Div>
        </ModalContent>
        <ModalActions>
          <Button
            color={cThemeColorType.Secondary}
            onClick={handleModalClose}
            testId="attach-supporting-doc-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button formId="attachSupportingDocForm" type={cButtonType.SubmitType} disabled={!isValid}>
            Save
          </Button>
        </ModalActions>
      </Modal>
      <DiscardChangesModal
        isOpen={isDiscardChangesModalOpen}
        handleClose={(canDiscard) => handleDiscardChangesModalClose(canDiscard)}
      />
      {Boolean(docUploadProgress) && <UploadProgressModal uploadProgress={docUploadProgress as number} />}
    </>
  );
}

export default AttachSupportingDocModal;
