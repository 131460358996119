import { createAsyncThunk, createSlice, ListenerEffectAPI, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import api from "../app/api";
import {
  notifyGroupAddRuleUrl,
  notifyGroupAddUrl,
  notifyGroupConfigUrl,
  notifyGroupDeleteRuleUrl,
  notifyGroupDeleteUrl,
  notifyGroupRefreshRuleUrl,
  notifyGroupsUrl,
  notifyGroupUsersUrl,
  notifyRuleRecipientsEditUrl,
  notifyRuleRecipientsListUrl,
} from "../app/apiUrls";
import { cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";
import { postUsersByID } from "./usersSlice";

/**
 * Describes a notify group
 */
export interface INotifyGroup {
  id: number;
  name: string;
  canBeDeleted: boolean;
  isRestricted: boolean;
  index: number;
}

/**
 * Describes a notify group user
 */
export interface INotifyGroupConfig {
  notifyGroupID: number;
  rules: IRules;
}

/**
 * Describes top level rules
 */
interface IRules {
  document_event: IRuleSet;
  matter_event: IRuleSet;
}

/**
 * Describes a rule set
 */
interface IRuleSet {
  default: IRule;
  exceptions: IRule[];
}

/**
 * Describes a rule
 */
export interface IRule {
  id: number;
  elements?: IRuleElement[];
  recipients: IRecipient[];
}

/**
 * Describes a rule element
 */
interface IRuleElement {
  event_type?: INamedItem;
  document_type?: IDocumentType;
  matter_type?: IMatterType;
}

/**
 * Describes an event type rule (as base)
 */
export interface INamedItem {
  id: number;
  name: string;
}

/**
 * Describes a document type rule
 */
export interface IDocumentType extends INamedItem {
  isRetired: boolean;
}

/**
 * Describes a matter type rule
 */
export interface IMatterType extends INamedItem {
  isRetired: boolean;
}

/**
 * Describes a notification recipient
 */
export interface IRecipient {
  id: number;
  name: string;
  pseudoBoo: boolean;
}

/**
 * Describes the rule items for add rule form
 */
interface IRuleItems {
  matter_type?: {
    items: IMatterType[];
  };
  doc_type?: {
    items: IDocumentType[];
  };
  event_type?: {
    items: INamedItem[];
  };
}

/**
 * Describes the notifiy group state object
 */
interface INotifyGroupsState {
  entries: Record<number, INotifyGroup>; // Notify group records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
  users?: number[];
  usersStatus: cStatusType;
  usersError?: string;
  deleteStatus: cStatusType;
  deleteError?: string;
  notifyGroupConfig?: INotifyGroupConfig;
  notifyGroupConfigStatus: cStatusType;
  notifyGroupConfigError?: string;
  notifyGroupDeleteRuleStatus: cStatusType;
  notifyGroupDeleteRuleError?: string;
  notifyGroupAddStatus: cStatusType;
  notifyGroupAddError?: string;
  ruleItems?: IRuleItems;
  ruleItemsStatus: cStatusType;
  ruleItemsError?: string;
  ruleItemsSaving: boolean;
  notifyRuleEditRecipientsStatus: cStatusType;
  notifyRuleEditRecipientsError?: string;
  recipients?: Record<number, IRecipient>;
  recipientsStatus: cStatusType;
  recipientsError?: string;
}

/**
 * Initial state
 */
const initialState: INotifyGroupsState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
  usersStatus: cStatusType.Idle,
  deleteStatus: cStatusType.Idle,
  notifyGroupConfigStatus: cStatusType.Idle,
  notifyRuleEditRecipientsStatus: cStatusType.Idle,
  notifyGroupDeleteRuleStatus: cStatusType.Idle,
  notifyGroupAddStatus: cStatusType.Idle,
  ruleItemsStatus: cStatusType.Idle,
  ruleItemsSaving: false,
  recipientsStatus: cStatusType.Idle,
};

/**
 * Thunk for fetching notify groups
 */
export const postNotifyGroups = createAsyncThunk(
  "notifyGroups/postNotifyGroups",
  async (data: number | "all", { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupsUrl;
      const response = await api({ endpoint, dispatch, body: { id: data } });

      // Add an index to the list to enable sorting
      const indexedList = response.data.notifyGroup.map((item: INotifyGroup, index: number) => ({
        ...item,
        index,
      }));

      return createIdList(indexedList);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching notify group users
 */
export const postNotifyGroupUsers = createAsyncThunk(
  "notifyGroups/postNotifyGroupUsers",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupUsersUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      return response.data.users;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a notify group
 */
export const postNotifyGroupDelete = createAsyncThunk(
  "notifyGroups/postNotifyGroupDelete",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupDeleteUrl;
      await api({ endpoint, dispatch, body: { id } });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching notify group config
 */
export const postNotifyGroupConfig = createAsyncThunk(
  "notifyGroups/postNotifyGroupConfig",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupConfigUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for deleting a notify group rule
 */
export const postNotifyGroupDeleteRule = createAsyncThunk(
  "notifyGroups/postNotifyGroupDeleteRule",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupDeleteRuleUrl;
      await api({ endpoint, dispatch, body: { id } });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for adding a notify group
 */
export const postNotifyGroupAdd = createAsyncThunk(
  "notifyGroups/postNotifyGroupAdd",
  async (name: string, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupAddUrl;
      await api({ endpoint, dispatch, body: { name } });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Describes the refresh from object
 */
interface IRefreshFrom {
  id: number;
}

/**
 * Describes the post refresh matter type object
 */
interface IPostRefreshMatterType {
  mattertype: IRefreshFrom;
}

/**
 * Describes the post refresh document type object
 */
interface IPostRefreshDocumentType {
  doc_type: IRefreshFrom;
}

/**
 * Describes the event type document post object
 */
type EventTypeDocument = "all" | { refreshFrom: IPostRefreshMatterType } | { refreshFrom: IPostRefreshDocumentType };

/**
 * Describes the event type matter post object
 */
type EventTypeMatter = "all" | { refreshFrom: IPostRefreshMatterType };

/**
 * Describes the post notify group refresh rule object
 */
export type TPostNotifyGroupRefreshRule = { event_document: EventTypeDocument } | { event_matter: EventTypeMatter };

/**
 * Thunk for refreshing notify group rules for the add rule form
 */
export const postNotifyGroupRefreshRule = createAsyncThunk(
  "notifyGroups/postNotifyGroupRefreshRule",
  async (options: TPostNotifyGroupRefreshRule, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupRefreshRuleUrl;
      const response = await api({ endpoint, dispatch, body: options });
      return response.data;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Describes the post notify group add rule object
 */
interface IPostNotifyGroupAddRule {
  notifyGroupId: number;
  elements: {
    event_document?: { mattertype?: { id: number }; doc_type?: { id: number }; event_type?: { id: number } };
    event_matter?: { mattertype?: { id: number }; event_type?: { id: number } };
  };
}

/**
 * Thunk for adding a notify group rule
 */
export const postNotifyGroupAddRule = createAsyncThunk(
  "notifyGroups/postNotifyGroupAddRule",
  async (data: IPostNotifyGroupAddRule, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyGroupAddRuleUrl;
      await api({ endpoint, dispatch, body: data });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for editing notify rule recipients
 */
export const postNotifyRuleRecipientsEdit = createAsyncThunk(
  "notifyGroups/postNotifyRuleRecipientsEdit",
  async (data: { id: number; recipientIDs: number[] }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyRuleRecipientsEditUrl;
      await api({ endpoint, dispatch, body: data });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching notify rule recipients list
 */
export const postNotifyRuleRecipientsList = createAsyncThunk(
  "notifyGroups/postNotifyRuleRecipientsList",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = notifyRuleRecipientsListUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      return createIdList(response.data.recipients);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch the users after notify group users have been fetched
 * @param action      The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchNotifyGroupUsersEffect = (
  _: UnknownAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;

  cancelActiveListeners();

  const state = getState() as RootState;
  const {
    notifyGroups: { users },
  } = state;

  try {
    if (users && users.length > 0) {
      dispatch(postUsersByID({ ids: users }));
    }
  } catch (e: any) {
    console.error(e.message);
  }
};

/**
 * Notify groups reducer
 */
export const notifyGroupsSlice = createSlice({
  name: "notifyGroups", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    // Reset the rule items
    resetRuleItems: (state) => {
      state.ruleItems = undefined;
    },
    // Update rule items error
    updateRuleItemsError: (state, action) => {
      state.ruleItemsError = action.payload;
    },
    // Clear add error
    clearNotifyGroupAddError: (state) => {
      state.notifyGroupAddError = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postNotifyGroups.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postNotifyGroups.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postNotifyGroups.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = action.payload;
        state.fetched = true; // Set fetched to true
      })
      // Notify group users
      .addCase(postNotifyGroupUsers.pending, (state) => {
        state.usersStatus = cStatusType.Loading;
        state.usersError = undefined;
      })
      .addCase(postNotifyGroupUsers.rejected, (state, action) => {
        state.usersStatus = cStatusType.Failed;
        state.usersError = action.payload as string;
      })
      .addCase(postNotifyGroupUsers.fulfilled, (state, action) => {
        state.usersStatus = cStatusType.Idle;
        state.users = action.payload;
      })
      // Notify group delete
      .addCase(postNotifyGroupDelete.pending, (state) => {
        state.deleteStatus = cStatusType.Loading;
        state.deleteError = undefined;
      })
      .addCase(postNotifyGroupDelete.rejected, (state, action) => {
        state.deleteStatus = cStatusType.Failed;
        state.deleteError = action.payload as string;
      })
      .addCase(postNotifyGroupDelete.fulfilled, (state) => {
        state.deleteStatus = cStatusType.Idle;
      })
      // Notify group config
      .addCase(postNotifyGroupConfig.pending, (state) => {
        state.notifyGroupConfigStatus = cStatusType.Loading;
        state.notifyGroupConfigError = undefined;
      })
      .addCase(postNotifyGroupConfig.rejected, (state, action) => {
        state.notifyGroupConfigStatus = cStatusType.Failed;
        state.notifyGroupConfigError = action.payload as string;
      })
      .addCase(postNotifyGroupConfig.fulfilled, (state, action) => {
        state.notifyGroupConfigStatus = cStatusType.Idle;
        state.notifyGroupConfig = action.payload;
      })
      // Delete rule
      .addCase(postNotifyGroupDeleteRule.pending, (state) => {
        state.notifyGroupDeleteRuleStatus = cStatusType.Loading;
        state.notifyGroupDeleteRuleError = undefined;
      })
      .addCase(postNotifyGroupDeleteRule.rejected, (state, action) => {
        state.notifyGroupDeleteRuleStatus = cStatusType.Failed;
        state.notifyGroupDeleteRuleError = action.payload as string;
      })
      .addCase(postNotifyGroupDeleteRule.fulfilled, (state) => {
        state.notifyGroupDeleteRuleStatus = cStatusType.Idle;
      })
      // Add notify group
      .addCase(postNotifyGroupAdd.pending, (state) => {
        state.notifyGroupAddStatus = cStatusType.Loading;
        state.notifyGroupAddError = undefined;
      })
      .addCase(postNotifyGroupAdd.rejected, (state, action) => {
        state.notifyGroupAddStatus = cStatusType.Failed;
        state.notifyGroupAddError = action.payload as string;
      })
      .addCase(postNotifyGroupAdd.fulfilled, (state) => {
        state.notifyGroupAddStatus = cStatusType.Idle;
      })
      // Refresh rule items
      .addCase(postNotifyGroupRefreshRule.pending, (state) => {
        state.ruleItemsStatus = cStatusType.Loading;
        state.ruleItemsError = undefined;
      })
      .addCase(postNotifyGroupRefreshRule.rejected, (state, action) => {
        state.ruleItemsStatus = cStatusType.Failed;
        state.ruleItemsError = action.payload as string;
      })
      .addCase(postNotifyGroupRefreshRule.fulfilled, (state, action) => {
        state.ruleItemsStatus = cStatusType.Idle;
        state.ruleItems = { ...state.ruleItems, ...action.payload };
      })
      // Add rule
      .addCase(postNotifyGroupAddRule.pending, (state) => {
        state.ruleItemsStatus = cStatusType.Loading;
        state.ruleItemsError = undefined;
        state.ruleItemsSaving = true;
      })
      .addCase(postNotifyGroupAddRule.rejected, (state, action) => {
        state.ruleItemsStatus = cStatusType.Failed;
        state.ruleItemsError = action.payload as string;
        state.ruleItemsSaving = false;
      })
      .addCase(postNotifyGroupAddRule.fulfilled, (state) => {
        state.ruleItemsStatus = cStatusType.Idle;
        state.ruleItemsSaving = false;
      })
      // Edit notify rule recipients
      .addCase(postNotifyRuleRecipientsEdit.pending, (state) => {
        state.notifyRuleEditRecipientsStatus = cStatusType.Loading;
        state.notifyRuleEditRecipientsError = undefined;
      })
      .addCase(postNotifyRuleRecipientsEdit.rejected, (state, action) => {
        state.notifyRuleEditRecipientsStatus = cStatusType.Failed;
        state.notifyRuleEditRecipientsError = action.payload as string;
      })
      .addCase(postNotifyRuleRecipientsEdit.fulfilled, (state) => {
        state.notifyRuleEditRecipientsStatus = cStatusType.Idle;
      })
      // Notify group recipients list
      .addCase(postNotifyRuleRecipientsList.pending, (state) => {
        state.recipientsStatus = cStatusType.Loading;
        state.recipientsError = undefined;
      })
      .addCase(postNotifyRuleRecipientsList.rejected, (state, action) => {
        state.recipientsStatus = cStatusType.Failed;
        state.recipientsError = action.payload as string;
      })
      .addCase(postNotifyRuleRecipientsList.fulfilled, (state, action) => {
        state.recipientsStatus = cStatusType.Idle;
        state.recipients = action.payload;
      });
  },
});

// Export actions
export const { resetRuleItems, updateRuleItemsError, clearNotifyGroupAddError } = notifyGroupsSlice.actions;

// Select all notifyGroups
export const selectNotifyGroups = (state: RootState) => state.notifyGroups.entries;
export const selectNotifyGroupsStatus = (state: RootState) => state.notifyGroups.status;
export const selectNotifyGroupsError = (state: RootState) => state.notifyGroups.error;

// Select notify group users
export const selectNotifyGroupUsers = (state: RootState) => state.notifyGroups.users;
export const selectNotifyGroupUsersStatus = (state: RootState) => state.notifyGroups.usersStatus;
export const selectNotifyGroupUsersError = (state: RootState) => state.notifyGroups.usersError;

// Select notify rule recipients edit
export const selectNotifyRuleRecipientsEditStatus = (state: RootState) =>
  state.notifyGroups.notifyRuleEditRecipientsStatus;
export const selectNotifyRuleRecipientsEditError = (state: RootState) =>
  state.notifyGroups.notifyRuleEditRecipientsError;

// Select notify group delete
export const selectNotifyGroupDeleteStatus = (state: RootState) => state.notifyGroups.deleteStatus;
export const selectNotifyGroupDeleteError = (state: RootState) => state.notifyGroups.deleteError;

// Select notify group config
export const selectNotifyGroupConfig = (state: RootState) => state.notifyGroups.notifyGroupConfig;
export const selectNotifyGroupConfigStatus = (state: RootState) => state.notifyGroups.notifyGroupConfigStatus;
export const selectNotifyGroupConfigError = (state: RootState) => state.notifyGroups.notifyGroupConfigError;

// Select notify group delete rule
export const selectNotifyGroupDeleteRuleStatus = (state: RootState) => state.notifyGroups.notifyGroupDeleteRuleStatus;
export const selectNotifyGroupDeleteRuleError = (state: RootState) => state.notifyGroups.notifyGroupDeleteRuleError;

// Select notify group add
export const selectNotifyGroupAddStatus = (state: RootState) => state.notifyGroups.notifyGroupAddStatus;
export const selectNotifyGroupAddError = (state: RootState) => state.notifyGroups.notifyGroupAddError;

// Select rule items
export const selectRuleItems = (state: RootState) => state.notifyGroups.ruleItems;
export const selectRuleItemsStatus = (state: RootState) => state.notifyGroups.ruleItemsStatus;
export const selectRuleItemsError = (state: RootState) => state.notifyGroups.ruleItemsError;
export const selectRuleItemsSaving = (state: RootState) => state.notifyGroups.ruleItemsSaving;

// Select recipients
export const selectNotifyRuleRecipientsList = (state: RootState) => state.notifyGroups.recipients;
export const selectNotifyRuleRecipientsListStatus = (state: RootState) => state.notifyGroups.recipientsStatus;
export const selectNotifyRuleRecipientsListError = (state: RootState) => state.notifyGroups.recipientsError;

export default notifyGroupsSlice.reducer;
