import {
  createAsyncThunk,
  createSlice,
  ListenerEffectAPI,
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
import api from "../app/api";
import { documentTypeStagesUrl, documentTypesUrl } from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import createIdList from "../utils/createIdList/createIdList";
import getExpiredIdCacheList, { TUnknownEntries } from "../utils/getExpiredIdCacheList/getExpiredIdCacheList";
import { postDocumentStages } from "./documentStagesSlice";

/**
 * Describe a document type
 */
export interface IDocumentType {
  name: string;
  id: number;
  code: string;
  draftAccess?: cAccessType;
  draftWalkTypeID?: number;
  fileAccess?: cAccessType;
  fileWalkTypeID?: number;
  retiredBoo?: boolean;
  fetchTime?: number;
}

/**
 * Describe the document types state object
 */
export interface IDocumentTypesState {
  entries: Record<number, IDocumentType>; // Document types records
  status: cStatusType; // API call status
  fetched: boolean; // Have entries been fetched?
  error?: string;
  stages?: number[];
  stagesStatus?: cStatusType;
  stagesError?: string;
}

/**
 * Initial state
 */
const initialState: IDocumentTypesState = {
  entries: {},
  status: cStatusType.Idle,
  fetched: false,
  stagesStatus: cStatusType.Idle,
};

/**
 * Interface for the postDocumentTypes thunk
 */
interface IPostDocumentTypes {
  ids: number[];
  force?: boolean;
}

/**
 * Thunk for fetching document types
 */
export const postDocumentTypes = createAsyncThunk(
  "documentTypes/postDocumentTypes",
  async ({ ids, force = false }: IPostDocumentTypes, { dispatch, rejectWithValue, getState }) => {
    try {
      const endpoint = documentTypesUrl;
      let id = ids;

      // If we are not forcing the fetch, check if we have the data in the cache
      // (force will fetch the data regardless of the cache state)
      if (!force) {
        const state = getState();

        const {
          documentTypes: { entries },
        } = state as RootState;

        // Get the expired ids
        id = getExpiredIdCacheList({ ids, entries: entries as unknown as TUnknownEntries });
      }

      // If we have no ids to fetch, return an empty object
      if (id.length === 0) {
        return {};
      }

      const response = await api({ endpoint, dispatch, body: { id } });

      return createIdList(response.data.documentType);
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Thunk for fetching document type stages
 */
export const postDocumentTypeStages = createAsyncThunk(
  "documentTypes/postDocumentTypeStages",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api({ endpoint: documentTypeStagesUrl, body: { id } });

      return response.data.stageIDs;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Fetch the stages after fetching the ids for a document type
 * @param _           The action
 * @param listenerApi The listener API
 * @returns void
 */
export const fetchDocumentTypeStagesEffect = (
  _: any,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
) => {
  const { dispatch, getState, cancelActiveListeners } = listenerApi;
  cancelActiveListeners();
  const state = getState() as RootState;
  const {
    documentTypes: { stages },
  } = state;

  if (stages && stages.length > 0) {
    dispatch(postDocumentStages(stages));
  }
};

/**
 * Documents reducer
 */
export const documentTypesSlice = createSlice({
  name: "documentTypes", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    // Update the modal is open state
    update: (state, action: PayloadAction<IDocumentType>) => {
      state.entries[action.payload.id] = { ...state.entries[action.payload.id], ...action.payload };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Set status to loading when the promise is pending
      .addCase(postDocumentTypes.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set status to failed if the promise is rejected
      .addCase(postDocumentTypes.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Set status back to idle once the promise has been fulfilled
      .addCase(postDocumentTypes.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, ...action.payload };
        state.fetched = true; // Set fetched to true
      })
      // Document type stages
      .addCase(postDocumentTypeStages.pending, (state) => {
        state.stagesStatus = cStatusType.Loading;
        state.stagesError = undefined;
      })
      .addCase(postDocumentTypeStages.rejected, (state, action) => {
        state.stagesStatus = cStatusType.Failed;
        state.stagesError = action.payload as string;
      })
      .addCase(postDocumentTypeStages.fulfilled, (state, action) => {
        state.stagesStatus = cStatusType.Idle;
        state.stages = action.payload;
      });
  },
});

export const { update } = documentTypesSlice.actions;

// Select the status
export const selectDocumentTypesStatus = (state: RootState) => state.documentTypes.status;
// Select all documentTypes
export const selectDocumentTypes = (state: RootState) => state.documentTypes.entries;
// Select fetched
export const selectDocumentTypesFetched = (state: RootState) => state.documentTypes.fetched;
// Select error
export const selectDocumentTypesError = (state: RootState) => state.documentTypes.error;
// Select stages
export const selectDocumentTypeStages = (state: RootState) => state.documentTypes.stages;
// Select stages status
export const selectDocumentTypeStagesStatus = (state: RootState) => state.documentTypes.stagesStatus;
// Select stages error
export const selectDocumentTypeStagesError = (state: RootState) => state.documentTypes.stagesError;

export default documentTypesSlice.reducer;
