import { TSpacingOpts } from "../../app/styleTypes";
import Div from "../Div/Div";
import Spinner from "../Spinner/Spinner";

const spacing = { base: 8 as TSpacingOpts };

/**
 * Renders a fallback component for lazy loaded pages
 * @returns JSX.Element
 */
function LazyPageFallback() {
  return (
    <Div mt={spacing} p={spacing}>
      <Spinner />
    </Div>
  );
}

export default LazyPageFallback;
