import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { cMonoColorType } from "../../../app/constants";
import { IXDirection } from "../../../app/types";
import fullWidthStyles from "../../../scss/generic/FullWidth.module.scss";
import Div from "../../Div/Div";
import Typography from "../../Typography/Typography";
import ClearField from "../ClearField/ClearField";
import clearStyles from "../ClearField/ClearField.module.scss";
import { IClearFn, IFormField } from "../FormField";
import styles from "./Textarea.module.scss";

export interface ITextarea {
  fieldProps: UseFormRegisterReturn;
  fieldClx: string;
  onClear?: IClearFn;
  fullWidth: IFormField["fullWidth"];
  name: string;
  disabled: boolean;
  defaultValue?: string;
  testId?: string;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  autoComplete?: "on" | "off";
  autoFocus?: boolean;
  autoResize?: boolean;
}

/**
 * A textarea form field
 * @param fieldProps     Field props from React Hook Form's register function
 * @param fieldClx       Classes for the field
 * @param onClear        If field is clearable, this will be a function
 * @param fullWidth      Should the field render full width?
 * @param name           The field name
 * @param disabled       Is the textarea disabled?
 * @param defaultValue   Default value for the textarea
 * @param testId         Test ID
 * @param placeholder    Placeholder text
 * @param maxLength      Max input length of the textarea
 * @param rows           The height of the input
 * @param autoComplete   Browser auto complete on/off
 * @param autoFocus      Should the field auto focus on render?
 * @param autoResize     Should the textarea resize automatically?
 * @returns JSX.Element
 */
function Textarea({
  fieldProps,
  fieldClx,
  onClear,
  fullWidth,
  name,
  disabled,
  defaultValue,
  testId,
  placeholder,
  maxLength,
  rows,
  autoComplete,
  autoFocus,
  autoResize = false,
}: ITextarea): JSX.Element {
  const minHeight = 50; // Minimum height for the textarea when auto resize is enabled
  const ref = useRef<HTMLTextAreaElement | null>(null); // Field ref
  const rhaRef = fieldProps.ref; // React Hook Form's ref method to run onChange
  const [count, setCount] = React.useState(0); // Character count
  const clx = fullWidth ? styles.fullWidthClassName : styles.className; // If full width, apply display: block to wrapper
  const textareaClx = classNames(
    // Combine field classes
    fieldClx, // Default
    { [clearStyles.clearableField]: onClear }, // On clear class if field is clearable
    { [fullWidthStyles.className]: fullWidth }, // Full width class if full width prop is true
  );

  useEffect(() => {
    // Auto focus textarea
    if (autoFocus === true) {
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    }
  }, [autoFocus]);

  // Auto resize textarea
  useEffect(() => {
    // If auto resize is disabled, exit
    if (!autoResize) return;

    // Get the textarea element
    const textarea = ref.current;
    if (!textarea) return;

    // Adjust the height of the textarea using the scroll height
    const adjustHeight = () => {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.max(textarea.scrollHeight, minHeight)}px`;
    };

    // Adjust on content change
    textarea.addEventListener("input", adjustHeight);
    // Initial adjustment
    adjustHeight();

    // Remove event listener on unmount
    return () => textarea.removeEventListener("input", adjustHeight);
  }, [minHeight]);

  /**
   * Combine setCount and RHA's onChange handler
   * @param e Change event
   */
  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCount(e.target.value.length);
    fieldProps.onChange(e);
  }

  return (
    <Div className={clx}>
      <textarea
        autoComplete={autoComplete}
        role={autoComplete === "off" ? "presentation" : "textbox"}
        {...fieldProps} // Spread field props from React Hook Form's register
        className={textareaClx}
        maxLength={maxLength}
        rows={rows}
        onChange={onChange}
        ref={
          (e) => {
            ref.current = e;
            rhaRef(e);
          } // Combine custom ref and React Hook Form ref
        }
        disabled={disabled} // If disabled
        id={name} // Field ID
        defaultValue={
          typeof defaultValue === "string" // If default value is a string
            ? defaultValue // Set the value
            : undefined // Otherwise, send undefined
        }
        data-testid={testId}
        placeholder={placeholder}
      />
      {
        // if maxLength has been set, show the character count
        maxLength ? (
          // if maxLength has been reached, turn the text color to red
          count === maxLength ? (
            <Typography align={IXDirection.Right} className={styles.charCount}>
              <Div className={styles.error}>
                {count}/{maxLength}
              </Div>
            </Typography>
          ) : (
            // otherwise, set the text color grey
            <Typography color={cMonoColorType.Light} align={IXDirection.Right} className={styles.charCount}>
              {count}/{maxLength}
            </Typography>
          )
        ) : null
      }

      {onClear && <ClearField onClear={onClear} fieldRef={ref} />}
    </Div>
  );
}

export default Textarea;
