import { cStatusType } from "../../../../../../app/constants";
import DisplayError from "../../../../../../components/DisplayError/DisplayError";
import Div from "../../../../../../components/Div/Div";
import Spinner from "../../../../../../components/Spinner/Spinner";
import Tabs from "../../../../../../components/Tabs/Tabs";
import tabStyles from "../../../../../../components/Tabs/Tabs.module.scss";
import Typography from "../../../../../../components/Typography/Typography";
import { ISpawnItem } from "../../../../../../modules/matterTasksSlice";
import MatterBoxWrapper from "../MatterBoxWrapper/MatterBoxWrapper";
import { IMatterTasksColumn } from "../MatterTasksColumn";
import GeneralResources from "./GeneralResources/GeneralResources";
import styles from "./MatterResources.module.scss";
import ResourcesForThisMatter from "./ResourcesForThisMatter/ResourcesForThisMatter";

type TMatterResources = {
  matterItems: ISpawnItem[];
  matterID: number;
} & Pick<
  IMatterTasksColumn,
  | "handleDraftDocument"
  | "handleLaunchExternalResource"
  | "tasksError"
  | "tasksStatus"
  | "externalResources"
  | "externalResourcesError"
  | "externalResourcesStatus"
  | "setEmbedData"
  | "allowEmbed"
  | "setInvalidateData"
>;

/**
 * Render the Resources component for the Matter Details page
 * @param matterItems                  The items to do
 * @param handleDraftDocument          Start spawn walk
 * @param handleLaunchExternalResource Start external resource walk
 * @param matterID                     Wrapping matter ID
 * @param tasksError                   API errors
 * @param tasksStatus                  API loading status
 * @param externalResources            The list of external resources
 * @param externalResourcesError       API errors
 * @param externalResourcesStatus      API loading status
 * @param setEmbedData                 Set the embed data for the send embed email notification modal
 * @param allowEmbed                   Is embed allowed in the customer config?
 * @param setInvalidateData            Set the invalidate data for the invalidate embed link modal
 * @returns JSX.Element
 */
function MatterResources({
  matterItems,
  handleDraftDocument,
  handleLaunchExternalResource,
  matterID,
  tasksError,
  tasksStatus,
  externalResources,
  externalResourcesError,
  externalResourcesStatus,
  setEmbedData,
  allowEmbed,
  setInvalidateData,
}: TMatterResources): JSX.Element {
  const isEmpty =
    matterItems && externalResources && Object.keys(externalResources).length === 0 && matterItems.length === 0;

  // Tab items
  const tabItems = [
    {
      header: <Typography>For this Matter</Typography>,
      contents: tasksError ? (
        <Div py={{ base: 5 }} px={{ base: 6 }}>
          <DisplayError>{tasksError}</DisplayError>
        </Div>
      ) : tasksStatus === cStatusType.Loading ? (
        <Spinner />
      ) : (
        <ResourcesForThisMatter
          matterItems={matterItems}
          handleDraftDocument={handleDraftDocument}
          matterID={matterID}
          setEmbedData={setEmbedData}
          allowEmbed={allowEmbed}
          setInvalidateData={setInvalidateData}
        />
      ),
    },
    {
      header: <Typography>General</Typography>,
      contents: externalResourcesError ? (
        <Div py={{ base: 5 }} px={{ base: 6 }}>
          <DisplayError>{externalResourcesError}</DisplayError>
        </Div>
      ) : externalResourcesStatus === cStatusType.Loading ? (
        <Spinner />
      ) : (
        <GeneralResources
          externalResources={externalResources}
          handleLaunchExternalResource={handleLaunchExternalResource}
          matterID={matterID}
          setEmbedData={setEmbedData}
          allowEmbed={allowEmbed}
        />
      ),
    },
  ];

  return (
    <MatterBoxWrapper
      header={
        <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
          <Typography weight="medium">Resources</Typography>
        </Div>
      }
    >
      <Div scroll={{ y: true }} testId="matter-resources-scroll" className={isEmpty ? styles.empty : ""}>
        <Div className={isEmpty ? styles.content : ""}>
          <Tabs items={tabItems} sticky className={tabStyles.tabsNavWrapperSmall} />
        </Div>
      </Div>
    </MatterBoxWrapper>
  );
}

export default MatterResources;
