import { UseFormRegister } from "react-hook-form";
import Div from "../../../../../../components/Div/Div";
import TableCard, { ITableCardColumnCells } from "../../../../../../components/TableCard/TableCard";
import tableStyles from "../../../../../../components/TableCard/TableCard.module.scss";
import Typography from "../../../../../../components/Typography/Typography";
import { advancedFiltersColumns } from "../../../../../../utils/analytics/analytics";
import styles from "../../../Analytics.module.scss";

interface IAnalyticsAdvancedFilters {
  register: UseFormRegister<any>;
  advancedFiltersRows: ITableCardColumnCells[];
}

/**
 * The analytics advanced filters
 * @param register            The register function from react-hook-form
 * @param advancedFiltersRows The advanced filters rows
 * @returns JSX.Element
 */
function AnalyticsAdvancedFilters({ register, advancedFiltersRows }: IAnalyticsAdvancedFilters): JSX.Element {
  return (
    <>
      <Div className={styles.formRowHeader}>
        <Typography variant="h4" spacing={{ mb: 0, pt: 4 }}>
          Advanced optional filters
        </Typography>
      </Div>
      <Div className={`${styles.tableWrapper} ${styles.borderBox}`}>
        <TableCard
          columns={advancedFiltersColumns({ register })}
          rows={advancedFiltersRows ?? []}
          className={tableStyles.tableThemeMedium}
          variant="flush"
          testId="advanced-filters-table"
        />
      </Div>
    </>
  );
}

export default AnalyticsAdvancedFilters;
