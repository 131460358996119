import { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { CUSTOMER_CODE } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getCompanyLogo } from "../../modules/customerSlice";
import { getMainMenu } from "../../modules/mainMenuSlice";
import { selectIsLoggedIn, selectRedirectUrl, selectResumeWalk, selectUser } from "../../modules/userSlice";
import {
  EWalkAction,
  postWalkAction,
  selectWalkData,
  selectWalkIsActive,
  selectWalkResume,
} from "../../modules/walkSlice";
import ResumeWalkModalContainer from "../ResumeWalkModalContainer/ResumeWalkModalContainer";

interface IAuthProviderProps {
  children: React.ReactNode;
}

/**
 * Provider for handling auth errors and redirecting users back to the login page
 * @param children Wrapped children
 * @returns JSX.Element
 */
function AuthProvider({ children }: IAuthProviderProps): JSX.Element {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn); // Get the state from the store
  const userDetails = useAppSelector(selectUser); // Subscribe to user details changes to check resumeWalk updates
  const resumeWalk = useAppSelector(selectResumeWalk);
  const walkShouldResume = useAppSelector(selectWalkResume); // Should the walk resume? (from state)
  const walkData = useAppSelector(selectWalkData);
  const redirectUrl = useAppSelector(selectRedirectUrl);
  const walkIsActive = useAppSelector(selectWalkIsActive);
  const [walkResumeCalled, setWalkResumeCalled] = useState<boolean>(false); // Has walk resume call been made?

  // Resume active walk if in progress and not already active
  useEffect(() => {
    if (!walkIsActive && resumeWalk && walkResumeCalled === false) {
      dispatch(postWalkAction({ type: EWalkAction.Resume }));
      setWalkResumeCalled(true); // Set walk resume called so that we don't call it again (causes error)
    }
  }, [userDetails, walkResumeCalled]);

  // Get the main menu if the user is logged in
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getMainMenu());
      dispatch(getCompanyLogo());
    }
  }, [isLoggedIn]);

  /**
   * Adds a redirectUrl to the redirect if we want to take the user back to a path after authentication
   * @returns string
   */
  function getRedirectUrl() {
    const customerCode = localStorage.getItem(CUSTOMER_CODE);

    if (redirectUrl) {
      return `/?ccof=${customerCode}&redirectUrl=${redirectUrl}`;
    } else {
      return `/?ccof=${customerCode}`;
    }
  }

  if (isLoggedIn) {
    // Otherwise, display the content
    return (
      <>
        {children}
        {walkShouldResume && <ResumeWalkModalContainer stage={walkData?.stage} documentType={walkData?.documentType} />}
      </>
    );
  } else {
    // If the user should be logged out
    return <Navigate to={getRedirectUrl()} />; // Redirect to the login page and with a redirect url if one exists in the store
  }
}

export default AuthProvider;
