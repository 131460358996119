import { Dispatch, SetStateAction } from "react";
import Div from "../../../../../../../../components/Div/Div";
import Typography from "../../../../../../../../components/Typography/Typography";
import { IWalkType } from "../../../../../../../../modules/matterResourcesSlice";
import { IPostMatterLaunchExternalResource } from "../../../../../../../../modules/walkSlice";
import styles from "./GeneralResource.module.scss";
import GeneralResourceActionRow from "./GeneralResourceActionRow/GeneralResourceActionRow";

interface IGeneralResource {
  matterID: number;
  documentType: string;
  draft?: IWalkType;
  file?: IWalkType;
  handleLaunchExternalResource: ({ custWalkTypeID, matterID }: IPostMatterLaunchExternalResource) => void;
  setEmbedData: Dispatch<
    SetStateAction<{ custWalkTypeID: number; matterID?: number; interviewSpawnID?: number } | null>
  >;
  allowEmbed?: boolean;
}

/**
 * General resource item in a matter
 * @param matterID                     The ID of the matter
 * @param documentType                 The document type name
 * @param draft                        Draft document
 * @param file                         File document
 * @param handleLaunchExternalResource Start external resource walk
 * @param setEmbedData                 Set the embed data for the send embed email notification modal
 * @param allowEmbed                   Is embed allowed in the customer config?
 * @returns JSX.Element
 */
function GeneralResource({
  matterID,
  documentType,
  draft,
  file,
  handleLaunchExternalResource,
  setEmbedData,
  allowEmbed,
}: IGeneralResource): JSX.Element {
  return (
    <Div border={{ bb: true }} pt={{ base: 5 }}>
      <Typography weight="medium" className={styles.documentType}>
        {documentType}
      </Typography>
      <Div>
        {draft && (
          <GeneralResourceActionRow
            type="draft"
            data={draft}
            matterID={matterID}
            handleLaunchExternalResource={handleLaunchExternalResource}
            setEmbedData={setEmbedData}
            allowEmbed={allowEmbed}
          />
        )}
        {file && (
          <GeneralResourceActionRow
            type="file"
            data={file}
            matterID={matterID}
            handleLaunchExternalResource={handleLaunchExternalResource}
            setEmbedData={setEmbedData}
            allowEmbed={allowEmbed}
          />
        )}
      </Div>
    </Div>
  );
}

export default GeneralResource;
