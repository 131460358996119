import { cThemeColorType } from "../../app/constants";
import Button from "../Button/Button";
import DisplayError from "../DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../Modal/Modal";
import Typography from "../Typography/Typography";

interface IConfirmDeleteEventModal {
  isOpen: boolean;
  handleModalClose: () => void;
  isLoading: boolean;
  handleDeleteEvent: () => void;
  error?: string;
}

/**
 * Confirm deletion of custom event modal
 * @param isOpen            Is the modal open
 * @param handleModalClose  Function to close the modal
 * @param isLoading         Is the API call being run?
 * @param handleDeleteEvent Is the API call being run?
 * @param error             Is the API call being run?
 * @returns JSX.Element
 */
function ConfirmDeleteEventModal({
  isOpen,
  handleModalClose,
  isLoading,
  handleDeleteEvent,
  error,
}: IConfirmDeleteEventModal): JSX.Element {
  return (
    <Modal isOpen={isOpen} handleClose={handleModalClose} testId="confirm-delete-event-modal" size="sm">
      <ModalHeader>
        <ModalTitle>Delete Event</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>Are you sure you want to delete this event?</Typography>
        {error && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions>
        <Button onClick={handleModalClose} color={cThemeColorType.Secondary} testId="delete-event-modal-cancel-button">
          Go back
        </Button>
        <Button disabled={isLoading} onClick={handleDeleteEvent}>
          Delete Event
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default ConfirmDeleteEventModal;
