import { cStatusType, cThemeColorType } from "../../../../../../app/constants";
import Button from "../../../../../../components/Button/Button";
import DisplayError from "../../../../../../components/DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../../components/Modal/Modal";
import Typography from "../../../../../../components/Typography/Typography";
import styles from "./FinaliseCollaborationModal.module.scss";

interface IFinaliseCollaborationModal {
  isOpen: boolean;
  handleClose: () => void;
  handleWalkForward: () => void;
  walkStatus?: cStatusType;
  error?: string;
}

/**
 * Finalise collaboration modal
 * @param isOpen            Is the modal open
 * @param handleClose       Function to close the modal
 * @param handleWalkForward Function to handle walk forward
 * @param walkStatus        API loading status of the walk
 * @param error             Error message
 * @returns JSX.Element
 */
function FinaliseCollaborationModal({
  isOpen,
  handleClose,
  handleWalkForward,
  walkStatus,
  error,
}: IFinaliseCollaborationModal): JSX.Element {
  return (
    <Modal isOpen={isOpen} portalClassName={styles.modalPortal}>
      <ModalHeader>
        <ModalTitle variant="h3">Finalise collaboration?</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>
          This will stop the collaboration process. Collaborators will not be able to make any further changes.
        </Typography>
        {error && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} color={cThemeColorType.Secondary} disabled={walkStatus === cStatusType.Loading}>
          Go back
        </Button>
        <Button onClick={handleWalkForward} isLoading={walkStatus === cStatusType.Loading}>
          Continue
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default FinaliseCollaborationModal;
