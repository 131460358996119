import { forOwn } from "lodash";
import { useEffect, useState } from "react";
import { cAccessType } from "../../../../../app/constants";
import { EIcon } from "../../../../../components/Icon/Icon";
import LinkList from "../../../../../components/LinkList/LinkList";
import {
  EDocumentActionMenuItemKey,
  IDocumentAction,
  IDocumentTermination,
} from "../../../../../modules/documentsSlice";
import StatusRow from "./StatusRow/StatusRow";

interface IContentsProps {
  handleTransferOwnership: () => void;
  handleAddNote: () => void;
  handleAddEvent: () => void;
  handleDraftNewVersion: () => void;
  handleUpdateInterviewData: () => void;
  handleAttachSupportingDoc: () => void;
  handleTerminateDocument: () => void;
  handleUploadNewVersion: () => void;
  handleRenameDocument: () => void;
  handleCloneDocument: (id: number) => void;
  handleNewCollaboration: () => Promise<void>;
  handleChangeStage: () => void;
  testId?: string;
  action?: Record<string, IDocumentAction>;
  termination?: IDocumentTermination;
  isSigned?: boolean;
  signedDate: string | null;
}

/**
 * Contents of document list entry action popover
 * @param handleUpdateInterviewData Start update interview data walk (amend)
 * @param handleDraftNewVersion     Start draft new document version walk (amend)
 * @param handleUploadNewVersion    Start upload new document version walk (amend)
 * @param handleTransferOwnership   Function to handle transfer ownership click
 * @param handleAddNote             Function to open the add note modal
 * @param handleAddEvent            Function to open the add event modal
 * @param handleAttachSupportingDoc Function to open the attach supporting file modal
 * @param handleTerminateDocument   Function to open the terminate document modal
 * @param handleRenameDocument      Function to open the terminate document modal
 * @param handleCloneDocument       Function to clone a document
 * @param handleNewCollaboration    Function to open the new collaboration modal
 * @param handleChangeStage         Function to open the change stage modal
 * @param testId                    The test id
 * @param action                    The action items
 * @param termination               The document termination
 * @param isSigned                  Has the document been signed
 * @param signedDate                Date of signature
 * @returns JSX.Element
 */
function Contents({
  handleTransferOwnership,
  handleAddNote,
  handleAddEvent,
  handleDraftNewVersion,
  handleUpdateInterviewData,
  handleUploadNewVersion,
  handleAttachSupportingDoc,
  handleTerminateDocument,
  handleRenameDocument,
  handleCloneDocument,
  handleNewCollaboration,
  handleChangeStage,
  testId,
  action,
  termination,
  isSigned,
  signedDate,
}: IContentsProps): JSX.Element {
  // The default menu items before the access has been set
  const defaultMenuItems: any[] = [
    {
      name: EDocumentActionMenuItemKey.DraftNewVersion,
      icon: EIcon.Edit,
      text: "Draft a new version",
      onClick: handleDraftNewVersion,
      access: cAccessType.Inaccessible,
      testId: "draft-new-version-btn",
    },
    {
      name: EDocumentActionMenuItemKey.UploadNewVersion,
      icon: EIcon.UploadDocument,
      text: "Upload a new or signed version",
      onClick: handleUploadNewVersion,
      access: cAccessType.Inaccessible,
      testId: "upload-new-version-btn",
    },
    {
      name: EDocumentActionMenuItemKey.CollaborateNewVersion,
      icon: EIcon.Collaboration,
      text: "New collaboration version",
      onClick: handleNewCollaboration,
      access: cAccessType.Inaccessible,
      testId: "word-collaboration-btn",
    },
    {
      name: EDocumentActionMenuItemKey.UpdateInterviewData,
      icon: EIcon.Edit,
      text: "Update interview data",
      onClick: handleUpdateInterviewData,
      access: cAccessType.Inaccessible,
      testId: "update-interview-data-btn",
    },
    {
      name: EDocumentActionMenuItemKey.TransferOwner,
      icon: EIcon.TransferOwnership,
      text: "Transfer ownership",
      onClick: handleTransferOwnership,
      access: cAccessType.Inaccessible,
      testId: "transfer-ownership-btn",
    },
    {
      name: EDocumentActionMenuItemKey.AddNote,
      icon: EIcon.AddNote,
      text: "Add note",
      onClick: handleAddNote,
      access: cAccessType.Inaccessible,
      testId: "add-note-btn",
    },
    {
      name: EDocumentActionMenuItemKey.AddEvent,
      icon: EIcon.AddEvent,
      text: "Add event",
      onClick: handleAddEvent,
      access: cAccessType.Inaccessible,
    },
    {
      name: EDocumentActionMenuItemKey.AttachSuportingDocument,
      icon: EIcon.Attachment,
      text: "Attach a supporting file",
      onClick: handleAttachSupportingDoc,
      access: cAccessType.Inaccessible,
    },
    {
      name: EDocumentActionMenuItemKey.ChangeStage,
      icon: EIcon.Stages,
      text: "Change stage",
      onClick: handleChangeStage,
      access: cAccessType.Inaccessible,
      testId: "change-stage-btn",
    },
    {
      name: EDocumentActionMenuItemKey.Terminate,
      icon: EIcon.DocumentTerminated,
      text: "Terminate document",
      onClick: handleTerminateDocument,
      access: cAccessType.Inaccessible,
    },
    {
      name: EDocumentActionMenuItemKey.RenameDocument,
      icon: EIcon.Rename,
      text: "Rename document",
      onClick: handleRenameDocument,
      access: cAccessType.Inaccessible,
    },
    {
      name: EDocumentActionMenuItemKey.Clone,
      icon: EIcon.Clone,
      text: "Clone document",
      onClick: handleCloneDocument,
      access: cAccessType.Inaccessible,
    },
  ];

  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  /**
   * Updates the menu items access using the action object
   */
  function updateMenuItemAccess() {
    forOwn(action, (value: { state: cAccessType; tooltip?: string }, key: string) => {
      setMenuItems((prev: any[]) => {
        const newState = [...prev];
        const item = newState.find((i) => i.name === key);
        if (item) {
          item.access = value.state;

          // If there is a tooltip from the back end, set it as the disabled reason
          if ("tooltip" in value) {
            item.disabledReason = value.tooltip;
          }
        }
        return newState;
      });
    });
  }

  useEffect(() => {
    updateMenuItemAccess();
  }, [action]);

  return (
    <>
      <StatusRow termination={termination} isSigned={isSigned} signedDate={signedDate} />
      <LinkList items={menuItems} testId={testId} />
    </>
  );
}

export default Contents;
