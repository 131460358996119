import { cThemeColorType } from "../../app/constants";
import Button from "../Button/Button";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../Modal/Modal";
import Typography from "../Typography/Typography";

interface IDiscardChangesModal {
  isOpen: boolean;
  handleClose: (canDiscard: boolean) => void;
  isLoading?: boolean;
  portalClassName?: string;
}

/**
 * The discard changes modal
 * @param isOpen           Is the modal open?
 * @param handleClose      Function to close the modal
 * @param isLoading        Is the API call being run?
 * @param portalClassName  The portal class name (if required)
 * @returns JSX.Element
 */
function DiscardChangesModal({ isOpen, handleClose, isLoading, portalClassName }: IDiscardChangesModal) {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      handleClose={() => handleClose(false)}
      testId="discard-changes-modal"
      portalClassName={portalClassName}
    >
      <ModalHeader>
        <ModalTitle>Discard changes?</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>Any changes you have made will be lost.</Typography>
      </ModalContent>
      <ModalActions>
        <Button
          color={cThemeColorType.Secondary}
          onClick={() => handleClose(false)}
          testId="discard-changes-modal-close-button"
        >
          Go back
        </Button>
        <Button onClick={() => handleClose(true)} isLoading={isLoading} testId="discard-changes-modal-confirm-button">
          Discard changes
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default DiscardChangesModal;
