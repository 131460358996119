import classNames from "classnames";
import { format } from "date-fns";
import { memo, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { cMonoColorType, cSizeType, cStatusType, cThemeColorType } from "../../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { EPopoverPlacement } from "../../../../../app/types";
import Button, { cButtonType, EButtonVariant } from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../components/FormField/FormField";
import Container from "../../../../../components/Grid/Container/Container";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Popover from "../../../../../components/Popover/Popover";
import Column from "../../../../../components/Row/Column/Column";
import Row from "../../../../../components/Row/Row";
import Spinner from "../../../../../components/Spinner/Spinner";
import Typography from "../../../../../components/Typography/Typography";
import {
  EReportCodes,
  IPackagedReport,
  IPackagedReportFilters,
  postRunPackagedReport,
  selectPackagedReportFiltersStatus,
  selectRunPackagedReportStatus,
} from "../../../../../modules/analyticsSlice";
import { errorToast } from "../../../../../toast/toast";
import { downloadBase64File } from "../../../../../utils/download/download";
import { isNotFoundError } from "../../../../../utils/errors/errors";
import styles from "../../Analytics.module.scss";

interface IPackagedReportProps {
  code: EReportCodes;
  name: IPackagedReport["name"];
  reportFilters?: IPackagedReportFilters;
  documentTypeFilterOptions: IFieldOption[];
  ownerFilterOptions: IFieldOption[];
  stageFilterOptions: IFieldOption[];
  stateFilterOptions: IFieldOption[];
  terminatedStateID: number;
}

export interface Inputs {
  date: string;
  documentClass: string | null;
  documentTypeIDs?: string[];
  description: string | null;
  descriptionCaseSensitive: boolean;
  documentData: string | null;
  documentDataCaseSensitive: boolean;
  stateIDs?: string[] | null;
  includeTerminatedID: number | null;
  stageIDs?: string[];
  ownerIDs?: string[];
  selectAllDocumentTypes: string | boolean;
  selectAllOwners: string | boolean;
  selectAllStages: string | boolean;
  dateFilter: string;
  startDate: string;
  endDate: string;
}

/**
 * The Packaged Report component
 * @param reportFilters             The packaged report filters
 * @param code                      The packaged report code
 * @param name                      The packaged report name
 * @param documentTypeFilterOptions The document type filter options
 * @param ownerFilterOptions        The owner filter options
 * @param stageFilterOptions        The stage filter options
 * @param stateFilterOptions        The state filter options
 * @param terminatedStateID         The terminated state ID
 * @returns JSX.Element
 */

const PackagedReport = memo(function PackagedReport({
  reportFilters,
  code,
  name,
  documentTypeFilterOptions,
  ownerFilterOptions,
  stageFilterOptions,
  stateFilterOptions,
  terminatedStateID,
}: IPackagedReportProps): JSX.Element {
  const dispatch = useAppDispatch();
  const runPackagedReportStatus = useAppSelector(selectRunPackagedReportStatus);
  const filtersStatus = useAppSelector(selectPackagedReportFiltersStatus);

  // Default values for the form
  const defaultValues = useMemo(() => {
    return {
      selectAllDocumentTypes: "allDocumentTypes",
      selectAllOwners: "allOwners",
      selectAllStages: "allStages",
      date: "",
      documentClass: "All",
      documentTypeIDs: reportFilters?.[code]?.optionalFilters.documentTypeIDs.map(String),
      description: null,
      descriptionCaseSensitive: false,
      documentData: null,
      documentDataCaseSensitive: false,
      stateIDs: reportFilters?.[code]?.optionalFilters.stateIDs.map(String),
      stageIDs: reportFilters?.[code]?.optionalFilters.stageIDs.map(String),
      ownerIDs: reportFilters?.[code]?.optionalFilters.ownerIDs.map(String),
      dateFilter: "Last Modified Date",
      startDate: "",
      endDate: "",
    };
  }, [reportFilters, code]);

  const {
    register, // Register prop for form inputs
    handleSubmit, // Submit handler wrapper
    reset, // Reset form function
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues,
  });

  // watch selected values for "select all" options
  const values = watch();
  const startDate = values.startDate;
  const endDate = values.endDate;
  const [stateFilters, setStateFilters] = useState<IFieldOption[]>();
  const formStateIDs = values.stateIDs;
  const stateIDs = stateFilters?.filter((state) => state.label !== "Include Terminated").reverse();
  const [includeTerminatedIDs, setIncludeTerminatedIDs] = useState<IFieldOption[]>([]);
  const [isTerminatedHidden, setIsTerminatedHidden] = useState<boolean>(true);

  useEffect(() => {
    // Set terminated state id to default the checkbox to true
    if (terminatedStateID) {
      setValue("includeTerminatedID", terminatedStateID);
    }
  }, [terminatedStateID]);

  useEffect(() => {
    // Set state filters
    setStateFilters(stateFilterOptions);
  }, [stateFilterOptions]);

  useEffect(() => {
    // Set terminated hidden state
    if (stateFilters && stateFilters.length > 0) {
      const terminatedShouldHide = !formStateIDs || formStateIDs.length === 0;
      setIsTerminatedHidden(terminatedShouldHide);

      // Reset terminated to selected on hide
      if (terminatedShouldHide) {
        setValue("includeTerminatedID", terminatedStateID);
      }

      const newStateFilters = stateFilters;
      const includeTerminated = newStateFilters.find((filter) => filter.label === "Include Terminated") as IFieldOption;
      setStateFilters(newStateFilters);
      setIncludeTerminatedIDs(includeTerminated ? [includeTerminated] : []);
    }
  }, [formStateIDs, stateFilters]);

  /**
   * Handle the "select all" options
   * @param fieldName       The name of the field to update
   * @param selectAllValue  The value of the "select all" option
   * @param itemIds         The item IDs to select
   */
  function handleSelectAll(fieldName: keyof Inputs, selectAllValue: string | boolean, itemIds?: number[]) {
    if (selectAllValue !== false && itemIds) {
      setValue(fieldName, itemIds.map(String));
    } else {
      setValue(fieldName, []);
    }
  }

  useEffect(() => {
    // Set up a subscription to watch for form changes
    const subscription = watch((value, { name, type }) => {
      // Only update on change events as others can be automatically triggered
      if (type === "change") {
        switch (true) {
          // Handle "select all" checkbox functionality for document types - check all document types if "All" is selected
          case name === "selectAllDocumentTypes":
            handleSelectAll(
              "documentTypeIDs",
              value.selectAllDocumentTypes as string,
              reportFilters?.[code]?.optionalFilters.documentTypeIDs?.map(Number),
            );
            break;
          // Set the "select all" checkbox state based on whether all document types are selected
          case name === "documentTypeIDs":
            // Update "select all" state based on whether all document types are selected
            setValue(
              "selectAllDocumentTypes",
              value.documentTypeIDs?.length === documentTypeFilterOptions.length ? "allDocumentTypes" : false,
            );
            break;
          // Handle "select all" checkbox functionality for owners - check all owners if "All" is selected
          case name === "selectAllOwners":
            handleSelectAll(
              "ownerIDs",
              value.selectAllOwners as string,
              reportFilters?.[code]?.optionalFilters.ownerIDs?.map(Number),
            );
            break;
          // Set the "select all" checkbox state based on whether all owners are selected
          case name === "ownerIDs":
            // Update "select all" state based on whether all owners are selected
            setValue("selectAllOwners", value.ownerIDs?.length === ownerFilterOptions.length ? "allOwners" : false);
            break;
          // Handle "select all" checkbox functionality for stages - check all stages if "All" is selected
          case name === "selectAllStages":
            handleSelectAll(
              "stageIDs",
              value.selectAllStages as string,
              reportFilters?.[code]?.optionalFilters.stageIDs?.map(Number),
            );
            break;
          case name === "stageIDs":
            // Update "select all" state based on whether all stages are selected
            setValue("selectAllStages", value.stageIDs?.length === stageFilterOptions.length ? "allStages" : false);
            break;
          default:
            break;
        }
      }
    });

    // Clean up the subscription when the component unmounts
    return () => subscription.unsubscribe();
  }, [watch, reportFilters]);

  // When the default values change it means that we have moved to
  // anoher report so we need to reset the form values to the default values
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  // Wrap options in a scrollable div if options are more than max number
  const maxOptions = 8;

  /**
   * Submit the form
   * @param data The form values on submit
   */
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // Destructure fields related to optionalFilters to group them separately
    // Exclude 'Select all' logic from the the data as it is for UI control only
    const {
      date,
      documentTypeIDs,
      description,
      descriptionCaseSensitive,
      documentData,
      documentDataCaseSensitive,
      stateIDs,
      includeTerminatedID,
      stageIDs,
      ownerIDs,
      dateFilter,
      startDate,
      endDate,
    } = data;

    // Convert data before dispatching
    const formattedDate = data.date ? format(new Date(data.date), "yyyy-MM-dd") : "";
    const formattedStartDate = data.startDate ? format(new Date(data.startDate), "yyyy-MM-dd") : "";
    const formattedEndDate = data.endDate ? format(new Date(data.endDate), "yyyy-MM-dd") : "";

    // Ensure that "All" is converted to null for the payload
    let includeAllDocumentClasses = data.documentClass;
    if (includeAllDocumentClasses === "" || includeAllDocumentClasses === "All") {
      includeAllDocumentClasses = null;
    }

    const documentTypeIDsAsNumbers = documentTypeIDs ? documentTypeIDs.map((documentType) => Number(documentType)) : [];

    let stateIDsAsNumbers = stateIDs ? stateIDs.map(Number) : [];

    // Conditionally include the terminatedStateID based on the includeTerminatedID option
    if (includeTerminatedID) {
      stateIDsAsNumbers = [...stateIDsAsNumbers, terminatedStateID];
    } else {
      stateIDsAsNumbers = stateIDsAsNumbers.filter((id) => id !== terminatedStateID);
    }

    const ownerIDsAsNumbers = ownerIDs ? ownerIDs.map((owner) => Number(owner)) : [];
    const stageIDsAsNumbers = stageIDs ? stageIDs.map((stageIDs) => Number(stageIDs)) : [];
    const descriptionContainsCaseSensitive = Boolean(descriptionCaseSensitive);
    const documentDataContainsCaseSensitive = Boolean(documentDataCaseSensitive);

    dispatch(
      postRunPackagedReport({
        [code as EReportCodes]: {
          // include date only for reports that require it
          ...(date && code === EReportCodes.Osr008
            ? {
                date: formattedDate,
              }
            : {}),
          // include dateFilter only for reports that require it
          ...(dateFilter &&
          (code === EReportCodes.Osr001 ||
            code === EReportCodes.Osr002 ||
            code === EReportCodes.Osr003 ||
            code === EReportCodes.Osr004 ||
            code === EReportCodes.Osr005)
            ? {
                dateFilter: dateFilter,
              }
            : {}),
          // include date range only for reports that require it
          ...(startDate &&
          endDate &&
          (code === EReportCodes.Osr001 ||
            code === EReportCodes.Osr002 ||
            code === EReportCodes.Osr003 ||
            code === EReportCodes.Osr004 ||
            code === EReportCodes.Osr005 ||
            code === EReportCodes.Osr006 ||
            code === EReportCodes.Osr007 ||
            code === EReportCodes.Osr009 ||
            code === EReportCodes.Osr010)
            ? {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
              }
            : {}),
          // include common and optional filters for all reports
          documentClass: includeAllDocumentClasses,
          optionalFilters: {
            documentTypeIDs: documentTypeIDsAsNumbers,
            stateIDs: stateIDsAsNumbers,
            ownerIDs: ownerIDsAsNumbers,
            stageIDs: stageIDsAsNumbers,
            descriptionContains: {
              description,
              caseSensitive: descriptionContainsCaseSensitive,
            },
            documentDataContains: {
              documentData,
              caseSensitive: documentDataContainsCaseSensitive,
            },
          },
        },
      }),
    )
      .then((action) => {
        const response = action.payload; // Access the payload
        const { fileData, fileName } = response; // Get the file name and data from response
        downloadBase64File({ fileData, fileName });
      })
      .catch((error) => {
        const errorCode = parseInt(error, 10);

        if (isNotFoundError(errorCode)) {
          errorToast(`${errorCode}: Report not found`);
        } else {
          errorToast(error);
        }
      });
  };

  /**
   * Date filter field for the selected report
   * @param label The field label text
   * @returns JSX.Element
   */
  const renderDateFormField = (label: string) => (
    <Div className={classNames(styles.formRow, styles.justified)} testId="date-filter">
      <label htmlFor="date" className={styles.label}>
        {label}
      </label>
      <FormField
        name="date"
        register={register}
        type={cFormFieldType.DatePicker}
        error={errors.date}
        required
        testId="date-picker"
      />
    </Div>
  );

  /**
   * Date filter type field for the selected report
   * @returns JSX.Element
   */
  const renderDateFilterTypeFormFields = () => (
    <Div className={classNames(styles.formRow, styles.justified)} testId="date-filter-type">
      <label htmlFor="dateFilter" className={styles.label}>
        Use
      </label>
      <FormField
        name="dateFilter"
        register={register}
        type={cFormFieldType.Buttons}
        options={[
          { label: "Original Creation Date", value: "Original Creation Date" },
          { label: "Last Modified Date", value: "Last Modified Date" },
        ]}
        defaultValue="Last Modified Date"
        testId="date-filter-type-button-group"
      />
    </Div>
  );

  /**
   * Date range filter fields for selected reports
   * @param label The form field label text
   * @returns JSX.Element
   */
  const renderDateRangeFormFields = (label: string) => (
    <Div className={classNames(styles.formRow, styles.justified)} testId="date-range-filter">
      <label htmlFor="startDate" className={styles.label}>
        {label}
      </label>
      <Div display={{ base: "flex" }} flexDirection={{ base: "column", sm: "row" }}>
        <FormField
          name="startDate"
          label="Start"
          register={register}
          type={cFormFieldType.DatePicker}
          error={errors.startDate}
          required
          maxDate={endDate ? new Date(endDate) : new Date()}
          testId="start-date-picker"
          spacing={{ mr: 7 }}
        />
        <FormField
          name="endDate"
          label="End"
          register={register}
          type={cFormFieldType.DatePicker}
          error={errors.endDate}
          required
          minDate={startDate ? new Date(startDate) : undefined}
          testId="end-date-picker"
        />
      </Div>
    </Div>
  );

  /**
   * Common form fields for all reports
   * @returns JSX.Element
   */
  const renderCommonFormFields = () => (
    <Div className={classNames(styles.formRow, styles.justified)} testId="document-class-filter">
      <label htmlFor="documentClass" className={styles.label}>
        Document class
      </label>
      <FormField
        name="documentClass"
        register={register}
        type={cFormFieldType.Buttons}
        options={[
          { label: "Draft", value: "draft" },
          { label: "File", value: "file" },
          { label: "All", value: "All" },
        ]}
        defaultValue="All"
        testId="document-class-button-group"
      />
    </Div>
  );

  /**
   * Common optional filter form fields for all reports
   * @returns JSX.Element
   */
  const renderCommonOptionalFilterFormFields = () => (
    <>
      <Div className={styles.formRowHeader}>
        <Typography variant="h4" spacing={{ mb: 0, pt: 4 }}>
          Optional filters
        </Typography>
      </Div>
      {documentTypeFilterOptions ? (
        documentTypeFilterOptions.length > 0 ? (
          <Div className={styles.formRow} testId="document-type-filter">
            <label htmlFor="documentTypeIDs" className={styles.label}>
              Document Type
            </label>
            <FormField
              type={cFormFieldType.Checkbox}
              name="selectAllDocumentTypes"
              options={[{ label: "All", value: "allDocumentTypes" }]}
              register={register}
              spacing={{ mb: 6 }}
            />
            <Div className={documentTypeFilterOptions.length > maxOptions ? styles.scrollBox : ""}>
              <FormField
                type={cFormFieldType.Checkbox}
                name="documentTypeIDs"
                options={
                  documentTypeFilterOptions
                    ? documentTypeFilterOptions.map((documentType, index) => ({
                        label: documentType.label,
                        value: documentType.value,
                        key: `documentType_${index}`,
                      }))
                    : []
                }
                register={register}
                testId="document-type-options"
              />
            </Div>
          </Div>
        ) : (
          <Spinner size={cSizeType.Small} />
        )
      ) : null}

      <Div className={styles.formRow} testId="description-filter">
        <Row>
          <Column sm={7}>
            <Div className={styles.labelWithPopover}>
              <label htmlFor="description">Document name contains{"\u00A0"}</label>
              <Popover
                popoverPlacement={EPopoverPlacement.Top}
                popoverContents="Include only documents containing this text as part of their name."
                buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
                buttonProps={{ variant: EButtonVariant.Link }}
                className={styles.popover}
              />
            </Div>
            <FormField
              name="description"
              register={register}
              data-lpignore={true}
              append={
                <FormField
                  spacing={{ ml: 5 }}
                  name="descriptionCaseSensitive"
                  register={register}
                  type={cFormFieldType.Switch}
                  options={[
                    {
                      label: "Case sensitive",
                      value: "false",
                    },
                  ]}
                  testId="document-description-case-sensitive-switch"
                />
              }
              testId="description-contains-input"
              fullWidth
            />
          </Column>
        </Row>
      </Div>

      <Div className={styles.formRow} testId="document-data-filter">
        <Row>
          <Column sm={7}>
            <label htmlFor="documentData" className={styles.labelWithPopover}>
              Document data contains{"\u00A0"}
              <Popover
                popoverPlacement={EPopoverPlacement.Top}
                popoverContents="Include only items containing this text as part of their interview."
                buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
                buttonProps={{ variant: EButtonVariant.Link }}
                className={styles.popover}
              />
            </label>
            <FormField
              name="documentData"
              register={register}
              data-lpignore={true}
              append={
                <FormField
                  spacing={{ ml: 5 }}
                  name="documentDataCaseSensitive"
                  register={register}
                  type={cFormFieldType.Switch}
                  options={[
                    {
                      label: "Case sensitive",
                      value: "false",
                    },
                  ]}
                  testId="document-data-contains-case-sensitive-switch"
                />
              }
              testId="document-data-contains-input"
              fullWidth
            />
          </Column>
        </Row>
      </Div>

      <Div className={styles.formRow} testId="document-stage-filter">
        <label htmlFor="stageIDs" className={styles.label}>
          Document stages
        </label>
        <FormField
          type={cFormFieldType.Checkbox}
          name="selectAllStages"
          options={[{ label: "All", value: "allStages" }]}
          register={register}
          spacing={{ mb: 6 }}
        />
        <Div className={stageFilterOptions.length > maxOptions ? styles.scrollBox : ""}>
          <FormField
            type={cFormFieldType.Checkbox}
            name="stageIDs"
            options={
              stageFilterOptions
                ? stageFilterOptions.map((stage, index) => ({
                    label: stage.label,
                    value: stage.value,
                    key: `stage_${index}`,
                  }))
                : []
            }
            register={register}
            testId="document-stage-options"
          />
        </Div>
      </Div>

      <Div className={styles.formRow} testId="document-state-filter">
        <label htmlFor="stateIDs" className={styles.label}>
          Status
        </label>
        {stateIDs && (
          <FormField
            type={cFormFieldType.Checkbox}
            name="stateIDs"
            options={
              stateFilterOptions
                ? stateFilterOptions
                    .filter((state) => state.label !== "Include Terminated")
                    .map((state, index) => ({
                      label: state.label,
                      value: state.value,
                      key: `state_${index}`,
                    }))
                : []
            }
            testId="document-state-options"
            register={register}
          />
        )}
        {includeTerminatedIDs && (
          <Div spacing={{ pt: 5 }} className={(isTerminatedHidden && styles.hidden) || ""}>
            <FormField
              type={cFormFieldType.Checkbox}
              name="includeTerminatedID"
              options={includeTerminatedIDs}
              register={register}
            />
          </Div>
        )}
      </Div>

      <Div className={styles.formRow} testId="owner-filter">
        <label htmlFor="ownerIDs" className={styles.label}>
          Owners
        </label>
        <FormField
          type={cFormFieldType.Checkbox}
          name="selectAllOwners"
          options={[{ label: "All", value: "allOwners" }]}
          register={register}
          spacing={{ mb: 6 }}
        />
        <Div className={ownerFilterOptions.length > maxOptions ? styles.scrollBox : ""}>
          <FormField
            type={cFormFieldType.Checkbox}
            name="ownerIDs"
            options={
              ownerFilterOptions
                ? ownerFilterOptions.map((owner, index) => ({
                    label: owner.label,
                    value: owner.value,
                    key: `owner_${index}`,
                  }))
                : []
            }
            register={register}
            testId="owner-options"
          />
        </Div>
      </Div>
    </>
  );

  {
    /* Render the filters for the selected report */
  }
  return (
    <Container
      className={styles.reportContainer}
      width="lg"
      mx={{ base: 0 }}
      pb={{ base: 5 }}
      bgColor={cMonoColorType.White}
    >
      {filtersStatus !== cStatusType.Loading ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Report header */}
          <Div
            border={{ bb: true }}
            className={styles.actionBar}
            display={{ base: "flex" }}
            alignItems={{ base: "center" }}
            justifyContent={{ base: "space-between" }}
          >
            <Typography variant="h4" spacing={{ mb: 0 }} testId="packaged-report-heading">
              {name}
            </Typography>
            <Div justifySelf={{ base: "end" }}>
              <Button
                type={cButtonType.SubmitType}
                isLoading={runPackagedReportStatus === cStatusType.Loading}
                testId="run-report-button"
              >
                Run report
              </Button>
            </Div>
          </Div>

          {/* Switch form fields based on report code */}
          {(() => {
            switch (code) {
              case EReportCodes.Osr001:
                return (
                  <>
                    {renderDateFilterTypeFormFields()}
                    {renderDateRangeFormFields("Within date range")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr002:
                return (
                  <>
                    {renderDateFilterTypeFormFields()}
                    {renderDateRangeFormFields("Within date range")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr003:
                return (
                  <>
                    {renderDateFilterTypeFormFields()}
                    {renderDateRangeFormFields("Within date range")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr004:
                return (
                  <>
                    {renderDateFilterTypeFormFields()}
                    {renderDateRangeFormFields("Within date range")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr005:
                return (
                  <>
                    {renderDateFilterTypeFormFields()}
                    {renderDateRangeFormFields("Within date range")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr006:
                return (
                  <>
                    {renderDateRangeFormFields("Contracts expiring within the period")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr007:
                return (
                  <>
                    {renderDateRangeFormFields("Includes contracts with Escalation dates that fall within")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr008:
                return (
                  <>
                    {renderDateFormField("Includes indefinite contracts, contracts expiring after date")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr009:
                return (
                  <>
                    {renderDateRangeFormFields(
                      "Includes contracts with Condition Precedent deadlines that fall within",
                    )}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );
              case EReportCodes.Osr010:
                return (
                  <>
                    {renderDateRangeFormFields("Includes contracts with Renewal deadlines that fall within")}
                    {renderCommonFormFields()}
                    {renderCommonOptionalFilterFormFields()}
                  </>
                );

              default:
                return <></>;
            }
          })()}
        </form>
      ) : (
        <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
          <Spinner />
        </Div>
      )}
    </Container>
  );
});

export default PackagedReport;
