import { find, map } from "lodash";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Outlet, useNavigate } from "react-router-dom";
import { cRouteType, cSizeType, cStatusType } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import InformationUpdatedModal from "../../components/InformationUpdatedModal/InformationUpdatedModal";
import Spinner from "../../components/Spinner/Spinner";
import { selectDocuments, selectIsInformationUpdatedModalOpen } from "../../modules/documentsSlice";
import { selectDocumentTypes } from "../../modules/documentTypesSlice";
import { selectMatterDocuments } from "../../modules/matterDocumentsSlice";
import { selectMatterDocumentTypes } from "../../modules/matterDocumentTypesSlice";
import { selectMatters } from "../../modules/mattersSlice";
import { selectMatterTypes } from "../../modules/matterTypesSlice";
import {
  getOverdueNotification,
  getPendingNotification,
  getTaskNotification,
  INotificationsEntry,
  postDeleteNotification,
  resetOverdueNotificationsState,
  resetPendingNotificationsState,
  resetTaskNotificationsState,
  selectOverdueLoadingStatus,
  selectOverdueNotifications,
  selectPendingEntriesLoadingStatus,
  selectPendingNotifications,
  selectTaskEntriesLoadingStatus,
  selectTaskNotifications,
} from "../../modules/notificationsSlice";
import { selectWalkIsActive } from "../../modules/walkSlice";
import ManageCollaborationModalContainer from "../DocumentsContainer/Documents/ManageCollaborationModalContainer/ManageCollaborationModalContainer";
import DeleteNotificationConfirmationModal from "./Notifications/DeleteNotificationConfirmationModal/DeleteNotificationConfirmationModal";
import Notifications from "./Notifications/Notifications";

/**
 * The notification types
 */
export enum ENotificationType {
  Pending = "pending",
  Overdue = "overdue",
  Task = "task",
}

export enum NotificationKey {
  Document = "document",
  DocumentType = "documentType",
  Matter = "matter",
  MatterType = "matterType",
  MatterDocuments = "matterDocuments",
  Task = "task",
  InterviewSpawn = "interviewSpawn",
}

/**
 * The Notifications page
 * @returns JSX.Element
 */
function NotificationsContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const pendingNotifications = useAppSelector(selectPendingNotifications);
  const overdueNotifications = useAppSelector(selectOverdueNotifications);
  const taskNotifications = useAppSelector(selectTaskNotifications);
  const pendingEntriesLoadingStatus = useAppSelector(selectPendingEntriesLoadingStatus);
  const overdueLoadingStatus = useAppSelector(selectOverdueLoadingStatus);
  const taskEntriesLoadingStatus = useAppSelector(selectTaskEntriesLoadingStatus);
  const documents = useAppSelector(selectDocuments);
  const documentTypes = useAppSelector(selectDocumentTypes);
  const matters = useAppSelector(selectMatters);
  const matterTypes = useAppSelector(selectMatterTypes);
  const matterDocuments = useAppSelector(selectMatterDocuments);
  const matterDocumentTypes = useAppSelector(selectMatterDocumentTypes);
  const isInformationUpdatedModalOpen = useAppSelector(selectIsInformationUpdatedModalOpen);
  const [inViewPendingRef, inViewPending] = useInView();
  const [inViewOverdueRef, inViewOverdue] = useInView();
  const [inViewTaskRef, inViewTask] = useInView();
  const [isDeleteNotificationModalOpen, setIsDeleteNotificationModalOpen] = useState(false);
  const [notificationType, setNotificationType] = useState<ENotificationType | null>(null);
  const [notification, setNotification] = useState<INotificationsEntry | undefined>(undefined);
  const walkIsActive = useAppSelector(selectWalkIsActive); // Get walk state
  const navigate = useNavigate(); // History object for handling redirect

  useEffect(() => {
    // If walk is active, redirect to the walk view
    if (walkIsActive) {
      navigate(`/${cRouteType.Walk}`);
    }
  }, [walkIsActive]);

  /**
   * Dispatches an action to reset the pending notifications state
   */
  function resetPendingNotifications() {
    dispatch(resetPendingNotificationsState());
  }

  /**
   * Dispatches an action to reset the overdue notifications state
   */
  function resetOverdueNotifications() {
    dispatch(resetOverdueNotificationsState());
  }

  /**
   * Dispatches an action to reset the task notifications state
   */
  function resetTaskNotifications() {
    dispatch(resetTaskNotificationsState());
  }

  /**
   * Handles deleting a notification
   * @param id The notification id to delete
   * @param notificationType The notification type
   */
  function handleDeleteNotification(id: number, notificationType: ENotificationType) {
    setNotificationType(notificationType);
    let notification;
    if (notificationType === ENotificationType.Pending) {
      notification = find(pendingNotifications, (n) => n.id === id);
    } else if (notificationType === ENotificationType.Overdue) {
      notification = find(overdueNotifications, (n) => n.id === id);
    } else if (notificationType === ENotificationType.Task) {
      notification = find(taskNotifications, (n) => n.id === id);
    }
    if (notification) {
      setNotification(notification);
      setIsDeleteNotificationModalOpen(true);
    }
  }

  /**
   * Handles delete notification modal close
   * @param canDelete Can the notification be deleted?
   */
  async function handleDeleteNotificationModalClose(canDelete: boolean) {
    setIsDeleteNotificationModalOpen(false);
    if (canDelete && notification && notificationType) {
      try {
        await dispatch(
          postDeleteNotification({ notificationID: notification.id, notificationType: notificationType }),
        ).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
    setNotification(undefined);
    setNotificationType(null);
  }

  useEffect(() => {
    if (inViewPending && pendingEntriesLoadingStatus !== cStatusType.Loading) {
      dispatch(getPendingNotification()); // Load notifications when react-intersection-observer is in view
    }
  }, [inViewPending]);

  useEffect(() => {
    if (inViewOverdue && overdueLoadingStatus !== cStatusType.Loading) {
      dispatch(getOverdueNotification()); // Load overdue notifications when react-intersection-observer is in view
    }
  }, [inViewOverdue]);

  useEffect(() => {
    if (inViewTask && taskEntriesLoadingStatus !== cStatusType.Loading) {
      dispatch(getTaskNotification()); // Load task notifications when react-intersection-observer is in view
    }
  }, [inViewTask]);

  useEffect(() => {
    if (overdueLoadingStatus !== cStatusType.Loading) {
      dispatch(getOverdueNotification()); // Load overdue notifications
    }
    return () => {
      resetPendingNotifications(); // Reset notifications when navigating away
      resetOverdueNotifications(); // Reset overdue notifications when navigating away
      resetTaskNotifications(); // Reset task notifications when navigating away
    };
  }, []);

  /**
   * Return a structured collection of notification entries suited for rendering
   * @param notifications Record set of notification entries
   * @returns Array
   */
  function getStructuredEntries(notifications: Record<string, INotificationsEntry>) {
    return map(notifications, (entry: INotificationsEntry) => {
      const document =
        documents[entry.transaction?.document?.id as number] ||
        matterDocuments[entry.transaction?.document?.groupingID as number]?.documents?.[
          entry.transaction?.document?.id as number
        ];

      let matter;
      if (entry.transaction?.hasOwnProperty("matter")) {
        matter = matters[entry.transaction?.matter?.id as number];
      } else {
        matter = matters[entry.transaction?.interviewSpawn?.matterID as number];
      }

      const interviewSpawn = entry.transaction?.interviewSpawn?.id as number;
      const canView = entry.transaction?.document?.canViewPrivilege;
      const canViewMatter = entry.transaction?.matter?.canViewPrivilege;
      const urn = entry.transaction?.document?.id;
      const status = entry.status;
      const invalidCode = entry.invalidCode;
      const assignedTo = entry.transaction?.interviewSpawn?.assignedTo as number;

      return {
        ...entry,
        assignedTo,
        matter,
        interviewSpawn,
        document,
        canView,
        canViewMatter,
        urn,
        status,
        invalidCode,
        deadlineDTM: new Date(entry.deadlineDTM),
        documentDescription: document?.description || matter?.description || "",
        matterDescription: matter?.description,
        matterType: matterTypes[matter?.matterType?.id as number]?.name,
        documentType: documentTypes[document?.documentType?.id as number]?.name ||
          matterDocumentTypes[document?.documentType?.id as number]?.name || <Spinner size={cSizeType.Small} />,
      };
    });
  }

  const listEntries = getStructuredEntries(pendingNotifications);
  const overdueEntries = getStructuredEntries(overdueNotifications);
  const taskEntries = getStructuredEntries(taskNotifications);

  return (
    <>
      <Notifications
        pendingEntries={listEntries}
        overdueEntries={overdueEntries}
        taskEntries={taskEntries}
        inViewOverdueRef={inViewOverdueRef}
        inViewPendingRef={inViewPendingRef}
        inViewTaskRef={inViewTaskRef}
        pendingEntriesLoadingStatus={pendingEntriesLoadingStatus}
        overdueLoadingStatus={overdueLoadingStatus}
        taskEntriesLoadingStatus={taskEntriesLoadingStatus}
        handleDeleteNotification={handleDeleteNotification}
      />

      {isDeleteNotificationModalOpen && notification && (
        <DeleteNotificationConfirmationModal
          isOpen={isDeleteNotificationModalOpen}
          handleClose={(canDelete: boolean) => handleDeleteNotificationModalClose(canDelete)}
          notification={notification}
        />
      )}
      <Outlet />
      <ManageCollaborationModalContainer />
      <InformationUpdatedModal isOpen={isInformationUpdatedModalOpen} />
    </>
  );
}

export default NotificationsContainer;
