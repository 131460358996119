import { Dispatch, SetStateAction, useEffect } from "react";
import { FieldError, useForm } from "react-hook-form";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../../components/FormField/FormField";
import { changeStageFormId } from "../ChangeStageModal";

interface IChangeStageModalForm {
  options: IFieldOption[];
  currentStage?: number;
  setFormIsChanged: Dispatch<SetStateAction<boolean>>;
  handleSetStage: (stageID: number) => void;
}

type Inputs = {
  stage: string;
};

/**
 * Change stage modal form
 * @param options          The options for the stage field
 * @param currentStage     The current stage ID
 * @param setFormIsChanged Set the form changed state
 * @param handleSetStage   Function to set/update the stage
 * @returns JSX.Element
 */
function ChangeStageModalForm({
  options,
  currentStage,
  setFormIsChanged,
  handleSetStage,
}: IChangeStageModalForm): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>({ defaultValues: { stage: String(currentStage) } });

  const { stage } = watch();

  useEffect(() => {
    // Set the form changed state
    setFormIsChanged(stage !== String(currentStage));
  }, [stage, currentStage]);

  /**
   * Handle form submission
   * @param stage The stage field value
   */
  const onSubmit = async ({ stage }: Inputs) => {
    handleSetStage(Number(stage));
  };

  return (
    <form id={changeStageFormId} onSubmit={handleSubmit(onSubmit)}>
      <FormField
        name="stage"
        register={register}
        type={cFormFieldType.Radio}
        error={errors.stage as FieldError}
        spacing={{ mb: 5 }}
        testId="change-stage-field"
        options={options}
        fullWidth
        required
      />
    </form>
  );
}

export default ChangeStageModalForm;
