import { sortBy } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { cStatusType } from "../../../../../app/constants";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Column from "../../../../../components/Row/Column/Column";
import Spinner from "../../../../../components/Spinner/Spinner";
import { IResource } from "../../../../../modules/matterResourcesSlice";
import { IMatterTaskEntry, ISpawnItem } from "../../../../../modules/matterTasksSlice";
import { IUser } from "../../../../../modules/usersSlice";
import { IPostMatterLaunchExternalResource, IPostMatterLaunchTask } from "../../../../../modules/walkSlice";
import MatterResources from "./MatterResources/MatterResources";
import styles from "./MatterTasksColumn.module.scss";
import MatterToDo from "./MatterToDo/MatterToDo";

export interface IMatterTasksColumn {
  tasks: IMatterTaskEntry;
  tasksError?: string;
  tasksStatus: cStatusType;
  externalResources?: Record<number, IResource & { documentType: string }>;
  externalResourcesError?: string;
  externalResourcesStatus: cStatusType;
  matterID: number;
  handleDraftDocument: ({ custWalkTypeID, matterID, spawnID }: IPostMatterLaunchTask) => void;
  handleLaunchExternalResource: ({ custWalkTypeID, matterID }: IPostMatterLaunchExternalResource) => void;
  taskAssignees?: IUser[];
  setSelectedTask: (task: Pick<ISpawnItem, "id" | "description" | "name" | "assigneeUserID">) => void;
  allowEmbed?: boolean;
  setEmbedData: Dispatch<
    SetStateAction<{ custWalkTypeID: number; matterID?: number; interviewSpawnID?: number } | null>
  >;
  setInvalidateData: Dispatch<
    SetStateAction<{
      interviewSpawnID: number;
      wipDTM?: string;
    } | null>
  >;
}

/**
 * Matter tasks and resources column on single matter page
 * @param tasks                        The list of tasks
 * @param tasksError                   API errors
 * @param tasksStatus                  API loading status
 * @param externalResources            The list of external resources
 * @param externalResourcesError       API errors
 * @param externalResourcesStatus      API loading status
 * @param matterID                     ID of wrapping matter
 * @param handleDraftDocument          Draft new spawn document
 * @param handleLaunchExternalResource Launch external resource walk
 * @param taskAssignees                The assignees for the tasks
 * @param setSelectedTask              Set the selected task for reassignment modal
 * @param allowEmbed                   Is embed allowed in the customer config?
 * @param setEmbedData                 Set the embed data for the send embed email notification modal
 * @param setInvalidateData            Set the invalidate data for the invalidate embed link modal
 * @returns JSX.Element
 */
function MatterTasksColumn({
  tasks,
  tasksError,
  tasksStatus,
  externalResources,
  externalResourcesError,
  externalResourcesStatus,
  matterID,
  handleDraftDocument,
  handleLaunchExternalResource,
  taskAssignees,
  setSelectedTask,
  allowEmbed,
  setEmbedData,
  setInvalidateData,
}: IMatterTasksColumn): JSX.Element {
  // Split spawns by to dos and resource items
  const todoItems = sortBy(
    tasks?.spawnItems?.filter((task) => task.isRequired === true),
    "position",
  );

  const matterItems = sortBy(
    tasks?.spawnItems?.filter((task) => task.isRequired === false),
    "position",
  );

  return (
    <Column all={12} md={3} className={styles.className}>
      {tasksError ? (
        <DisplayError>{tasksError}</DisplayError>
      ) : tasksStatus === cStatusType.Loading ? (
        <Spinner />
      ) : (
        <>
          <MatterToDo
            todoItems={todoItems}
            handleDraftDocument={handleDraftDocument}
            matterID={matterID}
            taskAssignees={taskAssignees}
            setSelectedTask={setSelectedTask}
            tasksError={tasksError}
            tasksStatus={tasksStatus}
            allowEmbed={allowEmbed}
            setEmbedData={setEmbedData}
            setInvalidateData={setInvalidateData}
          />
          <MatterResources
            matterItems={matterItems}
            handleDraftDocument={handleDraftDocument}
            matterID={matterID}
            tasksError={tasksError}
            tasksStatus={tasksStatus}
            externalResources={externalResources}
            externalResourcesError={externalResourcesError}
            externalResourcesStatus={externalResourcesStatus}
            handleLaunchExternalResource={handleLaunchExternalResource}
            setEmbedData={setEmbedData}
            allowEmbed={allowEmbed}
            setInvalidateData={setInvalidateData}
          />
        </>
      )}
    </Column>
  );
}

export default MatterTasksColumn;
