import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { cThemeColorType } from "../../../../../app/constants";
import Button, { cButtonType } from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import FormField, { cFormFieldType } from "../../../../../components/FormField/FormField";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";

/**
 * Form inputs
 */
type Inputs = {
  newTemplateName: string;
};

/**
 * Props for SaveAsTemplateModal component
 */
interface ISaveAsTemplateModalProps {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (data: Inputs) => void;
  error?: string;
}

/**
 * The Save as template modal component
 * @param isOpen                  Is the modal open
 * @param handleClose             Close the modal
 * @param onSubmit                The onsubmit handler
 * @param error                   Any errors
 * @returns JSX.Element
 */
function SaveAsTemplateModal({ isOpen, handleClose, onSubmit, error }: ISaveAsTemplateModalProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>();

  // Reset the form when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} testId="save-as-template-modal" size="sm">
      <ModalHeader>
        <ModalTitle>Save as new report template</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <form id="save-template-form" onSubmit={handleSubmit(onSubmit)}>
          <FormField
            label="Template name"
            name="newTemplateName"
            register={register}
            type={cFormFieldType.Text}
            error={errors.newTemplateName}
            spacing={{ mb: 5 }}
            testId="template-name-field"
            fullWidth
            required
          />
          {error && <DisplayError>{error}</DisplayError>}
        </form>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} color={cThemeColorType.Secondary} testId="cancel-button">
          Cancel
        </Button>
        <Button formId="save-template-form" type={cButtonType.SubmitType} testId="save-template-button">
          Save
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default SaveAsTemplateModal;
