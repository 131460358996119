import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/api";
import { matterReassignTaskUrl, matterTasksUrl } from "../app/apiUrls";
import { cAccessType, cStatusType } from "../app/constants";
import { RootState } from "../app/store";
import { IDocumentType } from "./documentTypesSlice";
import { EWalkType } from "./walkSlice";

/**
 * Describe a spawn item's state object
 */
interface ISpawnItemState {
  isLocked: boolean;
  lockedState: cAccessType;
  state: cAccessType;
  wipDTM?: string;
  wipUserID?: number;
  activeEmbedCreateDTM?: string;
}
/**
 * Describe a spawn item
 */
export interface ISpawnItem {
  id: number;
  name: string;
  description: string;
  isRequired: boolean;
  state: ISpawnItemState;
  walkType: EWalkType;
  custWalkTypeID: number;
  isLaunchExternally: boolean;
  isSingleUse: boolean;
  position: number;
  compositeState: number;
  assigneeUserID?: number;
  canReassign?: boolean;
}

/**
 * A matter task entry including spawn items and additional doc types
 */
export interface IMatterTaskEntry {
  spawnItems: ISpawnItem[]; // Spawn walk documents (to-dos and resources)
  additionalDocTypes: IDocumentType[]; // Additional external doc types that can be walked
}

/**
 * Describe the matter tasks state object
 */
interface IMatterTasksState {
  entries: Record<number, IMatterTaskEntry>; // Object keyed by parent matter
  status: cStatusType; // API call status
  error?: string;
  reassignStatus: cStatusType;
  reassignError?: string;
}

/**
 * Initial state
 */
const initialState: IMatterTasksState = {
  entries: {},
  status: cStatusType.Idle,
  reassignStatus: cStatusType.Idle,
};

/**
 * Thunk for fetching the timeline for a single matter by ID
 */
export const postMatterTasks = createAsyncThunk(
  "matterTasks/postMatterTasks",
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({ endpoint: matterTasksUrl, dispatch, body: { id } });
      return { id, tasks: response.data };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

type TPostMatterReassignTask = RequireAtLeastOne<{ taskID: number; assigneeUserID: number }>;

/**
 * Thunk for reassigning a task
 */
export const postMatterReassignTask = createAsyncThunk(
  "matters/postMatterReassignTask",
  async ({ taskID, assigneeUserID }: TPostMatterReassignTask, { dispatch, rejectWithValue }) => {
    const body = taskID ? { interviewSpawnID: taskID } : { userID: assigneeUserID };

    try {
      await api({
        endpoint: matterReassignTaskUrl,
        dispatch,
        body,
      });
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Create the state slice
 */
export const matterTasksSlice = createSlice({
  name: "matterTasks", // The name of the slice
  initialState, // Set the initialState
  reducers: {
    resetReassignError: (state) => {
      state.reassignError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postMatterTasks.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      .addCase(postMatterTasks.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      .addCase(postMatterTasks.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.entries = { ...state.entries, [action.payload.id]: action.payload.tasks };
      })
      .addCase(postMatterReassignTask.pending, (state) => {
        state.reassignStatus = cStatusType.Loading;
        state.reassignError = undefined;
      })
      .addCase(postMatterReassignTask.rejected, (state, action) => {
        state.reassignStatus = cStatusType.Failed;
        state.reassignError = action.payload as string;
      })
      .addCase(postMatterReassignTask.fulfilled, (state) => {
        state.reassignStatus = cStatusType.Idle;
      });
  },
});

export const { resetReassignError } = matterTasksSlice.actions;

export const selectMatterTasksStatus = (state: RootState) => state.matterTasks.status;
export const selectMatterTasksError = (state: RootState) => state.matterTasks.error;
export const selectMatterTasks = (state: RootState) => state.matterTasks.entries;
export const selectMatterReassignStatus = (state: RootState) => state.matterTasks.reassignStatus;
export const selectMatterReassignError = (state: RootState) => state.matterTasks.reassignError;

export default matterTasksSlice.reducer;
