import { cThemeColorType } from "../../../app/constants";
import Button from "../../../components/Button/Button";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import { EWalkStage } from "../../../modules/walkSlice";
import styles from "./ResumeWalkModal.module.scss";

interface IResumeWalkModal {
  continueWalk: () => void;
  discardWalk: () => void;
  stage?: EWalkStage;
  documentType?: string;
  isLoading: boolean;
}

/**
 * Modal alerting user to resume their active walk
 * @param continueWalk Function run on click of continue button
 * @param discardWalk  Function run on click of discard button
 * @param stage        The walk stage
 * @param discardWalk  The walk document type
 * @param isLoading    Is the API call being run?
 * @returns JSX.Element
 */
function ResumeWalkModal({ continueWalk, discardWalk, stage, documentType, isLoading }: IResumeWalkModal): JSX.Element {
  /**
   * Handle continuing a walk
   */
  function handleContinue() {
    continueWalk();
  }

  /**
   * Renders the modal body
   * @returns string
   */
  function renderModalBody() {
    switch (stage) {
      case EWalkStage.Events:
      case EWalkStage.Download:
        return <Typography>Resume {documentType}</Typography>;
      default:
        return (
          <>
            <Typography>
              You have an interview in progress (<b>{documentType}</b>).
            </Typography>
            <Typography>Would you like to continue or discard?</Typography>
          </>
        );
    }
  }

  /**
   * Renders the modal actions
   * @returns JSX.Element
   */
  function renderModalActions() {
    switch (stage) {
      case EWalkStage.Events:
      case EWalkStage.Download:
        return <Button onClick={handleContinue}>Resume</Button>;
      default:
        return (
          <>
            <Button onClick={discardWalk} color={cThemeColorType.Secondary} isLoading={isLoading}>
              Discard
            </Button>
            <Button testId="resume-walk-modal-continue-btn" onClick={handleContinue}>
              Continue
            </Button>
          </>
        );
    }
  }

  return (
    <Modal
      testId="resume-walk-modal"
      size={stage === EWalkStage.Events || stage === EWalkStage.Download ? "sm" : "md"}
      isOpen
      overlayClassName={styles.overlay}
    >
      <ModalHeader>
        <ModalTitle>In Progress Interview</ModalTitle>
      </ModalHeader>
      <ModalContent>{renderModalBody()}</ModalContent>
      <ModalActions>{renderModalActions()}</ModalActions>
    </Modal>
  );
}

export default ResumeWalkModal;
