import { format, parseISO, startOfYear } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cAccessType, cMonoColorType, cSizeType, cStatusType, cThemeColorType } from "../../../app/constants";
import { useAppDispatch } from "../../../app/hooks";
import { EEmptyType, EPopoverPlacement, IXDirection, TFilterType } from "../../../app/types";
import Button, { EButtonVariant } from "../../../components/Button/Button";
import DisplayError from "../../../components/DisplayError/DisplayError";
import Div from "../../../components/Div/Div";
import Drawer from "../../../components/Drawer/Drawer";
import DrawerRowWrapper from "../../../components/DrawerRowWrapper/DrawerRowWrapper";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { ETimelineType } from "../../../components/Events/TimelineEvents";
import { IFieldOption } from "../../../components/FormField/FormField";
import Icon, { EIcon } from "../../../components/Icon/Icon";
import Popover from "../../../components/Popover/Popover";
import Column from "../../../components/Row/Column/Column";
import Row from "../../../components/Row/Row";
import Spinner from "../../../components/Spinner/Spinner";
import TableCard, { ITableCard } from "../../../components/TableCard/TableCard";
import tableStyles from "../../../components/TableCard/TableCard.module.scss";
import TruncatedPopover from "../../../components/TruncatedPopover/TruncatedPopover";
import Typography from "../../../components/Typography/Typography";
import {
  IMatter,
  IPostMatters,
  resetSideFilterState,
  TMatterRender,
  updateSideFilterState,
} from "../../../modules/mattersSlice";
import { IMatterType } from "../../../modules/matterTypesSlice";
import TransferOwnershipModalContainer from "../../DocumentsContainer/Documents/TransferOwnershipModalContainer/TransferOwnershipModalContainer";
import NewEventModalContainer from "../../WalkContainer/Walk/NewEventModalContainer/NewEventModalContainer";
import MatterActionPopover from "./MatterActionPopover/MatterActionPopover";
import styles from "./Matters.module.scss";
import NewMatterModalContainer from "./NewMatterModalContainer/NewMatterModalContainer";
import { IAdvancedOptionalFiltersContents } from "./Sidebar/AdvancedOptionalFiltersContents/AdvancedOptionalFiltersContents";
import Sidebar from "./Sidebar/Sidebar";

export interface IMatterSettingsDrawer {
  handleDrawerState: (e: React.MouseEvent) => void;
}

export interface IMatters {
  inViewRef: (node?: Element | null | undefined) => void;
  matters: TMatterRender[];
  rawMatters: Record<number, IMatter>;
  typeFilterOptions: IFieldOption[];
  milestoneFilterOptions: IFieldOption[];
  ownerFilterOptions: IFieldOption[];
  onFilter: ITableCard["onFilter"];
  onInputChange: (value: string) => void;
  error?: string;
  emptyType?: EEmptyType;
  mattersStatus: cStatusType;
  mattersTableRef: React.MutableRefObject<any> | null;
  resetMatters: () => void;
  matterTypeTags: Record<number, IMatterType["filterTags"]>;
  handleOptionalFilterFocus: IAdvancedOptionalFiltersContents["handleFocus"];
  getTypeOptionalFilters: (id: number) => void;
  resetMilestones: () => void;
  resumeWalk: (wipID: number, id: number, compositeState?: number) => void;
  matterTypes: Record<number, IMatterType>;
  handleCreateOrCloneMatterClick: (id: number) => void;
}

/**
 * Matter Page
 * @param inViewRef                      React intersection observer ref
 * @param matters                        The matter records
 * @param rawMatters                     The matter records in standard form
 * @param typeFilterOptions              Filter options for type column
 * @param milestoneFilterOptions         Milestone autocomplete field options
 * @param ownerFilterOptions             Owner auto complete field options
 * @param onFilter                       Function to run on filter changes
 * @param onInputChange                  Function to run on change of owner auto complete filter
 * @param error                          Report any errors
 * @param emptyType                      Matters empty type
 * @param mattersStatus                  API status
 * @param mattersTableRef                A reference to the matters table
 * @param resetMatters                   A function to reset the matters state
 * @param matterTypeTags                 Advanced optional filter tags split by matter type ID
 * @param handleOptionalFilterFocus      Function to handle fetching of dropdown items when focussed on the dropdown
 * @param getTypeOptionalFilters         Function to get the type optional filters
 * @param resetMilestones                Function to reset milestone state
 * @param resumeWalk                     Function to resume constructor walk
 * @param matterTypes                    The matterTypes
 * @param handleCreateOrCloneMatterClick Function to start a Matter clone or create walk
 * @returns JSX.Element
 */
function Matters({
  inViewRef,
  matters,
  rawMatters,
  typeFilterOptions,
  milestoneFilterOptions,
  ownerFilterOptions,
  onFilter,
  onInputChange,
  error,
  emptyType,
  mattersStatus,
  mattersTableRef,
  resetMatters,
  matterTypeTags,
  handleOptionalFilterFocus,
  getTypeOptionalFilters,
  resetMilestones,
  resumeWalk,
  matterTypes,
  handleCreateOrCloneMatterClick,
}: IMatters): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hasValidMatterTypes = Object.values(matterTypes)?.some(
    (matterType) => !matterType.isRetired && !matterType.isLaunchExternally,
  );

  const [isNewEventModalOpen, setIsNewEventModalOpen] = useState<boolean>(false);
  const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalOpen] = useState<boolean>(false);
  const [matterID, setMatterID] = useState<number | null>(null);

  /**
   * Create table columns:
   * Set the width property of each column via consts, or use any px or % values.
   * Sizing rule: Since % and px values don't mix well (columns will overlap), use calc() to combine them.
   */
  const dateCol = "125px";
  const refCol = "195px";
  const typeCol = "60%";
  const actionsCol = "80px";
  const widestCol = "100%"; // Optional: Use if you want a column to take up the remaining space
  const remainingCols = "3"; // The total # of columns excluding 'dateCol', 'refCol', or any other px values (See 'Sizing rule' above)

  const columns = [
    {
      heading: "Date",
      filterType: TFilterType.Date as const,
      testId: "table-filter-date",
      width: dateCol,
    },
    {
      heading: "Ref",
      filterType: TFilterType.Input as const,
      testId: "table-filter-ref",
      width: refCol,
    },
    {
      heading: "Type",
      filterType: TFilterType.Select as const,
      filterOptions: typeFilterOptions,
      testId: "table-filter-type",
      width: typeCol,
    },
    {
      heading: "Matter name",
      filterType: TFilterType.Input as const,
      testId: "table-filter-matter",
      width: widestCol,
    },
    {
      heading: "Owner",
      filterType: TFilterType.AutoComplete as const,
      filterOptions: ownerFilterOptions,
      onInputChange,
      testId: "table-filter-owner",
      width: `calc(100% / ${remainingCols}`,
    },
    {
      heading: null,
      align: IXDirection.Right,
      className: tableStyles.actionsMenuColumn,
      width: actionsCol,
    },
  ];

  /**
   * Sets the drawer open/closed based on inner width
   */
  function handleResize() {
    const mobileBreakpoint = 992;
    const innerWidth = window.innerWidth;

    if (innerWidth > mobileBreakpoint) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  // Map data table rows into expected format
  const rows = matters.map(
    ({ id, referenceCode, createdDtm, owner, matterType, description, walkInProgress, action }) => {
      // Setting date column contents...
      let dateCol;

      // If there is an in progress walk on the matter constructor
      if (walkInProgress) {
        // and the matter is owned by the current user
        if (walkInProgress.ownedByMe === true) {
          // If the WIP state is not enabled, then the document type is locked for enhancement,
          // so display a disabled button with a tooltip
          if (walkInProgress.state !== cAccessType.Enabled) {
            dateCol = (
              <Popover
                variant="tooltip"
                popoverPlacement={EPopoverPlacement.Top}
                width="auto"
                popoverContents={matterType ? `${matterType} is currently locked for enhancement` : "Loading..."}
                buttonContents="Resume"
                buttonProps={{
                  size: cSizeType.Small,
                  color: cThemeColorType.Secondary,
                  disabled: true,
                }}
              />
            );
          } else {
            // Show paused text
            dateCol = (
              <Button
                size={cSizeType.Small}
                color={cThemeColorType.Secondary}
                onClick={(event) => {
                  if (walkInProgress.wipID) {
                    event.stopPropagation();
                    resumeWalk(walkInProgress.wipID, id, rawMatters[id].compositeState);
                  }
                }}
                testId="resume-button"
              >
                Resume
              </Button>
            );
          }
          // If not owned by the current user, set to user disable button
        } else {
          dateCol = (
            <Popover // Create the popover and button
              variant="tooltip"
              popoverPlacement={EPopoverPlacement.Top}
              width="auto"
              popoverContents={walkInProgress.tooltip}
              divProps={{
                icon: EIcon.UserDisable,
                iconColor: cThemeColorType.Secondary,
              }}
            />
          );
        }
        // If no walk in progress, set to created date
      } else {
        // If there is a created date
        if (createdDtm) {
          // If previous year, display the year, otherwise omit the year
          const dateFormat = new Date(createdDtm) < startOfYear(new Date()) ? "dd MMM yyyy" : "dd MMM";
          dateCol = (
            <Popover
              variant="tooltip"
              popoverPlacement={EPopoverPlacement.Top}
              width="auto"
              divProps={{
                text: format(parseISO(createdDtm), dateFormat),
              }}
              popoverContents={format(parseISO(createdDtm), "EEE dd MMM yyyy, HH:mm")}
            />
          );
        } else {
          dateCol = "";
        }
      }

      return [
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Modified:&nbsp;
              </Typography>
            </Div>
            {dateCol}
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Ref:&nbsp;
              </Typography>
            </Div>
            <TruncatedPopover testId="matter-ref">{referenceCode}</TruncatedPopover>
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Type:&nbsp;
              </Typography>
            </Div>
            {matterType ? (
              <TruncatedPopover testId="matter-type">{matterType}</TruncatedPopover>
            ) : (
              <Spinner size={cSizeType.Small} />
            )}
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Matter name:&nbsp;
              </Typography>
            </Div>
            <TruncatedPopover testId="matter-description">{description}</TruncatedPopover>
          </Div>
        </>,
        <>
          <Div className={styles.group}>
            <Div display={{ base: "block", md: "none" }}>
              <Typography variant="small" color={cMonoColorType.Light}>
                Owner:&nbsp;
              </Typography>
            </Div>
            {<TruncatedPopover testId="matter-owner">{owner}</TruncatedPopover>}
          </Div>
        </>,
        <>
          <MatterActionPopover
            matterID={Number(id)}
            setIsNewEventModalOpen={() => handleAddNewEventModalOpen(id)}
            setIsTransferOwnershipModalOpen={() => handleTransferOwnershipModalOpen(id)}
            action={action}
            handleCreateOrCloneMatterClick={handleCreateOrCloneMatterClick}
          />
        </>,
      ];
    },
  );
  const [isNewMatterModalOpen, setIsNewMatterModalOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  /**
   * Handle close of modal
   */
  const handleClose = () => {
    setIsNewMatterModalOpen(false);
  };

  /**
   * Handle click of the new matter button
   */
  const handleNewMatterBtnClick = () => {
    setIsNewMatterModalOpen(true);
    handleCreateOrCloneMatterClick(matterID as number);
  };

  /**
   * Dispatches an action to reset the matters state
   */
  function updateFilterData(filterValues: IPostMatters) {
    dispatch(updateSideFilterState(filterValues));
  }

  /**
   * Dispatches an action to reset the matter filter data
   */
  function resetFilterData() {
    dispatch(resetSideFilterState());
  }

  /**
   * Open / close the filters drawer on click of filter button
   */
  function handleFilterClick(): void {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    } else {
      setIsDrawerOpen(true);
    }
  }

  /**
   * Open the add new event modal
   * @param id The targetted matter ID
   */
  function handleAddNewEventModalOpen(id: number): void {
    setMatterID(id);
    setIsNewEventModalOpen(true);
  }

  /**
   * Open the transfer ownership modal
   * @param id The targetted matter ID
   */
  function handleTransferOwnershipModalOpen(id: number): void {
    setMatterID(id);
    setIsTransferOwnershipModalOpen(true);
  }

  /**
   * Go to the single matter page
   * @param i The targetted row index
   */
  function handleRowClick(i: number): void {
    const matter = matters[i];
    navigate(`${matter.id}`);
  }

  return (
    <Div className={styles.interfaceWrapper}>
      <DrawerRowWrapper direction={IXDirection.Right}>
        <Div className={styles.pageContent}>
          <Row
            justifyContent={{ base: "space-between" }}
            alignItems={{ base: "center" }}
            border={{ bb: true }}
            className={styles.actionBar}
          >
            <Column>
              {/* Conditionally render the New Matter button if there are matterTypes */}
              {hasValidMatterTypes && (
                <Button icon={EIcon.Add} onClick={handleNewMatterBtnClick}>
                  New Matter
                </Button>
              )}
            </Column>
            <Column>
              {!isDrawerOpen && (
                <Div className={styles.filterButtonColumn}>
                  {/** Filter button */}
                  <Button variant={EButtonVariant.Round} onClick={handleFilterClick}>
                    <Icon icon={EIcon.Search} />
                  </Button>
                </Div>
              )}
            </Column>
          </Row>
          <Div className={styles.tableCardContainer} ref={mattersTableRef}>
            <TableCard
              onRowClick={handleRowClick}
              columns={columns}
              rows={rows}
              testId="matters-table"
              hasClickableRows
            />
            <Div ref={inViewRef} py={{ base: 1 }} testId="matters-intersection-observer" />
          </Div>

          {emptyType && <EmptyState emptyType={emptyType} name="matters" />}

          {error && (
            <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
              <DisplayError>{error}</DisplayError>
            </Div>
          )}

          {mattersStatus === cStatusType.Loading && (
            <Div p={{ base: 8 }}>
              <Spinner />
            </Div>
          )}
        </Div>
        <Drawer isOpen={isDrawerOpen} direction={IXDirection.Right} width={360} testId="matters-drawer">
          <Sidebar
            setIsDrawerOpen={setIsDrawerOpen}
            typeFilterOptions={typeFilterOptions}
            milestoneFilterOptions={milestoneFilterOptions}
            ownerFilterOptions={ownerFilterOptions}
            onFilter={onFilter}
            resetMatters={resetMatters}
            resetFilterData={resetFilterData}
            updateFilterData={updateFilterData}
            getTypeOptionalFilters={getTypeOptionalFilters}
            isDrawerOpen={isDrawerOpen}
            matterTypeTags={matterTypeTags}
            handleOptionalFilterFocus={handleOptionalFilterFocus}
            resetMilestones={resetMilestones}
          />
        </Drawer>
      </DrawerRowWrapper>
      <NewMatterModalContainer isOpen={isNewMatterModalOpen} handleClose={handleClose} />
      <NewEventModalContainer
        isOpen={isNewEventModalOpen}
        handleClose={() => setIsNewEventModalOpen(false)}
        id={matterID}
        linkedToType="matter"
      />
      <TransferOwnershipModalContainer
        isOpen={isTransferOwnershipModalOpen}
        handleClose={() => setIsTransferOwnershipModalOpen(false)}
        type={ETimelineType.Matter}
        matterID={matterID as number}
      />
    </Div>
  );
}

export default Matters;
