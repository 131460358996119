import { cThemeColorType } from "../../../../app/constants";
import Button from "../../../../components/Button/Button";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../components/Modal/Modal";
import Typography from "../../../../components/Typography/Typography";
import { INotificationsEntry } from "../../../../modules/notificationsSlice";

interface IDeleteNotificationConfirmationModal {
  isOpen: boolean;
  handleClose: (canDelete: boolean) => void;
  notification: INotificationsEntry;
}

/**
 * A modal to confirm the user wants to delete a notification
 * @param isOpen           Is the modal open?
 * @param handleClose      Function to close the modal
 * @param notification     The notification selected for delete
 * @returns JSX.Element
 */
function DeleteNotificationConfirmationModal({
  isOpen,
  handleClose,
  notification,
}: IDeleteNotificationConfirmationModal) {
  return (
    <Modal size="sm" isOpen={isOpen} handleClose={() => handleClose(false)} testId="delete-notification-modal">
      <ModalHeader>
        <ModalTitle>Delete Notification</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>The Notification for {notification.description} will be permanently removed.</Typography>
      </ModalContent>
      <ModalActions>
        <Button
          color={cThemeColorType.Secondary}
          onClick={() => handleClose(false)}
          testId="delete-notification-modal-close-button"
        >
          Go back
        </Button>
        <Button onClick={() => handleClose(true)} testId="delete-notification-modal-submit-button">
          Delete Notification
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default DeleteNotificationConfirmationModal;
