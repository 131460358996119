import { useEffect, useState } from "react";
import { IBorder, ISpacing } from "../../app/types";
import Div from "../Div/Div";
import AccordionItem from "./AccordionItem/AccordionItem";

/**
 * Describe an accordion item
 */
export interface IAccordionItem {
  header: React.ReactNode;
  contents: React.ReactNode;
  className?: string | {};
  headerClassName?: string | {};
  contentsClassName?: string | {};
  chevronClassName?: string | {};
  spacing?: ISpacing;
  border?: IBorder;
  itemTestId?: string;
}

interface IAccordionProps {
  items: IAccordionItem[];
  showMultiple?: boolean;
  areItemsOpen?: boolean;
  testId?: string;
  defaultOpen?: number[];
}

export type TIsOpen = number[];

/**
 * An accordion component that allows for collapsing / expanding sections
 * @param items        The items passed into the accordion
 * @param showMultiple If true, allows multiple sections to be expanded, otherwise, only one at a time
 * @param areItemsOpen If true, all items are open by default
 * @param testId       The test id
 * @param defaultOpen  The default open item
 * @returns JSX.Element
 */
function Accordion({
  items,
  showMultiple = true,
  areItemsOpen = false,
  testId,
  defaultOpen,
}: IAccordionProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<TIsOpen>([]); // State to track what is open

  /**
   * Toggle expand / collapse of an item
   * @param i The index of the toggled item
   */
  function handleToggle(i: number): void {
    let newState: TIsOpen; // Initialise immutable new state array

    if (showMultiple) {
      // If we can show multiple items
      if (isOpen.includes(i)) {
        // If the current state includes the item's index
        newState = isOpen.filter((value) => {
          // Set new state by removing the item's index
          return value !== i;
        });
      } else {
        // If the current state does not include the item's index
        newState = [...isOpen, i]; // Add the item's index to the array
      }
    } else {
      // If we can only show a single item
      if (isOpen[0] === i) {
        // If the array has the item's index
        newState = []; // Clear the array
      } else {
        // Otherwise...
        newState = [i]; // Set the item's index as the only member of the array
      }
    }

    setIsOpen(newState); // Set the new state
  }

  /**
   * Opens all the accordion items
   */
  function openAllAccordionItems() {
    const filledArray = Array.from({ length: items.length }, (_, index) => index);
    setIsOpen(filledArray);
  }

  useEffect(() => {
    // If all items are open by default, open all items
    if (areItemsOpen) {
      openAllAccordionItems();
    } else if (defaultOpen !== undefined && defaultOpen.length > 0) {
      // If default open items are provided, open them
      setIsOpen(defaultOpen);
    }
  }, []);

  return (
    <Div testId={testId}>
      {items.map((props, index) => (
        <AccordionItem {...props} key={index} index={index} isOpen={isOpen.includes(index)} toggle={handleToggle} />
      ))}
    </Div>
  );
}

export default Accordion;
