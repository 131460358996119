import { UseFormHandleSubmit, UseFormTrigger } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../../../../../../app/constants";
import Button, { cButtonType, EButtonVariant } from "../../../../../../../components/Button/Button";
import Div from "../../../../../../../components/Div/Div";
import { EIcon } from "../../../../../../../components/Icon/Icon";
import Typography from "../../../../../../../components/Typography/Typography";
import { handleSaveAsTemplateClick } from "../../../../../../../utils/analytics/analytics";
import styles from "../../../../Analytics.module.scss";

export interface IAnalyticsFiltersHeader {
  configName?: string;
  configID: number | null;
  hasChanges: boolean;
  saveReportTemplateStatus: cStatusType;
  runReportStatus: cStatusType;
  handleBackClick: () => void;
  setIsDeleteReportTemplateModalOpen: (isOpen: boolean) => void;
  trigger: UseFormTrigger<any>;
  setIsSaveAsTemplateModalOpen: (isOpen: boolean) => void;
  handleSubmit: UseFormHandleSubmit<any>;
  handleRunReportClick: (data: any) => void;
  handleSaveTemplateClick: (data: any) => void;
  isAdmin: boolean;
}

/**
 * Renders the header for the analytics filters
 * @param configName                           The name of the configuration
 * @param configID                             The ID of the configuration
 * @param hasChanges                           Whether there are changes in the form values from the initial values
 * @param saveDocumentTypeReportTemplateStatus The status of saving the document type report template
 * @param runDocumentTypeReportStatus          The status of running the document type report
 * @param handleBackClick                      The function to handle the back button click
 * @param setIsDeleteReportTemplateModalOpen   The function to set the delete report template modal open state
 * @param trigger                              The form trigger
 * @param setIsSaveAsTemplateModalOpen         The function to set the save as template modal open state
 * @param handleSubmit                         The react-hook-form function to handle the form submit
 * @param handleRunReportClick                 The function to handle the run report button click
 * @param handleSaveTemplateClick              The function to handle the save template button click
 * @param isAdmin                              Whether the user is an admin
 * @returns JSX.Element
 */
function AnalyticsFiltersHeader({
  configName,
  configID,
  hasChanges,
  saveReportTemplateStatus,
  runReportStatus,
  handleBackClick,
  setIsDeleteReportTemplateModalOpen,
  trigger,
  setIsSaveAsTemplateModalOpen,
  handleSubmit,
  handleRunReportClick,
  handleSaveTemplateClick,
  isAdmin,
}: IAnalyticsFiltersHeader): JSX.Element {
  return (
    <Div
      border={{ bb: true }}
      className={styles.actionBar}
      display={{ base: "flex" }}
      justifyContent={{ base: "space-between" }}
    >
      <Div display={{ base: "flex" }} alignItems={{ base: "center" }}>
        <Button
          variant={EButtonVariant.Link}
          icon={EIcon.Back}
          type={cButtonType.ButtonType}
          testId="back-to-document-analytics-btn"
          onClick={handleBackClick}
        />
        <Typography variant="h4" spacing={{ mb: 0, pl: 4 }}>
          {configName}
        </Typography>
      </Div>

      <Div display={{ base: "flex" }} justifySelf={{ base: "end" }}>
        {configID !== null && isAdmin && (
          <Button
            color={cThemeColorType.Secondary}
            testId="delete-report-button"
            icon={EIcon.Delete}
            onClick={() => setIsDeleteReportTemplateModalOpen(true)}
            spacing={{ mr: 4 }}
          />
        )}
        {hasChanges && configID !== null && isAdmin && (
          <Button
            color={cThemeColorType.Secondary}
            type={cButtonType.ButtonType}
            isLoading={saveReportTemplateStatus === cStatusType.Loading}
            testId="save-changes-button"
            spacing={{ mr: 4 }}
            onClick={handleSubmit(handleSaveTemplateClick)}
          >
            Save changes
          </Button>
        )}
        {hasChanges && configID === null && isAdmin && (
          <Button
            color={cThemeColorType.Secondary}
            type={cButtonType.ButtonType}
            isLoading={saveReportTemplateStatus === cStatusType.Loading}
            testId="save-as-new-template-button"
            spacing={{ mr: 4 }}
            onClick={() => handleSaveAsTemplateClick({ trigger, setIsSaveAsTemplateModalOpen })}
          >
            Save as new template
          </Button>
        )}
        <Button
          type={cButtonType.ButtonType}
          isLoading={runReportStatus === cStatusType.Loading}
          testId="run-report-button"
          onClick={handleSubmit(handleRunReportClick)}
        >
          Run report
        </Button>
      </Div>
    </Div>
  );
}

export default AnalyticsFiltersHeader;
