import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import Button from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";

interface IConfirmDeleteReportTemplateModal {
  isOpen: boolean;
  handleModalClose: () => void;
  handleDeleteReportTemplate: () => void;
  deleteTemplateStatus?: cStatusType;
  error?: string;
}

/**
 * Confirm deletion of document type report template
 * @param isOpen                     Is the modal open?
 * @param handleModalClose           Function to close the modal
 * @param handleDeleteReportTemplate Function to delete the report template
 * @param deleteTemplateStatus       Status of the delete template API call
 * @param error                      Any errors that occurred during the delete template API call
 * @returns JSX.Element
 */
function ConfirmDeleteReportTemplateModal({
  isOpen,
  handleModalClose,
  handleDeleteReportTemplate,
  deleteTemplateStatus,
  error,
}: IConfirmDeleteReportTemplateModal): JSX.Element {
  return (
    <Modal isOpen={isOpen} handleClose={handleModalClose} testId="delete-report-template-modal" size="sm">
      <ModalHeader>
        <ModalTitle>Delete custom report?</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>This will permanently remove this report template from the system.</Typography>
        {error && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions>
        <Button onClick={handleModalClose} color={cThemeColorType.Secondary} testId="cancel-button">
          Cancel
        </Button>
        <Button
          isLoading={deleteTemplateStatus === cStatusType.Loading}
          onClick={handleDeleteReportTemplate}
          testId="delete-button"
        >
          Delete
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default ConfirmDeleteReportTemplateModal;
