import classNames from "classnames";
import { cMonoColorType } from "../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../app/types";
import Div from "../../../../../components/Div/Div";
import Popover from "../../../../../components/Popover/Popover";
import Column from "../../../../../components/Row/Column/Column";
import Typography from "../../../../../components/Typography/Typography";
import {
  EInvalidatedReasonType,
  ENotificationStatusType,
  INotificationsEntry,
} from "../../../../../modules/notificationsSlice";
import InsufficientPermissions from "../InsufficientPermissions/InsufficientPermissions";
import InvalidatedDocument from "../InvalidatedTransaction/InvalidatedTransaction";
import styles from "../ListEntry.module.scss";
import TransactionLink from "../TransactionLink/TransactionLink";

/**
 * Display standard content
 * @param transaction          Detail of the related transaction
 * @param remainingDays        Days to go until or days passed since the deadline
 * @param description          The notification description text
 * @param documentType         The document type text
 * @param matterType           The matter type text
 * @param urn                  The document URN
 * @param invalidCode          Reason for invalidation
 * @param status               The status of the notification
 * @param documentDescription  The document description
 * @param matterDescription    The matter description
 * @param document             The document related to the notification
 * @returns JSX.Element
 */
function StandardContent({
  remainingDays,
  transaction,
  description,
  documentType,
  matterType,
  urn,
  invalidCode,
  status,
  documentDescription,
  matterDescription,
  document,
}: Pick<
  INotificationsEntry,
  | "remainingDays"
  | "transaction"
  | "invalidCode"
  | "status"
  | "description"
  | "documentType"
  | "matterType"
  | "urn"
  | "documentDescription"
  | "matterDescription"
  | "document"
>): JSX.Element {
  function getTransactionLink() {
    // If the status is not invalidated and the user has the privilege to view the document
    // OR the user has the privilege to view the matter
    // OR the user has the privilege to view the interviewSpawn
    if (
      (status !== ENotificationStatusType.Invalidated && transaction?.document?.canViewPrivilege === true) ||
      transaction?.matter?.canViewPrivilege === true ||
      transaction?.interviewSpawn?.canViewPrivilege === true
    ) {
      // Return the TransactionLink component with the necessary props
      return (
        <TransactionLink
          documentType={documentType}
          transaction={transaction}
          documentDescription={documentDescription}
          matterDescription={matterDescription}
          status={status}
          invalidCode={invalidCode}
          matterType={matterType}
          document={document}
        />
      );
    }
    // If the status is invalidated and the reason for invalidation is that the document was terminated
    else if (status === ENotificationStatusType.Invalidated && invalidCode === EInvalidatedReasonType.DocTerminated) {
      // Return the TransactionLink component with the necessary props
      return (
        <TransactionLink
          documentType={documentType}
          transaction={transaction}
          documentDescription={documentDescription}
          matterDescription={matterDescription}
          status={status}
          invalidCode={invalidCode}
          matterType={matterType}
          document={document}
        />
      );
    }
    // If the status is invalidated and the reason for invalidation is that the document was superseded
    else if (status === ENotificationStatusType.Invalidated && invalidCode === EInvalidatedReasonType.Superseded) {
      // Return the TransactionLink component with the necessary props
      return (
        <TransactionLink
          documentType={documentType}
          transaction={transaction}
          documentDescription={documentDescription}
          matterDescription={matterDescription}
          status={status}
          invalidCode={invalidCode}
          matterType={matterType}
          document={document}
        />
      );
    }
    // If the status is invalidated and the reason for invalidation is not that the document was superseded
    else if (status === ENotificationStatusType.Invalidated && invalidCode !== EInvalidatedReasonType.Superseded) {
      // Return the InvalidatedDocument component with the invalidCode
      return <InvalidatedDocument invalidCode={invalidCode} />;
    }
    // If the user does not have the privilege to view the document
    // OR the user does not have the privilege to view the matter
    // OR the user does not have the privilege to view the interviewSpawn
    else if (
      transaction?.document?.canViewPrivilege === false ||
      transaction?.matter?.canViewPrivilege === false ||
      transaction?.interviewSpawn?.canViewPrivilege === false
    ) {
      // Return the InsufficientPermissions component with the urn
      return <InsufficientPermissions urn={urn} />;
    }
  }

  // Display the popover text based on the invalidation reason

  let popoverText;

  switch (invalidCode) {
    case EInvalidatedReasonType.Superseded:
      popoverText = `Notification no longer relevant - the event has been deleted`;
      break;

    case EInvalidatedReasonType.DocDeleted:
      popoverText = `Notification no longer relevant - the Document has been deleted`;
      break;

    case EInvalidatedReasonType.DocTerminated:
      popoverText = `Notification no longer relevant - the state of the document has been set to "terminated"`;
      break;

    case EInvalidatedReasonType.MatterDeleted:
      popoverText = `Notification no longer relevant - the Matter has been deleted`;
      break;
  }

  return (
    <>
      <Column spacing={{ px: 5 }} all={12} lg={5} className={styles.overflowFix}>
        <Div display={{ base: "block" }}>
          {status === ENotificationStatusType.Invalidated && invalidCode === EInvalidatedReasonType.Superseded && (
            <InvalidatedDocument invalidCode={invalidCode} />
          )}
          <Typography
            variant="small"
            weight="medium"
            color={cMonoColorType.Light}
            className={classNames(
              status === ENotificationStatusType.Invalidated &&
                (invalidCode === EInvalidatedReasonType.DocTerminated ||
                  invalidCode === EInvalidatedReasonType.MatterDeleted ||
                  invalidCode === EInvalidatedReasonType.Superseded ||
                  invalidCode === EInvalidatedReasonType.DocDeleted) &&
                styles.strikethrough,
            )}
          >
            {remainingDays}
          </Typography>
        </Div>
        <Div spacing={{ pb: 3 }} display={{ base: "inline-flex" }}>
          {status === ENotificationStatusType.Invalidated &&
          (invalidCode === EInvalidatedReasonType.DocTerminated ||
            invalidCode === EInvalidatedReasonType.MatterDeleted ||
            invalidCode === EInvalidatedReasonType.Superseded ||
            invalidCode === EInvalidatedReasonType.DocDeleted) ? (
            <Popover // Create the popover and button
              variant="tooltip"
              popoverPlacement={EPopoverPlacement.Top}
              width="300"
              popoverContents={popoverText}
              divProps={{
                text: (
                  <Typography weight="medium" className={classNames(styles.notificationTitle, styles.strikethrough)}>
                    {description}
                  </Typography>
                ),
              }}
            />
          ) : (
            <Typography weight="medium" className={styles.notificationTitle}>
              {description}
            </Typography>
          )}
        </Div>
      </Column>
      <Column all={12} lg={7} spacing={{ px: 5 }}>
        <Div display={{ base: "inline-flex" }} alignItems={{ base: "center" }}>
          <Div spacing={{ py: 4 }}>{getTransactionLink()}</Div>
        </Div>
      </Column>
    </>
  );
}

export default StandardContent;
