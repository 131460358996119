import { Outlet } from "react-router-dom";
import { cStatusType } from "../../../app/constants";
import Badge from "../../../components/Badge/Badge";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import { IDataTable } from "../../../components/DataTable/DataTable";
import Div from "../../../components/Div/Div";
import { IFieldOption } from "../../../components/FormField/FormField";
import Container from "../../../components/Grid/Container/Container";
import Column from "../../../components/Row/Column/Column";
import Row from "../../../components/Row/Row";
import { TOrder } from "../../../components/TableCard/TableCardHeaderCell/TableCardHeaderCell";
import Tabs from "../../../components/Tabs/Tabs";
import Typography from "../../../components/Typography/Typography";
import { ETimelineGroup, TDocumentRender } from "../../../modules/documentsSlice";
import { IMatterDocumentsEntry } from "../../../modules/matterDocumentsSlice";
import { IMatter, TLinkedMatterRender } from "../../../modules/mattersSlice";
import { IMatterTagsEntry } from "../../../modules/matterTagsSlice";
import { EWalkType } from "../../../modules/walkSlice";
import { INewEventModal } from "../../WalkContainer/Walk/NewEventModalContainer/NewEventModal/NewEventModal";
import LinkedMatters from "./LinkedMatters/LinkedMatters";
import styles from "./Matter.module.scss";
import MatterDocuments from "./MatterDocuments/MatterDocuments";
import MatterHeader from "./MatterHeader/MatterHeader";
import MatterTasksColumnContainer from "./MatterTasksColumnContainer/MatterTasksColumnContainer";
import MatterTimelineContainer from "./MatterTimelineContainer/MatterTimelineContainer";

export interface IMatterComponent {
  id: string;
  urn: string;
  matterStatus?: cStatusType;
  error?: string;
  matterType: string;
  matterTypeStatus: cStatusType;
  matterTags?: IMatterTagsEntry;
  matterTagsStatus: cStatusType;
  matterDocumentsStatus: cStatusType;
  matterDocumentsError?: string;
  title: string;
  documents?: TDocumentRender[];
  rawDocuments?: IMatterDocumentsEntry["documents"];
  onOrder?: (value: TOrder, heading: string | null) => void;
  onInputChange?: (value: string) => void;
  typeFilterOptions?: IFieldOption[];
  onFilter?: IDataTable["onFilter"];
  owners?: IFieldOption[];
  displayMilestone: boolean;
  milestoneDisplayName: string | null;
  handleDocumentAmend: (id: number, action: EWalkType, compositeState: number) => Promise<void>;
  handleCloneDocumentClick: (id: number) => void;
  onAddEventSuccess: INewEventModal["onAddEventSuccess"];
  onDocumentUpdate: () => void;
  matters: Record<number, IMatter>;
  linkedMatters: TLinkedMatterRender[];
  resumeWalk: (wipID: number, id: string, compositeState?: number, isCollab?: boolean) => void;
  getMatterData: () => void;
  handleCreateOrCloneMatterClick: (id: number) => void;
}

/**
 * Display a detailed Matter page
 * @param id		  			                 The matter ID
 * @param urn		  			                 The matter URN
 * @param matterStatus			             The matters loading state
 * @param error	      		               Any errors from the matters API
 * @param matterType  		               The matter documentType
 * @param matterTypeStatus               Matter types loading state
 * @param matterTags  		               Tags for this matter
 * @param matterTagsStatus               Matter tags loading state
 * @param title 				                 The matter title
 * @param documents				               Document list to display
 * @param rawDocuments   	               Raw documents data
 * @param displayMilestone		           Should the milestone be displayed?
 * @param milestoneDisplayName           Milestone text to be rendered
 * @param handleDocumentAmend            Function to handle amend of a document
 * @param handleCloneDocumentClick       Function to start a document clone walk
 * @param onAddEventSuccess              Reload the timeline when a new event is added
 * @param onDocumentUpdate               Reload the matter documents on any document update
 * @param matters                        Matters records
 * @param linkedMatters                  The matters linked to this matter
 * @param resumeWalk                     Function to resume a paused walk
 * @param getMatterData                  Get base matter data
 * @param handleCreateOrCloneMatterClick Function to start a Matter clone walk
 * @returns JSX.Element
 */
function Matter({
  documents,
  rawDocuments,
  id,
  urn,
  matterStatus,
  error,
  matterType,
  matterTypeStatus,
  matterTags,
  matterTagsStatus,
  title,
  displayMilestone,
  milestoneDisplayName,
  handleDocumentAmend,
  handleCloneDocumentClick,
  onAddEventSuccess,
  onDocumentUpdate,
  matters,
  linkedMatters,
  resumeWalk,
  getMatterData,
  handleCreateOrCloneMatterClick,
}: IMatterComponent): JSX.Element {
  const tabs = [
    {
      header: <Typography>Tasks Completed</Typography>,
      contents: (
        <MatterDocuments
          documents={documents}
          rawDocuments={rawDocuments}
          matterId={id}
          handleDocumentAmend={handleDocumentAmend}
          handleCloneDocumentClick={handleCloneDocumentClick}
          onDocumentUpdate={onDocumentUpdate}
          resumeWalk={resumeWalk}
          getMatterData={getMatterData}
        />
      ),
    },
    {
      header: (
        <>
          <Typography spacing={{ mr: 3 }}>Linked Matters</Typography>
          <Badge>
            <span>{linkedMatters.length || 0}</span>
          </Badge>
        </>
      ),
      contents: <LinkedMatters linkedMatters={linkedMatters} />,
    },
    {
      header: <Typography>Timeline</Typography>,
      contents: <MatterTimelineContainer id={parseInt(id, 10)} />,
    },
    {
      header: <Typography>Events</Typography>,
      contents: <MatterTimelineContainer id={parseInt(id, 10)} filterGroup={ETimelineGroup.Events} />,
    },
  ];

  // Breadcrumb nav links
  const links = [
    {
      id: "Matters",
      label: "Matters",
      url: "/matters",
    },
    {
      id: urn,
      label: urn,
      url: `/matters/${id}`,
    },
  ];

  return (
    <Div className={styles.interfaceWrapper}>
      <Container width="fluid" className={styles.pageContent} fullViewportHeight>
        <Breadcrumb links={links} />
        <Row className={styles.scrollingColumnsContainer}>
          <Column all={12} md={9} className={styles.leftColumn}>
            <MatterHeader
              urn={urn}
              title={title}
              matterStatus={matterStatus}
              error={error}
              matterType={matterType}
              matterTypeStatus={matterTypeStatus}
              matterTags={matterTags}
              matterTagsStatus={matterTagsStatus}
              displayMilestone={displayMilestone}
              milestoneDisplayName={milestoneDisplayName}
              id={id}
              onAddEventSuccess={onAddEventSuccess}
              matters={matters}
              handleCreateOrCloneMatterClick={handleCreateOrCloneMatterClick}
            />
            <Tabs items={tabs} sticky />
          </Column>
          <MatterTasksColumnContainer id={parseInt(id, 10)} matterRef={matters?.[parseInt(id, 10)]?.referenceCode} />
        </Row>
        <Outlet />
      </Container>
    </Div>
  );
}

export default Matter;
