import { cThemeColorType } from "../../../../../app/constants";
import Button from "../../../../../components/Button/Button";
import DisplayError from "../../../../../components/DisplayError/DisplayError";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../../../../../components/Modal/Modal";
import Typography from "../../../../../components/Typography/Typography";
import { IUser } from "../../../../../modules/usersSlice";

interface IDisableUserModal {
  isOpen: boolean;
  handleClose: (canDisable: boolean) => void;
  user?: IUser;
  error?: string;
}

/**
 * A modal to confirm if the admin wants to disable a user
 * @param isOpen      Is the modal open?
 * @param handleClose Function to close the modal
 * @param user        The user to disable
 * @param error       The error message
 * @returns JSX.Element
 */
function DisableUserModal({ isOpen, handleClose, user, error }: IDisableUserModal) {
  return (
    <Modal isOpen={isOpen} handleClose={() => handleClose(false)} testId="disable-user-modal">
      <ModalHeader>
        <ModalTitle>Disable user?</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Typography>
          Account{" "}
          <b>
            {user?.firstName} {user?.lastName}
          </b>{" "}
          (<b>{user?.loginName}</b>) will be disabled.
        </Typography>
        <Typography spacing={{ pt: 5 }}>
          Work currently in progress by this user may be lost.
          {user?.isTeamLead && " Any tasks assigned to this user will be reassigned."}
        </Typography>
        {error && <DisplayError>{error}</DisplayError>}
      </ModalContent>
      <ModalActions>
        <Button color={cThemeColorType.Secondary} onClick={() => handleClose(false)}>
          Go back
        </Button>
        <Button onClick={() => handleClose(true)}>Disable user</Button>
      </ModalActions>
    </Modal>
  );
}

export default DisableUserModal;
