import classNames from "classnames";
import { FieldError, UseFormRegister } from "react-hook-form";
import Div from "../../../../../../components/Div/Div";
import FormField, { cFormFieldType } from "../../../../../../components/FormField/FormField";
import styles from "../../../Analytics.module.scss";

interface IAnalyticsDateRangeFilter {
  register: UseFormRegister<any>;
  startDateError?: FieldError;
  endDateError?: FieldError;
  startDate: string;
  endDate: string;
}

/**
 * The analytics date range filter
 * @param register       The register function from react-hook-form
 * @param startDateError The start date error
 * @param endDateError   The end date error
 * @param startDate      The start date
 * @param endDate        The end date
 * @returns JSX.Element
 */
function AnalyticsDateRangeFilter({
  register,
  startDateError,
  endDateError,
  startDate,
  endDate,
}: IAnalyticsDateRangeFilter): JSX.Element {
  return (
    <Div className={classNames(styles.formRow, styles.justified)} testId="date-range-filter">
      <label htmlFor="startDate" className={styles.label}>
        Within date range
      </label>
      <Div display={{ base: "flex" }} flexDirection={{ base: "column", sm: "row" }}>
        <FormField
          name="startDate"
          label="Start"
          register={register}
          type={cFormFieldType.DatePicker}
          error={startDateError}
          required
          maxDate={endDate ? new Date(endDate) : new Date()}
          testId="start-date-picker"
          spacing={{ mr: 7 }}
        />
        <FormField
          name="endDate"
          label="End"
          register={register}
          type={cFormFieldType.DatePicker}
          error={endDateError}
          required
          minDate={startDate ? new Date(startDate) : undefined}
          testId="end-date-picker"
        />
      </Div>
    </Div>
  );
}

export default AnalyticsDateRangeFilter;
