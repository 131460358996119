import { Dispatch, SetStateAction } from "react";
import Div from "../../../../../../../components/Div/Div";
import { ISpawnItem } from "../../../../../../../modules/matterTasksSlice";
import { IPostMatterLaunchTask } from "../../../../../../../modules/walkSlice";
import MatterTask from "../../MatterTask/MatterTask";

interface IResourcesForThisMatter {
  matterItems: ISpawnItem[];
  handleDraftDocument: ({ custWalkTypeID, matterID, spawnID }: IPostMatterLaunchTask) => void;
  matterID: number;
  setEmbedData: Dispatch<
    SetStateAction<{ custWalkTypeID: number; matterID?: number; interviewSpawnID?: number } | null>
  >;
  allowEmbed?: boolean;
  setInvalidateData: Dispatch<
    SetStateAction<{
      interviewSpawnID: number;
      wipDTM?: string;
    } | null>
  >;
}

/**
 * "Resources for this matter" tab contents
 * @param matterItems           The items to do
 * @param handleDraftDocument   Start spawn walk
 * @param matterID              Wrapping matter ID
 * @param setEmbedData          Set the embed data for the send embed email notification modal
 * @param allowEmbed            Is embed allowed in the customer config?
 * @param setInvalidateData     Set the invalidate data for the invalidate embed link modal
 * @returns JSX.Element
 */
function ResourcesForThisMatter({
  matterItems,
  handleDraftDocument,
  matterID,
  setEmbedData,
  allowEmbed,
  setInvalidateData,
}: IResourcesForThisMatter): JSX.Element {
  return (
    <Div px={{ base: 6 }}>
      {matterItems
        ? matterItems.map((matterItem) => (
            <MatterTask
              key={matterItem.id}
              {...matterItem}
              handleDraftDocument={handleDraftDocument}
              matterID={matterID}
              setEmbedData={setEmbedData}
              allowEmbed={allowEmbed}
              setInvalidateData={setInvalidateData}
            />
          ))
        : null}
    </Div>
  );
}

export default ResourcesForThisMatter;
