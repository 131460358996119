import { map } from "lodash";
import Div from "../../../../../../../components/Div/Div";
import { IMatterTasksColumn } from "../../MatterTasksColumn";
import GeneralResource from "./GeneralResource/GeneralResource";

/**
 * General resources in a matter
 * @param externalResources            The list of external resources
 * @param handleLaunchExternalResource Start external resource walk
 * @param matterID                     The ID of the matter
 * @param setEmbedData                 Set the embed data for the send embed email notification modal
 * @param allowEmbed                   Is embed allowed in the customer config?
 * @returns JSX.Element
 */
function GeneralResources({
  externalResources,
  handleLaunchExternalResource,
  matterID,
  setEmbedData,
  allowEmbed,
}: Pick<
  IMatterTasksColumn,
  "externalResources" | "handleLaunchExternalResource" | "matterID" | "setEmbedData" | "allowEmbed"
>): JSX.Element {
  return (
    <Div px={{ base: 6 }}>
      {map(externalResources, (resource) => (
        <GeneralResource
          key={resource.documentTypeID}
          matterID={matterID}
          documentType={resource.documentType}
          draft={resource.draft}
          file={resource.file}
          handleLaunchExternalResource={handleLaunchExternalResource}
          setEmbedData={setEmbedData}
          allowEmbed={allowEmbed}
        />
      ))}
    </Div>
  );
}

export default GeneralResources;
