import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { postUsersByID, selectUsers } from "../../../../../../../modules/usersSlice";
import TaskLockedMessage from "./TaskLockedMessage/TaskLockedMessage";

interface ITaskLockedMessageContainer {
  wipDTM?: string;
  wipUserID?: number;
  activeEmbedCreateDTM?: string;
}

/**
 * Container for the matter task locked message
 * @param wipDTM               The date time string of the start of the walk in progress
 * @param wipUserID            The user ID of the user who started the walk in progress
 * @param activeEmbedCreateDTM The date time string of the initial send of the embed email notification
 * @returns JSX.Element
 */
function TaskLockedMessageContainer({
  wipDTM,
  wipUserID,
  activeEmbedCreateDTM,
}: ITaskLockedMessageContainer): JSX.Element | null {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const user = wipUserID && users[wipUserID];
  const userName = user ? `${user?.firstName} ${user?.lastName}` : "";

  // Fetch the user who started the walk in progress
  useEffect(() => {
    if (wipUserID) {
      dispatch(postUsersByID({ ids: [wipUserID] }));
    }
  }, [wipUserID]);

  return (
    <TaskLockedMessage
      isEmbed={!!activeEmbedCreateDTM}
      userName={userName}
      wipDTM={wipDTM}
      activeEmbedCreateDTM={activeEmbedCreateDTM}
    />
  );
}

export default TaskLockedMessageContainer;
