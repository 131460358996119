import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ETimelineType } from "../../../../components/Events/TimelineEvents";
import { IFieldOption } from "../../../../components/FormField/FormField";
import {
  openInformationUpdatedModal,
  postDocumentOwnershipTransfer,
  postDocumentTransferUsers,
  selectDocumentReplacementUserIDs,
  selectDocuments,
  selectDocumentsError,
  selectDocumentsStatus,
  selectDocumentsTransferUsersStatus,
  selectDocumentTransferUsersError,
} from "../../../../modules/documentsSlice";
import { selectMatterDocuments } from "../../../../modules/matterDocumentsSlice";
import {
  postMatterOwnershipTransfer,
  postMatterTransferUsers,
  selectMatterReplacementUserIDs,
  selectMatters,
  selectMattersError,
  selectMattersStatus,
  selectMattersTransferUsersError,
  selectMattersTransferUsersStatus,
} from "../../../../modules/mattersSlice";
import { postUsers, selectUsers, selectUsersError, selectUsersStatus } from "../../../../modules/usersSlice";
import { errorToast } from "../../../../toast/toast";
import { isOutdatedContentError } from "../../../../utils/errors/errors";
import TransferOwnershipModal from "./TransferOwnershipModal/TransferOwnershipModal";

interface ITransferOwnershipModalContainer {
  isOpen: boolean;
  handleClose: () => void;
  onSubmitSuccess?: () => void;
  type: ETimelineType;
  documentID?: number;
  matterID?: number;
}

/**
 * Interface for handling ownership transfer function
 */
export interface IHandleOwnershipTransfer {
  user: IFieldOption | null;
  transferNote: string;
  id: number;
  ownerUser: any;
}

/**
 * Transfer ownership modal container
 * @param isOpen           Is the modal open
 * @param handleClose      Function to close the modal
 * @param onSubmitSuccess  Function to call on submit success
 * @param type             The type of item we are dealing with (document or matter)
 * @param documentID       The document ID
 * @param matterID         The matter ID
 * @returns JSX.Element
 */
function TransferOwnershipModalContainer({
  isOpen,
  handleClose,
  onSubmitSuccess,
  type,
  documentID,
  matterID,
}: ITransferOwnershipModalContainer): JSX.Element {
  const dispatch = useAppDispatch();

  // Get users data
  const users = useAppSelector(selectUsers);
  const usersStatus = useAppSelector(selectUsersStatus);
  const usersError = useAppSelector(selectUsersError);

  // Get transfer users data. Get from document or matter depending on type
  const transferUsersStatus = useAppSelector(
    type === ETimelineType.Document ? selectDocumentsTransferUsersStatus : selectMattersTransferUsersStatus,
  );

  // Get transfer users error. Get from document or matter depending on type
  const transferUsersError = useAppSelector(
    type === ETimelineType.Document ? selectDocumentTransferUsersError : selectMattersTransferUsersError,
  );

  // Get replacement user IDs. Get from document or matter depending on type
  const replacementUserIDs = useAppSelector(
    type === ETimelineType.Document ? selectDocumentReplacementUserIDs : selectMatterReplacementUserIDs,
  );

  // Get documents data. Get documents from document or matter slice depending on type
  const documentsStatus = useAppSelector(selectDocumentsStatus);
  const documentsError = useAppSelector(selectDocumentsError);
  const documents =
    type === ETimelineType.Document && matterID
      ? useAppSelector(selectMatterDocuments)?.[matterID]?.documents
      : useAppSelector(selectDocuments);

  // Get matters data
  const mattersStatus = useAppSelector(selectMattersStatus);
  const mattersError = useAppSelector(selectMattersError);
  const matters = useAppSelector(selectMatters);

  // Fetch users on open for replacement user options. Get from document or matter depending on type
  useEffect(() => {
    if (isOpen) {
      if (type === ETimelineType.Document) {
        dispatch(postDocumentTransferUsers(documentID as number)).unwrap();
      } else {
        dispatch(postMatterTransferUsers(matterID as number)).unwrap();
      }
    }
  }, [isOpen, documentID, matterID]);

  /**
   * Set the user loading status before fetching users and reset it after
   * @returns Promise<void>
   */
  async function handleUsersFetch() {
    try {
      await dispatch(postUsers({}));
    } catch (error: any) {
      console.error(error);
    }
  }

  // Fetch users on open for replacement user options
  useEffect(() => {
    if (isOpen) {
      handleUsersFetch();
    }
  }, [isOpen]);

  /**
   * Handle ownership transfer
   * @param user          The user to transfer ownership to
   * @param transferNote  The transfer note
   * @param id            The document or matter ID
   * @param ownerUser     The current owner user retrieved from the document or matter item
   */
  async function handleOwnershipTransfer({ user, transferNote, id, ownerUser }: IHandleOwnershipTransfer) {
    try {
      // If the type is document, post the document ownership transfer
      if (type === ETimelineType.Document) {
        await dispatch(
          postDocumentOwnershipTransfer({
            documentID: id,
            fromUserID: ownerUser.id,
            toUserID: user?.value as number,
            transferNote,
          }),
        ).unwrap();
      } else {
        // If the type is matter, post the matter ownership transfer
        await dispatch(
          postMatterOwnershipTransfer({
            matterID: id,
            fromUserID: ownerUser.id,
            toUserID: user?.value as number,
            transferNote,
          }),
        ).unwrap();
      }
    } catch (err: any) {
      console.error(err);
      const errorCode = parseInt(err);
      // If the composite state does not match, show the information updated modal
      // Otherwise show the error toast
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      } else {
        errorToast(err);
      }
    }
  }

  return (
    <TransferOwnershipModal
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmitSuccess={onSubmitSuccess}
      type={type}
      documentID={documentID}
      matterID={matterID}
      users={users}
      usersStatus={usersStatus}
      usersError={usersError}
      transferUsersStatus={transferUsersStatus}
      transferUsersError={transferUsersError}
      replacementUserIDs={replacementUserIDs}
      documentsStatus={documentsStatus}
      documentsError={documentsError}
      documents={documents}
      mattersStatus={mattersStatus}
      mattersError={mattersError}
      matters={matters}
      handleOwnershipTransfer={handleOwnershipTransfer}
    />
  );
}

export default TransferOwnershipModalContainer;
