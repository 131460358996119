import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  postAddCollaborator,
  resetAddError,
  selectAddError,
  selectAddStatus,
} from "../../../../../../modules/collaborationSlice";
import {
  postAddFilterPeopleByEmail,
  resetFilteredEmailAddressOptions,
  resetFoundESignUser,
  selectFilteredEmailAddressOptions,
  selectFoundESignUser,
} from "../../../../../../modules/esignSlice";
import AddCollaboratorModal, { TAddCollaboratorInputs } from "./AddCollaboratorModal/AddCollaboratorModal";

interface IAddCollaboratorModalContainer {
  isOpen: boolean;
  handleClose: () => void;
}

/**
 * Add recipient modal container
 * @param isOpen     Is the modal open
 * @param handleClose Function to close the modal
 * @returns JSX.Element
 */
function AddCollaboratorModalContainer({ isOpen, handleClose }: IAddCollaboratorModalContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const foundPerson = useAppSelector(selectFoundESignUser);
  const filteredEmailAddressOptions = useAppSelector(selectFilteredEmailAddressOptions);
  const status = useAppSelector(selectAddStatus);
  const error = useAppSelector(selectAddError);

  // Reset the add error on modal close
  useEffect(() => {
    if (!isOpen) {
      dispatch(resetAddError());
    }
  }, [isOpen]);

  /**
   * Search for a person by email address
   * @param emailAddress The email address
   */
  function handleEmailFieldChange(emailAddress: string) {
    const minEmailSearchLength = 5; // Minimum email search length

    // If the email address changes, search for a user
    if (emailAddress.length >= minEmailSearchLength) {
      try {
        dispatch(postAddFilterPeopleByEmail(emailAddress));
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Clears the autofill email address options
   */
  function clearAutofillOptions() {
    dispatch(resetFilteredEmailAddressOptions());
  }

  /**
   * Handle submit
   * @param data The form data
   * @returns Promise<void>
   * @throws Error
   */
  async function handleSubmit(data: TAddCollaboratorInputs) {
    try {
      await dispatch(postAddCollaborator(data)).unwrap();
      dispatch(resetFoundESignUser());
      toast("Collaborator has been added and notified");
    } catch (error: any) {
      throw new Error(error);
    }
  }

  return (
    <AddCollaboratorModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleEmailFieldChange={handleEmailFieldChange}
      foundPerson={foundPerson}
      clearAutofillOptions={clearAutofillOptions}
      filteredEmailAddressOptions={filteredEmailAddressOptions}
      handleSubmitFn={handleSubmit}
      status={status}
      error={error}
    />
  );
}

export default AddCollaboratorModalContainer;
